import { OrderDetailsType } from './order-details.type';

const INITIAL_STATE = {
    //default: errorMessage:''
    orderId:""

};

const ordeDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
     

        case OrderDetailsType.setOrderId:
            return {
                    ...state,
                orderId: action.payload,
            };
           
       
        case OrderDetailsType.RESET:
            return INITIAL_STATE;
                                                           
        default:
            return state;
    }
};

export default ordeDetailsReducer; 