import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import WalletService from '../spar-service-impl/walletService';
import TransactionService from '../spar-service-impl/transactionService';
import ApiCallingService from '../api-call-service';
import { setToken, isAuthenticated } from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin, setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID, setUserType, setLoginUserName, setUserLastLoginTime } from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { orderErrorMessage, showErrorMessageSection } from '../../redux/error-message/eror-message.action';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { setOrderId } from '../../redux/order-details/order-details.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { setAmount } from "../../redux/misc-data/misc-data.action";
import { setPhone } from "../../redux/misc-data/misc-data.action";
import { setTransactionId } from "../../redux/misc-data/misc-data.action";
import { setTransactionTimeStamp } from "../../redux/misc-data/misc-data.action";
import transactionAddService from './transactionAddService';
import deviceDetails from './deviceDetails';
import { connect } from 'react-redux';
import { orderTrnasferMessage } from './../../redux/error-message/eror-message.action';
import swal from 'sweetalert';
class SparService {

  apiCallForBoNusClub(url, requestTosend, headerToCall, routePage, phoneNo,
    otpVal, otpRefNo) {
    console.log('inside SparService ', url);
    console.log(routePage.callBackMethod.toLowerCase());
    //store.dispatch(setLoggedInUserID(username));

    var response = [];

    async function run() {

      response = await ApiCallingService.orderServiceCall(url, requestTosend, routePage);

      console.log("__createorder response__", JSON.stringify(response));
      if (response === 'error' || response == undefined) {
        console.log(response);

        var errorMessage = 'Somthing went wrong we are looking at our End';
        store.dispatch(orderErrorMessage(errorMessage));

      } else if (response != undefined && response.status == "200") {

        console.log('res_code', response.responseCode);


        if (parseInt(response.responseCode) == 0) {

          console.log('res_code in orderservice', response.responseCode);
          store.dispatch(orderTrnasferMessage("You will be redirected to the transaction screen."));
          setTimeout(() => {
            store.dispatch(showDashBoardComponentSection('trasactionHistory'));
          }, 5000);

          console.log("createOrder_response for 0", response);
          var errorMessage = '';
          store.dispatch(showErrorMessageSection(errorMessage));
          store.dispatch(orderErrorMessage(errorMessage));
          console.log('order id.........................', JSON.stringify(response.response.orderId));
          store.dispatch(setOrderId(JSON.stringify(response.response.orderId)));

          const requestHeaderCreateOrder = {
            requestType: "CREATE_ORDER",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: phoneNo,
            originalDialogueTraceId: null,
            walletOwner: "SPAR",
            channel: "WEB"
          }

          const notificationInfo = {
            itemType: 'Add Money',
            itemDescription: 'Spar Cash Wallet',
            txnId: response.response.txnId,
            firstname: response.response.firstName,
            lastName: response.response.lastName,
            email: response.response.email,
            phone: phoneNo,
            surl: response.response.surl,
            amount: response.response.amount,
            productinfo: response.response.productInfo,
            furl: response.response.fuUrl,
            hash: response.response.hash,
            orderId: response.response.orderId,
            randomNumber: response.response.randomNumber
          }


          const creditInfoDetails = {
            amount: response.response.amount,
            orderId: response.response.orderId,
            transactionId: response.response.txnId,
            phone: phoneNo
          }


          const walletRequest = {
            requestHeader: requestHeaderCreateOrder,
            notificationInfo: notificationInfo,
            deviceInfo: requestTosend.deviceInfo
          }

          url = "/api/v1/pg/notification";

          console.log('requestTosend data ', JSON.stringify(requestTosend));

          const walletPageesponse =
            await ApiCallingService.postMethodCallforPaymentForBonusClub(url, walletRequest, '', phoneNo);
          var sBrowser, sUsrAg = navigator.userAgent;
          if(sUsrAg!=undefined && sUsrAg!=null){
            if (sUsrAg.indexOf("Firefox") > -1) {
              sBrowser = "Mozilla Firefox";
              // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
              sBrowser = "Samsung Internet";
              // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
            } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
              sBrowser = "Opera";
              // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
            } else if (sUsrAg.indexOf("Trident") > -1) {
              sBrowser = "Microsoft Internet Explorer";
              // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (sUsrAg.indexOf("Edge") > -1) {
              sBrowser = "Microsoft Edge";
              // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (sUsrAg.indexOf("Chrome") > -1) {
              sBrowser = "Google Chrome or Chromium";
              // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (sUsrAg.indexOf("Safari") > -1) {
              sBrowser = "Apple Safari";
              // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
              sBrowser = "unknown";
            }
          }else{
            sBrowser="unknown";
          }
         

          if (sBrowser == "Mozilla Firefox") {
            swal({
              title: "Window is redirecting to payment gateway",
              text: "After transaction please re-login and check wallet.",
              icon: "success",
              button: true,
              dangerMode: false,
            }).then(() => {
              var res=walletPageesponse.response;
              res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
              
              window.document.write(res);
              var newwindow= window.open("");
            });
          } else {
            var newWindow = window.open("https://api.payu.in/");
            window.alert = null;
            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
              swal({
                title: "Window is redirecting to payment gateway",
                text: "After transaction please re-login and check wallet.",
                icon: "success",
                button: true,
                dangerMode: false,
              }).then(() => {
                var res=walletPageesponse.response;
                res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
                window.document.write(res);
              });

            } else {
              console.log('window response', walletPageesponse);
              var res=walletPageesponse.response;
              res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
              newWindow.document.write(res);
              setInterval(() => {

              }, 1000);

              console.log('response html', response);


              const checkOrderIdCompletedInWallet = '/api/v1/pg/checkWalletUpdate';


              //-------------orderservice new code------
              const requestToOrderIdCompletinCheck = {
                requestHeader: requestHeaderCreateOrder,
                orderId: response.response.orderId,
                txnId: response.response.txnId,
                phoneNo: phoneNo,
                randomNumber: response.response.randomNumber,
                status: "2",
                deviceInfo: requestTosend.deviceInfo,
                amount: response.response.amount
              }


              var counter = 0;
              var timeOut = 900000;
              var timeOutInterval = 5000;

              var interval = setInterval(function () {
                counter++;
                console.log('counter....' + counter);

                if (counter >= timeOut / timeOutInterval) {
                  clearInterval(interval);


                  return;
                } else {

                  var response = ApiCallingService.checkCallForWallet(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck);

                  response.then((res) => {
                    if (res.responseCode == 4) {
                      WalletService.checkWalletForBonusClub(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck, creditInfoDetails
                        , otpVal, otpRefNo);
                      clearInterval(interval);
                    }
                    if (res.responseCode == 3 || res.responseCode == 5 || res.responseCode == 6 || res.responseCode == 7) {
                      console.log('order is failed');
                      WalletService.checkWalletFailed(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck, creditInfoDetails
                        , otpVal, otpRefNo);
                      if (res.responseCode == 3 | res.responseCode == 5) {
                        store.dispatch(showDashBoardComponentSection('orderfailed'));

                      }
                      if (res.responseCode == 6) {
                        store.dispatch(showDashBoardComponentSection('orderfailedWithRefundSuceesFul'));
                      }

                      if (res.responseCode == 7) {
                        store.dispatch(showDashBoardComponentSection('orderfailedWithRefundFailed'));
                      }
                      clearInterval(interval);
                    }


                    return res.data;
                  }).catch(error => {
                    var errorData = '';
                    console.log('error', error);
                    errorData = error.data;
                    return errorData;
                  })
                }
              }
                , 5000);

            }
          }



        }
        else if (response == undefined) {
          console.log("response_login_", response);
          var errorMessage = 'Somthing went wrong we are looking at our End';
          store.dispatch(showErrorMessageSection(errorMessage));
          store.dispatch(orderErrorMessage(errorMessage));

        } else {



        }

      }

    }
    run();

  }


  apiCall(url, requestTosend, headerToCall, routePage, phoneNo,
    otpVal, otpRefNo) {
    console.log('inside SparService ', url);
    console.log(routePage.callBackMethod.toLowerCase());
    //store.dispatch(setLoggedInUserID(username));

    var response = [];

    async function run() {

      response = await ApiCallingService.orderServiceCall(url, requestTosend, routePage);

      console.log("__createorder response__", JSON.stringify(response));
      if (response === 'error' || response == undefined) {
        console.log(response);
        var errorMessage = 'Somthing went wrong we are looking at our End';
        store.dispatch(orderErrorMessage(errorMessage));
      }

      else if (response != undefined && response.status == "200") {
        console.log('res_code in orderservice', response.responseCode);
        store.dispatch(orderTrnasferMessage("You will be redirected to the transaction screen."));
        setTimeout(() => {
          store.dispatch(showDashBoardComponentSection('trasactionHistory'));
        }, 5000);
        if (parseInt(response.responseCode) == 0) {
          console.log("createOrder_response for 0", response);

          var errorMessage = '';
          store.dispatch(orderErrorMessage(errorMessage));
          store.dispatch(orderErrorMessage(errorMessage));
          console.log('order id.........................', JSON.stringify(response.response.orderId));
          store.dispatch(setOrderId(JSON.stringify(response.response.orderId)));

          const requestHeaderCreateOrder = {
            requestType: "CREATE_ORDER",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: phoneNo,
            originalDialogueTraceId: null,
            walletOwner: "SPAR",
            channel: "WEB"
          }



          const createTransactionRecord = {
            itemType: 'Add Money',
            itemDescription: 'Spar Cash Wallet',
            timestamp: '',
            orderId: response.response.orderId,
            transactionAmount: response.response.amount,
            transactionStatusMessage: '',
            getTransactionStatusCode: '',
            phoneNo: phoneNo
          }

          const notificationInfo = {
            itemType: 'Add Money',
            itemDescription: 'Spar Cash Wallet',
            txnId: response.response.txnId,
            firstname: response.response.firstName,
            lastName: response.response.lastName,
            email: response.response.email,
            phone: phoneNo,
            surl: response.response.surl,
            amount: response.response.amount,
            productinfo: response.response.productInfo,
            furl: response.response.fuUrl,
            hash: response.response.hash,
            orderId: response.response.orderId,
            randomNumber: response.response.randomNumber
          }

          //store save data
          //  store.setAmount(response.response.amount);
          //  store.setOrderId(response.response.orderId);
          //  store.setTransactionId(response.response.txnId);
          //  store.setPhone(response.response.phoneNo);

          const creditInfoDetails = {
            amount: response.response.amount,
            orderId: response.response.orderId,
            transactionId: response.response.txnId,
            phone: phoneNo
          }

          const transactionAddRequest = {
            requestHeader: requestHeaderCreateOrder,
            createTransactionRecord: createTransactionRecord,
            deviceInfo: requestTosend.deviceInfo
          }

          const walletRequest = {
            requestHeader: requestHeaderCreateOrder,
            notificationInfo: notificationInfo,
            deviceInfo: requestTosend.deviceInfo
          }



          url = "/api/v1/pg/notification";

          console.log('requestTosend data ', JSON.stringify(walletRequest));

          const walletPageesponse = await ApiCallingService.postMethodCallforPayment(url,
            walletRequest,
            '', phoneNo);


          var sBrowser, sUsrAg = navigator.userAgent;

          if(sUsrAg!=undefined && sUsrAg!=null){
            if (sUsrAg.indexOf("Firefox") > -1) {
              sBrowser = "Mozilla Firefox";
              // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
              sBrowser = "Samsung Internet";
              // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
            } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
              sBrowser = "Opera";
              // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
            } else if (sUsrAg.indexOf("Trident") > -1) {
              sBrowser = "Microsoft Internet Explorer";
              // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (sUsrAg.indexOf("Edge") > -1) {
              sBrowser = "Microsoft Edge";
              // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (sUsrAg.indexOf("Chrome") > -1) {
              sBrowser = "Google Chrome or Chromium";
              // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (sUsrAg.indexOf("Safari") > -1) {
              sBrowser = "Apple Safari";
              // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
              sBrowser = "unknown";
            }
          }else{
            sBrowser="unknown";
          }
          if (sBrowser == "Mozilla Firefox") {
            swal({
              title: "Window is redirecting to payment gateway",
              text: "After transaction please re-login and check wallet.",
              icon: "success",
              button: true,
              dangerMode: false,
            }).then(() => {
              var res=walletPageesponse.response;
              res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
              
              // alert(res)
              window.document.write(res);
            });
          } else {

            console.log('window response', walletPageesponse);

            var newWindow = window.open("https://api.payu.in/");
            window.alert = null;


            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

              swal({
                title: "Window is redirecting to payment gateway",
                text: "After transaction please re-login and check wallet.",
                icon: "success",
                button: true,
                dangerMode: false,
              }).then(() => {
                var res=walletPageesponse.response;
                res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
                window.document.write(res);
              });

            } else {
              newWindow.focus();
              var res=walletPageesponse.response;
              res=res.replace("<%@ taglib prefix=spring uri=http://www.springframework.org/tags%><%@ taglib prefix=c uri=http://java.sun.com/jsp/jstl/core%>","");
              newWindow.document.write(res);
              var resposne = '';
              const checkOrderIdCompletedInWallet = '/api/v1/pg/checkWalletUpdate';
              //-------------orderservice new code------
              const requestToOrderIdCompletinCheck = {
                requestHeader: requestHeaderCreateOrder,
                orderId: response.response.orderId,
                txnId: response.response.txnId,
                phoneNo: phoneNo,
                randomNumber: response.response.randomNumber,
                status: "2",
                deviceInfo: requestTosend.deviceInfo,
                amount: response.response.amount,
              }



              // startTime=(new Date().getTime());
              var counter = 0;
              var timeoutSeconds = 900000;
              var intervalSeconds = 5000;

              var interval = setInterval(function () {
                counter++;
                console.log("Counter is: " + counter);


                if (counter >= timeoutSeconds / intervalSeconds) {
                  console.log('max timeout reached');
                  clearInterval(interval);



                  return;
                } else {



                  var response = ApiCallingService.checkCallForWallet(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck);

                  response.then((res) => {
                    if (res.responseCode == 4) {
                      WalletService.checkWallet(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck, creditInfoDetails
                        , otpVal, otpRefNo);
                      clearInterval(interval);

                    }
                    if (res.responseCode == 3 || res.responseCode == 5 || res.responseCode == 6 || res.responseCode == 7) {
                      console.log('order is failed');
                      WalletService.checkWalletFailed(checkOrderIdCompletedInWallet, requestToOrderIdCompletinCheck, creditInfoDetails
                        , otpVal, otpRefNo);
                      if (res.responseCode == 3 | res.responseCode == 5) {
                        store.dispatch(showDashBoardComponentSection('orderfailed'));

                      }
                      if (res.responseCode == 6) {
                        store.dispatch(showDashBoardComponentSection('orderfailedWithRefundSuceesFul'));
                      }

                      if (res.responseCode == 7) {
                        store.dispatch(showDashBoardComponentSection('orderfailedWithRefundFailed'));
                      }
                      clearInterval(interval);
                    }

                    return res.data;
                  }).catch(error => {
                    var errorData = '';
                    console.log('error', error);
                    errorData = error.data;
                    return errorData;
                  })
                }

              }
                , intervalSeconds);
            }

          }









        }
        else if (response == undefined) {
          console.log("response_login_", response);
          var errorMessage = 'Somthing went wrong we are looking at our End';
          store.dispatch(showErrorMessageSection(errorMessage));
          store.dispatch(orderErrorMessage(errorMessage));
        }


      }

    }
    run();

  }

}

const mapStateToProps = ({ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
  responseReduce: { responseDetailsVal, dashBoardResponseDetails,
    userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse, fetchTransactionResponse
    , expiringFirstResponse },
  leadingOverLay: { blockScreenConfirm },
  ordeDetailsReduce: { orderId },

  miscReduce: { phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
    , deviceInfo, myCouponDetails
    , completeOrderId } }
) => ({
  viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse,
  userProfileResponse, orderServiceResponse, fetchTransactionResponse,
  expiringFirstResponse,
  blockScreenConfirm, orderId, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints,
  deviceInfo, myCouponDetails,
  completeOrderId
});

// export default connect(mapStateToProps, null)(SparService);


export default new SparService(connect(mapStateToProps, null));
