import { RoleActionTypes } from './role.types';

const INITIAL_STATE = {
  roleData: null
};

const roleReducer = (state = INITIAL_STATE, action) => {
 // console.log("saurabh_action" + JSON.stringify(action.type) + " ==== "+RoleActionTypes.SET_ROLE_DATA + " RoleData =" + JSON.stringify(action.payload));
  switch (action.type) {
    
    case RoleActionTypes.SET_ROLE_DATA:
      console.log("hi saurabh = "+ JSON.stringify(action.payload) );
      return {
        ...state, 
        roleData: action.payload
        
      };
      case RoleActionTypes.RESET:
        return INITIAL_STATE;
       
    default:
      return state;
  }
};

export default roleReducer;
