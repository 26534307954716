import React from "react";
import history from '../../auth/history';
import {store} from "../../redux/store";
//import { ReCaptcha } from 'react-recaptcha-google'
//import { loadReCaptcha } from 'react-recaptcha-google'
import AuthService from "../../service/retailer-service-impl/AuthService";

import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    
  } from 'react-share';
  import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    EmailShareButton,
  } from 'react-share';

  import { FacebookIcon } from "react-share";
  import { LinkedinIcon } from "react-share";
  import { WhatsappIcon } from "react-share";
  import { EmailIcon } from "react-share";
  import { TwitterIcon } from "react-share";


class TransactionFailureComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
            shareButtonActive:false,
			shareUrl:"https://www.wallet.sparindia.com"
        }
    }
     componentDidMount() {
	 	
    }

    print= (event) => {
		window.print();
	  }

	  share=(event)=>{
		console.log('shere clicked');
		if(this.state.shareButtonActive==false){
		this.setState({
			shareButtonActive:true
		})}else{
			this.setState({
				shareButtonActive:false
			})
		}
		
		}
      render(){

        console.log("---in transaction details---");
        console.log(this.props.response.txnType);

    
          return(
            <>
                                        {this.props.response.txnType=="CREDIT_WALLET" &&
                                            <div class="txn-item">
													<div class="txn-head" id={this.props.response.id}>
													<div class="collapsed" data-bs-toggle="collapse" data-bs-target={"#txn"+this.props.response.id} 
																			 aria-expanded="false" aria-controls={"txn"+this.props.response.id}>
                                                            <div class="txncard cdt">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="txntype">
                                                                            <i class="credit"></i>
                                                          <h4>{this.props.response.txnType=="CREDIT_WALLET"?'Add Money':''}</h4>
                                                                                                                     
                                                            {this.props.response.subWalletTxnIds.ECASH!=null &&
                                                            <p>Spar E-Cash</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.GIFT_CARD!=null &&
                                                            <p>Spar Gift Cards</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.CASHBACK!=null &&
                                                            <p>Spar My Cashback</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.BONUS_CASH!=null &&
                                                            <p>Spar Bonus</p>
                                                            }

                                                            {this.props.response.subWalletTxnIds.CREDIT_NOTE!=null &&
                                                            <p>Spar Credit Note</p>
                                                            }

                                                            </div>
                                                            </div>
                                                            <div class="col-7 col-md-4">
                                                            <div class="txnid">										
                                                                <h4>xxxx-{this.props.response.id.slice(30,36)}</h4>
                                                                <p>{this.props.response.createdOn}</p>
                                                            </div>
                                                          </div>
															<div class="col-5 col-md-3">
                                                            <div class="txnamt">									
                                                                <h4>₹ {this.props.response.appliedAmount}</h4>
                                                                <h6 class="failed">Failed</h6>
                                                            </div>
                                                         </div>
                                                            </div>
                                                            </div>
                                                            </div>
															</div>
                                                           
                                                            <div id={"txn"+this.props.response.id} 
                                                            class="accordion-collapse collapse" 
                                                            aria-labelledby={this.props.response.id} data-bs-parent="#txnlist">
                                                           

                                                           
                                                            
                                                   

															<div class="subtxnshow">
                                                            {
                                                            this.props.response.subWalletTxnIds.BONUS_CASH!=null &&
                                                           <div>
                                                             <div class="txndtls scs">
                                                              <div class="row">
                                                                   
                                                             <div class="col-7 col-md-5">
                                                               <div class="subwlt">Mpney Added To Wallet</div>
                                                             </div>
                                                           
                                                            <div class="col-5 col-md-7">
                                                               <div class="subamnt">₹ {parseFloat(this.props.response.requestedAmount).toFixed(2)}  
                                                               <span class="substs">Failed</span></div>
                                                              </div>										
                                                          </div>
                                                          </div>
                                                          

                                                          <div class="txndtls scs">
                                                            <div class="row">
                                                                   
                                                            <div class="col-7 col-md-5">
                                                                <div class="subwlt">Cashback Received From Spar</div>
                                                            </div>
                                                            
                                                            <div class="col-5 col-md-7">
                                                                <div class="subamnt">₹ {parseFloat(parseFloat(this.props.response.appliedAmount)-parseFloat(this.props.response.requestedAmount)).toFixed(2)}  
                                                                <span class="substs">Failed</span></div>
                                                            </div>										
                                                             </div>
                                                             </div>
															 </div>
                                                             
                                                            
                                                            }
															
															   <div class="actnbtn">
                                                                    <button type="button" class="print">Print</button>
                                                                    <button type="button" class="share" onClick={this.share}>share</button>
                                                                    <button type="button" class="help">Support</button>
                                                                </div>

                                                                {this.state.shareButtonActive==true && 
                                                                <div class="txndtls scs">
                                                                <div class="row">
                                                                <div>
                                                                <FacebookShareButton url={this.state.shareUrl}>
        
                                                                <FacebookShareCount url={this.state.shareUrl}>
                                                                {shareCount => (
                                                                    <span className="myShareCountWrapper">{shareCount}</span>
                                                                )}
                                                                </FacebookShareCount> 
                                                                <FacebookIcon ></FacebookIcon>
                                                                </FacebookShareButton>
                                                                
                                                                 <WhatsappShareButton url={this.state.shareUrl}>
                                                                 <WhatsappIcon ></WhatsappIcon>
                                                                 </WhatsappShareButton>

                                                                 <EmailShareButton url={this.state.shareUrl}
                                                                  subject="wallet sparindia share via mail" >
                                                                 <EmailIcon ></EmailIcon>
                                                                 </EmailShareButton>
                                                                </div>
                                                                </div>
                                                                </div>
                                                                }
															

														</div>
                                                        </div>
                                                        
                                                       
                                                        </div>   

                                                        
                                                          
                                                       
                                                            }
                                                       
                                                


                                                    {this.props.response.txnType=="DEBIT_WALLET" &&
                                                    <div class="txn-item">
                                                        <div class="txn-head" id={this.props.response.id}>
                                                             <div class="collapsed" data-bs-toggle="collapse" data-bs-target={"#txn"+this.props.response.id} 
                                                             aria-expanded="false" aria-controls={"txn"+this.props.response.id}>
                                                            <div class="txncard dbt">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="txntype">
                                                                            <i class="debit"></i>
                                                         <h4>{this.props.response.txnType=="DEBIT_WALLET"?'Debit Money':''}</h4>
                                                            <p>{this.props.response.txnType=="DEBIT_WALLET"?'Spar Wallet':''}</p>
                                                            </div>
                                                            </div>
                                                            <div class="col-7 col-md-4">
                                                            <div class="txnid">										
                                                                <h4>xxxx-{this.props.response.id.slice(30,36)}</h4>
                                                                <p>{this.props.response.createdOn}</p>
                                                            </div>
                                                            </div>
                                                             <div class="col-5 col-md-3">
                                                            <div class="txnamt">									
                                                                <h4>₹ {this.props.response.appliedAmount}</h4>
                                                                <h6 class="failed">Failed</h6>
                                                            </div>
                                                         </div>
                                                            </div>
                                                            </div>   
                                                            </div> 
                                                            </div>
                                                            {this.props.response.appliedDetails!=null  && 
                                                            <div id={"txn"+this.props.response.id} class="accordion-collapse collapse" aria-labelledby={this.props.response.id} data-bs-parent="#txnlist">
                                                            <div class="subtxnshow">

                                                            
                                                            
                                                            {parseInt(this.props.response.appliedDetails.creditNoteAmount)>0 &&
                                                            <div class="txndtls scs">
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Credit Note</div>
                                                                        </div>
                                                                  
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.creditNoteAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    }
                                                                     {parseInt(this.props.response.appliedDetails.bonusCashAmount)>0 &&
                                                                     <div class="txndtls scs">
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Bonus</div>
                                                                        </div>
                                                                  
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹  {parseFloat(this.props.response.appliedDetails.bonusCashAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                    </div>
                                                                    </div>
                                                                    }
                                                                     {parseInt(this.props.response.appliedDetails.giftCardAmount)>0 &&
                                                                      <div class="txndtls scs">
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Gift Card</div>
                                                                        </div>
                                                                  
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹  {parseFloat(this.props.response.appliedDetails.giftCardAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                    </div>
                                                                    </div>
                                                                    }
                                                                     {parseInt(this.props.response.appliedDetails.cashbackAmount)>0 &&
                                                                      <div class="txndtls scs">
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar My Cashback</div>
                                                                        </div>
                                                                  
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹  {parseFloat(this.props.response.appliedDetails.cashbackAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                    </div>
                                                                    </div>
                                                                    }
                                                                    {parseInt(this.props.response.appliedDetails.ecashAmount)>0 &&
                                                                     <div class="txndtls scs">
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar E-Cash</div>
                                                                        </div>
                                                                  
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.ecashAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                    </div>
                                                                    </div>
                                                                    }
                                                                   
                                                                    <div class="actnbtn">
                                                                    <button type="button" class="print">Print</button>
                                                                    <button type="button" class="share">share</button>
                                                                    <button type="button" class="help">Support</button>
                                                                </div>
                                                            </div> 

                                                            </div>
                                                          
                                                            
                                                            }
                                                        </div> 
                                                        
                                                            
                                                         }


                                                    {this.props.response.txnType=="LINK_GIFTCARD" &&
                                                                              <div class="txn-item">
                                                                              <div class="txn-head" id={this.props.response.id}>
                                                                                   <div class="collapsed" data-bs-toggle="collapse" data-bs-target={"#txn"+this.props.response.id} 
                                                                                   aria-expanded="false" aria-controls={"txn"+this.props.response.id}>                          
                                                            <div class="txncard cdt">
                                                                <div class="row">
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="txntype">
                                                                            <i class="credit"></i>
                                                            <h4>{this.props.response.txnType=="LINK_GIFTCARD"?'Add Gift Card':''}</h4>
                                                            {this.props.response.subWalletTxnIds.ECASH!=null &&
                                                            <p>Spar E-Cash</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.GIFT_CARD!=null &&
                                                            <p>Spar Gift Cards</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.CASHBACK!=null &&
                                                            <p>Spar My Cashback</p>
                                                            }
                                                            {this.props.response.subWalletTxnIds.BONUS_CASH!=null &&
                                                            <p>Spar Bonus</p>
                                                            }
                                                            </div>
                                                            </div>
                                                            <div class="col-7 col-md-4">
                                                            <div class="txnid">										
                                                            <h4>xxxx-{this.props.response.id.slice(30,36)}</h4>
                                                                <p>{this.props.response.createdOn}</p>
                                                            </div>
                                                            </div>
                                                            

                                                        <div class="col-5 col-md-3">
                                                            <div class="txnamt">									
                                                                <h4>₹ {this.props.response.appliedAmount}</h4>
                                                                <h6 class="failed">Failed</h6>
                                                            </div>
                                                         </div>
                                                            </div>
                                                            </div>       
                                                        </div> 
                                                        </div>

                                                     
                                                            <div id={"txn"+this.props.response.id} class="accordion-collapse collapse" 
                                                            aria-labelledby={this.props.response.id} data-bs-parent="#txnlist">
                                                          
                                                            <div class="subtxnshow">
                                                            {this.props.response.appliedDetails!=null  && 
                                                            <div class="txndtls scs">
                                                                 {parseInt(this.props.response.appliedDetails.creditNoteAmount)>0 &&
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Credit Note</div>
                                                                        </div>
                                                                        
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.creditNoteAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                     </div>
                                                                    }
                                                                     {parseInt(this.props.response.appliedDetails.bonusCashAmount)>0 &&
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Bonus</div>
                                                                        </div>
                                                                        
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.bonusCashAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                     </div>
                                                                    }
                                                                     {parseInt(this.props.response.appliedDetails.giftCardAmount)>0 &&
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar Gift Card</div>
                                                                        </div>
                                                                        
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.giftCardAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                     </div>
                                                                    }

                                                                    {parseInt(this.props.response.appliedDetails.cashbackAmount)>0 &&
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar My Cashback</div>
                                                                        </div>
                                                                        
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.cashbackAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                     </div>
                                                                    }


                                                                {parseInt(this.props.response.appliedDetails.ecashAmount)>0 &&
                                                                    <div class="row">
                                                                   
                                                                        <div class="col-7 col-md-5">
                                                                            <div class="subwlt">Spar E-Cash</div>
                                                                        </div>
                                                                        
                                                                        <div class="col-5 col-md-7">
                                                                            <div class="subamnt">₹ {parseFloat(this.props.response.appliedDetails.ecashAmount).toFixed(2)}  
                                                                            <span class="substs">Failed</span></div>
                                                                        </div>										
                                                                     </div>
                                                                    }
                                                                    </div>
                                                            
                                                           
                                                            }
                                                             <div class="actnbtn">
                                                            <button type="button" class="print">Print</button>
                                                            <button type="button" class="share">share</button>
                                                            <button type="button" class="help">Support</button>
                                                                </div>
                                                            </div>
                                               
                                                            </div>

                                                        </div> 
                                                      
                                                       
                                                         }

                                                         
          </>
          );
    } 
}
export default TransactionFailureComponent;