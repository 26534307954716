import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import {store} from '../../redux/store';
import {showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
 
import { default as ReactSelect } from "react-select";
import Pagination from "react-js-pagination";

import { components } from "react-select";

const Option = (props) => {
	return (
	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };
class expiringDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
			activePage: 1,
			zResponse:[],
			tResponse:this.props.expiringFirstResponse==[]?[]:this.props.expiringFirstResponse.slice(0,9),
			sResposne:[],
			uResponse:this.props.expiringFirstResponse,
			type:[],
			typeList:[],
				value:'select',
			formDate:'',
			toDate:'',
			message:'',
			
			typeOptions : [
				{ value: "Bonus Club", label: "Bonus Club" },
				{ value: "Cashback", label: "Cashback" },
				{ value: "Coupon", label: "Coupon" },
				{ value: "Credit Note", label: "Gift Card" },
			 ],
			 
			  optionSelected: null,
			  statusList:[],
			  responseTypeVal:'',
			  typeResponse:[],
			  formDateResponse:[],
			  toDateResponse:[]
        }
    }
     componentDidMount() {
	 	
    }
	
	
	  closeModal= (event) => {		
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		}
		
		
		handleChange = (selected) => {
	
				this.state.type=[];
				this.setState({
					typeList:selected
				})
				if(selected.length>0){
				selected.map(response => { 
				this.state.type.push(response.value);
				})}

				console.log(this.state.type);
			this.handleTypeStatus();
				
			};
			
		handleFormDate(){

			this.state.formDateResponse=[];
			this.props.expiringFirstResponse.map(response => { 
				
				
				const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
				
			
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
				
				
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
			
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
				
				
	

				if(date1.getTime()>date2.getTime()){
				this.state.formDateResponse.push(response);
				}
					
			})

			if(this.state.formDateResponse.length>0){
				this.state.formDateResponse.map(response => {  
					if(!this.state.uResponse.includes(response)){
						this.state.uResponse.push(response);
				}	 			
			})
			}
			
			this.commonFilter();
		}

			handleToDate(){
			this.state.toDateResponse=[];
			this.props.expiringFirstResponse.map(response => { 
				const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
		
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
			
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				console.log(date1.getTime());
				console.log(date2.getTime());
    			
				if(date1.getTime()<date2.getTime()){
				this.state.toDateResponse.push(response);
				}	 			
			})

			if(this.state.toDateResponse.length>0){
				this.state.toDateResponse.map(response => {  
					if(!this.state.uResponse.includes(response)){
						this.state.uResponse.push(response);
				}	 			
			})
			}
			console.log(this.state.uResponse.length);
			this.commonFilter();
		}		
			
		
		
		
	   handleTypeStatus(){
		this.state.typeResponse=[];

		this.props.expiringFirstResponse.map(response => { 
			//console.log('All Response ',response);
			
			
			if(response.bonusCashType!=null){
                 this.state.responseTypeVal='Bonus Club';
			}else if(response.campaignId!=null){
				this.state.responseTypeVal='Cashback';
		    }
			
			
			
			console.log('responseTypeVal',this.state.responseTypeVal);

			//console.log("txnType::::",response.txnType)
			
			if(this.state.type.includes(this.state.responseTypeVal)){
			console.log('matched');
			this.state.typeResponse.push(response);
			}	 			
		}
		)

		if(this.state.typeResponse.length>0){
			this.state.typeResponse.map(response => {  
				if(!this.state.uResponse.includes(response)){
					this.state.uResponse.push(response);
			}	 			
		})
		}
	
		this.commonFilter();	
		}
		
		
		
				commonFilter(){
					
	
				this.state.zResponse=[];
				var x=[];
				var y=[];


		     


				this.state.uResponse.map(response => {
					
					if(this.state.type.length>0){
					if(response.bonusCashType!=null){
					this.state.responseTypeVal='Bonus Club';
					}else if(response.campaignId!=null){
					this.state.responseTypeVal='Cashback';
					}
					
					//codes needs to be added
					
				   console.log('responseTypeVal',this.state.responseTypeVal);
	   
			
				   
				   if(!this.state.type.includes(this.state.responseTypeVal)){
					if(!y.includes(response)){	
						y.push(response);
					}
				   }	 
				 
				
				}
				});


			
				this.state.uResponse.map(
					response => { 
				if(this.state.formDate!=''){
					
		        const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
					
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
			
				if(date1.getTime()<date2.getTime()){
				 
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate greater::",date1.getTime());
					console.log("uResponse formdate::",date2.getTime()); 
				}
				}
				}
				);
			    

                //TODATE
				this.state.uResponse.map(
					response => { 
				 if(this.state.toDate!=''){
				const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				if(date1.getTime()>date2.getTime()){
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate lesser::",date1.getTime());
					console.log("uResponse todate::",date2.getTime()); 
				}
				}
				}
			);
		
				
				 	
			console.log('y length::',y.length);
			console.log('uResponse length::',this.state.uResponse.length);
            this.state.uResponse.map(response =>{
             if(!y.includes(response)){
				console.log('response remains',response);
				x.push(response);
			 }
			});
			console.log('x.length c:',x.length);
			    if(x.length>0){
				x.map(response=>{
				this.state.zResponse.push(response);
				});
				this.setState({
				message:'',
				sResposne:this.state.zResponse.slice(0,9)
				}
				)
			   }else{
				this.setState({
					zResponse:[],
					message:'No record found',
					sResposne:[]
				})  
			   }
			
			
		
		
	}
		
	dateFromSelect= (event) => {
		this.state.formDate=moment(event.target.value).format("DD-MM-YYYY");
	
		this.handleFormDate();
		
	}

	dateToSelect= (event) => {
		this.state.toDate=moment(event.target.value).format("DD-MM-YYYY");
		this.handleToDate();
		
	}

	closeModal=(event)=>{
      store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	}
		
		handlePageChange(pageNumber) {
		console.log('Inside handlePageChange');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		//console.log(JSON.stringify(this.state.uResponse));
		
		this.setState({
			sResposne:this.state.zResponse.slice((pageNumber-1)*9,(pageNumber)*9)
		})
	   }

		handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	
		this.setState({
			tResponse:this.props.expiringFirstResponse.slice((pageNumber-1)*9,(pageNumber)*9)
		 })
	   }

	   clearAll= (event) => {
		//event.preventDefault();
		console.log('clearAll');
		this.setState({
			message:'',
			status:[],
			typeSelect:[],
			uResposne:[],
			zResponse:[],
			tResponse:this.props.expiringFirstResponse.slice(0,9),
			statusList:[],
			typeList:[],
			toDate:'',
			formDate:''
		})
	console.log('clearAllAfter');

	}
      render(){
    
          return(
           
			<div class="innercard">
					<div class="headtxt">
						<h3>Expiring Soon</h3>
						
						<button type="button" class="close" onClick={this.closeModal}>close</button>
						
						<div class="dropdown gcfltr">
							<button type="button" class="filter dropdown-toggle" id="fillist" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filter</button>
							
							<div class="dropdown-menu dropdown-menu-end" aria-labelledby="fillist">
	
								<form>
									<div class="filters">
										<label for="type">Type:</label>
										
										
											<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
											options={this.state.typeOptions}
											isMulti
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											components={{
												Option
											}}
											onChange={this.handleChange}
											allowSelectAll={true}
											value={this.state.typeList}
											/>
									</div>
									
									<div class="filters">
										<label for="select-state">From Date:</label>
										<input type="date"  name="" id="" onChange={this.dateFromSelect}/>
									</div>
									
									<div class="filters">
										<label for="select-state">To Date:</label>
										<input type="date"  name="" id="" onChange={this.dateToSelect}/>
									</div>

									<div class="fbtn">
									<button class="mediumbtn" type="reset" id="btnClear"
									 onClick={this.clearAll}
									>Clear all</button>
									</div>
								</form>
							</div>
						</div>
						</div>




						<div class="expiry-list">
						<div class="row">
						
					{
					(this.state.message=='' &&
					this.state.zResponse.length==0)?
					this.state.tResponse!=[] &&
				 		this.state.tResponse.map(response => (
							<div class="col-12 col-md-4">
                            <div class="expiry-box">
									<h5>{response.bonusCashType!=null?'SPAR Bonus Cash':
                                    response.campaignId!='null'?'SPAR My Cashback':response.giftcardNumber!=null?
                                    'SPAR Gift Card':'SPAR E-Cash'}</h5>
									<h4>₹ {parseFloat(response.balance).toFixed(2)}</h4>
                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)<=7 &&
                                	
									<p class="soon">Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==1?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }

                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)>7 &&
                                									
									<p>Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==1?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }
									<p class="date">{response.expiringOn.split(" ")[0]}</p>
							</div> 
                        </div>
							
						)
								
						)
						:		
						
						this.state.sResposne.map(response => (
							<div class="col-12 col-md-4">
                            <div class="expiry-box">
									<h5>{response.bonusCashType!=null?'SPAR Bonus Cash':
                                    response.campaignId!='null'?'SPAR My Cashback':response.giftcardNumber!=null?
                                    'SPAR Gift Card':'SPAR E-Cash'}</h5>
									<h4>₹ {parseFloat(response.balance).toFixed(2)}</h4>
                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)<=7 &&
                                									
									<p class="soon">Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==1?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }

                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)>7 &&
                                									
									<p>Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==1?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }
									<p class="date">{response.expiringOn.split(" ")[0]}</p>
							</div> 
                        </div>
							
								
								)
								)
								
						} 

						{
						this.state.message!='' &&
						<div>
						{this.state.message}
						</div>
	  					}

						  </div>
						  </div>

					
					
					
					
					<div class="pager">
						

					{
					(this.state.message=='') ?
					this.state.zResponse.length==0 ?
					(this.props.expiringFirstResponse.length>=9)?

					<div>
					<p>Showing {this.props.expiringFirstResponse.length} entries</p>
					<Pagination
					activePage={this.state.activePage}
					itemsCountPerPage={9}
					totalItemsCount={this.props.expiringFirstResponse.length}
					pageRangeDisplayed={5}
					onChange={this.handlePageChangeDefault.bind(this)}
					className="pagination"
					linkClass="page-link"
					itemClass="page-item"
					/>  
					</div>
					:
					<div>
					<p>Showing {this.props.expiringFirstResponse.length} entries</p>
					</div>
					:
					(this.state.zResponse.length>=9) &&
					<div>
					<p>Showing {this.state.zResponse.length} entries</p>
					<Pagination
          				activePage={this.state.activePage}
						itemsCountPerPage={9}
						totalItemsCount={this.state.zResponse.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>  :
						<div>
						</div>
					}     

					</div>
					</div>
					
                              


		
          );
    } 
}

const mapStateToProps = (
    { showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},responseReduce:
	{responseDetailsVal,dashBoardResponseDetails
	,expiringFirstResponse},
    leadingOverLay:{blockScreenConfirm},
    ordeDetailsReduce:{orderId}}
    ) => ({
        viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,
		expiringFirstResponse,
		blockScreenConfirm,orderId
});

const mapDispatchToProps = (dispatch) => {
    return {
        transactionDetails : (showDashBoardComponentSection) => {
           dispatch(showDashBoardComponentSection('transactionDetails'))
        }
    }
   }

export default connect(mapStateToProps, mapDispatchToProps)(expiringDetails);
