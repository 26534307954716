import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import moment from 'moment';


class GiftCardAddedDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0
        }
    }
	
    closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	  print= (event) => {
		window.print();
	  }

	
	render(){

	

return(
	<>

<div class="innercard">
			   <div class="head"><h3>Gift Card Details </h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button>
			  	</div>
      
					
					<div class="loadtls">
						<div class="row">
							
							<div class="col-12">
							<div class="txnfail">Oops! Transaction failed. Please try after some time!</div>
							</div>
						
							<div class="col-12">
								<div class="loadtxn">
									<h5><i class="gicon"></i> Gift Card</h5>
									<h4>Card Number <span>{this.props.cardNumber}</span></h4>
									<h4>Expiry <span>{this.props.giftCardBalnceEnquiry.expiringOn}</span></h4>
									<h6>Card Amount <span>₹ {this.props.giftCardBalnceEnquiry.balance}</span></h6>	
									<p>Order ID: #{this.props.giftCardLinkResponse.transactionId}</p> 
									 <p class="date">{moment(this.props.giftCardBalnceEnquiry.requestHeader.requesterTimestamp).format("MM-DD-YYYY HH:mm:ss")}</p>
									<p class="txnissue">See an issue? Report to us @ <a href="mailto:care@sparindia.com">care@sparindia.com</a>  or Talk to us at <a href="tel:18605009418">18605009418</a></p>
									<div class="actionbtn">
										<button type="button" id="print" class="print" onClick={this.print}>Print</button>
										<button type="button" id="share" class="share"
										>share</button>
									</div>
								</div>
							</div>
						</div>						
					</div>
					</div>
						
	
</>)

}
}


const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},miscReduce:{cardNumber,cardPin,phone,giftCardLinkResponse}},

	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,cardNumber,cardPin,phone,giftCardLinkResponse
});

export default connect(mapStateToProps, null)(GiftCardAddedDetails);


