import { MiscType } from './misc-data.type';
export const setOrderId = (data) => ({
  type: MiscType.setOrderId,
  payload: data
  
});
export const setPhone = (data) => ({
    type: MiscType.setPhone,
    payload: data
    
  });
  export const setAmount = (data) => ({
    type: MiscType.setAmount,
    payload: data
    
  }); 
  export const setTransactionId = (data) => ({
    type: MiscType.setTransactionId,
    payload: data
    
  }); 
  export const setTransactionTimeStamp = (data) => ({
    type: MiscType.setTransactionTimeStamp,
    payload: data
    
  });    
  export const setCardNumber = (data) => ({
    type: MiscType.setCardNumber,
    payload: data
    
  });  
  export const setCardPin = (data) => ({
    type: MiscType.setCardPin,
    payload: data
    
  }); 
  export const setQCAuthToken = (data) => ({
    type: MiscType.setQCAuthToken,
    payload: data
    
  });
  export const setGiftCardBalanceEnquiry = (data) => ({
    type: MiscType.setGiftCardBalanceEnquiry,
    payload: data
    
  });
  export const setGiftCardLinkResponse = (data) => ({
    type: MiscType.setGiftCardLinkResponse,
    payload: data
    
  });

  export const setGiftCardRedeemResponse = (data) => ({
    type: MiscType.setGiftCardRedeemResponse,
    payload: data
    
  });
  export const setOtp = (data) => ({
    type: MiscType.setOtp,
    payload: data
    
  });
  export const setOtpRefNo = (data) => ({
    type: MiscType.setOtpRefNo,
    payload: data
    
  });
  export const setOrderServiceResponse = (data) => ({
    type: MiscType.setOrderServiceResponse,
    payload: data
    
  });
  export const setMyCouponDetails = (data) => ({
    type: MiscType.setMyCouponDetails,
    payload: data
    
  });
  export const setCampaignRecords = (data) => ({
    type: MiscType.setCampaignRecords,
    payload: data
    
  });
  export const setMyPoints = (data) => ({
    type: MiscType.setMyPoints,
    payload: data
    
  });
  export const setUserFirstName = (data) => ({
    type: MiscType.setUserFirstName,
    payload: data
    
  });

  export const getCashBack = (data) => ({
    type: MiscType.getCashBack,
    payload: data
    
  });

  export const cashBackCampaign = (data) => ({
    type: MiscType.cashBackCampaign,
    payload: data
    
  });



  export const setDeviceInfo = (data) => ({
    type: MiscType.setDeviceInfo,
    payload: data
    
  });

  export const setUserRegisterData = (data) => ({
    type: MiscType.setUserRegisterData,
    payload: data
    
  });

  export const setCreditNoteData = (data) => ({
    type: MiscType.setCreditNoteData,
    payload: data
    
  });
  
  export const setCouponData = (data) => ({
    type: MiscType.setCouponData,
    payload: data
    
  });

  export const setCompleteOrderId = (data) => ({
    type: MiscType.setCompleteOrderId,
    payload: data
    
  });

  export const setTxnLoader = (data) => ({
    type: MiscType.setTxnLoader,
    payload: data
    
  });

  export const setUploadedFiles = (data) => ({
    type: MiscType.setUploadedFiles,
    payload: data
    
  });


  export const complaintIdValue = (data) => ({
    type: MiscType.complaintIdValue,
    payload: data
    
  });


  export const setGameId = (data) => ({
    type: MiscType.setGameId,
    payload: data
    
  });

  export const setGameofWheelReditect = (data) => ({
    type: MiscType.setGameofWheelReditect,
    payload: data
    
  });

  
  export const setLock = (data) => ({
    type: MiscType.setLock,
    payload: data
    
  });

  export const setPlayCount = (data) => ({
    type: MiscType.setPlayCount,
    payload: data
    
  });


  export const setTncGameOfwhhel = (data) => ({
    type: MiscType.setTncGameOfwhhel,
    payload: data
    
  });
  export const resetMiscData ={
    type: MiscType.RESET
  }

