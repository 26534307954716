import { LeadingOverlayType } from './leading-overlay.type';

export const showLeadingOverLay = (data) => ({
  type: LeadingOverlayType.blockScreen,
  payload: data
  
});

export const resetLeadingOverLay ={
  type: LeadingOverlayType.RESET
}