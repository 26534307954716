import React from "react";
import {store} from "../../redux/store";
import axios from "axios";

import Header from './header.component';
import LoadMoney from './loadmoney';

import Footer from './footer.component';
import SubFooter from './subFooter';
import { connect } from "react-redux";

import { setUploadedFiles } from "../../redux/misc-data/misc-data.action";

import ProfileSectionComponent from './profileSectionComponet';

import ExpringComponent from './exiringComponent';
import ExPiringDetails from './expiringSoonDetails';
import NotificationSection from "./notificationSection";

import Sidebar from './sidebar';
import SparCash from './sparCash';
import SparCashBackPlus from './sparCashBackPlusComponent';
import SparCashBackDetails from './sparCashBackDetailsComponent';
import MyCoupon from './myCouponsComponent';
import GiftCardAdd from './giftCardAdd';
import PurchaseGiftCard from './purchaseGiftCard';
import GiftCardOtp from './giftCardOtp';
import GiftCardAddedDetails from './giftcardAddedDetails';
import PaymentComponent from './paymentComponet';
import {showDashBoardComponentSection, showUserComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import TransactionHeader from "./transactionHeader";
import TransactionDetails from "./transactionDetails";
import CreditNote from "./creditNote";
import CreditNoteDetails from "./creditNoteDetails";
import BonusClub from "./bonusclubSection";
import CouponDetails from "./couponDetailsComponet";
import GiftCardReview from "./giftCardReview";
import LoadDetail from "./loaddetail";
import BlankExpiringComponemt from "./BlankExpiringComponent";
import BlankTransactionCompoment from "./BlankTransactionComponet";
import TransactionSuccessComponent from "./TransactionSuccessComponet";
import TransactionFailureComponent from "./TranscationFailureComponent";
import createHistory from 'history/createBrowserHistory';
import WalletService from "../../service/spar-service-impl/walletService";
import walletServiceImpl from "../../service/spar-service-impl/walletServiceImpl";
import getMyPointsService from "../../service/spar-service-impl/getMyPointsService";
import campaignService from "../../service/spar-service-impl/campaignService";
import transactionService from "../../service/spar-service-impl/transactionService";
import myCouponService from "../../service/spar-service-impl/myCouponService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import BonusClubSubSection from "./bonusClubSubSection";
import GiftCardFailedDetails from "./giftCardFailedDetails";
import { resetErrorMessage } from '../../redux/error-message/eror-message.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { resetLeadingOverLay } from '../../redux/leading-overlay/leading-overlay.action';
import { resetOrderId } from '../../redux/order-details/order-details.action';
import { resetResponse } from '../../redux/response/response.action';
import Orderfailed from "./orderFailed";
import OrderFailedWithRefundSuccessFul from "./orderFailedWithRefundSuccessFul";
import Complaints from "./complaints";
const httpClient = axios.create();

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;


class UserDashBoard extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
            amount:0
        }
    }

    callEvent = () => {

        localStorage.clear();
  
		store.dispatch(resetDivShowAndHide);
		store.dispatch(resetErrorMessage);
		store.dispatch(resetLeadingOverLay);
		store.dispatch(resetOrderId);
		store.dispatch(resetResponse);
    }

    componentDidMount = () => {
       
      console.log(store);
      const cHistory = createHistory();
      console.log('history:::',JSON.stringify(cHistory));
     
         
        if (cHistory.location.pathname="/user-dashboard") {
          console.log('history.location.pathname::',cHistory.location.pathname)
          store.dispatch(showDashBoardComponentSection('trasactionHistory'));

      }

       

        // window.addEventListener('beforeunload', function (e) {
        //     e.preventDefault();
        //     e.returnValue = '';
        //   });

        // window.addEventListener("beforeunload ", this.callEvent);
        const history = createHistory();
        console.log('history:::',JSON.stringify(history));
       
        if (history.location.pathname=="/user-dashboard") {
            console.log('history.location.pathname::',history.location.pathname);

      
       
     
        var walletUrl='/api/v1/wallet/details';

        const walletRequestHeader={
            requestType: "GET_WALLET_DETAILS",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: this.props.phone,
            originalDialogueTraceId:  uuidv4(),
            walletOwner: "SPAR",
            channel: "WEB"
        }



 

        const wallerServiceRequest={
            requestHeader:walletRequestHeader,
            
            deviceInfo:this.props.deviceInfo
            }
           
            WalletService.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
           
      
    
            const myCouponRequestHeader={
                  requestType: "GET_COUPONS",
                  requestId: uuidv4(),
                   requesterTimestamp: moment(),
                   mobileNumber:this.props.phone,
                   originalDialogueTraceId: null,
                   walletOwner: "SPAR",
                   channel: "WEB"
            }
        const couponRequest={
          requestHeader:myCouponRequestHeader,
          deviceInfo:this.props.deviceInfo
        }
    
        console.log('mycoupon request::',JSON.stringify(couponRequest));
    
        const myCouponUrl='/api/v1/landmark/rewards/mycoupons';
       
        myCouponService.fetchMyCoupons(myCouponUrl,couponRequest);
       
            //get My points service
    
            const getMyPointsRequestHeader={
                requestType: "GET_POINTS",
                requestId: uuidv4(),
                 requesterTimestamp: moment(),
                 mobileNumber:this.props.phone,
                 originalDialogueTraceId: null,
                 walletOwner: "SPAR",
                 channel: "WEB"
          }
    
          const getMyPointsRequest={
              requestHeader:getMyPointsRequestHeader
          }
          console.log('lmr points request::'+JSON.stringify(getMyPointsRequest));
          const myPointsUrl='/api/v1/landmark/customer/points';
         
          getMyPointsService.fetchMyPoints(myPointsUrl,getMyPointsRequest);
       
        // //fetch campaign
        const campaignUrl='/api/v1/subwallet/bonuscash/campaign/get/'+this.props.phone;
        // const campaignUrl='/api/v1/subwallet/bonuscash/campaign/get';
       
        campaignService.fetchCampaigns(campaignUrl,'');
     
        
        //new userTransactionData
          
    
           const transactionRequestHeader={
            requestType: "GET_TRANSACTION_HISTORY",
            requestId: uuidv4(),
             requesterTimestamp: moment(),
             mobileNumber:this.props.phone,
             originalDialogueTraceId: null,
             walletOwner: "SPAR",
             channel: "WEB"
           }
    
    
           const fetchTransactionHistoryRequest={
          requestHeader:transactionRequestHeader,
          deviceInfo:this.props.deviceInfo
           }
    
              
           const fetchTransactionUrl="/api/v1/transaction/history";
            console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
           
            transactionService.fetchAllTransactionData(fetchTransactionUrl,
          fetchTransactionHistoryRequest);
       
            //store.dispatch(showDashBoardComponentSection('trasactionHistory'));

            //expiring first		
		      const expiringFirstRequestHeader={
            requestType: "GET_EXPIRING_FIRST",
                  requestId: uuidv4(),
                  requesterTimestamp: moment(),
                  mobileNumber:this.props.phone,
                  originalDialogueTraceId:  uuidv4(),
                  walletOwner: "SPAR",
                  channel: "WEB"
              }
              
            const expiringFirstRequest={
              requestHeader:expiringFirstRequestHeader,
              deviceInfo:this.props.deviceInfo
            }
      
            const expiringFirstUrl='/api/v1/wallet/expiringFirst';
          
            console.log('expiringFirstRequest.....',JSON.stringify(expiringFirstRequest));
           
            WalletService.expiringFirstApiCall(expiringFirstUrl,expiringFirstRequest);
            //store.dispatch(setUploadedFiles([]));

            var requestForBannerDownload={
              pageNo:1
            }
            httpClient.post('/api/v1/banner/download', 
            requestForBannerDownload).then((res) => {
              
                    console.log("download response::"+JSON.stringify(res.data));
                    if(res.data.response.length>0){
                     store.dispatch(setUploadedFiles(res.data.response));
                    }else{
                      store.dispatch(setUploadedFiles([]));
                    }
                    
            }).catch(error=>{
              //console.log(error);
              console.log(error.data);
            });

        }else{
            console.log('Going to abort...');
        }

    }

    handleCallBack= (response) => {
    
        this.setState({
            amount:response
        }            
        )
      };

      expiringSoon= (event) => {
		event.preventDefault();
		var data='expiringSoonDetails';
        console.log(data);
		store.dispatch(showDashBoardComponentSection('expiringSoonDetails'));
	}

    
    render(){


    console.log('txnResponse...',this.props.txnResponse);
   

  
        return(
             <div>
            <Header/>


            <div class="mid">
                        <div class="container">
                            <div class="row">
                                {Boolean(
                                window.navigator.userAgent.match(
                                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
                                )) ?
                                this.props.viewDashBoardSection == 'trasactionHistory' ?
                                <Sidebar/>
                                :<div></div>
                                :<Sidebar/>
                                }

                                

                               
                                <div class="col-12 col-lg-8">

                                        {
                                        ( this.props.viewDashBoardSection == 'sparCashSection') &&
                                        <SparCash parentCallback={this.handleCallBack} response={this.props.userProfileResponse} 
                                        walletServiceResponse={this.props.walletServiceResponse}/>
                                        }
                                        {
                                        (this.props.viewDashBoardSection == 'sparCashBackPlusSection') &&
                                        <SparCashBackPlus parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }
                                        {
                                        (this.props.viewDashBoardSection == 'sparCashBackDetailsSection') &&
                                        <SparCashBackDetails parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }
                                        {
                                        (this.props.viewDashBoardSection == 'sparCouponSection') &&
                                        <MyCoupon parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }
                                       
                                        {
                                        (this.props.viewDashBoardSection == 'couponDetailsSection') &&
                                        <CouponDetails parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }
                                        {
                                        (this.props.viewDashBoardSection == 'creditNoteSection') &&
                                        <CreditNote parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }
                                        
                                        {
                                        (this.props.viewDashBoardSection == 'creditNoteDetailsSection') &&
                                        <CreditNoteDetails parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'bonusClubSection') &&
                                        <BonusClub parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'bonusclubSubSection') &&
                                        <BonusClubSubSection parentCallback={this.handleCallBack}/>
                                        }


                                        {
                                        (this.props.viewDashBoardSection == 'transactionDetails') &&
                                        <TransactionDetails transactionResponse={this.props.transactionResposne}/>
                                        }
                                       
                                        {(this.props.viewDashBoardSection == 'trasactionHistory') &&
                                            <div class="dashcard"> 
                                            <div class="head">
                                            <h3>Expiring Soon</h3>
                                            {
                                            (this.props.expiringFirstResponse!=null &&
                                          
                                                this.props.expiringFirstResponse.length>3) &&
                                                
                                                <button type="button" class="next" onClick={this.expiringSoon}>next</button>
                                                
                                             
                                            } 
                                            </div>
                                            <div class="expiry-soon">
                                            
                                            
                                         
                                            {
                                            this.props.expiringFirstResponse!=[] &&
                                              
                                            <ExpringComponent/>
                                             
                                            } 
                                            </div>
                                            
                                          
                                             


                                               <div class="head">  
                                                
                                                                                   
                                             
                                               
                                                
                                                <TransactionHeader transactionResposne={this.props.transactionResposne}/>
                                                
                                                 
                                                </div>

                                              <div class="txnhistory" id="txnlist">	
                                              {

                                                this.props.txnLoader=="active" &&
                                                this.props.txnResponse!=null &&
                                                    
                                                    this.props.txnResponse.length>0 &&

                                              
                                                      this.props.txnResponse.map((resp,index) => (
                                                              index<=2 &&
                                                
                                                                
                                                                parseInt(resp.appliedAmount)>0
                                                                
                                                                 &&
                                                                 (

                                                                    (JSON.stringify(resp.status).includes("SUCCESS"))? 
                                                                    <TransactionSuccessComponent response={resp}/>
                                                                    :<TransactionFailureComponent response={resp}/>
                                                                    
                                                                  ) 
                                                                    
                                               
                                                    ) 
                                                                                                        
                                                )
                                                
                                                     
                                                }   
                                                


                                                  {
                                                   
                                                    (this.props.txnResponse.length==0||this.props.txnResponse=='') &&
                                                // this.props.transactionResposne.txnHistory.length==0 &&
                                                <div>
                                                
                                                 <BlankTransactionCompoment/>
                                                 </div>
                                                } 
                                        
                                                
  

                                                
                                            </div>
                                    
                                    
                                        </div>
                                        } 

                                        
                                        {
                                        (this.props.viewDashBoardSection == 'expiringSoonDetails') &&
                                
                                        <ExPiringDetails/>
                                       
                                        
                                       

                                        }
                                  
                                        {
                                        (this.props.viewDashBoardSection == 'addMoneySection') &&
                                      
                                       <LoadMoney parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}  walletServiceResponse={this.props.walletServiceResponse.walletDetails}/>
                                        }


                                      {
                                        (this.props.viewDashBoardSection == 'loadDetails') &&
                                      
                                       <LoadDetail parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}  
                                        walletServiceResponse={this.props.walletServiceResponse.walletDetails}
                                        orderServiceResponse={this.props.orderServiceResponse}/>
                                      }

                                        {
                                        (this.props.viewDashBoardSection == 'orderfailedWithRefundSuceesFul') &&
                                      
                                       <OrderFailedWithRefundSuccessFul parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}  
                                        walletServiceResponse={this.props.walletServiceResponse.walletDetails}
                                        orderServiceResponse={this.props.orderServiceResponse}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'orderfailedWithRefundFailed') &&
                                      
                                       <OrderFailedWithRefundSuccessFul parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}  
                                        walletServiceResponse={this.props.walletServiceResponse.walletDetails}
                                        orderServiceResponse={this.props.orderServiceResponse}/>
                                      }



                                        {
                                        (this.props.viewDashBoardSection == 'orderfailed') &&
                                      
                                       <Orderfailed parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}  
                                        walletServiceResponse={this.props.walletServiceResponse.walletDetails}
                                        orderServiceResponse={this.props.orderServiceResponse}/>
                                      }
                                        
                                       
                                       {
                                        (this.props.viewDashBoardSection == 'notificationSection') &&
                                      
                                       <NotificationSection parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletInfo={this.props.walletResponse}
                                        orderId={this.props.orderId}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'addGiftCardSection') &&
                                      
                                        <GiftCardAdd parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletServiceResponse={this.props.walletServiceResponse}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'giftCardPurChase') &&
                                        <PurchaseGiftCard parentCallback={this.handleCallBack} response={this.props.userProfileResponse} walletServiceResponse={this.props.walletServiceResponse.walletDetails}/>
                                        }



                                        {
                                        (this.props.viewDashBoardSection == 'giftCardOtp') &&
                                        <GiftCardOtp parentCallback={this.handleCallBack} response={this.props.userProfileResponse} />
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'giftCardAddDetails') &&
                                        <GiftCardAddedDetails parentCallback={this.handleCallBack} response={this.props.userProfileResponse} giftCardBalnceEnquiry={this.props.giftCardBalnceEnquiry}/>
                                        }


{
                                        (this.props.viewDashBoardSection == 'giftCardFailedDetails') &&
                                        <GiftCardFailedDetails parentCallback={this.handleCallBack} response={this.props.userProfileResponse} giftCardBalnceEnquiry={this.props.giftCardBalnceEnquiry}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'giftCardReview') &&
                                        <GiftCardReview parentCallback={this.handleCallBack} response={this.props.userProfileResponse} 
                                        giftCardBalnceEnquiry={this.props.giftCardBalnceEnquiry}/>
                                        }

                             
                                        {
                                        (this.props.viewDashBoardSection == 'profileSection') &&
                                         <ProfileSectionComponent parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }

                                        {
                                        (this.props.viewDashBoardSection == 'complaintSection') &&
                                         <Complaints parentCallback={this.handleCallBack} response={this.props.userProfileResponse}/>
                                        }

                                      

                                    
                                            
                                
                                        		
                                {/* </div> */}
                                </div>
                                </div>

                                {/* {
                                    this.props.uploadFile.length>0 &&
                        <SubFooter/>
                                } */}
                                 <SubFooter/>
                                                   
                    </div>
                    </div>
           
            <Footer/>
            
            
           </div>
        //    </LoadingOverlay>
        );
    } 
  }

    const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
        responseReduce:{responseDetailsVal,dashBoardResponseDetails,
            userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse
        ,expiringFirstResponse
      ,txnResponse
    },
        leadingOverLay:{blockScreenConfirm},
        ordeDetailsReduce:{orderId},
    
        miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
        ,deviceInfo,myCouponDetails,txnLoader,uploadFile}}
        ) => ({
            viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
            userProfileResponse,orderServiceResponse,fetchTransactionResponse,
            expiringFirstResponse
            ,txnResponse,
            blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
            deviceInfo,myCouponDetails,txnLoader,uploadFile
    });

    export default connect(mapStateToProps, null)(UserDashBoard);
