import React, { useState, useEffect } from 'react';

import {store} from '../../redux/store';
import { connect } from 'react-redux';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";

import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';

class CouponDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',			
        }
    }


    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		
		

       //new userTransactionData
	  

	   const transactionRequestHeader={
		requestType: "GET_TRANSACTION_HISTORY",
		requestId: uuidv4(),
		 requesterTimestamp: moment(),
		 mobileNumber:localStorage.getItem('mobile'),
		 originalDialogueTraceId: null,
		 walletOwner: "SPAR",
		 channel: "WEB"
	   }

	 



	   const fetchTransactionHistoryRequest={
		requestHeader:transactionRequestHeader,
		deviceInfo:this.props.deviceInfo,
	   }

		  
	

		console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
		

		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	  couponSection= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('sparCouponSection'));
	  }
    couponDetails= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('couponDetailsSection'));
	}

	
	copy=(event,value)=> {
		event.preventDefault();
		
	    console.log('copied value',value);
		 /* Copy the text inside the text field */
		navigator.clipboard.writeText(value.couponCode);
	
	  }
    
	render(){
console.log('couponData:::::',this.props.couponData)
return(
	<>

           <div class="innercard">
		   <div class="head"><h3>Coupon Details</h3><button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button> 
					<button type="button" class="back" onClick={this.couponSection} data-toggle="tooltip" title="Back">back</button>
					</div>
					<div class="couponshow">
						<div class="cpnxt">
						{ 
						parseFloat((moment(this.props.couponData.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                    			 /31536000000*365).toFixed(0)>-1?
							<div class="row">
							
								<div class="col-12 col-sm-7 col-md-8 col-xl-9">
								
									<h4>{this.props.couponData.couponDesc}</h4>
									<p>Validity: 
									<span> {this.props.couponData.validTillDate.split("T")[0]}</span>
									</p>	
								</div>
								
								<div class="col-12 col-sm-5 col-md-4 col-xl-3">
									<h5>
										{this.props.couponData.couponCode}
										 <button type="button" class="copy" data-toggle="tooltip" 
										 title="Copy" onClick={(e)=>this.copy(e,this.props.couponData)}
										 id={this.props.couponData.couponCode}
										 >Copy</button>
									</h5>
								</div>
							</div>:
							<div class="row">
							
							<div class="col-12 col-sm-7 col-md-8 col-xl-9">
							
								<h4>{this.props.couponData.couponDesc}</h4>
								<p>Validity: 
									<span> {this.props.couponData.validTillDate.split("T")[0]}</span>
									</p>	
							  </div>
							
							   <div class="col-12 col-sm-5 col-md-4 col-xl-3">
								<h5>
									{this.props.couponData.couponCode}
								</h5>
							</div>
						</div>
						}
						</div>

						
						<div class="cpnxtdtl">
							<h4>Terms & Conditions</h4>							
							<ol>
								{/* <li>Now buy 1 and get 1 Free on your order.</li>
								<li>Lorem Ipsum is simply dummy text of the typesetting industry</li>
								<li>Standard dummy text ever since the 1500s</li>
								<li>Lorem Ipsum is not simply random text</li>
								<li>Now buy 1 and get 1 Free on your order.</li>
								<li>Lorem Ipsum is simply dummy text of the printing</li>
								<li>Standard dummy text ever since the 1500s</li>
								<li>Lorem Ipsum is not simply random text</li> */}
							</ol>
						</div>
					</div>
				</div>	
	
</>)

}
}


const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
	,deviceInfo,myCouponDetails,couponData}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
		deviceInfo,myCouponDetails,couponData
});


export default connect(mapStateToProps, null)(CouponDetails);

