import { UserActionTypes } from './user.types';

export const setRoMdn = mdn => ({ 
  type: UserActionTypes.SET_RO_MDN,
  payload: mdn
})
export const setCurrentUser = user => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user
});
export const setPricingGroupId = data => ({
  type: UserActionTypes.SET_PRICING_GROUP_ID,
  payload: data
})

export const setProductId = data => ({
  type: UserActionTypes.SET_PRODUCT_ID,
  payload: data
})

export const setMaxMinAmount =data => ({
  type: UserActionTypes.SET_MAX_MIN_AMOUNT,
  payload: data
})
export const setUserType =data => ({
  type: UserActionTypes.SET_USER_TYPE,
  payload: data
})

export const setLoginUserName =data => ({
  type: UserActionTypes.SET_LOGIN_USER_NAME,
  payload: data
})

export const setUserLastLoginTime =data => ({
  type: UserActionTypes.SET_USER_LAST_LOGIN_TIME,
  payload: data
})

export const resetUser ={
  type: UserActionTypes.RESET
}

export const inSufficientBasedOnCondition = data =>({
  type:UserActionTypes.SET_IN_SUFFICIENT_BASED_CONDITION,
  payload:data
})
export const setLoggedInUserID = data =>({
  type:UserActionTypes.SET_LOGGED_USER_ID,
  payload:data
})
export const setClientID = data =>({
  type:UserActionTypes.SET_USER_CLIENTID,
  payload:data
})

export const setTransactionType =data => ({
  type: UserActionTypes.SET_Transaction_Type,
  payload: data
})
export const logoutUser =data => ({
  type: UserActionTypes.SET_USER_LOGOUT,
  payload: data
})
export const setChannelId  =data => ({
  type: UserActionTypes.SET_setChannelId,
  payload: data
}) 