
export const MiscType = {
    
    setOrderId:'setOrderId',
    setPhone:'setPhone',
    setAmount:'setAmount',
    setTransactionId:'setTransactionId',
    setTransactionTimeStamp:'setTransactionTimeStamp',
    setCardNumber:"setCardNumber",
    setCardPin:"setCardPin",
    setQCAuthToken:"setQCAuthToken",
    setGiftCardBalanceEnquiry:"setGiftCardBalanceEnquiry",
    setGiftCardLinkResponse:"setGiftCardLinkResponse",
    setGiftCardRedeemResponse:"setGiftCardRedeemResponse",
    setOtp:"setOtp",
    setOrderServiceResponse:"setOrderServiceResponse",
    setMyCouponDetails:"setMyCouponDetails",
    setMyPoints:"setMyPoints",
    setUserFirstName:'setUserFirstName',
    setOtpRefNo:'setOtpRefNo',
    setCampaignRecords:'setCampaignRecords',
    getCashBack:"getCashBack",
    setDeviceInfo:"setDeviceInfo",
    setUserRegisterData:"setUserRegisterData",
    setCreditNoteData:"setCreditNoteData",
    setCouponData:"setCouponData",
    cashBackCampaign:"cashBackCampaign",
    setTxnLoader:"setTxnLoader",
    setUploadedFiles:"setUploadedFiles",
    complaintIdValue:"complaintIdValue",
    setGameId:"setGameId",
    setGameofWheelReditect:"setGameofWheelReditect",
    setLock:"setLock",
    setPlayCount:"setPlayCount",
    setTncGameOfwhhel:"setTncGameOfwhhel",
    RESET: "RESET"
};