import { OrderDetailsType } from './order-details.type';

export const setOrderId = (data) => ({
  type: OrderDetailsType.setOrderId,
  payload: data
  
});


export const resetOrderId ={
  type: OrderDetailsType.RESET
}