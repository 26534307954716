import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import history from '../../auth/history';
import Winwheel from "winwheel";

import swal from 'sweetalert';
import moment from "moment";
import Header from './headerGameOfWheel';
import { v4 as uuidv4 } from 'uuid';
import FooterComponent from './footer.component';
import HowToPlay from './HowToPlay';

import audio from "../../assests/images/tick.mp3";
import { setGameId, setGameofWheelReditect, setLock, setMyCouponDetails, setPhone, setPlayCount, setTncGameOfwhhel } from '../../redux/misc-data/misc-data.action';
import { store } from '../../redux/store';
import { setGameOfWheelLoadResponse, setGameOfWheelTextValues } from '../../redux/response/response.action';
import { setAuth, setErrMsg } from '../../redux/auth/auth.action';
import fetchUserDetails from "../../service/spar-service-impl/fetchUserDetails";
let wheelPower = 0;
let wheelSpinning = false;

const httpClient = axios.create();

class SpinWheelAppTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winningSliceId: 0,
            winningTicketId: null,
            buttonDisabled: false,
            celeBreationEnabled: false,
            loadingGameDate: 'false',
            tncArray: []
        }
    }
    componentDidMount() {
        store.dispatch(setTncGameOfwhhel([]))
        store.dispatch(setGameOfWheelLoadResponse([]));
        store.dispatch(setAuth(true));
        if (this.props.phone != null &&
            this.props.phone != undefined &&
            this.props.phone != '' &&
            this.props.gameIdVal != null &&
            this.props.gameIdVal != undefined &&
            this.props.gameIdVal != '') {
            this.checkWheelEnabled(this.props.phone,this.props.gameIdVal);
        }
    }

    checkWheelEnabled=(mobile,gameId)=>{

        var url='/api/v1/game/spin-the-wheel/check/'+mobile
        +'/';
        httpClient.get(url + gameId,
            { mode: 'cors', }
        ).then((response) => {
         
            if(response.data.includes("IS ALLOWED TO PLAY")){
                this.loadWheel();
            }else{
                this.setState({
                    loadingGameDate: 'wheel-not-allowed'
                })    
            }
        }).catch(error=>{
            this.setState({
                loadingGameDate: 'wheel-not-allowed'
            })
       
        })
    }


    loadWheel() {
        this.setState({
            loadingGameDate: 'loading'
        })
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));
        headers.append('Origin', 'http://localhost:3000');

        // var gameOfWheelUrl = 'http://3.109.173.21:8888/api/v1/game/spin-the-wheel/get/';
        var gameOfWheelUrl = '';
        if (process.env.REACT_APP_ENV == 'UAT') {
            gameOfWheelUrl = 'http://3.109.173.21:8888/api/v1/game/spin-the-wheel/get/';
        }
        if (process.env.REACT_APP_ENV == undefined ||
            process.env.REACT_APP_ENV == 'PROD') {
            gameOfWheelUrl = '/api/v1/game/spin-the-wheel/get/';
        }


        if (this.props.phone != null &&
            this.props.phone != undefined &&
            this.props.phone != '' &&
            this.props.gameIdVal != null &&
            this.props.gameIdVal != undefined &&
            this.props.gameIdVal != '') {
            httpClient.get(gameOfWheelUrl + this.props.gameIdVal,
                { mode: 'cors', }
            ).then((response) => {

                var termsAndConditions = "";
                var termsAndConditions1 = "";
                var tncArray = [];
                if (response.data.termsAndConditions != null &&
                    response.data.termsAndConditions != undefined &&
                    response.data.termsAndConditions != "") {
                    termsAndConditions = response.data.termsAndConditions;
                    termsAndConditions1 = response.data.termsAndConditions.toLowerCase();

                    if (termsAndConditions1.includes("<br>")) {
                        tncArray = termsAndConditions.split("<br>");
                    }

                }

                this.setState({
                    tncArray: tncArray
                })

                store.dispatch(setGameOfWheelLoadResponse(response.data));

                console.log('enter httpclient call section ' + gameOfWheelUrl);
                console.log('inside gameOfWheelUrl service', response.data);

                var gameTextValArray = [];

                var colorList =
                    ['#2faf74',
                        '#EC407A',
                        '#283593',
                        '#39a1e8',
                        '#e65051',
                        '#fba629',
                        '#C0CA33',
                        '#fba629',
                        '#455A64',
                        '#6D4C41'];

                if (response.data != null &&
                    response.data.slices != null &&
                    response.data.slices.length > 0
                ) {
                    response.data.slices.map((res, index) => {
                        var resItem = {
                            fillStyle: colorList[index % (colorList.length)],
                            text: res.text
                        }
                        gameTextValArray.push(resItem);
                    })
                }
                store.dispatch(setGameOfWheelTextValues(gameTextValArray));
                store.dispatch(setGameofWheelReditect('true'));
                store.dispatch(setLock('true'));
                store.dispatch(setPlayCount(0));

                setTimeout(() => {
                    var colorList = ['#D32F2F',
                        '#2E7D32',
                        '#283593',
                        '#795548',
                        '#8E24AA',
                        '#304FFE',
                        '#C0CA33',
                        '#F57C00',
                        '#455A64',
                        '#6D4C41'];

                    // Define the outer wheel, we will treat this as the PRIMARY which means it clears the canvas when drawing and also
                    // gets the animaton applied to it. We must callback a function during the animation to move and draw the inner wheel
                    // so the 2 wheels appear as one thing on the canvas.

                    this.setState({
                        loadingGameDate: 'true'
                    })
                    window.outerWheel = new Winwheel({
                        'numSegments': this.props.gameOfWheelTexts.length,
                        'textMargin': 10,
                        'outerRadius': 210,
                        'textFontSize': 17,
                        'lineWidth': 0.1,
                        //'innerRadius' : 150,    // Set inner radius to the size of the inner wheel since the inner part of the wheel
                        'textFillStyle': '#ffffff',
                        //'textOrientation' : 'curved',
                        'textFontFamily': 'Arial',
                        'textFontWeight': 'normal',
                        'textOrientation': 'horizontal',
                        'textAlignment': 'center',
                        'segments': this.props.gameOfWheelTexts,
                        'strokeStyle': '#454545',
                        'animation': {
                            'type': 'spinToStop',                     // Define animation more or less as normal, except for the callbackAfter().
                            'duration': 5,
                            'spins': 5,
                            //'callbackSound' : this.playSound, // Function to call when the tick sound is to be triggered.
                            'easing': 'Power3.easeOut',
                            //'callbackAfter' : this.drawInnerWheel,     // Call back after each frame of the animation a function we can draw the inner wheel from.
                            'callbackFinished': this.alertPrize
                        }
                    });

                    window.outerWheel.draw();
                    this.setState({
                        loadingGameDate: 'completed'
                    })

                }, 400)
            }).catch(error => {

                this.setState({
                    loadingGameDate: 'error'
                })
                // alert(error.message)
                console.log('error', error.message);
                // this.showException('Error in loading items..', false)
                return error;
            })

        } else {
            this.setState({
                loadingGameDate: 'error'
            })

        }
    }

    playSound() {
        var audio1 = new Audio(audio);
        console.log(audio1);
        audio1.play();
    }

    goToDashBoard() {

        const requestHeader = {
            requestType: "GET_USER_PROFILE",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: this.props.phone,
            originalDialogueTraceId: null,
            walletOwner: "SPAR",
            channel: "WEB"
        }



        const request = {
            requestHeader: requestHeader,
            deviceInfo: this.props.deviceInfo
        }

        var userFetchUrl = '/api/v1/user/profile';
        console.log('userprofile request' + JSON.stringify(request));
        fetchUserDetails.fetchUserCall(userFetchUrl, request);
    }


    startSpin = (event) => {
        event.preventDefault();

        this.startSpinAction();

    }

    startSpinAction() {
        this.setState({
            buttonDisabled: true
        })

        const requestHeader = {
            requestType: "PLAY_GAME",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: this.props.phone,
            originalDialogueTraceId: null,
            walletOwner: "SPAR",
            channel: "WEB",

        }
        const request = {
            requestHeader: requestHeader,
            gameId: this.props.gameIdVal
        }


        var playUrl = '/api/v1/game/spin-the-wheel/play';
        httpClient.post(playUrl, request,
            {
                mode: 'cors',
                type: "POST",
                method: 'POST',

            }
        ).then((response) => {
            var playCount = this.props.playCount + 1;

            store.dispatch(setPlayCount(playCount));

            console.log('enter httpclient call section' + playUrl);
            console.log('inside all play wheel service', response.data);
            this.setState({
                winningSliceId: response.data.winningSliceId,
                winningTicketId: response.data.winningTicketId,
            })



            // Ensure that spinning can't be clicked again while already running.
            if (wheelSpinning == false) {

                // Reset things with inner and outer wheel so spinning will work as expected. Without the reset the
                // wheel will probably just move a small amount since the rotationAngle would be close to the targetAngle
                // figured out by the animation.
                window.outerWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.

                window.outerWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
                window.outerWheel.draw();                // Call draw to render changes to the wheel.
                window.outerWheel.animation.spins = 8;
                window.outerWheel.animation.duration = 7;

                var stopAngle = 0;
                // Begin the spin animation by calling startAnimation on the wheel object.
                if (parseInt(response.data.winningSliceId) > 0) {
                    stopAngle = ((360 / this.props.gameOfWheelTexts.length) * response.data.winningSliceId) - 30;
                } else {
                    stopAngle = 0;
                }


                window.outerWheel.animation.stopAngle = stopAngle
                window.outerWheel.startAnimation();

                if (response.data.responseHeader.responseCode != undefined &&
                    response.data.responseHeader.responseCode != null) {
                    if (response.data.responseHeader.responseCode.includes('SUCCESS')) {
                        //wheelSpinning = true;
                        setTimeout(() => {
                            this.alertPrize(response.data.winningTicketId, response.data.winningSliceId);
                        }, window.outerWheel.animation.duration * 1000);
                    } else {
                        setTimeout(() => {

                            this.showException(response.data.responseHeader.responseMsg, true)
                        }, window.outerWheel.animation.duration * 1000);
                    }
                }


            }
        }).catch(error => {
            console.log('error', error);


            this.showException(error.message, true)
            // return error;
        })

        store.dispatch(setLock(''));
    }

    howToPlay = () => {
        history.push('/HowToPlay');
        history.go();
    }

    tncForGameOfWheel = () => {
        store.dispatch(setTncGameOfwhhel(this.state.tncArray))
        history.push('/tncGameOfWheel');
        history.go();
    }

    showError = (moveToDashBoardPage) => {
        swal({
            title: "Better Luck Next Time!",
            text: "Please try later ",
            icon: "error",
            button: true,
            dangerMode: true,
        }).then((dashBoardRoute) => {
            if (moveToDashBoardPage == true) {
                this.goToDashBoard();
            }
        });
    }

    showException = (title, moveToDashBoardPage) => {
        swal({
            title: "Better Luck Next Time!",
            text: "Please try later ",
            icon: "error",
            button: true,
            dangerMode: true,
        }).then((dashBoardRoute) => {
            if (moveToDashBoardPage == true) {
                this.goToDashBoard();
            }

        });
    }


    alertPrize(ticketId, sliceId) {


        if (sliceId != null && ticketId != null) {
            this.playSound();
            this.setState({
                celeBreationEnabled: true
            })

            if (sliceId > 0) {
                sliceId = sliceId - 1;
            }

            // alert(sliceId);


            this.setState({
                celeBreationEnabled: false
            })
            var claimUrl = '/api/v1/game/spin-the-wheel/claim';

            const requestHeader = {
                requestType: "CLAIM_WIN",
                requestId: uuidv4(),
                requesterTimestamp: moment(),
                mobileNumber: this.props.phone,
                originalDialogueTraceId: null,
                walletOwner: "SPAR",
                channel: "WEB"
            }

            const request = {
                requestHeader: requestHeader,
                gameId: this.props.gameIdVal,
                winningTicket: ticketId
            }
            console.log('request...' + JSON.stringify(request));

            httpClient.post(claimUrl, request
                , { mode: 'cors', }
            ).then((response) => {
                console.log('enter httpclient call section' + claimUrl);
                console.log('inside all play wheel claim service', response.data);


                if (response.data.responseHeader.responseCode == 'SUCCESS') {
                    console.log(JSON.stringify(response.data));
                    this.playSound();
                    this.setState({
                        celeBreationEnabled: true
                    })
                    //this.showError(false);

                    swal({
                        title: "Congratulation!",
                        text: "Yay!! You have won an exclusive discount voucher.Get "+this.props.gameOfWheelTexts[sliceId].text+".",
                        icon: "success",
                        showCloseButton: true,

                    }).then(() => {
                        this.goToDashBoard();
                    })

                } else {
                    this.setState({
                        celeBreationEnabled: false
                    })
                    this.showException(response.data.responseHeader.responseMsg, true);

                }
            }).catch(err => {
                this.showException(err.message, true);

            });
        }
    }




    goToDashBoardPage = () => {

        this.goToDashBoard();

        // history.push('/user-dashboard');
        // history.go();
    }


    render() {
        return (
            <div>
                <div class="gameid">
                    <div class="container">
                        <div class="row justify-content-md-center">
                            <div class="col-12">
                                <div class="spintxt">
                                    <h2>Spin the Wheel Game</h2>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="spinbox">
                                    {this.state.loadingGameDate == 'loading' &&
                                        <p>Loading game data,Please wait...</p>}
                                    {this.state.loadingGameDate == 'error' &&
                                        <div class='valid-error'>
                                            <p>Error in loading game data,Please try later</p>

                                        </div>
                                    }
                                    {this.state.loadingGameDate == 'wheel-not-allowed' &&
                                        <div class='valid-error'>
                                            <p>You've already availed this offer.</p>
                                        </div>
                                    }
                                    {(this.state.loadingGameDate == 'true' ||
                                        this.state.loadingGameDate == 'completed') &&
                                        <div>
                                            <canvas id="canvas" width="420" height="420" data-responsiveMinWidth="180" data-responsiveScaleHeight="true">Canvas not supported, use another browser.</canvas>
                                            <button id="spin_button" class="plybtn" onClick={this.startSpin}
                                                disabled={this.state.buttonDisabled}>Play</button>
                                        </div>

                                    }
                                </div>

                            </div>


                            <div class="col-12">

                                <div class="spinlink">
                                    <button class="linkbtn" onClick={this.goToDashBoardPage}>Go To Dashboard</button>
                                    <button class="linkbtn" onClick={this.howToPlay}>How to Play</button>
                                    {this.state.tncArray != undefined &&
                                        this.state.tncArray != null &&
                                        this.state.tncArray != "" &&
                                        this.state.tncArray.length > 0 &&
                                        <button class="linkbtn" onClick={this.tncForGameOfWheel}>Terms & Conditions</button>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {this.state.celeBreationEnabled == true &&
                    <div class="fireft">
                        <div class="firework"></div>
                    </div>
                }

            </div>



        );
    }
}

const mapStateToProps = (
    { showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
        responseReduce: { responseDetailsVal, dashBoardResponseDetails, gameOfWheelResponse, gameOfWheelTexts }
        , miscReduce: { phone, gameIdVal, deviceInfo, gameOfWheelRedirect, lockVal, playCount } }

) => ({
    viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails,
    gameOfWheelResponse, gameOfWheelTexts, phone, gameIdVal, deviceInfo, gameOfWheelRedirect
    , lockVal, playCount
});

const mapDispatchToProps = (dispatch) => {
    return {
        transactionDetails: (showDashBoardComponentSection) => {
            dispatch(showDashBoardComponentSection('transactionDetails'))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinWheelAppTest);