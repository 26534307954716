import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from './userService';
import ApiCallingService from '../api-call-service';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import qcConnectorEnquiryService from './qcConnectorEnquiryService';

class QcConnectorServiceRetry {



 checkAuthToken(url, requestTosend,routePge,cardInfo) {
  console.log('inside qcConnectorService ', url);
  //store.dispatch(setLoggedInUserID(username));

  var response = [];
  
   async function run() {
    console.log('checkAuthToken service Request',JSON.stringify(requestTosend));

    response=  await ApiCallingService.checkAuthToken(url, requestTosend);
    
    
     console.log("checkAuthToken response__",JSON.stringify(response));
     if (response === 'error'||response==undefined) {
      console.log(response);
      
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    }
   
    
    else if(response !=undefined && response.responseHeader!=undefined
      //&& response.status==200
      ){ 

    console.log(' checkAuthToken res_code',response.responseCode);
     if(parseInt(response.responseCode)==0){
       console.log("---response for checkAuthToken",response);
      var errorMessage='';
      store.dispatch(showErrorMessageSection(errorMessage));
      //store.dispatch(showDashBoardComponentSection(routePge.destinationComponrnt));
     
    console.log("----checkAuthToken call ended----");
    console.log("---giftcard enquiry call started----");



    const requestHeader={
        requestType: "CHECK_USER",
        requestId: uuidv4(),
         requesterTimestamp: moment(),
         mobileNumber:localStorage.getItem('mobile'),
         originalDialogueTraceId: null,
         walletOwner: "SPAR",
         channel: "POS"
   }

    var transactionId=Math.floor(1000000 + Math.random() * 9000000);
    const giftCardCallInfo={
        TransactionId:transactionId ,
        CardNumber: cardInfo.cardNumber,
        CardPin: cardInfo.cardPin,
        Idempotencykey:transactionId,
        DateAtClient: moment().format("YYYY-MM-DD HH:mm:ss"),
        Notes: "VldType~GCRDM"
    }

    const header="Bearer "+localStorage.getItem('qcAuthToken');


    const balanceEnquiryRequest={
        requestHeader:requestHeader,
        cardNumber:cardInfo.cardNumber,
        cardPin:cardInfo.cardPin,
        transactionId:''
    }

    console.log('request for giftcard balance enquiry:::',JSON.stringify(balanceEnquiryRequest));
   

    const qcConnectorUrl='/api/v1/qc/giftCardBalanceEnQuiry';
  

    qcConnectorEnquiryService.checkEnquiryBalance(qcConnectorUrl,balanceEnquiryRequest,routePge,cardInfo);



    }
    else{
      console.log("error response!",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    } 
    
  }
 
}
run();

 }

}

export default new QcConnectorServiceRetry();
