import React, { useState, useEffect } from 'react';
import SparPng from "../../assests/images/spar.png";

import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import {showUserComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";

import history from '../../auth/history';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import TransactionService from '../../service/spar-service-impl/transactionService';

import UserMale from "../../assests/images/user-male.png";
import UserFemale from "../../assests/images/user-female.png";


class HowToPlay extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',

		
        }
    }
	

	
    gameOfWheelRedIrect=()=>{
        history.push('/gameOfWheel');
        history.go();
    }		
	


	
	render(){

     

	  
       return(
	<>
        
        <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="howtxt">
					<h2>How to Play <button class="linkbtn" onClick={this.gameOfWheelRedIrect}>Back</button></h2>
					
					<ul>
						<li>
							<i class="play1"></i>
							<p>Click on the "Play button" to spin the wheel</p>
						</li>
						<li>
							<i class="play2"></i>
							<p>Confirm your Winnings</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>	

	
</>)

}
}





const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection}}
	) => ({
		viewDashBoardSection
});

export default connect(mapStateToProps, null)(HowToPlay);

