import React, { useState, useEffect } from 'react';
import SparPng from "../../assests/images/spar.png";
import UserJpg from "../../assests/images/user.jpg"
//import user8 from './images/user-8.jpg';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';
//import { setRoMdn, setUserFullName } from '../../redux/user/user.actions';
import { setRoMdn, setUserFullName } from "../../redux/user/user.action";
import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import {showLeadingOverLay} from "../../redux/leading-overlay/leading-overlay.action";
import SparServiceImpl from '../../service/spar-service-impl/SparService.js';
import transactionService from '../../service/spar-service-impl/transactionService';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import history from '../../auth/history';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import {setOrderId} from "../../redux/misc-data/misc-data.action";
import {setAmount} from "../../redux/misc-data/misc-data.action";
import {setPhone} from "../../redux/misc-data/misc-data.action";
import {setTransactionId} from "../../redux/misc-data/misc-data.action";
import {setTransactionTimeStamp} from "../../redux/misc-data/misc-data.action";
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import ProfileComponent from "./profileComponents";
import TransactionService from '../../service/spar-service-impl/transactionService';
import deviceDetails from '../../service/spar-service-impl/deviceDetails';
import { orderErrorMessage } from '../../redux/error-message/eror-message.action';
import { orderTrnasferMessage } from './../../redux/error-message/eror-message.action';
class LoadMoney extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
            firstName:this.props.userProfileResponse.customerInfo.firstName,
            lastName:this.props.userProfileResponse.customerInfo.lastName,
            surl:'',
            phone:this.props.userProfileResponse.customerInfo.mobileNumber,
            key:'',
            hash:'',
            curl:'',
            furl:'',
            txnId:'',
            productInfo:'',
            submitAmount:this.props.amount,
            email:this.props.userProfileResponse.customerInfo.email,
			loading: false,
			isActive:false,
			buttonVisible:true,
			displayMessage:'',
			transactionMessage:''
			
        }
    }

	componentDidMount() {
		store.dispatch(orderErrorMessage(''));
		store.dispatch(orderTrnasferMessage(''));
	}
	addMoney= (event) => {
		store.dispatch(orderErrorMessage(''));
		store.dispatch(orderTrnasferMessage(''));
		event.preventDefault();
		var data='addMoneySection';
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection(data));
	}


	notificationDetails= (event) => {
		
		event.preventDefault();
		var data='notificationSection';
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection(data));
	}
	backModal= (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}

    closeModal = (event) => {
		event.preventDefault();

	
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }

	  loading= (event) => {
		this.setState({
            active:true,
			loading:true
		})
		  
	  }

	  paymentSubmit= (event) => {
		event.preventDefault();
		var num1 =0;

		if(this.props.walletServiceResponse!=null &&
			this.props.walletServiceResponse.walletDetails!=null &&
			this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null ){

		 num1 = parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance);
		}else{
		num1=0;	
		}

		const num2 = parseInt(this.state.amount);
		// add two numbers
		const sum = num1 + num2;
		  console.log("amount::"+sum);
        
		store.dispatch(orderErrorMessage(''));
		store.dispatch(orderTrnasferMessage(''));
		//store.dispatch(showUserComponentSection('login'));
		this.setState({
          displayMessage:''
		})
        // if(this.state.amount>0 && sum<10000 ){
			if(this.state.amount>0 && sum<10000 ){
           


			this.setState({
				buttonVisible: false,
				active:true
			})

		
		
		setTimeout(()=>{
			this.setState({
				
				transactionMessage:'',
				buttonVisible:true
			  })	
		},30000);
		//event.preventDefault();
        this.props.parentCallback(this.state.amount); 

		var firstNameArray=this.props.userProfileResponse.customerInfo.firstName.split(" ");
		var firstname=firstNameArray.join();
		const request={
			phoneNo: this.state.phoneNo,
			amount:this.state.amount,
            firstName:firstname,
            lastName:this.props.userProfileResponse.customerInfo.lastName,
            surl:"",
            phone:this.props.userProfileResponse.customerInfo.mobileNumber,
            key:"",
            hash:"",
            curl:"",
            furl:"",
            txnId:"",
            productInfo:"loadMoney",
            submitAmount:this.state.amount.toString,
			email:this.props.userProfileResponse.customerInfo.email==''?'nouser@email.com':this.props.userProfileResponse.customerInfo.email
		}
		
	

		console.log('createOrder reuest',JSON.stringify(request));

		const routePage={
			origincomponent:'addMoney',
			destinationcomponent:'addMoney',
			callBackMethod:'POST',
			dashBoardContentType:'dashBoard',
			responseContentType:'userData'
		  }

		  const requestHeaderCreateOrder={
			requestType: "CREATE_ORDER",
			requestId: uuidv4(),
			 requesterTimestamp: moment(),
			 mobileNumber:this.props.userProfileResponse.customerInfo.mobileNumber,
			 originalDialogueTraceId: null,
			 walletOwner: "SPAR",
			 channel: "WEB"
	     }
		 	const orderInfo={
			itemType:'Add Money',
            itemDescription:'Spar Cash Wallet',
			phoneNo:this.props.userProfileResponse.customerInfo.mobileNumber,
			amount:this.state.amount,
            firstName:firstname,
            lastName:this.props.userProfileResponse.customerInfo.lastName,
            surl:"",
            phone:this.props.userProfileResponse.customerInfo.mobileNumber,
            key:"",
            hash:"",
            curl:"",
            furl:"",
            txnId:"",
            productInfo:"loadMoney",
            submitAmount:this.state.amount.toString,
			email:this.props.userProfileResponse.customerInfo.email==''?'nouser@email.com':this.props.userProfileResponse.customerInfo.email
		 }


         const deviceInfoM={
			deviceId : this.props.deviceInfo.deviceId,
			deviceIdValue :this.props.deviceInfo.deviceIdValue,
			osName : this.props.deviceInfo.osName,
			osVer : this.props.deviceInfo.osVer,
			ipAddr : this.props.deviceInfo.ipAddr,
			browserVer : this.props.deviceInfo.browserVer,
			mobileNumber:this.props.userProfileResponse.customerInfo.mobileNumber
		 }


		 const requestForCreateOrder={
			requestHeader:requestHeaderCreateOrder,
			orderInfo:orderInfo,
			deviceInfo:deviceInfoM
		 }
    
		//const url='/order/createOrder';
		const creaateOrderUrl='/api/v1/pg/createOrder';
		
	     SparServiceImpl.apiCall(creaateOrderUrl,requestForCreateOrder,'',routePage,
		 this.props.userProfileResponse.customerInfo.mobileNumber
		 ,this.props.otpVal,this.props.otpRefNo);
	    

        }else{

			if(this.state.amount<=0){
			this.setState({
			buttonVisible: true,
			active:true,
			displayMessage:"Please enter a valid amount to proceed."
          	})	
			}

		const num1 = 10000;
		var num3=0;

		if(this.props.walletServiceResponse!=null &&
			this.props.walletServiceResponse.walletDetails!=null &&
			this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null ){
				num3 = parseInt(this.props.walletServiceResponse
			.walletDetails.totalWalletBalance);
		 }else{
			num3=0;
		 }
		// add two numbers
		const diff = num1 - num3;
			// if(sum>10000){
				if(sum>10000){
				this.setState({
					buttonVisible: true,
					active:true,
					displayMessage: diff>0?"Please enter amount less or equal to "+diff:"Your wallet balance reached 10000."
					})	
			}
		}
		//event.preventDefault();
	  }


      handleAmountChange= (event) => {
      this.setState({
            amount: event.target.value
		})		
	
		 }
      addFiveHundred= (event) => {
			if(this.state.amount==''){
			this.state.amount=0;
			}
        this.setState({
          amount:parseInt(this.state.amount)+500
        })		
        }
        addOneThousand= (event) => { 
			if(this.state.amount==''){
				this.state.amount=0;
			}
    
		this.setState({
		amount:parseInt(this.state.amount)+1000
		})		
		}
		addThreeThousand= (event) => {
			if(this.state.amount==''){
				this.state.amount=0;
			}
		this.setState({
		amount:parseInt(this.state.amount)+3000
		})		
		}
		addFiveThousand= (event) => {
			if(this.state.amount==''){
				this.state.amount=0;
			}
		this.setState({
		amount:parseInt(this.state.amount)+5000
		})		
		}
		
	render(){

return(
	<>

   {/* <ClipLoader css={override} active={this.state.active} size={150} color={"#123abc"} loading={this.state.loading} speedMultiplier={1.5} > */}
                  
           <div class="innercard">
			   <div class="head"><h3>Load Money </h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button>

			   </div>	
		   	 
					
								
					<form class="text-center" onSubmit={this.paymentSubmit} onClick={this.loading}
					>

					
					<div class="loadmoney">		
						<div class="row">
							<div  class="col-12 valid-success">{this.props.orderTrnasferMsg}</div>
							<div class="col-12">
								
								<div class="availblnc">
			
                                
								{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
										   this.props.walletServiceResponse.walletDetails.ecash!=null) &&
										<h4>₹ {parseFloat(this.props.walletServiceResponse.walletDetails.ecash.totalSubWalletBalance).toFixed(2)}</h4>
	  									}
										  {(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
										   this.props.walletServiceResponse.walletDetails.ecash==null) &&
										<h4>₹ 0.00</h4>
	  									}
									<i class="wplus"></i>
									<p>SPAR Wallet Balance</p>
								</div>
							</div>
							
							<div class="col-12">
								<div class="form-floating">
									<input type="number"  value={this.state.amount} 

									
                                    onChange={this.handleAmountChange} class="form-control" id="amt" placeholder="Amount"/>
									<label for="amt">Amount</label>
									<div class="valid-error">
									{this.props.orderErrorMsg}
									</div>
									<div class="valid-error">{this.state.displayMessage}</div>
								</div>
							</div>
							
							<div class="col-12">
								<div class="sugamt">
                                   
									<button type="button" id="" class="btn sugbtn" onClick={this.addFiveHundred}>₹ 500</button>
									<button type="button" id="" class="btn sugbtn" onClick={this.addOneThousand}>₹ 1000</button>                                                                    
									<button type="button" id="" class="btn sugbtn" onClick={this.addThreeThousand}>₹ 3000</button>
									<button type="button" id="" class="btn sugbtn" onClick={this.addFiveThousand}>₹ 5000</button>
                                   
								</div>
							</div>
							
							<div class="col-12">
							{(this.state.buttonVisible==true)  &&
								<button type="submit" className="btn primary" >Continue </button>
	                            }
								{(this.state.buttonVisible==false)  &&
								<button type="submit" disabled className="btn primary" >Continue 
								<span class="spinner-border spinner-border-sm"></span> </button>
	                            }
							</div>
						</div>						
					</div>
					
					</form>
						
			</div>
	
		
			

	
</>)

}
}

const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,orderErrorMsg,orderTrnasferMsg},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
		,otpVal,otpRefNo
	,deviceInfo}}
	) => ({
		viewDashBoardSection,errorMessage,orderErrorMsg,orderTrnasferMsg,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
		,otpVal,otpRefNo
		,deviceInfo
});

export default connect(mapStateToProps, null)(LoadMoney);
