import React, { useState, useEffect } from 'react';
import SparPng from "../../assests/images/spar.png";

import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import {showUserComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";

import history from '../../auth/history';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import TransactionService from '../../service/spar-service-impl/transactionService';

import UserMale from "../../assests/images/user-male.png";
import UserFemale from "../../assests/images/user-female.png";


class Header extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',

			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
			timeOfTheDay:''
        }
    }
	

	userProfileView= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		var data='profileSection';
		store.dispatch(showDashBoardComponentSection(data));
	}

	addMoney= (event) => {
		event.preventDefault();
        store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));

		// var walletResponse=[];
		
		// var userWalletUrl='/wallet/fetchWallet';

		// var transactionResponse=[];


		// const requestHeaderForFetchWallet={
		// 	requestType: "CREATE_ORDER",
		// 	requestId: uuidv4(),
		// 	 requesterTimestamp: moment(),
		// 	 mobileNumber:this.props.phone,
		// 	 originalDialogueTraceId: null,
		// 	 walletOwner: "SPAR",
		// 	 channel: "WEB"
		// }

       //new userTransactionData
	  

	//    const transactionRequestHeader={
	// 	requestType: "GET_TRANSACTION_HISTORY",
	// 	requestId: uuidv4(),
	// 	 requesterTimestamp: moment(),
	// 	 mobileNumber:this.props.phone,
	// 	 originalDialogueTraceId: null,
	// 	 walletOwner: "SPAR",
	// 	 channel: "WEB"
	//    }


	


	//    const fetchTransactionHistoryRequest={
	// 	requestHeader:transactionRequestHeader,
	// 	deviceInfo:this.props.deviceInfo
	//    }

		  
	//    const fetchTransactionUrl="/api/v1/transaction/history";


		//console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
		
		// TransactionService.fetchAllTransactionData(fetchTransactionUrl,fetchTransactionHistoryRequest);
		// console.log('fetchAllTransaction response'+JSON.stringify(transactionResponse));

		var data='addMoneySection';
		// setTimeout(() => {
		store.dispatch(showDashBoardComponentSection(data));
			
		// },1000);

	}


	notificationDetails= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		var data='notificationSection';
		store.dispatch(showDashBoardComponentSection(data));
	}

	userComPlaint=(event)=>{
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('complaintSection'));
	}

	userTransaction= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		console.log('Inside userTransaction');
		var data='transactionDetails';
	

	   const transactionRequestHeader={
		requestType: "GET_TRANSACTION_HISTORY",
		requestId: uuidv4(),
		 requesterTimestamp: moment(),
		 mobileNumber:this.props.phone,
		 originalDialogueTraceId: null,
		 walletOwner: "SPAR",
		 channel: "WEB"
	   }


		


	   const fetchTransactionHistoryRequest={
		requestHeader:transactionRequestHeader,
		deviceInfo:this.props.deviceInfo,
	   }

		  
	   const fetchTransactionUrl="/api/v1/transaction/history";

	

		console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
		
		TransactionService.fetchAllTransactionData(fetchTransactionUrl,fetchTransactionHistoryRequest);
		// setTimeout(() =>{
		store.dispatch(showDashBoardComponentSection('transactionDetails'));
		// store.dispatch(showUserComponentSection('login'));
		// },1200);
	}

	userNotification= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('notificationSection'));
		
	}

	realoadAllData= (event) => {
	store.dispatch(showErrorMessageSection(''));
	store.dispatch(showDashBoardComponentSection('trasactionHistory'))
	history.push('/user-dashboard');
	// setTimeout(()=>{
	history.go();
	// },1000);
	}

	logOut= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));

	
		
		
		
		history.push('/');
		history.go();
		
		
	}


	
	render(){

       console.log('walletInfo',this.props.walletServiceResponse);

	   console.log('myPointsResponse::',this.props.myPointsResponse);
	   console.log('userResponse...1:',this.props.userProfileResponse);
	   console.log('userResponse2:',this.props.userResponse);
		
		
	   

	   var d = new Date();
	   var n = d.getHours();
       return(
	<>
        <header class="top">
		<div class="container">
		<div class="row">			
		<div class="col-4 col-sm-6 col-lg-3 col-xl-4 order-lg-1 headbg">
			<div class="logo"><button type="button" onClick={this.realoadAllData}><img src={SparPng} alt="SPAR" class="img-fluid"/></button></div>
		</div>
			

		{/* {this.props.errorMessage} */}

		<div class="col-8 col-sm-6 col-lg-3 col-xl order-lg-3 headbg">
				<div class="profile">
					<div class="dropdown">
						<button type="button" class="dropdown-toggle" id="prolist" data-bs-toggle="dropdown" aria-expanded="false">
                        {
						// this.props.isProfile==true &&
						this.props.userProfileResponse!=''&&
						this.props.userProfileResponse.customerInfo!=null &&
						this.props.userProfileResponse.customerInfo.gender!=null ?
							this.props.userProfileResponse.customerInfo.gender=="FEMALE" ?
								<img src={UserFemale} alt="SPAR" class="img-fluid"/>
								
								
							:
							
									this.props.userProfileResponse.customerInfo.gender=="MALE"?
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								
								:
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								:
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								}
							
						
						</button>
						
					
					</div>
   

                  {n<=11 &&
                    this.props.userProfileResponse!=null &&
					this.props.userProfileResponse.customerInfo!=null &&
                     
					<p>Good morning, <span>{this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].charAt(0).toUpperCase()
						+this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].slice(1).toLowerCase()}</span></p>
				  }
				   {n>11 &&
				     n<=18 &&
					 
					 this.props.userProfileResponse!=null &&
					 this.props.userProfileResponse.customerInfo!=null &&

					<p>Good afternoon, <span>{this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].charAt(0).toUpperCase()
					+this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].slice(1).toLowerCase()}</span></p>
					}

					{n>18 &&
				     n<=24 &&					 
                    this.props.userProfileResponse!=null &&
					this.props.userProfileResponse.customerInfo!=null &&
					<p>Good evening, <span>{this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].charAt(0).toUpperCase()
					+this.props.userProfileResponse.customerInfo.firstName.split(" ")[0].slice(1).toLowerCase()}</span></p>
					}
				</div>
			</div>
				
    <div class="col-12 col-lg col-xl-5 order-lg-2">

				<div class="balbg">
				<div class="row">
				<div class="col-12 col-sm-5 col-md-4 col-lg-6 order-sm-2">
						
					</div>
					
			
					
					
					</div>
				</div>
			</div>
		</div>
	</div>
</header>

	
</>)

}
}



const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},
	authReduce:{isProfile},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
	,deviceInfo,myPoints}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,
		isProfile,
		phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
		,deviceInfo,myPoints
});

export default connect(mapStateToProps, null)(Header);

