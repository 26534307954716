const rules = {
  
  developer: {
    static: [
     
    ]
  },
  tester: {
    static: [
      
    ]
  },
  employee: {
    static: [

    ]
  },
  admin: {
    static: [

    ]

  },

  TechSupport: {
    static: [
     
    ]

  }

};

export default rules;