import React from "react";
import history from '../../auth/history';
import {store} from '../../redux/store';

//import { ReCaptcha } from 'react-recaptcha-google'
//import { loadReCaptcha } from 'react-recaptcha-google'
import AuthService from "../../service/retailer-service-impl/AuthService";
import UserService from "../../service/spar-service-impl/userService";
import Payloads from "./payloads";
import {showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
class TransactionHeader extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false
        }
    }
     componentDidMount() {
	 	
       }
       transactionDetails= (event) => {
        event.preventDefault();
        store.dispatch(showDashBoardComponentSection('transactionDetails'));
       }
	   
      render(){
        console.log('Inside render');
          return(
            <>
            <h3>Transactions
               
            </h3> {this.props.transactionResposne!=null &&
                    this.props.transactionResposne.length>3 && 
                <button type="button" class="next" onClick={this.transactionDetails}>next</button>    
                }
        </>

		
          );
      } 
}
const mapStateToProps = (
    { showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},responseReduce:{responseDetailsVal,transactionResposne,dashBoardResponseDetails},
    leadingOverLay:{blockScreenConfirm},
    ordeDetailsReduce:{orderId}}
    ) => ({
        viewDashBoardSection,errorMessage,responseDetailsVal,transactionResposne,dashBoardResponseDetails,blockScreenConfirm,orderId
});

const mapDispatchToProps = (dispatch) => {
    return {
        transactionDetails : (showDashBoardComponentSection) => {
           dispatch(showDashBoardComponentSection('transactionDetails'))
        }
    }
   }

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHeader);