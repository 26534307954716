import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {store} from '../../redux/store';
import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { setCreditNoteData } from '../../redux/misc-data/misc-data.action';

import Pagination from "react-js-pagination";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import moment from 'moment';

class CreditNote extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
			activePage:1
        }
    }
	
    closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	  backModal= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	  }
    creditNoteDetailsSection= (event,creditNoteResponse) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));

		event.preventDefault();
		console.log('creditNoteResponse',creditNoteResponse);
		store.dispatch(setCreditNoteData(creditNoteResponse));

		
		store.dispatch(showDashBoardComponentSection('creditNoteDetailsSection'));
	  }
	  
	  
	    handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	
		this.setState({
			tResponse:this.props.creditNoteResponse.slice((pageNumber-1)*6,(pageNumber)*6)
		 })
	   }
    

     render(){

return(
	
    			<div class="innercard">
					<div class="head">
						<h3>Credit Note</h3>
						<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
						<button type="button" onClick={this.backModal} 
			   		class="back">close</button>
					</div>				
					
					<div class="creditshow">
						<div class="creditdtl">
							<div class="row">
								<div class="col-12 col-sm-8 col-xl-9">
									<div class="creditblc">
										<i></i>
										<h6>Credit Note Balance: 



										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null) &&
												<span>₹ {this.props.walletServiceResponse.walletDetails.creditNote.totalSubWalletOwnerBalance}</span>

	  									}

										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote==null) && 
										
										<span>Not Available</span>
	  									}

								 		{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails==null) && 
										
										<span>Not Available</span>
	  									}  

										{(this.props.walletServiceResponse==null) && 
										
										<span>Not Available</span>
	  									}					
										
											</h6>
									</div>
								</div>
								
								<div class="col-12 col-sm-4 col-xl-3">
									<div class="creditqty">										
										<h6>Total Credit: 
											
										


										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null &&
												  this.props.walletServiceResponse.walletDetails.creditNote.subSections!=null) &&
										  <span>
										{this.props.walletServiceResponse.walletDetails.creditNote.subSections.length}</span>
	  									}

										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null &&
												  this.props.walletServiceResponse.walletDetails.creditNote.subSections==null) &&
										  <span>
										0</span>
	  									}

										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote==null) &&
										  <span>
										0</span>
	  									}

										{(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails==null ) &&
										  <span>
										0</span>
	  									}


										{(this.props.walletServiceResponse==null) &&
										  <span>
										0</span>
	  									}   
										  </h6>
									</div>
								</div>
							</div>
						</div>
						
						<div class="notelist">
							<div class="row">

								
						{/* <div class="alertPopUp success">
					
						<strong>No Credit Note Available!</strong> 
						</div> */}

									{this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null &&
												  this.props.walletServiceResponse.walletDetails.creditNote.subSections!=null &&
									(this.props.creditNoteResponse.length>0) &&
												  this.props.creditNoteResponse.map(response =>(
												  <div class="col-12 col-sm-6 col-md-4">
											{parseInt(response.value)==0 &&		  
												  <div class="noteview used">
													  <button type="button"
													  	onClick={(e)=>this.creditNoteDetailsSection(e,response)} >
														  <h4>₹ {response.balance}</h4>
														  <h5>Referral Code</h5>
														  {/* TODO--referral code needs to be replaced with proper value */}
														  <h6 class="text-truncate">#{response.id}</h6>
														  <p class="date">{response.addedOn.split(" ")[0]}</p>
														  <p>Validity <span>{response.expiringOn.split(" ")[0]}</span></p>
													  </button>
												  </div>
												}
												{moment(response.expiringOn,"DD-MM-YYYY HH:mm:ss")<new Date()?
																								  <div class="noteview exprd">
																								  <button type="button"
																									  onClick={(e)=>this.creditNoteDetailsSection(e,response)} >
																									  <h4>₹ {response.balance}</h4>
																									  <h5>Referral Code</h5>
																									  {/* TODO--referral code needs to be replaced with proper value */}
																									  <h6 class="text-truncate">#{response.id}</h6>
																									  <p class="date">{response.addedOn.split(" ")[0]}</p>
																									  <p>Validity <span>{response.expiringOn.split(" ")[0]}</span></p>
																								  </button>
																							  </div>
												:
												<div class="noteview">
												<button type="button"
													onClick={(e)=>this.creditNoteDetailsSection(e,response)} >
													<h4>₹ {response.balance}</h4>
													<h5>Referral Code</h5>
													{/* TODO--referral code needs to be replaced with proper value */}
													<h6 class="text-truncate">#{response.id}</h6>
													<p class="date">{response.addedOn.split(" ")[0]}</p>
													<p>Validity <span>{response.expiringOn.split(" ")[0]}</span></p>
												</button>
											</div>
												}

											  </div>
											)
											)
	  									}
			         
								
							</div>
							
							
							
							
					<div class="pager">
						
						{this.props.creditNoteResponse!=null &&
										this.props.creditNoteResponse.length>=6 ?
	
						<div>
						<p>Showing {this.props.creditNoteResponse.length} entries</p>
						<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={6}
						totalItemsCount={this.props.creditNoteResponse.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChangeDefault.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>
						:
						<div> 
								<p>Showing {this.props.creditNoteResponse!=null ?
									this.props.creditNoteResponse.length:0} entries</p>
							
							   </div>
						}

	
						</div>
						
					
					</div>
					</div>
				</div>		

)

}
}


const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		creditNoteResponse,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,
		creditNoteResponse,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
});

export default connect(mapStateToProps, null)(CreditNote);

