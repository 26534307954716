import React from "react";
import history from '../../auth/history';
//import { ReCaptcha } from 'react-recaptcha-google'
//import { loadReCaptcha } from 'react-recaptcha-google'
import AuthService from "../../service/retailer-service-impl/AuthService";
import UserService from "../../service/spar-service-impl/userService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import dateFormat from "dateformat";
import {store} from "../../redux/store";
import { connect } from "react-redux";
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";
import { showRegisterErrorMessage } from "../../redux/error-message/eror-message.action";
import { showDashBoardComponentSection, showUserComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import {setUserFirstName} from "../../redux/misc-data/misc-data.action";
import deviceDetails from "../../service/spar-service-impl/deviceDetails";
import otpService from "../../service/spar-service-impl/otpService";
import { setUserRegisterData } from './../../redux/misc-data/misc-data.action';

class userRegistration extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			username: '',
			dateOfBirth: '',
			emailId: '',
			pincode: '',
			gender: '',
			selectedOption:'Male',
			firstname:'',
			lastname:'',
			buttonVisible:true,
			displayMessage:'',
			displayFisrtNameMessage:'',
			disableStatus:false,
			displayGenderMessage:'',
			displayLastNameMessage:'',
			emailErrorMessage:'',
			pinCodeErrorMessage:''
        }

		this.handleGenderChange = this.handleGenderChange.bind(this); 
		this. handleDobChange = this.handleDobChange.bind(this);
    }
     componentDidMount() {
	 	
     }
    handleUserNameChange= (event) => {
		this.setState({
			username: event.target.value
		})
	  }

	  handleUserFirstNameChange= (event) => {
	 
		this.setState({
			firstname: event.target.value
		})
	  }

	  handleUserLastNameChange= (event) => {
		this.setState({
			lastname: event.target.value
		})
	  }


	handleEmailChange = (event) => {
		this.setState({
			emailId: event.target.value
		})
	  }

	  handleDobChange= (event) => {
		
		var gender= this.state.selectedOption;
		// console.log('gender: ',gender);
		this.setState({
			
			dateOfBirth: event.target.value
		})
        //this.state.dateOfBirth = moment(this.state.dateOfBirth).format("MM/DD/YYYY");
		//console.log('Testing-handleDobChange :' + this.state.dateOfBirth)
	  }
	  handleGenderChange = (event) => {
		this.setState({
			selectedOption: event.currentTarget.value,
			gender:event.target.value
		  });

	 	//console.log('Testing-handleGender :' + this.state.selectedOption)
	  };	

	  handleFemaleGenderChange= (event) => {
		event.preventDefault();
		this.setState({
			selectedOption: event.currentTarget.value,
			gender:event.target.value
		  });

	 	//console.log('Testing-handleGender :' + this.state.selectedOption)
	  };	

	  handleMaleGenderChange= (event) => {
		event.preventDefault();

		this.setState({
			selectedOption: event.currentTarget.value,
			gender:event.target.value
		  });

	 	//console.log('Testing-handleGender :' + this.state.selectedOption)
	  };	

	  handleOtherGenderChange= (event) => {
		event.preventDefault();

		this.setState({
			selectedOption: event.target.value,
			gender:event.target.value
		  });

	 	//console.log('Testing-handleGender :' + this.state.selectedOption)
	  };	

	  handlePincodeChange = (event) => {
		var gender= this.state.selectedOption;
		console.log('gender: ',this.state.selectedOption);

		this.setState({
			pincode: event.target.value
		})
		//console.log('Testing-handlePincodeChange :' + this.state.pincode)
	  }

		handleRegistration = (event) => {
		
		event.preventDefault();


		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showRegisterErrorMessage(''));

			var url =  process.env.REACT_APP_url_For_WhitelabelRetailer_login;

            var firstname = this.state.firstname;
		   	var lastname = this.state.lastname;
			var	dateOfBirth=moment(this.state.dateOfBirth).format("MM-DD-YYYY");
			// var d = new Date(moment(this.state.dateOfBirth).format("MM-DD-YYYY"));


			
			var dateofBirthSplitter=dateOfBirth.split("-");
			var month=dateofBirthSplitter[0];
			var day=dateofBirthSplitter[1];
			var year=dateofBirthSplitter[2];
			// var d = new Date(moment(this.state.dateOfBirth).format("MM-DD-YYYY"));


			var d = new Date(year+"-"+month+"-"+day+"T00:00");
			console.log("d:::"+d);
			// var d = new Date(moment(this.state.dateOfBirth).format("YYYY-MM-DDThh:mm"));
  			var n = d.getFullYear();
			var curDate=new Date();
            console.log(curDate.getTime());
			console.log(d.getTime());
			var val= ((curDate.getTime() - d.getTime()) / 31536000000).toFixed(0);
			console.log('number',((curDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1);
			console.log('dateOfBirth: ',dateOfBirth);
			
			console.log('gender: ',this.state.selectedOption);


			const requestHeader={
				requestType: "CREATE_USER",
				requestId: uuidv4(),
				 requesterTimestamp: moment(),
				 mobileNumber:this.props.phone,
				 originalDialogueTraceId: null,
				 walletOwner: "SPAR",
				 channel: "WEB"
		  	}
	
			const otpDetails={
				otpType: "LOGIN",
			  	refNo: "",
				otp: ""
			}

		
			const customerInfo= {
			
			    mobileNumber:this.props.phone, 
			    email: this.state.emailId,
			    firstName: firstname,
				lastName: lastname,
				postalCode: this.state.pincode,
				gender:this.state.selectedOption==''?'MALE':this.state.selectedOption=='Other'?'OTHERS':
				this.state.selectedOption.toUpperCase(),
				dob: dateFormat(this.state.dateOfBirth,year+"-"+day+"-"+month+"'T'"+"00:00")+":00+0000"
			}
			
			const requestToRegsiter={
				requestHeader:requestHeader,
				deviceInfo:this.props.deviceInfo,
				customerInfo:customerInfo,

			}
			const routePage={
			    origincomponent:'register',
				//destinationcomponent:'welcome',
				destinationcomponent:'otpAfterRegsiter',
				failureComponent:'register',
				callBackMethod:'POST',
				dashBoardContentType:'user',
				responseContentType:'userData',
				dashBoardContentType:'user'
			}


			var url =  '/api/v1/user/create';
			var currentDate=new Date();

			console.log(JSON.stringify(requestToRegsiter));


			var rgularExp = {
				// contains_alphaNumeric : /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/,
				containsNumber : /\d+/,
				containsAlphabet : /[a-zA-Z]/,
			
				onlyLetters : /^[a-zA-Z. ]{3,30}$/,
				onlyLettersForLastName:/^[a-zA-Z. ]{1,30}$/,
				onlyNumbers : /^[0-9]+$/,
				onlyMixOfAlphaNumeric : /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/,
				onlyEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/
			}
		
            var condition1=false;
			var condition2=false;
			var genderCondition=true;
			var extraCondition=true;
			var emailCondition=true;
			var pincodeCondition=true;
           

			

			if(this.state.emailId!=''){
				if(rgularExp.onlyEmail.test(this.state.emailId)){
					emailCondition=true;
				}else{
					emailCondition=false;
					this.setState({
						emailErrorMessage:"Please enter valid email."
						})
						setTimeout(()=>{
							this.setState({
								emailErrorMessage:'',
							})	
						},3000);
				}	
				}

				
				if(this.state.pincode!=''){
					if(this.state.pincode.length==6 && !isNaN(this.state.pincode)){
						pincodeCondition=true;
					}else{
						pincodeCondition=false;
						this.setState({
							pinCodeErrorMessage:"Please enter valid Pincode."
							})
							setTimeout(()=>{
								this.setState({
									pinCodeErrorMessage:'',
								})	
							},3000);
					}	
					}

			if(rgularExp.onlyLetters.test(this.state.firstname)){
					condition1=true;
				
			}else{
				this.setState({
					displayFisrtNameMessage:"Minimum three characters required."
				
					})
					setTimeout(()=>{
						this.setState({
							displayFisrtNameMessage:'',
							
						})	
					},3000);
			}

			if(this.state.lastname!=''){
			if(rgularExp.onlyLettersForLastName.test(this.state.lastname)){
				extraCondition=true;
			}else{
				extraCondition=false;
				this.setState({
					displayLastNameMessage:"Minimum one characters required."
				
					})
					setTimeout(()=>{
						this.setState({
							displayLastNameMessage:'',
							
						})	
					},3000);
			}
		}

			 if(
				 (
			   ((currentDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1>=18
		   		&& (
			   (currentDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1<=99)){

				condition2=true;
			   }else{
				this.setState({
					displayMessage:"Age must be between 18 to 99."
				
					})
					setTimeout(()=>{
						this.setState({
							displayMessage:'',
							
						})	
					},3000);
			   }

            if(extraCondition==true && genderCondition==true && pincodeCondition==true
				&& emailCondition==true){
			if(condition1==true && condition2==true){



				store.dispatch(setUserFirstName(firstname)); 
				this.setState({
					buttonVisible: false,
					disableStatus:true,
				})
				setTimeout(()=>{
				this.setState({
					buttonVisible:true,
					disableStatus:false
				})	
				},1000);
				


				//generate otp call
				var otpurl= "/api/v1/otp/generate";        
				const requestHeader={
				  requestType: "GENERATE_OTP",
				  requestId: uuidv4(),
				  requesterTimestamp: moment(),
				  mobileNumber:this.props.phone,
				  originalDialogueTraceId: null,
				  walletOwner: "SPAR",
				  channel: "WEB"
				}
				
			
				const otpDetails={
				  otpType: "LOGIN",
				  refNo: null,
				  otp: null
	  
				}
				const request={
				  requestHeader:requestHeader,
				  deviceInfo:this.props.deviceInfo,
				  otpDetails:otpDetails
				}
	  
			  console.log('otp generate request:',JSON.stringify(request));  
			  store.dispatch(setUserRegisterData(requestToRegsiter));
			  otpService.generateOtpServiceCallInRegister(otpurl,request,routePage,this.props.phone);
               

			   //store.dispatch(showUserComponentSection('otpAfterRegsiter'));
			   //UserService.registerCall(url, request, routePage,this.props.phone);	
			}
		}
			
			
	  } 
      render(){

		console.log('register page loaded..');
		 return(
            <div>	
				{this.props.registerErrorMessage!='' &&
				<div class="valid-error">
				{this.props.registerErrorMessage}
				</div>
	  			}
		   		
            	<form  onSubmit={this.handleRegistration}>
					<div className="form-floating">
						<input type="text"  
						// minLength={3} 
						onChange={this.handleUserFirstNameChange}
						 class="form-control" id="name" placeholder="Name" required/>
						<label for="name">First Name*</label>
						{this.state.displayFisrtNameMessage!='' &&
						<div class="valid-error">
						{this.state.displayFisrtNameMessage}
						</div>
						}

					</div>
					
					
					<div className="form-floating">
						<input type="text"  
						 onChange={this.handleUserLastNameChange}
						 class="form-control" id="name" placeholder="Name" />
						<label for="name">Last Name</label>
						{this.state.displayLastNameMessage!='' &&
						<div class="valid-error">
						{this.state.displayLastNameMessage}
						</div>
						}
					</div>	

						
					<div className="form-floating">
						<input type="date" onChange={this.handleDobChange} 
						autoFocus  title="Please enter date of birth"
						class="form-control cldr" id="dob" placeholder="DD/MM/YYYY" required/>
						<label for="dob">Date of Birth*</label>
						{this.state.displayMessage!='' &&
					<div class="valid-error">
					{this.state.displayMessage}
					</div>
					}	
					</div>	

					
								
					<div className="form-floating">
						<input type="text" onChange={this.handleEmailChange}
						// autoFocus  title="Please enter email" 
						class="form-control" id="email" placeholder="Email ID"/>
						<label for="email">Email ID</label>
						{this.state.emailErrorMessage!='' &&
					<div class="valid-error">
					{this.state.emailErrorMessage}
					</div>
					}	
					</div>
				
					<div className="form-floating">
					<input type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
						onChange={this.handlePincodeChange} class="form-control" 
						id="pin" 
						// maxLength="6" 
						placeholder="Pincode"/>
						<label for="pin">Pincode</label>
						{this.state.pinCodeErrorMessage!='' &&
					<div class="valid-error">
					{this.state.pinCodeErrorMessage}
					</div>
					}	
					</div>

					

										
					<div className="formgender">
						
						<div className="form-check form-check-inline">
							<input class="form-check-input"
							 
							 
							checked={this.state.selectedOption === "Male"}
							onChange={this.handleMaleGenderChange}  
							type="radio" name="gender" id="Male" value="Male" />
							<label class="form-check-label" for="Male"> Male</label>
						</div>
						
						<div className="form-check form-check-inline">
							<input class="form-check-input" 

							
							// checked={this.state.selectedOption === "Female"}
							 onChange={this.handleFemaleGenderChange}  
							 type="radio" name="gender" id="Female" value="Female" />
							<label class="form-check-label" for="Female"> Female</label>
						</div>
						
						<div className="form-check form-check-inline">
							<input class="form-check-input" 
							//  checked={this.state.selectedOption === "Other"}
							
							 onChange={this.handleOtherGenderChange}   
							 type="radio" name="gender" id="Other" value="Other" />
							<label class="form-check-label" for="Other"> Other</label>
						</div>
					</div>	 
					
					{this.state.displayGenderMessage!='' &&
						<div class="valid-error">
						{this.state.displayGenderMessage}
						</div>
						}

					{this.state.disableStatus==false ?
					this.state.firstname!='' &&
					moment(this.state.dateOfBirth).format("DD/MM/YYYY")!='Invalid date' &&
					
					<button type="submit" className="btn primary">Register Me</button>
					:
					<button type="submit" disabled className="btn primary">Register Me 
					 <span class="spinner-border spinner-border-sm"></span></button>
		  			}
				
					{
				 	this.state.disableStatus==false
					&& (this.state.firstname=='' ||		
					moment(this.state.dateOfBirth).format("DD/MM/YYYY")=='Invalid date') &&
					
					<button type="submit" disabled className="btn primary">Register Me</button>
		  			}
					</form>
					</div>
          );
      } 
    }

const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,registerErrorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
	,deviceInfo}}
	) => ({
		viewDashBoardSection,errorMessage,
		registerErrorMessage,
		responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
		,deviceInfo
});

export default connect(mapStateToProps, null)(userRegistration);
