import { UserActionTypes } from './user.types';
import { act } from 'react-dom/test-utils';

const INITIAL_STATE = {
  roMdn:'',
  currentUser:'', 
  pricingGroupId:'',
  productId:'',
  maxTxnAmount:'', 
  minTxnAmount:'',
  inSufficientBasedOnCondition:true,
  userLastLoginTime:'',
  loginUserName:'',
  userType : '',
  channelId:'',
  transactionType:'',
  clientId:'',
  loggedInUserId :''
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_RO_MDN:
      return {
        ...state,
        roMdn: action.payload
      };
      case UserActionTypes.SET_PRICING_GROUP_ID:
      return {
        ...state,
        pricingGroupId: action.payload
      };
      case UserActionTypes.SET_PRODUCT_ID:
      return {
        ...state,
        productId: action.payload
      };
      case UserActionTypes.SET_MAX_MIN_AMOUNT:
        return{
          ...state,
          maxTxnAmount: action.payload.maxTxnAmount,
          minTxnAmount: action.payload.minTxnAmount
        }
      case UserActionTypes.SET_IN_SUFFICIENT_BASED_CONDITION:
        return{
          ...state,
          inSufficientBasedOnCondition: action.payload
        }
        case UserActionTypes.SET_USER_TYPE:
        return{
          ...state,
          userType: action.payload
        }
        case UserActionTypes.SET_LOGIN_USER_NAME:
        return{
          ...state,
          loginUserName: action.payload
        }
        case UserActionTypes.SET_USER_LAST_LOGIN_TIME:
        return{
          ...state,
          userLastLoginTime: action.payload
        }
        case UserActionTypes.SET_Transaction_Type:
        return{
          ...state,
          transactionType: action.payload
        }
        case UserActionTypes.SET_setChannelId:
        return{
          ...state,
          channelId: action.payload
        }
        case UserActionTypes.SET_LOGGED_USER_ID:
        return{
          ...state,
          loggedInUserId: action.payload
        }
        case UserActionTypes.SET_USER_CLIENTID:
          return{
            ...state,
            clientId: action.payload
          }
          case UserActionTypes.SET_USER_LOGOUT:
            return{
              ...state,
              roMdn:'',
              currentUser:'', 
              pricingGroupId:'',
              productId:'',
              maxTxnAmount:'', 
              minTxnAmount:'',
              inSufficientBasedOnCondition:true,
              userLastLoginTime:'',
              loginUserName:'',
              userType : '',
              channelId:'',
              transactionType:'',
              clientId:'',
              loggedInUserId :''
            }
      case UserActionTypes.RESET:
        return INITIAL_STATE;
    default:
      return state; 
  }
};
//  



export default userReducer;