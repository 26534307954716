import { AuthType } from "./auth.type";

export const setAuth = (data) => ({
    type: AuthType.setAuth,
    payload: data
    
  });

  

  export const setProfile = (data) => ({
    type: AuthType.setProfile,
    payload: data
    
  });

  export const setErrMsg = (data) => ({
    type: AuthType.setErrMsg,
    payload: data
    
  });

  export const resetAuth ={
    type: AuthType.RESET
  }