import { ErrorMessageType } from './error-message.type';

const INITIAL_STATE = {
    //default: errorMessage:''
    errorMessage:'',
    otpErrorMessage:'',
    registerErrorMessage:'',
    profileError:'',
    loginErrorMessage:'',
    orderErrorMsg:'',
    orderTrnasferMsg:''

};

const errorMessageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
     

        case ErrorMessageType.showErrorMessageSection:
            return {
                    ...state,
                    errorMessage: action.payload,
            };
            case ErrorMessageType.showOtpErrorMessage:
                return {
                        ...state,
                        otpErrorMessage: action.payload,
                };
                case ErrorMessageType.showRegisterErrorMessage:
                return {
                        ...state,
                        registerErrorMessage: action.payload,
                };
                case ErrorMessageType.profileUpdateErrorMessage:
                    return {
                            ...state,
                            profileError: action.payload,
                    };
                    case ErrorMessageType.showLoginErrorMessage:
                    return {
                            ...state,
                            loginErrorMessage: action.payload,
                    };
                    case ErrorMessageType.orderErrorMessage:
                        return {
                            ...state,
                            orderErrorMsg: action.payload,
                    };
                    case ErrorMessageType.orderTrnasferMessage:
                        return {
                            ...state,
                            orderTrnasferMsg: action.payload,
                    };
           
       
        case ErrorMessageType.RESET:
            return INITIAL_STATE;
                                                           
        default:
            return state;
    }
};

export default errorMessageReducer; 