import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import ApiCallingService from '../api-call-service';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import qcConnectorService from './qcConnectorService';
import giftCardLinkService from './giftCardLinkService';
import qCConnectorEnquiryServiceRetry from './QCConnectorEnquiryServiceRetry';
import deviceDetails from './deviceDetails';

class QCConnectorEnquiryService {

 checkEnquiryBalance(url, requestTosend,routePge,cardInfo,phoneNo) {
  console.log('inside checkEnquiryBalance ', url);
  //store.dispatch(setLoggedInUserID(username));

  var response = [];
  //process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
  
   async function run() {
    console.log('checkEnquiryBalance service Request',JSON.stringify(requestTosend));

    response=  await ApiCallingService.giftCardEnquiryCall(url, requestTosend);
    
    
     console.log("checkEnquiryBalance response__",JSON.stringify(response));
     if (response === 'error'||response==undefined) {
      console.log(response);
      
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));


      // setTimeout(()=>{
      //   qCConnectorEnquiryServiceRetry.checkEnquiryBalance(url, requestTosend,routePge,cardInfo);
      // },15000)
      
    }
    
    
   
    
    else if(response !=undefined 
      //&& response.status==200
      ){ 

    console.log(' checkEnquiryBalance res_code',response.responseCode);
    //  if(parseInt(response.responseCode)==0){
       console.log("---response for checkEnquiryBalance",response);
      var errorMessage='';
      store.dispatch(showErrorMessageSection(errorMessage));
    
    console.log("----checkEnquiryBalance call ended----");
    console.log("---giftcard linking call started----");

    
    const requestHeader={
        requestType: "LINK_GIFTCARD",
        requestId: uuidv4(),
         requesterTimestamp: moment(),
         mobileNumber:phoneNo,
         originalDialogueTraceId: uuidv4(),
         walletOwner: "SPAR",
         channel: "WEB"
   }

 
		


   if(parseInt(response.balance)>0){
      store.dispatch(showDashBoardComponentSection('giftCardReview'));

    }else{
      store.dispatch(showDashBoardComponentSection('giftCardOtp'));
      if(response.giftCardCheckResponse.ResponseMessage=="Transaction successful."){
      store.dispatch(showErrorMessageSection("Gift Card Balance is 0"));
      }else{
        store.dispatch(showErrorMessageSection(response.giftCardCheckResponse.ResponseMessage));
      }
      
    }
  }

    else{
      console.log("error response!",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    } 
    
  }
  run();
}


 }



export default new QCConnectorEnquiryService();
