import React, { useState, useEffect } from 'react';
import pageNotAccessable from "../../assests/images/icons/403.svg";
import history from '../../auth/history';



class PageNotAccessable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNo: '',
            amount: 0
        }
    }

    loginRedirect = (event) => {

        history.push('/');

        history.go();


    }


    render() {

        return (
            <>

                <div class="container">
                    <div class="row justify-content-sm-center">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div class="notfound">


                                {(this.props.errorMsg != undefined && this.props.errorMsg != null && this.props.errorMsg !== "") ?
                                    <div class='valid-error'>
                                        <img src={pageNotAccessable} alt="403 Error" class="img-fluid" />
                                        <h3>We are sorry... Access denied!</h3>
                                        <h3>Error: {' ' + this.props.errorMsg}</h3>
                                    </div> :
                                    <div>
                                        <img src={pageNotAccessable} alt="403 Error" class="img-fluid" />
                                        <h3>We are sorry... Access denied!</h3>
                                        <p>The Page you are trying to access has restricted access...</p>
                                    </div>

                                }
                                <button type="submit" class="btn primary" onClick={this.loginRedirect}>Go to login</button>
                            </div>
                        </div>
                    </div>
                </div>


            </>)

    }
}

export default PageNotAccessable;
