import React from 'react';
import { connect } from 'react-redux';
import logger from 'redux-logger';

import '../src/admin/common/css/bootstrap.min.css';
import '../src/admin/common/css/master.css';
import 'owl.carousel';


import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PageNotFoundSection from './omts/component/pageNotFoundComponent';
import PageNotAccessable from './omts/component/pageNotAccessable';
import AuthService from './service/service-impl/AuthService';
import HowToPlay from './omts/component/HowToPlay';
import TncGameOfWheel from './omts/component/TncGameOfWheel';
import Tes1 from './omts/component/Tes1';
import Tes2 from './omts/component/Tes2';
import spinWheelTest from './omts/component/spinWheelTest';
import SpinWheelApp from './omts/component/spinWheel13122021';
import history from './auth/history';
import SpinWheelTest from './omts/component/spinWheelTest';


import {

  Link

} from "react-router-dom";


import UserComponents from "./omts/component/userComponets";
import UserDashBoard from "./omts/component/userDashBoard";
import SSORedirect from './omts/component/ssoRedirect';
import spinwheenl2 from './omts/component/spinwheenl2';
// import { browserHistory } from 'react-router';



//import './App.css';

class App extends React.Component {


  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 ,  currentUser: null };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  

  // Things to do before unloading/closing the tab
  doSomethingBeforeUnload = () => {

   //AuthService.clearState();
}

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
      window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          return this.doSomethingBeforeUnload();
      });
  };
  componentDidMount() {
   //his.context.router.transitionTo("route", { search: this.state.search, type: this.state.type });


    // Activate the event listener
    //this.setupBeforeUnloadListener();
 
  // window.onbeforeunload = () => {
    
  // }
    
   this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
  //  window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  
  }

  redirect() {
    this.context.router.push("/path")
}




render() {

  const middlewares = [];
 
if(process.env.NODE_ENV==='development'){
 middlewares.push(logger);
}

  // // console.log('width and height is : ' + this.state.width + " and " + this.state.height)
  // const authResult = new URLSearchParams(window.location.search); 
  // const queryString = authResult.get('user');
  // //console.log("user_saurabh_singh", this.href.substring(this.href.lastIndexOf('/') + 1));
  // // console.log("window.location.pathname = " , window.location.pathname.split("/"));
  // const UrlSegment =  window.location.pathname.split("/");
  //  const  lastUrlSegment = UrlSegment[1] !=undefined ?UrlSegment[1]:'';
  
  // console.log("lastUrlSegment "+lastUrlSegment);
  // const isadmin = lastUrlSegment =="admin"?'':"main";
  // const state = store.getState();
  // const authToken = state.token.token;
  // const authenticated = state.token.authenticated;
  // const isRoLogin = state.user.roMdn;
  // console.log("header_roleData_appjs = "+ JSON.stringify(this.props.userRoleId));

  // console.log('1::::',this.props.userResponse);
  // console.log(this.props.walletResponse);
  // console.log(this.props.transactionResposne);
  // console.log(this.props.walletServiceResponse);
  // console.log("this.props.fetchTransactionResponse::",this.props.transactionResposne)
  // console.log('this.props.userProfileResponse...',this.props.userProfileResponse);
  
  var userProfileResponseStatus=this.props.userProfileResponse==''?'blank':this.props.userProfileResponse==null?'null':'above value';
  console.log('userProfileResponseStatus..',userProfileResponseStatus);


return(
<Router>
<Switch>
{/* 
<Route exact path="/retailer-login" component={RetailerLogin} /> */}
  <Route exact path='/google' component={() => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL;
   
    return null;
  }} />

{this.props.isAuth==false &&
<Route exact path="/user-dashboard" component={() => (<PageNotAccessable 
errorMsg={'Authentication failed'}/>)}/>
}  


{this.props.isAuth==true &&
<Route exact path="/user-dashboard" component={UserDashBoard} 
/>
}

{/* <Route exact path="/user-dashboard" component={UserDashBoard}></Route> */}
<Route exact path="/test1" component={Tes1} ></Route>
<Route exact path="/test2" component={Tes2} ></Route>

{this.props.isAuth==false &&
<Route exact path="/gameOfWheel" component={() => (<PageNotAccessable 
errorMsg={'Authentication failed'}/>)}/>
}  


{this.props.isAuth==true &&
<Route exact path="/gameOfWheel" component={spinWheelTest} 
/>
}
{this.props.isAuth==false &&
<Route exact path="/gameOfWheelTest" component={() => (<PageNotAccessable 
errorMsg={'Authentication failed'}/>)}/>
}  


{this.props.isAuth==true &&
<Route exact path="/gameOfWheelTest" component={spinWheelTest} 
/>
}

{this.props.isAuth==false &&
<Route exact path="/HowToPlay" component={() => (<PageNotAccessable 
errorMsg={'Authentication failed'}/>)}/>
}  


{this.props.isAuth==true &&
<Route exact path="/HowToPlay" component={HowToPlay} 
/>
}

{this.props.isAuth==false &&
<Route exact path="/tncGameOfWheel" component={() => (<PageNotAccessable 
errorMsg={'Authentication failed'}/>)}/>
}  


{this.props.isAuth==true &&
<Route exact path="/tncGameOfWheel" component={TncGameOfWheel} 
/>
}

<Route exact path="/wheel-test" component={spinwheenl2} />
<Route exact path="/ssoRedirect" component={SSORedirect} />
<Route exact path="/403" component={() => (
<PageNotAccessable errorMsg={this.props.errorMsg}/>)}/>
<Route exact path="/user-dashboard/*" component={PageNotFoundSection} ></Route>
<Route exact path="/" component={UserComponents} ></Route>
<Route exact path="/*" component={PageNotFoundSection} ></Route>


</Switch>
</Router>)

}
}


const mapStateToProps = ({ 
  showAddHide:{viewSection,viewDashBoardSection},errorMessageReduce:{errorMessage},responseReduce:{responseDetailsVal,dashBoardResponseDetails,
    userResponse,walletResponse,transactionResposne,walletServiceResponse,
    fetchTransactionResponse,userProfileResponse},ordeDetailsReduce:{orderId},
    authReduce:{isAuth,errorMsg}
  }) => ({
    viewSection,viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,
    userResponse,walletResponse,transactionResposne,walletServiceResponse,
    fetchTransactionResponse,userProfileResponse,
    orderId,isAuth,errorMsg
  });

  export default connect(mapStateToProps,null)(App);