
import axios from 'axios';
import store from '../../redux/store';

const httpClient = axios.create();
class DeviceInfo {

  async fetchDeviceInfo(latitude, longitude) {
    const mobile = Boolean(
      window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    var mobileVar = 'Laptop'

    if (mobile) {
      mobileVar = 'Mobile'
    }

    var nav = navigator.userAgent;
    var subNav = nav.split(';');
    console.log('nav  ', nav);

    var os = ''
    var device_os = ''

    if (mobile) {
      os = subNav[1].split(') ')
      device_os = os[0]
    } else {
      os = subNav[0].split('(')
      device_os = os[1]
    }

    console.log('os  ', os);


    var osVersion = subNav[1].split(')')

    console.log('device_ os ', device_os);
    console.log('os Version', osVersion);

    var sBrowser, sUsrAg = navigator.userAgent;


    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }

    console.log('os sBrowser', sBrowser);



    var osname = this.getOS();


    if (osname == 'Mac OS') {
      osname = 'MacOS';
    } else if (osname == 'iOS') {
      osname = 'IOS';
    } else if (osname == 'Windows') {
      osname = 'WINDOWS';
    } else if (osname == 'Linux') {
      osname = 'LINUX';

    } else if (osname == 'Android') {
      osname = 'ANDROID';

    }
    console.log('os osname....', osname);
    
    
    var coordinates = [];
      coordinates.push(latitude);
      coordinates.push(longitude);

      var geoLocation = {
        type: 'Point',
        coordinates: coordinates

      }


    const deviceInfo = {
      deviceId: mobileVar == 'Laptop' ? 'MAC_ADDR' : 'IMEI',
      id: null,
      osName: osname,
      osVer: osVersion[0],
      ipAddr: '',
      browserVer: sBrowser,
      location: geoLocation
    }

    return this.getIp1(deviceInfo,latitude,longitude);

    


    return deviceInfo;

  }



  getOS() {
    console.log('enter get os');
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }


  getIp1(deviceInfo,latitude,longitude) {
    //return axios.get('https://api.db-ip.com/v2/free/self/');
    httpClient.get('https://api.db-ip.com/v2/free/self/').
    then((ip)=>{
      console.log('ip address from new approach....', ip.data.ipAddress);
      if(ip.data.ipAddress==undefined||ip.data.ipAddress==null||ip.data.ipAddress==''){
        deviceInfo.ip = '127.0.0.1';
      }else{        
        var ip1=ip.data.ipAddress;
        var ipArr=ip1.split('.');
     
        if(ipArr.length==4){
          deviceInfo.ip = ip.data.ipAddress;
        }else{
          deviceInfo.ip = '127.0.0.1';
        }
        
      }

      console.log('deviceInfo...'+JSON.stringify(deviceInfo));
    }).catch(err=>{
  
      deviceInfo.ip = '127.0.0.1';
      console.log('deviceInfo...'+JSON.stringify(deviceInfo));
    })

    return deviceInfo;
  }




}
export default new DeviceInfo();
