import React from "react";

import {store} from "../../redux/store";

import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import userUpdateService from "../../service/spar-service-impl/userUpdateService";
import UserMale from "../../assests/images/user-male.png";
import UserFemale from "../../assests/images/user-female.png";
import { showUserComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { connect } from "react-redux";
import { profileUpdateErrorMessage, showErrorMessageSection } from "../../redux/error-message/eror-message.action";

import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
class profileSectionComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={
			response: null,
			userName:this.props.userProfileResponse.customerInfo.firstName+' '+this.props.userProfileResponse.customerInfo.lastName,
			dateOfBirth:this.props.userProfileResponse.customerInfo.dob,
			phoneNo:this.props.userProfileResponse.customerInfo.mobileNumber,
			email:this.props.userProfileResponse.customerInfo.email,
			pinCode:this.props.userProfileResponse.customerInfo.postalCode,
			gender:this.props.userProfileResponse.customerInfo.gender,
		    readOnly:true,
			activeProfileUpdateButton:0,
			selectedOption:'',
			firstName:this.props.userProfileResponse.customerInfo.firstName,
			lastName:this.props.userProfileResponse.customerInfo.lastName,
			displayMessage:'',
			displayFisrtNameMessage:'',
			displayLastNameMessage:'',
			emailErrorMessage:'',
			pinCodeErrorMessage:'',
		
			

        }

       }

		componentDidMount() {
		}

		
		profileInfoEdit = (event) => {
			//event.preventDefault();
			console.log('Inside profileInfoEdit');
			this.setState({
				readOnly: false,
			activeProfileUpdateButton:1

			})
			
	     }
	 	userNameChange = (event) => {	
		this.setState({
			readOnly: false
		})		 	
		this.setState({
            userName: event.target.value
		})		
	   }

	   userFirstNameChange =(event) => {	
		this.setState({
			readOnly: false
		})		 	
		this.setState({
            firstName: event.target.value
		})		
	   }
	   userLastNameChange =(event) => {	
		this.setState({
			readOnly: false
		})		 	
		this.setState({
            lastName: event.target.value
		})		
	   }


	  dateOfBirthChange = (event) => {
		this.setState({
			readOnly: false
		})		
		this.setState({
            dateOfBirth: event.target.value
		})		
	  	}
	  mobileChange = (event) => {
		// this.setState({
		// 	readOnly: false
		// })		
		this.setState({
            phoneNo: event.target.value
		})		
	   }
	   emailChange = (event) => {
		this.setState({
			readOnly: false
		})		
		this.setState({
            email: event.target.value
		})		
	   }
		pinCodeChange = (event) => {
			this.setState({
				readOnly: false
			})			
		this.setState({
			pinCode: event.target.value
		})		
		}
		gender= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		genderr= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		genderx= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		gendery= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		genderz= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		gender1= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		

		genderCheck= (event) => {
			console.log('gender clicked');
			this.setState({
				selectedOption: event.target.value,
				readOnly: false,
				gender: event.target.value
			})
		//console.log('gender:::',this.state.gender);			
		}
		lettersOnly(event) {
			
            var charCode = event.keyCode;
			console.log('charCode>>>>',charCode);
            if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8)
                return true;
            else
                return false;
		}

		closeModal=(event)=>{
			store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		  }
		


		profileUpdate= (event) => {	
            event.preventDefault();
		
	
			console.log('gender value::::',this.state.gender)
			event.preventDefault();
			store.dispatch(showUserComponentSection('login'));
			store.dispatch(profileUpdateErrorMessage(''));
			// localStorage.setItem('profileError','');
			var response=[];	

	
			var	dateOfBirth=moment(this.state.dateOfBirth).format("MM-DD-YYYY");

		
			//var d = new Date(moment(this.state.dateOfBirth).format("MM-DD-YYYY"));
			var d = new Date(moment(this.state.dateOfBirth).format("YYYY-MM-DDThh:mm"));
			var currentDate=new Date();

			var curDate=new Date();
            console.log(curDate.getTime());
			console.log(d.getTime());
			var val= ((curDate.getTime() - d.getTime()) / 31536000000).toFixed(0);
			console.log('difference of years',((curDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1);
			console.log('dateOfBirth: ',dateOfBirth);
			
			var gender='';
	        if(this.state.gender=='Male'){
             gender='MALE';
			}else if(this.state.gender=='Female'){
			gender='FEMALE';
			}else if(this.state.gender=='Other'){
			gender='OTHERS';
			}
		
			
			const requestHeader={
				requestType: "UPDATE_USER_PROFILE",
				requestId: uuidv4(),
				 requesterTimestamp: moment(),
				 mobileNumber:this.state.phoneNo,
				 originalDialogueTraceId: null,
				 walletOwner: "SPAR",
				 channel: "WEB"
		  	}


		

	
			const otpDetails={
				otpType: "CREATE_CUSTOMER",
			  	refNo: this.props.otpRefNo,
				otp: this.props.otpVal
			}
			const customerInfo= {
				
			    mobileNumber:this.state.phoneNo, 
			    email: this.state.email,
			    firstName: this.state.firstName,
				lastName: this.state.lastName,
				postalCode: this.state.pinCode,
				gender:gender==""?this.props.userProfileResponse.customerInfo.gender:gender,
				dob: this.state.dateOfBirth
			}
			
			const request={
				requestHeader:requestHeader,
				deviceInfo:this.props.deviceInfo,
				customerInfo:customerInfo,
				promoCode:""
			}



			var rgularExp = {
				// contains_alphaNumeric : "/^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/",
				containsNumber : /\d+/,
				containsAlphabet : /[a-zA-Z]/,
			
				onlyLetters : /^[a-zA-Z. ]{3,30}$/,
				onlyLettersForLastName : /^[a-zA-Z. ]{1,30}$/,
				onlyNumbers : /^[0-9]+$/,
				onlyMixOfAlphaNumeric : /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/,
				onlyEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/
			}
		
            var condition1=false;
			var condition2=false;
			var extraContidion=true;
			var emailCondition=true;
			var pincodeCondition=true;

			if(this.state.email!='' && this.state.email!=null){
			if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/.test(this.state.email)){
				emailCondition=true;
			}else{
				emailCondition=false;
				this.setState({
					emailErrorMessage:"Please enter valid email."
					})
					setTimeout(()=>{
						this.setState({
							emailErrorMessage:'',
						})	
					},3000);
			}	
			}

			if(this.state.pinCode!=''&& this.state.pinCode!=null){
				if(this.state.pinCode.length==6 && !isNaN(this.state.pinCode)){
					pincodeCondition=true;
				}else{
					pincodeCondition=false;
					this.setState({
						pinCodeErrorMessage:"Please enter valid Pincode."
						})
						setTimeout(()=>{
							this.setState({
								pinCodeErrorMessage:'',
							})	
						},3000);
				}	
				}

			if(rgularExp.onlyLetters.test(this.state.firstName)){
				condition1=true;
			}else{
				this.setState({
					displayFisrtNameMessage:"Minimum three characters required."
					})
					setTimeout(()=>{
						this.setState({
							displayFisrtNameMessage:'',
						})	
					},3000);
				}

				if(this.state.lastName!=''){
					if(rgularExp.onlyLettersForLastName.test(this.state.lastName)){
							extraContidion=true;
					}else{
						extraContidion=false;
						this.setState({
							displayLastNameMessage:"Only Characters allowed."
						
							})
							setTimeout(()=>{
								this.setState({
									displayLastNameMessage:'',
									
								})	
							},3000);
					}
					}

				if((
				((currentDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1>=18
					&& (
				(currentDate.getTime() - d.getTime()) / 31536000000).toFixed(0)-1<=99)){
				 	condition2=true;
				}else{
				 this.setState({
					 displayMessage:"Age must be between 18 to 99."
				 
					 })
					 setTimeout(()=>{
						 this.setState({
							 displayMessage:'',
							 
						 })	
					 },3000);
				}


			if(extraContidion==true && emailCondition==true && pincodeCondition==true){
	        if(condition1==true && condition2==true){
					this.setState({
						activeProfileUpdateButton: 2,
						readOnly:true
			
					})
		
					setTimeout(()=>{
						this.setState({
						activeProfileUpdateButton:0})
					},1000);		
			console.log('user update request:::'+JSON.stringify(request));
			const updateUrl='/api/v1/user/update';

			userUpdateService.userUpdateServiceCall(updateUrl,request);

		
			
		}
			
		}

		}
	  
      	render(){  
	
		var datevalue=new Date(moment(this.state.dateOfBirth).format("MM-DD-YYYY"));
		console.log('datevalue::',datevalue);
		console.log('userProfileResponse::::',this.props.userProfileResponse);
          return(
            <><div>
				

				<form onSubmit={this.profileUpdate}>

                <div class='valid-error'>
				{this.props.errorMessage}
				</div>
				<div class="innercard">
					<div class="headtxt">
						<h3>Profile</h3>
						<button type="button" class="close" onClick={this.closeModal}>close</button>
						<button type="button" id="proedit" class="edit" onClick={this.profileInfoEdit}></button>
						</div>

						
					<div class="user-basic">
						
					
						<div class="row">
							<div class="col-12 col-md-3">
						
								<div class="prophoto">
							 
								{
								// this.props.isProfile==true &&
								this.props.userProfileResponse!=''&&
								this.props.userProfileResponse.customerInfo!=null &&
								this.props.userProfileResponse.customerInfo.gender!=null ?
									this.props.userProfileResponse.customerInfo.gender=="FEMALE" ?
								<img src={UserFemale} alt="SPAR" class="img-fluid"/>
								
								
							:
							
									this.props.userProfileResponse.customerInfo.gender=="MALE"?
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								
								:

					
									
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								:
								<img src={UserMale} alt="SPAR" class="img-fluid"/>
								}
							
								</div>
							</div>

							{(this.state.readOnly==false)&&
							<div class="col-12 col-md-9">
							<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-floating">
									<input type="text"
									 onChange={this.userFirstNameChange} 
									 value={this.state.firstName} 
									  id="" class="form-control" placeholder="Name"/>
									<label for="name">First Name*</label>
									{this.state.displayFisrtNameMessage!='' &&
									<div class="valid-error">
									{this.state.displayFisrtNameMessage}
									</div>
								}
								</div> 
								
								
						        </div>

							

							
							<div class="col-12 col-md-6">
								<div class="form-floating">
									<input type="text"
									onChange={this.userLastNameChange} 
									value={this.state.lastName} 
									id="" class="form-control" placeholder="Name"/>
									<label for="name">Last Name</label>
									{this.state.displayLastNameMessage!='' &&
									<div class="valid-error">
									{this.state.displayLastNameMessage}
									</div>
								}
								</div> 
								
							</div>	
						
							</div>
					
						
								<div class="form-floating">
									
									<input type="text"  onChange={this.mobileChange} value={this.state.phoneNo}  class="form-control" id="mob" placeholder="Name" 
                                    readOnly/>
									<label for="name">Mobile Number</label>
								</div>
							</div>
							 
	  						}
							{(this.state.readOnly==true)&&
							<div class="col-12 col-md-9">
							<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-floating">
									<input type="text"
									pattern="[a-zA-Z. ]{3,30}"
									minLength={3} readOnly required 
									onChange={this.userFirstNameChange} 
									value={this.state.firstName}  
									id="" class="form-control" placeholder="Name"/>
									<label for="name">First Name*</label>
								</div> 
								</div>
								<div class="col-12 col-md-6">
								<div class="form-floating">
									
									<input type="text" 
								 	pattern="[a-zA-Z. ]{1,30}"
									  readOnly onChange={this.userLastNameChange} 
									  value={this.state.lastName}  id="" 
									  class="form-control" placeholder="Name"/>
									<label for="name">Last Name</label>
								</div> 
								</div>
								
								</div>
								<div class="form-floating">
									<input 
									type="tel"  pattern="[123456789][0-9]{9}" 
									autoFocus required title="Please enter phone no." onChange={this.mobileChange} value={this.state.phoneNo} class="form-control" id="mob" placeholder="Name" 
                                    readOnly/>
									<label for="name">Mobile Number</label>
								</div>
								
							</div>
	  						}
						</div>
						</div>
					
						<div class="user-profile">		
							<div class="row">
								<div class="col-12 col-md-6">
									{(this.state.readOnly==false) &&
									<div class="form-floating">
									
									<input type="date" onChange={this.dateOfBirthChange} 
									value={this.state.dateOfBirth}
									autoFocus required title="Please enter date in correct format." 
									maxLength="10"
									class="form-control cldr" id="dob" 
                                    />
									{this.state.displayMessage!='' &&
									<div class="valid-error">
									Age must be between 18 to 99!.
									</div>
								}
									<label for="dob">Date of Birth*</label>
								</div>
								
	  							}

								
								{(this.state.readOnly==true) &&
								<div class="form-floating">
									<input type="text" 
							
									  value={moment(this.state.dateOfBirth).format("DD-MM-YYYY")}   
									  class="form-control cldr" id="dob" placeholder="DD/MM/YYYY" 
                                    readOnly/>
									<label for="dob">Date of Birth*</label>

								
								</div>
	  							}
							</div>
							{
							(this.state.readOnly==false) &&
							<div class="col-12 col-md-6">
								<div class="form-floating">
									<input type="text" 
									onChange={this.emailChange}   
									value={this.state.email} class="form-control" id="email" 
									placeholder="Email ID" 
                                    />
									<label for="email">Email ID</label>
									{this.state.emailErrorMessage!='' &&
									<div class="valid-error">
								{this.state.emailErrorMessage}
									</div>
		  						}
								</div>
								
					
								
							</div>

								
	  						 }
							{
							(this.state.readOnly==true) &&
							<div class="col-12 col-md-6">
								<div class="form-floating">
									<input type="email" onChange={this.emailChange} value={this.state.email} 
									pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"
									class="form-control" id="email" placeholder="Email ID" 
                                    readOnly/>
									<label for="email">Email ID</label>
								</div>
							</div>


							
								}


	  						 


							{(this.state.readOnly==false) &&
							<div class="col-12 col-md-6">
								<div class="form-floating">
								<input type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} 
									onChange={this.pinCodeChange}   
									// maxLength={6}
									value={this.state.pinCode}  class="form-control" id="pin"
									 placeholder="Pincode" 
                                    />
										{this.state.pinCodeErrorMessage!='' &&
									<div class="valid-error">
									{this.state.pinCodeErrorMessage}
									</div>
		  }
									<label for="pin">Pincode</label>
								</div>

							
							</div>
	  						}
							{(this.state.readOnly==true) &&
							<div class="col-12 col-md-6">
								<div class="form-floating">
								<input type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
									 onChange={this.pinCodeChange} value={this.state.pinCode}  class="form-control" id="pin" 
									 placeholder="Pincode" 
                                    readOnly/>
									<label for="pin">Pincode</label>
								</div>
							</div>
	  						}


							<div class="col-12 col-md-6">
							
							{
							(this.state.readOnly==true) &&
							 (this.props.userProfileResponse.customerInfo.gender=="MALE") &&
							 
								<div class="formgender">
								
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Male"}
										onChange={this.genderz} type="radio" name="gender" id="Male" value="Male"   disabled/>
										<label class="form-check-label" for="Male">Male</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Female"}
										onChange={this.genderz} type="radio" name="gender" id="Female" value="Female"  disabled/>
										<label class="form-check-label" for="Female">Female</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Other"}
										onChange={this.genderz} type="radio" name="gender" id="Other" value="Other"  disabled/>
										<label class="form-check-label" for="Other">Other</label>
									</div>
	  								

								</div>
	  						}
						
							{
							(this.state.readOnly==true) &&
							 (this.props.userProfileResponse.customerInfo.gender=="FEMALE") &&
								<div class="formgender">
								
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Male"}
										onChange={this.genderx}
										 type="radio" name="gender" id="Male" value="Male" readOnly disabled />
										<label class="form-check-label" for="Male">Male</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Female"}
										onChange={this.genderx} type="radio" name="gender" id="Female" value="Female"  readOnly disabled/>
										<label class="form-check-label" for="Female">Female</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Other"}
										onChange={this.genderx} type="radio" name="gender" id="Other" value="Other" readOnly disabled/>
										<label class="form-check-label" for="Other">Other</label>
									</div>
	  								

								</div>
	  						}

							{
							(this.state.readOnly==false) &&
							
								<div class="formgender">
								
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Male"}
										onChange={this.genderCheck}
										 type="radio" name="gender" id="Male" value="Male" />
										<label class="form-check-label" for="Male">Male</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Female"}
										onChange={this.genderCheck} type="radio" name="gender" id="Female" value="Female" />
										<label class="form-check-label" for="Female">Female</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Other"}
										onChange={this.genderCheck} type="radio" name="gender" id="Other" value="Other"/>
										<label class="form-check-label" for="Other">Other</label>
									</div>
	  								

								</div>
	  						}


							{/* {                           
							(this.state.readOnly==false) &&
							 (this.props.userProfileResponse.customerInfo.gender=="OTHERS") &&
								<div class="formgender">
							
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Male"}
										onChange={this.gender2} type="radio" name="gender" id="Male" value="Male"  />
										<label class="form-check-label" for="Male">Male</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input"
										checked={this.state.selectedOption === "Female"}
										onChange={this.gender2} type="radio" name="gender" id="Female" value="Female" />
										<label class="form-check-label" for="Female">Female</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Other"}
										onChange={this.gender2} type="radio" name="gender" id="Other" value="Other" checked/>
										<label class="form-check-label" for="Other">Other</label>
									</div>
	  								

								</div>
	  							} */}

							{                           
							(this.state.readOnly==true) &&
							 (this.props.userProfileResponse.customerInfo.gender=="OTHERS") &&
								<div class="formgender">
								
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Male"}
										onChange={this.gender1} type="radio" name="gender" id="Male" value="Male" disabled />
										<label class="form-check-label" for="Male">Male</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Female"}
										onChange={this.gender1} type="radio" name="gender" id="Female" value="Female" disabled/>
										<label class="form-check-label" for="Female">Female</label>
									</div>
									
									<div class="form-check form-check-inline">
										<input class="form-check-input" 
										checked={this.state.selectedOption === "Other"}
										onChange={this.gender1} type="radio" name="gender" id="Other" value="Other"  disabled/>
										<label class="form-check-label" for="Other">Other</label>
									</div>
								</div>
	  							}
								</div>
							
								{
								this.state.activeProfileUpdateButton==1 && 
									this.state.firstName!='' &&
									
								<div class="col-12 col-md-6">
									<button type="submit" id="proupdate" class="btn primary">Profile Update</button>
								</div>
	  							}
								{
								this.state.activeProfileUpdateButton==0 && 
									this.state.firstName=='' &&
								<div class="col-12 col-md-6">
									<button type="submit" disabled id="proupdate" class="btn primary">Profile Update 
									<span class="spinner-border spinner-border-sm"></span>
									</button>
								</div>
	  							}
								  {
								this.state.activeProfileUpdateButton==2 && 
									
								<div class="col-12 col-md-6">
									<button type="submit" disabled id="proupdate" class="btn primary">Profile Update 
									<span class="spinner-border spinner-border-sm"></span>
									</button>
								</div>
	  							}
						</div>
					</div>
					</div>

				</form>

			
            </div>
            </>

		
          );
      } 
}

const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,profileError},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
	,otpVal,
    otpRefNo,
	deviceInfo
	},
	authReduce:{isProfile},
},

	) => ({
		viewDashBoardSection,errorMessage,
		profileError,
		responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
		otpVal,
    	otpRefNo,
		deviceInfo,
		isProfile
		
});

export default connect(mapStateToProps, null)(profileSectionComponent);
