import React from  "react";
import {store} from '../../redux/store';
import axios from "axios";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { setComplaintFetchResponse } from "../../redux/response/response.action";
import { connect } from "react-redux";
const httpClient = axios.create();
const Option = (props) => {
	return (
	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };
class Complaints extends React.Component{

        // eslint-disable-next-line no-useless-constructor
        constructor(){
            super();

            this.state={
                typeOptions:[
                    { value: "Add Money", label: "Add Money" },	
                    { value: "Bonus Club", label: "Bonus Club" },
                    { value: "Cashback", label: "Cashback" },
                    { value: "Coupon", label: "Coupon" },	
                    { value: "Credit Note", label: "Credit Note" },	
                    { value: "Gift Card", label: "Gift Card" }	
                                                    
                 ],
                 statusOptions:[
                    { value: "Open", label: "Open" },	
                    { value: "Closed", label: "Closed" },
                    { value: "Re-Open", label: "Re-Open" }
                    
                                                    
                 ],
                 typeList:[],
                 typeSelect:[],
                 statusList:[],
                 statusSelect:[]
            }
        }


        componentDidMount(){
            var url='/api/v1/complaint/fetchComplaint'
            httpClient.get(url).then((res) => {
              
                console.log("response::"+JSON.stringify(res.data));
                // alert('fetched response!'+JSON.stringify(res.data));
                store.dispatch(setComplaintFetchResponse(res.data));
                
              
                                
        }).catch(error=>{
          //console.log(error);
          console.log(error);
          console.log(error.data);
        });


        }

        typeChange= (selected) => {
	
            this.state.typeSelect=[];
            this.setState({
                typeList:selected
            })
            if(selected.length>0){
            selected.map(response => { 
            this.state.typeSelect.push(response.value);
            })}

        console.log(this.state.typeSelect);
        // this.handleFilterItemType();
            
        };

        statusChange= (selected) => {
	
            this.state.statusSelect=[];
            this.setState({
                statusList:selected
            })
            if(selected.length>0){
            selected.map(response => { 
            this.state.statusSelect.push(response.value);
            })}

        console.log(this.state.statusSelect);
        // this.handleFilterItemType();
            
        };
        closeModal = (event) => {
            event.preventDefault();
            store.dispatch(showDashBoardComponentSection('trasactionHistory'));
          }
          
    
                    render(){
                        return(
                     <div class="innercard">
                                            <div class="headtxt">
                                                <h3>Complaints</h3>
                                                <button type="button" class="close" onClick={this.closeModal} 
                                                data-toggle="tooltip" title="" data-bs-original-title="Close">close</button>
                                                

                                                <div class="dropdown fltr">
                                                    <button type="button" class="filter dropdown-toggle" id="fillist" 
                                                    data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filter</button>
                                                    
                                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="fillist">
                                                        <i class="closefil">x</i>
                                                        <form>									
                                                            <div class="filters">
                                                                <label for="status">Status:</label>
                                                                {/* <select data-placeholder="..." multiple class="chosen-select" tabindex="8" id="status">
                                                                    <option value="2">Open</option>
                                                                    <option value="3">Closed</option>
                                                                    <option value="4">Re-open</option>											
                                                                </select> */}

                                                                <ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
                                                                options={this.state.statusOptions}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={this.statusChange}
                                                                allowSelectAll={true}
                                                                value={this.state.statusList}
                                                                ></ReactSelect>
                                                            </div>
                                                            
                                                            <div class="filters">
                                                                <label for="type">Type:</label>
                                                                {/* <select data-placeholder="..." multiple class="chosen-select" tabindex="8" id="type">
                                                                    <option value="1">Add Money</option>
                                                                    <option value="2">Bonus Club</option>
                                                                    <option value="3">Cashback</option>
                                                                    <option value="4">Coupon</option>
                                                                    <option value="5">Credit Note</option>
                                                                    <option value="6">Gift Card</option>
                                                                    <option value="6">Other</option>
                                                                </select> */}

                                                                <ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
                                                                options={this.state.typeOptions}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={this.typeChange}
                                                                allowSelectAll={true}
                                                                value={this.state.typeList}
                                                                ></ReactSelect>
                                                            </div>
                                                            
                                                            <div class="filters">
                                                                <label for="select-state">From Date:</label>
                                                                <input type="date" value="" name="" id=""/>
                                                            </div>
                                                            
                                                            <div class="filters">
                                                                <label for="select-state">To Date:</label>
                                                                <input type="date" value="" name="" id=""/>
                                                            </div>

                                                            <div class="fbtn">
                                                                <button class="mediumbtn" type="reset" id="btnClear">Clear all</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="allcomplaints" id="complist">
                                                {this.props.complaitFetchResponse.length==0 &&
                                                <div>No complaints!</div>}

                                                {this.props.complaitFetchResponse.length>0 && 
                                                this.props.complaitFetchResponse.map(rsp=>(
                                                     <div class="compitem">
                                                     <div class="txn-head" id="comphead3">
                                                         <div class="collapsed" data-bs-toggle="collapse" data-bs-target={"#comp"+rsp.txnId} aria-expanded="false" aria-controls="comp3">
                                                             <div class="comphead">
                                                                 <div class="row">								
                                                                     <div class="col-12 col-md-5">
                                                                         <div class="comptxt">
                                                                             <i class="bonus"></i>
                                                                             <h5>{rsp.complaintType}</h5>
                                                                             {rsp.complaintType=='BONUS_CASH' &&										
                                                                             <p>Buy Bonus Club</p>
                                                                             }
                                                                             {rsp.complaintType=='ECASH' &&										
                                                                             <p>Add Money</p>
                                                                             }
                                                                         </div>
                                                                     </div>
                                                                     <div class="col-9 col-md-5">
                                                                         <div class="comptxt">
                                                                             <h6>Ticket ID: #{rsp.ticketId}</h6>
                                                                             <p>{rsp.ticketCreationTime}</p>
                                                                         </div>
                                                                     </div>								
                                                                     <div class="col-3 col-md-2">
                                                                         <div class="comptxt comprgt">
                                                                             <p>Status</p>
                                                                             <h4 class="reopen">{rsp.status}</h4>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     
                                                     <div id={"comp"+rsp.txnId} class="accordion-collapse collapse" aria-labelledby="comphead3" data-bs-parent="#complist">
                                                         <div class="compdtl">
                                                             <div class="row">									
                                                                 <div class="col-12"><hr/></div>
                                                                 <div class="col-12 col-md-5">
                                                                     <div class="comptxt">
                                                                         <h6>Txn ID: #{rsp.txnId}</h6>
                                                                         <p>{rsp.ticketCreationTime}</p>
                                                                     </div>
                                                                 </div>
 
                                                                 <div class="col-6 col-md-5">
                                                                     <div class="comptxt">
                                                                         <h4>₹ {rsp.amount}</h4>
                                                                         {rsp.status='SUCCESS' ?
                                                                         <p class="success">Success</p>:
                                                                         <p class="failed">Failed</p>
                                                                            }
                                                                             {/* {rsp.status='Failed' &&
                                                                            <p class="failed">Failed</p>
                                                                            }									 */}
                                                                     </div>
                                                                 </div>
                                                                 {/* {rsp.attachment}								 */}
                                                                 {(rsp.attachment!=null|| rsp.attachment!='') &&
                                                                 <div class="col-6 col-md-2">
                                                                     <div class="comptxt comprgt">
                                                                         <p>Upload File</p>
                                                                         
                                                                         <h6><a href={rsp.attachment} target="_blank" class="attach">Attached</a></h6>
                                                                     </div>
                                                                 </div>
                                                                    }
                                                                     {(rsp.attachment==null) &&
                                                                 <div class="col-6 col-md-2">
                                                                     <div class="comptxt comprgt">
                                                                         <p>Upload File</p>
                                                                         
                                                                         <h6>NA</h6>
                                                                     </div>
                                                                 </div>
                                                                    }
                                                                 
                                                                 {/* <div class="col-12"><hr/></div>
                                                                 <div class="col-12 col-md-8">
                                                                     <div class="comptxt">
                                                                         <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                         <h6>Lorem Ipsum is simply dummy text</h6>
                                                                     </div>
                                                                 </div>							
                                                                 <div class="col-6 col-md-4">
                                                                     <div class="comptxt text-md-end">
                                                                         <p>Remark by</p>
                                                                         <h6>Vishal Verma</h6>
                                                                     </div>
                                                                 </div> */}
                                                                 
                                                                 {/* <div class="col-12"><hr/></div>
                                                                 <div class="col-12 col-md-8">
                                                                     <div class="comptxt">
                                                                         <p>User - Remark - 04-09-2020 11:34:54</p>
                                                                         <h6>Lorem Ipsum is simply dummy text</h6>
                                                                     </div>
                                                                 </div>							
                                                                 <div class="col-6 col-md-4">
                                                                     <div class="comptxt text-md-end">
                                                                         <p>Remark by</p>
                                                                         <h6>Ramesh Swamy</h6>
                                                                     </div>
                                                                 </div> */}

<div class="col-12">
                                                                    <hr/>
                                                                    <button type="button" class="mediumbtn" data-bs-toggle="modal" data-bs-target="#reopen">Re-open</button>
                                                                </div>
                                                             </div>
                                                         </div>
                                                     </div>						
                                                 </div>
                                                  
                                                ))}
                                            
                                                {/* <div class="compitem">
                                                    <div class="txn-head" id="comphead3">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp3" aria-expanded="false" aria-controls="comp3">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="bonus"></i>
                                                                            <h5>SPAR Bonus</h5>										
                                                                            <p>Buy Bonus Club</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="reopen">Re-open</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp3" class="accordion-collapse collapse" aria-labelledby="comphead3" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 12000.00</h4>
                                                                        <p class="failed">Failed</p>								
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6>NA</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Vishal Verma</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>User - Remark - 04-09-2020 11:34:54</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Ramesh Swamy</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div>
                                                 */}
                                                {/* <div class="compitem">
                                                    <div class="txn-head" id="comphead1">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp1" aria-expanded="false" aria-controls="comp1">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="gift"></i>
                                                                            <h5>Gift Cards</h5>										
                                                                            <p>SPAR Cash Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="open">Open</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp1" class="accordion-collapse collapse" aria-labelledby="comphead1" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>										
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 12343.00</h4>
                                                                        <p class="success">Success</p>									
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6><a href="images/giftcard-1.jpg" target="_blank" class="attach">Attached</a></h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div> */}
                                                
                                                {/* <div class="compitem">
                                                    <div class="txn-head" id="comphead2">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp2" aria-expanded="false" aria-controls="comp2">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="add"></i>
                                                                            <h5>Add Money</h5>										
                                                                            <p>E-Cash Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="closed">Closed</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp2" class="accordion-collapse collapse" aria-labelledby="comphead2" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 5000.00</h4>
                                                                        <p class="failed">Failed</p>								
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6>NA</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Rajesh Kumar</h6>												
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12">
                                                                    <hr/>
                                                                    <button type="button" class="mediumbtn" data-bs-toggle="modal" data-bs-target="#reopen">Re-open</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div> */}
                                                
                                                {/* <div class="compitem">
                                                    <div class="txn-head" id="comphead4">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp4" aria-expanded="false" aria-controls="comp4">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="credit"></i>
                                                                            <h5>Credit Note</h5>										
                                                                            <p>E-Cash Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="closed">Closed</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp4" class="accordion-collapse collapse" aria-labelledby="comphead4" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 670.00</h4>
                                                                        <p class="failed">Failed</p>								
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6>NA</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Rajesh Kumar</h6>												
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12">
                                                                    <hr/>
                                                                    <button type="button" class="mediumbtn" data-bs-toggle="modal" data-bs-target="#reopen">Re-open</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div> */}
                                                
                                                {/* <div class="compitem">
                                                    <div class="txn-head" id="comphead5">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp5" aria-expanded="false" aria-controls="comp5">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="cashback"></i>
                                                                            <h5>My Cashback</h5>										
                                                                            <p>SPAR Cash Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="closed">Closed</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp5" class="accordion-collapse collapse" aria-labelledby="comphead5" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 670.00</h4>
                                                                        <p class="failed">Failed</p>								
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6>NA</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Rajesh Kumar</h6>												
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12">
                                                                    <hr/>
                                                                    <button type="button" class="mediumbtn" data-bs-toggle="modal" data-bs-target="#reopen">Re-open</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div>
                                                
                                                <div class="compitem">
                                                    <div class="txn-head" id="comphead6">
                                                        <div class="collapsed" data-bs-toggle="collapse" data-bs-target="#comp6" aria-expanded="false" aria-controls="comp6">
                                                            <div class="comphead">
                                                                <div class="row">								
                                                                    <div class="col-12 col-md-5">
                                                                        <div class="comptxt">
                                                                            <i class="coupon"></i>
                                                                            <h5>My Coupon</h5>										
                                                                            <p>SPAR Cash Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-md-5">
                                                                        <div class="comptxt">
                                                                            <h6>Ticket ID: #SPAR1234567890</h6>
                                                                            <p>31-08-2020 17:56:09</p>
                                                                        </div>
                                                                    </div>								
                                                                    <div class="col-3 col-md-2">
                                                                        <div class="comptxt comprgt">
                                                                            <p>Status</p>
                                                                            <h4 class="closed">Closed</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div id="comp6" class="accordion-collapse collapse" aria-labelledby="comphead6" data-bs-parent="#complist">
                                                        <div class="compdtl">
                                                            <div class="row">									
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h6>Txn ID: #SPAR1234567890</h6>
                                                                        <p>31-08-2020 17:56:09</p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6 col-md-5">
                                                                    <div class="comptxt">
                                                                        <h4>₹ 670.00</h4>
                                                                        <p class="failed">Failed</p>								
                                                                    </div>
                                                                </div>								
                                                                
                                                                <div class="col-6 col-md-2">
                                                                    <div class="comptxt comprgt">
                                                                        <p>Upload File</p>
                                                                        <h6>NA</h6>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12"><hr/></div>
                                                                <div class="col-12 col-md-8">
                                                                    <div class="comptxt">
                                                                        <p>CSD - Remark - 31-08-2020 17:56:09</p>
                                                                        <h6>Lorem Ipsum is simply dummy text</h6>
                                                                    </div>
                                                                </div>							
                                                                <div class="col-6 col-md-4">
                                                                    <div class="comptxt text-md-end">
                                                                        <p>Remark by</p>
                                                                        <h6>Rajesh Kumar</h6>												
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-12">
                                                                    <hr/>
                                                                    <button type="button" class="mediumbtn" data-bs-toggle="modal" data-bs-target="#reopen">Re-open</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>						
                                                </div> */}
                                            
                                            </div>
                                            </div>
                                          
                                          

                        )
}
}
const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
    responseReduce:{responseDetailsVal,complaitFetchResponse
}}) => ({
        viewDashBoardSection,errorMessage,responseDetailsVal,complaitFetchResponse
});

export default connect(mapStateToProps, null)(Complaints);