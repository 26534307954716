import React, { useState, useEffect } from 'react';

import SparServiceImpl from '../../service/spar-service-impl/SparService';
import { connect } from "react-redux";
import { store } from '../../redux/store';

import campaignService from "../../service/spar-service-impl/campaignService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import deviceDetails from '../../service/spar-service-impl/deviceDetails';

class BonusClub extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',
			amount: 0,
			firstName: this.props.userProfileResponse.customerInfo.firstName,
			lastName: this.props.userProfileResponse.customerInfo.lastName,
			surl: '',
			phone: this.props.userProfileResponse.customerInfo.mobileNumber,
			key: '',
			hash: '',
			curl: '',
			furl: '',
			txnId: '',
			productInfo: '',
			submitAmount: this.props.amount,
			email: this.props.userProfileResponse.customerInfo.email,
			loading: false,
			isActive: false,
			buttonVisible: true,
			displayMessage: '',
			transactionAmount: '5000'
		}
	}

	change(e, key) {

		console.log('curval,,', e.currentTarget.value);
		// this.setState({
		// 	transcationAmount:e.currentTarget.value
		// })
		const newState = {};
		newState[key] = e.currentTarget.value;
		this.setState(newState)
	}

	submit = (event) => {
		event.preventDefault();
		console.log("send via AJAX", this.state.transactionAmount);
	}
	submitVal(transactionValue) {
		console.log('transactionValue', transactionValue);
		console.log('entered here');


	}

	closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	}
	backModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('bonusclubSubSection'));
	}

	test(e, value) {
		console.log("event val...", e);

		console.log('entering methods......');

		console.log('value...', value);
	}
	addMoney = (event, transactionAmount) => {
		event.preventDefault();
		var allow = true;
		this.setState({
			displayMessage: ''
		})

		if (this.props.walletServiceResponse != null &&
			this.props.walletServiceResponse.walletDetails != null &&
			this.props.walletServiceResponse.walletDetails.totalWalletBalance != null) {
			var remainingAmnt = 10000 - transactionAmount;
			if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) >= 10000) {
				allow = false;
			}
			if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) < 10000) {

				if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) > remainingAmnt) {
					allow = false;
					remainingAmnt = 10000 - parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)
				}
			}

			if (allow == true) {
				console.log('entering methods......');

				var firstNameArray = this.props.userProfileResponse.customerInfo.firstName.split(" ");
				var firstname = firstNameArray.join();
				console.log('value...', transactionAmount);
				this.setState({
					buttonVisible: false,
					active: true,
					displayMessage: "You will be redirected to the transaction screen."

				})
				setTimeout(() => {
					this.setState({
						displayMessage: '',
						buttonVisible: true
					})
				}, 20000);

				// store.dispatch(showUserComponentSection('login'));
				const request = {
					phoneNo: this.props.userProfileResponse.customerInfo.mobileNumber,
					amount: transactionAmount,
					firstName: firstname,
					lastName: this.props.userProfileResponse.customerInfo.lastName,
					surl: "",
					phone: this.props.userProfileResponse.customerInfo.mobileNumber,
					key: "",
					hash: "",
					curl: "",
					furl: "",
					txnId: "",
					productInfo: "BonusClub",
					submitAmount: transactionAmount,
					email: this.props.userProfileResponse.customerInfo.email == '' ? 'nouser@email.com' : this.props.userProfileResponse.customerInfo.email
				}




				console.log('createOrder reuest', JSON.stringify(request));

				const routePage = {
					origincomponent: 'addMoney',
					destinationcomponent: 'addMoney',
					callBackMethod: 'POST',
					dashBoardContentType: 'dashBoard',
					responseContentType: 'userData'
				}

				const requestHeaderCreateOrder = {
					requestType: "CREATE_ORDER",
					requestId: uuidv4(),
					requesterTimestamp: moment(),
					mobileNumber: this.props.phone,
					originalDialogueTraceId: null,
					walletOwner: "SPAR",
					channel: "WEB"
				}
				const orderInfo = {
					itemType: 'Bonus Club',
					itemDescription: 'Spar Cash Wallet',
					phoneNo: this.props.userProfileResponse.customerInfo.mobileNumber,
					amount: transactionAmount,
					firstName: firstname,
					lastName: this.props.userProfileResponse.customerInfo.lastName,
					surl: "",
					phone: this.props.userProfileResponse.customerInfo.mobileNumber,
					key: "",
					hash: "",
					curl: "",
					furl: "",
					txnId: "",
					productInfo: "BonusClub",
					submitAmount: transactionAmount,
					email: this.props.userProfileResponse.customerInfo.email == '' ? 'nouser@email.com' : this.props.userProfileResponse.customerInfo.email
				}

				const deviceInfoM = {
					deviceId: this.props.deviceInfo.deviceId,
					deviceIdValue: this.props.deviceInfo.deviceIdValue,
					osName: this.props.deviceInfo.osName,
					osVer: this.props.deviceInfo.osVer,
					ipAddr: this.props.deviceInfo.ipAddr,
					browserVer: this.props.deviceInfo.browserVer,
					mobileNumber: this.props.userProfileResponse.customerInfo.mobileNumber
				}


				const requestForCreateOrder = {
					requestHeader: requestHeaderCreateOrder,
					orderInfo: orderInfo,
					deviceInfo: deviceInfoM
				}




				console.log("Bonus club request::", requestForCreateOrder);

				const creaateOrderUrl = '/api/v1/pg/createOrder';
				setTimeout(() => {
					SparServiceImpl.apiCallForBoNusClub(creaateOrderUrl, requestForCreateOrder, '', routePage,
						this.props.userProfileResponse.customerInfo.mobileNumber
						, this.props.otpVal, this.props.otpRefNo);
				}, 2000);

				setTimeout(() => {
					store.dispatch(showDashBoardComponentSection('trasactionHistory'));
				}, 5000);

			} else {
				if (remainingAmnt > 0 && parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) < 10000) {
					this.setState({
						displayMessage: 'Exceeded the maximum wallet balance.'
					})
				} else {
					this.setState({
						displayMessage: 'Exceeded the maximum wallet balance.'
					})
				}

			}
		}
	}


	componentDidMount() {
		// //fetch campaign
		const campaignUrl = '/api/v1/subwallet/bonuscash/campaign/get/'+this.props.phone;
		campaignService.fetchCampaigns(campaignUrl, '');
		// const campaignUrl='/api/v1/subwallet/bonuscash/campaign/get';
	}
	render() {
		console.log('campaign:::::', this.props.campaigns);
		return (

			<>


				<div class="innercard">
					<div class="head">

						<h3>Add Bonus Club</h3>
						<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
						<button type="button" class="back" onClick={this.backModal} data-toggle="tooltip" title="Back">back</button>

					</div>

					<div class="bonushow">
						<div class="bonusdtl">
							<div class="row">
								<div class="col-12 col-sm-9 col-xl-10">
									<div class="bonusblc">
										<i></i>


										<h6>Bonus Club:
											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails != null &&
												this.props.walletServiceResponse.walletDetails.bonusCash != null) &&
												<span>₹ {this.props.walletServiceResponse.walletDetails.bonusCash.totalSubWalletOwnerBalance}.00</span>
											}
											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails != null &&
												this.props.walletServiceResponse.walletDetails.bonusCash == null) &&
												<span>₹ 0.00</span>
											}
											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails == null) &&
												<span>₹ 0.00</span>
											}
											{(this.props.walletServiceResponse == null) &&

												<span>₹ 0.00</span>
											} </h6>

									</div>
								</div>


								<div class="col-12 col-sm-3 col-xl-2">
									<div class="bonusqty">
									</div>
								</div>
							</div>
						</div>
						{this.state.displayMessage != '' &&
							<div class="valid-success">{this.state.displayMessage}</div>
						}
						<div class="bonuslist">

							<div class="row">
								{/* <form onSubmit={this.addMoney()} ref={formRef}>	 */}
								{this.props.campaigns != [] &&
									this.props.campaigns.map(resp => (
										<div class="col-12 col-sm-6">
											<div class="bonusbox">

												<h4><span class="amt">PAY ₹ {resp.transactionValue}</span>
													<br /><span class="shop">{resp.campaignName}</span>
												</h4>



												<h6>Get more buying power for your money and Shop more at SPAR with Bonus Club</h6>

												<p><a href="https://www.sparindia.com/pages/terms/pgid-1395927.aspx" target="_blank">T&C*</a></p>

												{(this.state.buttonVisible == true) &&
													<button type="button" onClick={(e) => this.addMoney(e, resp.transactionValue)}
														id={resp.transactionValue}
														class="mediumbtn"

													>Buy Now</button>
												}
												{(this.state.buttonVisible == false) &&

													<div>
													</div>
												}
												{/* </form> */}

											</div>
										</div>


									))
								}
								{
									this.props.campaigns == [] &&
									<div class='valid-success'>
										No Campaigns available now!
									</div>
								}

							</div>
						</div>
					</div>
				</div>
			</>)

	}
}

const mapStateToProps = ({ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
	responseReduce: { responseDetailsVal, dashBoardResponseDetails,
		userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse, fetchTransactionResponse },
	leadingOverLay: { blockScreenConfirm },
	ordeDetailsReduce: { orderId },

	miscReduce: { phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
		, campaigns
		, deviceInfo } }
) => ({
	viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse,
	userProfileResponse, orderServiceResponse, fetchTransactionResponse,
	blockScreenConfirm, orderId, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
	, campaigns
	, deviceInfo
});

export default connect(mapStateToProps, null)(BonusClub);


