import React from "react";
import history from '../../auth/history';
//import { ReCaptcha } from 'react-recaptcha-google'
//import { loadReCaptcha } from 'react-recaptcha-google'
import AuthService from "../../service/retailer-service-impl/AuthService";  
import UserService from "../../service/spar-service-impl/userService";
import WalletService from "../../service/spar-service-impl/walletService";
import walletServiceImpl from "../../service/spar-service-impl/walletServiceImpl";
import TransactionService from "../../service/spar-service-impl/transactionService";
import otpService from "../../service/spar-service-impl/otpService";
import {showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import Payloads from "./payloads";
import { store } from '../../redux/store';
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";
import myCouponService from "../../service/spar-service-impl/myCouponService";
import getMyPointsService from "../../service/spar-service-impl/getMyPointsService";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import userService from "../../service/spar-service-impl/userService";
import deviceDetails from "../../service/spar-service-impl/deviceDetails";

//added codefix

import {showOtpErrorMessage} from "../../redux/error-message/eror-message.action";
class userOtpLogin extends React.Component{
    constructor(props){
        super(props);
        this.state={
		
			phoneNo: '',
			resendVisible:true,
			timerValue:20,
			timerValueDefault:20,
			buttonVisible:true,


			seconds:20,
			
			displaySeconds:20,
		
			interval:null,
			status:"stopped",
			otpCode:'',
			displayErrorMessage:''

			
        }
    }
	componentDidMount() {
		this.startStop();
		setTimeout(()=>{
			
		this.reset();
		},20000);

	}
	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}

  //Stopwatch function (logic to determine when to increment next value, etc.)
  stopWatch= (event) =>{

    this.state.seconds--;

    //Logic to determine when to increment next value
    if(this.state.seconds / 60 === 1){
		this.setState({
			seconds:0
		})
		//console.log('second val...',this.state.seconds);
    }
		//If seconds/minutes/hours are only one digit, add a leading 0 to the value
		if(this.state.seconds < 10){
			this.setState({
				displaySeconds:"0" + this.state.seconds.toString()
			})
		
			
		}
		else{
			this.setState({
				displaySeconds:this.state.seconds
			})
			
		}

}
	startStop= (event) =>{
    console.log('enter startstop...');
    if(this.state.status === "stopped"){
		console.log('enter startstop...status  stopped');
		this.setState({
			interval:window.setInterval(this.stopWatch, 1000),
			resendVisible:false
		})
        //Start the stopwatch (by calling the setInterval() function)
        // this.state.interval = window.setInterval(this.stopWatch, 1000);
		console.log('this.state.interval1:::',this.state.interval);
		this.setState({
			status:"started"
		})
        // this.state.status = "status";

    }
    else{
		console.log('enter startstop...status not stopped');
        window.clearInterval(this.state.interval);
		this.setState({
			status:"stopped"
		})
        // this.state.status = "stopped";

    }

}

		reset= (event) =>{
		
            console.log('reset called....');
			window.clearInterval( this.state.interval);

			this.setState({
				seconds:20,
				interval:null,
				displaySeconds:20,
				status:"stopped",
				resendVisible:true
			})

			console.log('this.state.interval2:::',this.state.interval);
			// this.state.seconds = 0;


		}

	handleOtpChange = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	
		this.setState({
            otpCode: event.target.value
		})
	
		console.log('Testing-handleOtpChange :' + this.state.otpCode)
	  }
	

	  handleGetProfile = (event) => {	
		store.dispatch(showOtpErrorMessage(''));
		event.preventDefault();
        //added codefix 13092021
		this.setState({
				buttonVisible: false,
				displayErrorMessage:''
	
		})
		setTimeout(()=>{
			this.setState({
				buttonVisible:true
			  })	
		},1000);
		
        

		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		store.dispatch(showErrorMessageSection(''));

        //if(this.props.otpVal=this.state.otpCode){
		const requestHeaderForOtpCheck={
            requestType: "LOGIN_USER",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: this.props.phone,
            walletOwner: "SPAR",
            channel: "WEB",
            originalDialogueTraceId : ""
          }
   

          const otpDetails={
            otpType: "LOGIN",
            refNo: this.props.otpRefNo,
            otp:this.state.otpCode
          }
        
        const otpValidationRequest={
          requestHeader:requestHeaderForOtpCheck,
          deviceInfo:this.props.deviceInfo,
          otpDetails:otpDetails
        }

        console.log('otpValidationRequest__',JSON.stringify(otpValidationRequest));
    
		const otpValidationUrl='/api/v1/landmark/user/login';
		if(this.state.otpCode.length>=4 
		&& this.state.otpCode.length<=8 
		&& !isNaN(this.state.otpCode)){
     	userService.otpValidationCheck(otpValidationUrl,otpValidationRequest
			,this.props.phone,otpDetails);
		}else{
			this.setState({
				displayErrorMessage:'Uh-Oh! Incorrect Otp!.'
			})
		}
	

	
	}
	   
	handleReOTP = (event) =>{
		store.dispatch(showOtpErrorMessage(''));
	
		this.startStop();
		setTimeout(()=>{
			
		this.reset();
		},21000);

				
		store.dispatch(showOtpErrorMessage(''));

		store.dispatch(showDashBoardComponentSection('transactionHsitory'));
		store.dispatch(showErrorMessageSection(''));
	

		
		event.preventDefault();
		  const routePage={
			origincomponent:'otp',
			destinationcomponent:'otp',
			callBackMethod:'POST',
			dashBoardContentType:'user',
			responseContentType:'userData'
		}
		  
		var otpurl= "/api/v1/otp/generate";        
		const requestHeaderForOtp={
		  requestType: "GENERATE_OTP",
		  requestId: uuidv4(),
		  requesterTimestamp: moment(),
		  mobileNumber:this.props.phone,
		  originalDialogueTraceId: null,
		  walletOwner: "SPAR",
		  channel: "WEB"
		}
       
	
	
			const otpDetails={
			otpType: "LOGIN",
			refNo: null,
			otp: null
		}

		
		const request={
		  requestHeader:requestHeaderForOtp,
		 
		  deviceInfo:this.props.deviceInfo,
		  otpDetails:otpDetails
		}

	  console.log('otp generate request:',JSON.stringify(request));  
	  otpService.generateOtpServiceCall(otpurl,request,routePage);

	  }
      render(){    
		
          return(
            
			<div>
            <form onSubmit={this.handleGetProfile}>
		
		
		
			{this.props.otpErrorMessage!='' &&						
                            <div class="valid-error">
                
                           {this.props.otpErrorMessage} 
                            </div>
                            }
						{this.state.displayErrorMessage!='' &&						
                            <div class="valid-error">
                
                           {this.state.displayErrorMessage} 
                            </div>
                            }

					<div class="form-floating">


						<input type="tel" 
						onChange={this.handleOtpChange}
						// pattern="[0-9]{4,8}" 
						autoFocus required 
						class="form-control" id="userOtp"   
						maxLength="8" placeholder="Enter OTP" 
						//required 
						/>
						<label for="userOtp">Enter OTP</label>
						
						
					</div>
					{(this.state.buttonVisible==true) &&
					<button type="submit" class="btn primary">Continue</button>
					}
					{(this.state.buttonVisible==false) &&
					<button type="submit" disabled class="btn primary"> Continue  <span class="spinner-border spinner-border-sm"></span></button>
					}
				
				</form>	
			
                {this.state.resendVisible==true &&
				<h6>Didn’t receive OTP? <button type="submit" class="resend" onClick={this.handleReOTP}>Resend
				  </button> <span class="timer">in 0:{this.state.displaySeconds} secs</span></h6> 
					
				}
				 {this.state.resendVisible==false &&
				<h6>Didn’t receive OTP? <button type="submit" disabled class="resend">Resend
				  </button> <span class="timer">in 0:{this.state.displaySeconds} secs</span></h6> 
					
				}
				</div>
          );
      } 
    }



const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,otpErrorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
		,otpVal,otpRefNo,deviceInfo
	}}
	) => ({
		viewDashBoardSection,errorMessage,
		otpErrorMessage,
		responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,otpVal,otpRefNo
		,deviceInfo
});

export default connect(mapStateToProps, null)(userOtpLogin);
