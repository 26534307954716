
export const ResponseType = {
  showResponse:'showResponse',
  showDashBoardResponse:'showDashBoardResponse',
  showUserResponse:'showUserResponse',
  showWalletResponse:'showWalletResponse',
  showWalletServiceResponse:'showWalletServiceResponse',
  showUserProfileResponse:"showUserProfileResponse",
  showTransaction:"showTransaction",
  fetchTransaction:"fetchTransaction",
  showExpiringFirstResponse:"showExpiringFirstResponse",
  showCashBackResponse:"showCashBackResponse",
  showCreditNoteResponse:"showCreditNoteResponse",
  showBonusCashResponse:"showBonusCashResponse",
  showTxnResponse:"showTxnResponse",
  showTxnResponsePager:"showTxnResponsePager",
  showTxnResponseLength:"showTxnResponseLength",
  setComplaintFetchResponse:"setComplaintFetchResponse",
  setGameOfWheelLoadResponse:"setGameOfWheelLoadResponse",
  setGameOfWheelTextValues:"setGameOfWheelTextValues",
  RESET: "RESET",
      
      
    };