import React from "react";

import { store } from "../../redux/store";

import UserService from "../../service/spar-service-impl/userService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection, showLoginErrorMessage } from "../../redux/error-message/eror-message.action";
import { connect } from "react-redux";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { setDeviceInfo, setPhone } from "../../redux/misc-data/misc-data.action";
import deviceDetails from "../../service/spar-service-impl/deviceDetails";

import { resetErrorMessage } from '../../redux/error-message/eror-message.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { resetLeadingOverLay } from '../../redux/leading-overlay/leading-overlay.action';
import { resetOrderId } from '../../redux/order-details/order-details.action';
import { resetResponse, showDashBoardResponse, showTransaction } from '../../redux/response/response.action';

import axios from 'axios'
import { resetRoleData } from './../../redux/admin/admin.action';
import { resetToken } from './../../redux/token/token.action';
import { resetUser } from './../../redux/user/user.action';
import { resetRole } from "../../redux/role/role.action";
import { setAuth } from "../../redux/auth/auth.action";

class userLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',

			response: null,
			captchaDone: false,
			showLoginSection: true,
			showOtpSection: false,
			showReferralSection: false,
			showRegisterSection: false,
			buttonVisible: true,
			displayMessage: '',
			ip: '',
			deviceDataDetails: '',
			lat: '0.00',
			lan: '0.0'
		}
	}
	componentDidMount() {

		store.dispatch(setAuth(false));
		store.dispatch(showLoginErrorMessage(''));
		localStorage.clear();
		setTimeout(() => {
			store.dispatch(resetDivShowAndHide);
			store.dispatch(resetErrorMessage);
			store.dispatch(resetLeadingOverLay);
			store.dispatch(resetOrderId);
			store.dispatch(resetResponse);
			store.dispatch(resetRoleData);
			store.dispatch(resetToken);
			store.dispatch(resetUser);
			store.dispatch(resetRole);

		}, 500);



		if (navigator.geolocation) {
			try {
				navigator.geolocation.getCurrentPosition(this.showPosition, this.posError);
			} catch (error) {
				this.setState({
					lat: '0.00',
					lan: '0.0'
				})
			}


		} else {

			this.setState({
				lat: '0.00',
				lan: '0.0'
			})
		}

	}


	showPosition = (position) => {
		let lat = position.coords.latitude;
		let lan = position.coords.longitude;
		this.setState({
			lat: lat,
			lan: lan
		})
	}

	posError = () => {

		this.setState({
			lat: '0.00',
			lan: '0.0'
		})
	}
	componentWillUnmount() {

	}


	numberOnly(id) {
		// Get element by id which passed as parameter within HTML element event
		var element = document.getElementById(id);
		// This removes any other character but numbers as entered by user
		element.value = element.value.replace(/[^0-9]/gi, "");
	}

	handlePhoneNoChange = (event) => {
		if (event.target.value.length == 10) {
			this.setState({
				phoneNo: event.target.value
			})

		} else {
			this.setState({
				phoneNo: ''
			})
		}

	}





	handleSubmit = (event) => {
		event.preventDefault();
		store.dispatch(showLoginErrorMessage(''));


		// this.getData();

		this.setState({
			buttonVisible: false,
			displayMessage: ''
		})
		setTimeout(() => {
			this.setState({
				buttonVisible: true
			})
		}, 1000);

		console.log('navigator info...', navigator);
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(setPhone(this.state.phoneNo));


		const actionurl = {
			url: "/api/v1/user/check/"
		}

		const routePage = {
			origincomponent: 'login',
			destinationcomponent: 'otp',
			failureComponent: 'register',
			callBackMethod: 'POST',
			dashBoardContentType: 'user',
			responseContentType: 'userData'
		}
		const requestHeader = {
			requestType: "CHECK_USER",
			requestId: uuidv4(),
			requesterTimestamp: moment(),
			mobileNumber: this.state.phoneNo,
			originalDialogueTraceId: null,
			walletOwner: "SPAR",
			channel: "WEB"
		}






		var url = actionurl.url;
		console.log('this.state.phoneNo:::', this.state.phoneNo);
		console.log('ph..', this.state.phoneNo.slice(1, 2));
		if (this.state.phoneNo.length == 10 && !isNaN(this.state.phoneNo)) {

			const deviceInfo = deviceDetails.fetchDeviceInfo(this.state.lat, this.state.lan);
			deviceInfo.then((deviceResponse) => {
				store.dispatch(setDeviceInfo(deviceResponse));
				

				console.log('deviceData...', deviceResponse);
				const request = {
					requestHeader: requestHeader,
					deviceInfo: deviceResponse

				}

				// store.dispatch(setDeviceInfo(JSON.parse(this.state.deviceDataDetails)));
				console.log('user check...', JSON.stringify(request));

				UserService.apiCall(url, request, routePage, this.state.phoneNo);


				this.props.parentCallback(this.state.phoneNo); // We've got parameters - submit them!	
			})
		} else {
			this.setState({
				displayMessage: 'Please enter 10 digit valid mobile no.'
			})
		}

	}


	render() {
		console.log('Inside render');



		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<div class="form-floating">
						<input type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 10)}

							onChange={this.handlePhoneNoChange} class="form-control"
							id="userMobile"
							required placeholder="Mobile Number" />
						<div class='valid-error'>
							{this.props.loginErrorMessage}
						</div>
						{this.state.displayMessage !== '' &&
							<div class='valid-error'>
								{this.state.displayMessage}
							</div>
						}
						<label for="userMobile">Mobile Number</label>
					</div>
					<div class="logtxt">Note: Your earnings, cashbacks, discounts will be credited to this phone number
					</div>


					{(this.state.buttonVisible == true) &&
						<button type="submit" class="btn primary">Continue</button>
					}
					{(this.state.buttonVisible == false) &&
						<button type="submit" disabled class="btn primary"> Continue  <span class="spinner-border spinner-border-sm"></span></button>
					}
				</form>

			</div>
		);
	}
}

const mapStateToProps = (
	{ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage, loginErrorMessage },
		responseReduce: { responseDetailsVal, dashBoardResponseDetails,
			userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse },
		leadingOverLay: { blockScreenConfirm },
		ordeDetailsReduce: { orderId },

		miscReduce: { phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken,
			giftCardBalnceEnquiry } }
) => ({
	viewDashBoardSection, errorMessage, loginErrorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse
	, blockScreenConfirm, orderId, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry
});

export default connect(mapStateToProps, null)(userLogin);
