import { DivShowAndHideTypes } from './div-show-and-hide.type';

const INITIAL_STATE = {
    //default: viewSection:'login'
    viewSection:'login',
    viewDashBoardSection:'trasactionHistory'

};

const divShowAndHideReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        

        case DivShowAndHideTypes.showUserComponenetSection:
            return {
                    ...state,
                 viewSection: action.payload,
                 //viewDashBoardSection:''
            };
         case DivShowAndHideTypes.showDashBoardComponentSection:
            return {
                    ...state,
               viewDashBoardSection: action.payload,
                //viewSection:''
        };   
       
        case DivShowAndHideTypes.RESET:
            return INITIAL_STATE;
                                                           
        default:
            return state;
    }
};

export default divShowAndHideReducer; 