
import { TokenActionTypes } from './token.types';

export const setToken = token => ({
  type: TokenActionTypes.SET_TOKEN,
  payload: token
});
export const setUserRole = role => ({
  type: TokenActionTypes.SET_ROLE,
  payload: role
});

export const isAuthenticated = (auth) => ({
  type: TokenActionTypes.IS_AUTHENTICATED,
  payload: auth
})

export const userType = (auth) => ({
  type: TokenActionTypes.USER_TYPE,
  payload: auth
})

export const setNoPricingAvailable = (auth) => ({
  type: TokenActionTypes.SET_NO_PRICING_AVAILABLE,
  payload: auth
})
export const setUniqueNumber = (auth) => ({
  type: TokenActionTypes.SET_UNIQUE_ID,
  payload: auth
})
export const resetToken ={ 
  type: TokenActionTypes.RESET
}
