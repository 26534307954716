import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import ApiCallingService from '../api-call-service';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import walletServiceImpl from './walletServiceImpl';
import transactionService from './transactionService';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
class WalletServiceFetch  extends React.Component {
   fetchWalletServiceApiCall(url, requestTosend) {

    
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('fetchWalletApiCall service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForWalletService(url, requestTosend);
      
      
       console.log("_fetchWalletApiCall response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
       }else if(response !=undefined){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("api_response for fetchwallet api call",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }
}
   export default new WalletServiceFetch();