import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import {store} from '../../redux/store';
import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";

import moment from "moment";
import { v4 as uuidv4 } from 'uuid';


import giftCardLinkService from '../../service/spar-service-impl/giftCardLinkService';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';



class GiftCardPinAdd extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
			cardPin:'',
			buttonVisible:true
        }
    }
	
    closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }


	 closeModal= (event) => {
        event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('trasactionHistory'));
     }
	 
	 addGiftCardSection= (event) => {
        event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('addGiftCardSection'));
     }

	 backModal=(event)=>{
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('giftCardOtp'));
	 }


	 linkGiftCardSection= (event) => {
        event.preventDefault();
		store.dispatch(showUserComponentSection('login'));

		this.setState({
			buttonVisible: false,
		})
	setTimeout(()=>{
		this.setState({
			buttonVisible:true
		  })	
	},22000);
		

		const requestHeader={
			requestType: "LINK_GIFTCARD",
			requestId: uuidv4(),
			 requesterTimestamp: moment(),
			 mobileNumber:this.props.phone,
			 originalDialogueTraceId: uuidv4(),
			 walletOwner: "SPAR",
			 channel: "WEB"
	   }

	
	   const giftCardLinkRequest={
		  requestHeader:requestHeader,
		  deviceInfo:this.props.deviceInfo,
		  cardNumber:this.props.cardNumber,
		  cardPin:this.props.cardPin,
		  transactionId:uuidv4()
	   }

	
	   const linkingRequestUrl='/api/v1/wallet/link';
	   giftCardLinkService.giftCardLinkServiceCall(linkingRequestUrl,giftCardLinkRequest,this.props.giftCardBalnceEnquiry,
		this.props.phone);
	
		store.dispatch(showDashBoardComponentSection('giftCardReview'));
	
        
     }
	 

     render(){


		console.log('::::giftCardBalnceEnquiry:::::',this.props.giftCardBalnceEnquiry);
		return(
			<>
    			<div class="innercard">
					<div class="head">
						<h3>Gift Card Review</h3>
						<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
						<button type="button" class="back" onClick={this.backModal} data-toggle="tooltip" title="Back">back</button>					
					</div>
					<div class="loadtls">
						<div class="row">
							<div class="col-12">
								<div class="loadtxn">
									<h5><i class="gicon"></i> Gift Card</h5>
									<h4>Card Number <span>{this.props.cardNumber}</span></h4>
									<h4>Expiry <span>{this.props.giftCardBalnceEnquiry.expiringOn}</span></h4>
									<h6>Card Amount <span>₹ {this.props.giftCardBalnceEnquiry.balance}</span></h6>	
								</div>
							</div>
							{this.state.buttonVisible==true &&
							<div class="col-12 mt-4">
								<button type="submit" class="btn primary" onClick={this.linkGiftCardSection}>Continue</button>
							</div>
	 						}
							 {this.state.buttonVisible==false &&
							<div class="col-12 mt-4">
								<button type="submit" disabled class="btn primary" onClick={this.linkGiftCardSection}> Continue <span class="spinner-border spinner-border-sm"></span></button>
							</div>
	 						}
						</div>						
					</div>
					
				</div>			
		
</>)

}
}


const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},
	miscReduce:{cardNumber,cardPin,phone
	,deviceInfo}},

	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,cardNumber,cardPin,phone
		,deviceInfo
});

export default connect(mapStateToProps, null)(GiftCardPinAdd);
