

import { resetErrorMessage } from '../../redux/error-message/eror-message.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { resetLeadingOverLay } from '../../redux/leading-overlay/leading-overlay.action';
import { resetOrderId } from '../../redux/order-details/order-details.action';
import { resetResponse, showDashBoardResponse, showTransaction } from '../../redux/response/response.action';

import axios from 'axios'
import { resetRoleData } from './../../redux/admin/admin.action';
import { resetToken } from './../../redux/token/token.action';
import { resetUser } from './../../redux/user/user.action';
import { resetRole } from "../../redux/role/role.action";
import { setAuth } from "../../redux/auth/auth.action";
import { store } from '../../redux/store';
class AuthService {


    clearState() {
      
        store.dispatch(setAuth(false));
		localStorage.clear();
		setTimeout(() =>{
		store.dispatch(resetDivShowAndHide);
		store.dispatch(resetErrorMessage);
		store.dispatch(resetLeadingOverLay);
		store.dispatch(resetOrderId);
		store.dispatch(resetResponse);
		store.dispatch(resetRoleData);
		store.dispatch(resetToken);
		store.dispatch(resetUser);
		store.dispatch(resetRole);

		},500);
  }
}
  
  export default new AuthService();