import  AdminType  from './admin.type';
export const setIsAdmin = data =>({
    type: AdminType.SET_IS_ADMIN,
    payload: data
});
export const setUserRole = data =>({
    type: AdminType.SET_USER_ROLE,
    payload: data
});
export const resetRoleData = ({
    type: AdminType.RESET
})