import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../../src/omts/component/systemErrorCodes';
import systemErrorCodes from '../../../src/omts/component/systemErrorCodes';

class AuthService {
 

   apiCall(url, requestTosend, headerToCall,routePage) {
    console.log('inside AuthService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {

        if(routePage.callBackMethod.toLowerCase()=="get"){
       response=  await ApiCallingService.getMethodCallWithParam(url, requestTosend,routePage);
        }else  if(routePage.callBackMethod.toLowerCase()=="post"){
          response=  await ApiCallingService.postMethodCall(url, requestTosend,routePage);
        }
       console.log("__response__",response);
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      else if (response.status === 404) {
        console.log(response);
       
       var errorMessage='Somthing went wrong we are looking at our End--400 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 504) {
        console.log(response);
       
       var errorMessage='Somthing went wrong we are looking at our End--500 error gateway timeout';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 500) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--500 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 404) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--404 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      
      else if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.response.responseCode);
       if(parseInt(response.response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
        if(routePage.dashBoardContentType=='user'){
          store.dispatch(showUserComponentSection(routePage.destinationcomponent));
          }else{
          store.dispatch(showDashBoardComponentSection(routePage.destinationcomponent));
          }
          
       }

       {systemErrorCodes.syetemErrorCodesDetails.map(systemErrCodes=>{
       if(systemErrCodes.id==parseInt(response.response.responseCode))
       {
        console.log("api_response for "+ systemErrCodes.id,response);
       var errorMessage=systemErrCodes.details;
       if(routePage.dashBoardContentType=='user'){
        store.dispatch(showUserComponentSection(routePage.failureComponent));
        }else{
        store.dispatch(showDashBoardComponentSection(routePage.failureComponent));
        }
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      })}
      }
      else{
        console.log("response_login_",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
    run();
  }
  
}

export default new AuthService();
