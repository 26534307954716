import React, { useState, useEffect } from 'react';


class BlankTransactionCompoment extends React.Component{
    constructor(props){
        super(props);
        
    }
	


     render(){

return(
	<>
   
    	                <div class="expiry-list">
                        {/* <div class="valid-success">You don't have any Transaction History Yet! 
                        </div> */}
                        <div class="valid-success">No Transaction History Available! 
                        </div>
                
                                            <div class="row">

                             
                                                            <div class="col-12 col-md-4">
                                                                
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>

                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                       
                                		
</>)

}
}

export default BlankTransactionCompoment;
