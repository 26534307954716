import axios from 'axios';



class LogData {

  infolog(status, uri, LogType, data, processingTime, message) {
    console.log('inside logData method', process.env.REACT_APP_INFOLOG_URL)
    axios.post(
      process.env.REACT_APP_INFOLOG_URL,
      {
        httpStatus: status,
        uri: uri,
        LogType: LogType,
        data: data,
        processingTime: processingTime,
        message: message
      }
    );
  }

  errorlog(status, uri, LogType, data, processingTime, message, exception) {
    console.log('inside logData method', process.env.REACT_APP_ERRORLOG_URL)
    axios.post(
      process.env.REACT_APP_ERRORLOG_URL,
      {
        httpStatus: status,
        uri: uri,
        LogType: LogType,
        data: data,
        processingTime: processingTime,
        message: message,
        excepton: exception
      }
    );
  }

  debug(message) {
    console.log('inside logData method', process.env.REACT_APP_DEBUGLOG_URL)
    axios.post(
      process.env.REACT_APP_DEBUGLOG_URL,
      {
        message: message
      }
    );
  }

}


export default new LogData();