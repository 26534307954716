import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import ApiCallingService from '../api-call-service';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import WalletService from './walletService';
import deviceDetails from './deviceDetails';

class GiftCardLinkService {



 giftCardLinkServiceCall(url, requestTosend,balanceenquiry,phoneNo) {
  console.log('inside giftCardLinkService ', url);

  var response = [];
  
   async function run() {
    console.log('giftCardLinkService service Request',JSON.stringify(requestTosend));

    response=  await ApiCallingService.giftCardLinkServiceCall(url, requestTosend);
    
    
     console.log("giftCardLinkService response__",JSON.stringify(response));
     if (response === 'error'||response==undefined) {
      console.log(response);
      
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    }else if(response !=undefined && response.responseHeader!=undefined
      //&& response.status==200
      ){ 

    console.log(' giftCardLinkService res_code',response.responseHeader.responseCode);
    if(response.responseHeader.responseCode=="SUCCESS"){
       console.log("---response for giftCardLinkService",response);
      var errorMessage='';
      store.dispatch(showErrorMessageSection(errorMessage));
      //store.dispatch(showDashBoardComponentSection(routePge.destinationComponrnt));
     
    console.log("----giftCardLinkService call ended----");



    var walletUrl='/api/v1/wallet/details';

		const walletRequestHeader={
			requestType: "GET_WALLET_DETAILS",
			requestId: uuidv4(),
			requesterTimestamp: moment(),
			mobileNumber: phoneNo,
			originalDialogueTraceId:  uuidv4(),
			walletOwner: "SPAR",
			channel: "WEB"
		  }

		


		const wallerServiceRequest={
			requestHeader:walletRequestHeader,
      deviceInfo:requestTosend.deviceInfo
		
		}

    setTimeout(()=>{
	  WalletService.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
    },1500);

    store.dispatch(showDashBoardComponentSection('giftCardAddDetails'));
    
    
    
    //redeem,reverse redeem will be called from service itself
    //console.log("---giftcard balance redeem call started----");



  //   const requestHeader={
  //       requestType: "CHECK_USER",
  //       requestId: uuidv4(),
  //        requesterTimestamp: moment(),
  //        mobileNumber:localStorage.getItem('mobile'),
  //        originalDialogueTraceId: null,
  //        walletOwner: "SPAR",
  //        channel: "POS"
  //  }

  //  const giftCardRedeemInfo={
  //   TransactionId: balanceenquiry.giftCardCheckResponse.TransactionId,
  //   CardNumber:requestTosend.cardNumber,
  //   CardPin:requestTosend.cardPin, 
  //   Amount: parseInt(balanceenquiry.giftCardCheckResponse.Amount),
  //   BillAmount:parseInt(balanceenquiry.giftCardCheckResponse.Amount),
  //   InvoiceNumber: balanceenquiry.giftCardCheckResponse.TransactionId,
  //   Idempotencykey: balanceenquiry.giftCardCheckResponse.TransactionId,
  //   DateAtClient:moment().format("YYYY-MM-DD HH:mm:ss"),
  //   Notes:"VldType~GCRDM"
  //  }

  


  //  const redeemRequest={
  //    requestHeader:requestHeader,
  //    giftCardRedeemInfo:giftCardRedeemInfo,
  //    header:"Bearer "+localStorage.getItem('qcAuthToken')
  //  }


  //  const redeemUrl='/api/v1/qc/giftCardBalanceRedeem';


   //giftCardRedeemServiceCall.fetchGiftCardRedeemServiceCall(redeemUrl,redeemRequest,balanceenquiry);


    }else if(response.responseHeader.responseCode=="UNKNOWN_EXCEPTION"){
    store.dispatch(showDashBoardComponentSection('giftCardFailedDetails'));
    }else{
      console.log("error response!",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    } 
    
  
 
}





}
run();
 }
}


export default new GiftCardLinkService();
