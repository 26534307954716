
import { ResponseType } from './response.type';

export const showResponse = (data) => ({
  type: ResponseType.showResponse,
  payload: data
  
});
export const showDashBoardResponse = (data) => ({
  type: ResponseType.showDashBoardResponse,
  payload: data
  
});

//showUserProfileResponse
export const showUserProfileResponse = (data) => ({
  type: ResponseType.showUserProfileResponse,
  payload: data
  
});
export const showUserResponse = (data) => ({
  type: ResponseType.showUserResponse,
  payload: data
  
});
export const showWalletResponse = (data) => ({
  type: ResponseType.showWalletResponse,
  payload: data
  
});


export const showWalletServiceResponse = (data) => ({
  type: ResponseType.showWalletServiceResponse,
  payload: data
  
});


export const showCashBackResponse = (data) => ({
  type: ResponseType.showCashBackResponse,
  payload: data
  
});


export const showCreditNoteResponse = (data) => ({
  type: ResponseType.showCreditNoteResponse,
  payload: data
  
});

export const showBonusCashResponse = (data) => ({
  type: ResponseType.showBonusCashResponse,
  payload: data
  
});
export const showTransaction = (data) => ({
  type: ResponseType.showTransaction,
  payload: data
  
});

export const showTxnResponse = (data) => ({
  type: ResponseType.showTxnResponse,
  payload: data
  
});

export const showTxnResponsePager = (data) => ({
  type: ResponseType.showTxnResponsePager,
  payload: data
  
});

export const showTxnResponseLength = (data) => ({
  type: ResponseType.showTxnResponseLength,
  payload: data
  
});


export const fetchTransaction = (data) => ({
  type: ResponseType.fetchTransaction,
  payload: data
  
});


export const showExpiringFirstResponse = (data) => ({
  type: ResponseType.showExpiringFirstResponse,
  payload: data
  
});


export const setComplaintFetchResponse = (data) => ({
  type: ResponseType.setComplaintFetchResponse,
  payload: data
  
});

export const setGameOfWheelLoadResponse = (data) => ({
  type: ResponseType.setGameOfWheelLoadResponse,
  payload: data
  
});

export const setGameOfWheelTextValues = (data) => ({
  type: ResponseType.setGameOfWheelTextValues,
  payload: data
  
});

export const resetResponse ={
  type: ResponseType.RESET
}