import React from "react";

import otpService from "../../service/spar-service-impl/otpService";
import { store } from '../../redux/store';
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";

import {showOtpErrorMessage} from "../../redux/error-message/eror-message.action";

import UserService from "../../service/spar-service-impl/userService";
import { setUserFirstName } from "../../redux/misc-data/misc-data.action";
class userOtpLoginAfterRegsiter extends React.Component{
    constructor(props){
        super(props);
        this.state={
		
			phoneNo: '',
	
			timerValue:20,
			timerValueDefault:20,
			buttonVisible:true,
			otpCode:'',

			resendVisible:true,
			timerValue:20,
			timerValueDefault:20,
			buttonVisible:true,


			seconds:20,
			
			displaySeconds:20,
		
			interval:null,
			status:"stopped",
			displayErrorMessage:''
			
			
        }
    }
	componentDidMount() {

		this.startStop();
		setTimeout(()=>{
			
		this.reset();
		},21000);
	}


 //Stopwatch function (logic to determine when to increment next value, etc.)
 stopWatch= (event) =>{

    this.state.seconds--;

    //Logic to determine when to increment next value
    if(this.state.seconds / 60 === 1){
		this.setState({
			seconds:0
		})
		//console.log('second val...',this.state.seconds);
    }
		//If seconds/minutes/hours are only one digit, add a leading 0 to the value
		if(this.state.seconds < 10){
			this.setState({
				displaySeconds:"0" + this.state.seconds.toString()
			})
		
			
		}
		else{
			this.setState({
				displaySeconds:this.state.seconds
			})
			
		}

}
	startStop= (event) =>{
    console.log('enter startstop...');
    if(this.state.status === "stopped"){
		console.log('enter startstop...status  stopped');
		this.setState({
			interval:window.setInterval(this.stopWatch, 1000),
			resendVisible:false
		})
        //Start the stopwatch (by calling the setInterval() function)
        // this.state.interval = window.setInterval(this.stopWatch, 1000);
		console.log('this.state.interval1:::',this.state.interval);
		this.setState({
			status:"started"
		})
        // this.state.status = "status";

    }
    else{
		console.log('enter startstop...status not stopped');
        window.clearInterval(this.state.interval);
		this.setState({
			status:"stopped"
		})
        // this.state.status = "stopped";

    }

}

		reset= (event) =>{
		
            console.log('reset called....');
			window.clearInterval( this.state.interval);

			this.setState({
				seconds:20,
				interval:null,
				displaySeconds:20,
				status:"stopped",
				resendVisible:true
			})

			console.log('this.state.interval2:::',this.state.interval);
			// this.state.seconds = 0;


		}
		
		

	handleOtpChange = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	
		this.setState({
            otpCode: event.target.value
		})
	
		console.log('Testing-handleOtpChange :' + this.state.otpCode)
	  }
	

	  handleGetProfile = (event) => {
		  store.dispatch(showOtpErrorMessage(''));	

		event.preventDefault();
		 this.setState({
			buttonVisible: false,
			displayErrorMessage:''
			})
			setTimeout(()=>{
				this.setState({
					buttonVisible:true
				})		
			},1000);
	
		store.dispatch(showOtpErrorMessage(''));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		store.dispatch(showErrorMessageSection(''));

        //if(this.props.otpVal=this.state.otpCode){
		const requestHeaderForOtpCheck={
            requestType: "LOGIN_USER",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber: this.props.phone,
            walletOwner: "SPAR",
            channel: "WEB",
            originalDialogueTraceId : ""
          }
  

          const otpDetails={
            otpType: "LOGIN",
            refNo: this.props.otpRefNo,
            otp:this.state.otpCode
          }
        

		  const routePage={
			origincomponent:'register',
			//destinationcomponent:'welcome',
			destinationcomponent:'welcome',
			failureComponent:'register',
			callBackMethod:'POST',
			dashBoardContentType:'user',
			responseContentType:'userData',
			dashBoardContentType:'user'
		}

        const otpValidationRequest={
          requestHeader:requestHeaderForOtpCheck,
          deviceInfo:this.props.deviceInfo,
          otpDetails:otpDetails
        }


		const registerDataRequest={
			requestHeader:this.props.userRegisterData.requestHeader,
			deviceInfo:this.props.userRegisterData.deviceInfo,
			customerInfo:this.props.userRegisterData.customerInfo,
			otpDetails:otpDetails

		}
		store.dispatch(setUserFirstName(this.props.userRegisterData.customerInfo.firstName));

		console.log('registerDataRequest::',JSON.stringify(registerDataRequest));

		var url =  '/api/v1/user/create';
		console.log(this.state.otpCode.length);
		console.log(isNaN(this.state.otpCode));
		if(
		this.state.otpCode.length>=4 
		&& this.state.otpCode.length<=8 
		&& !isNaN(this.state.otpCode)){
		UserService.registerCall(url, registerDataRequest, routePage,this.props.phone);
		}else{
			this.setState({
				displayErrorMessage:'Uh-Oh! Incorrect Otp!.'
			})

		}
	}
	   
	handleReOTP = (event) =>{
		store.dispatch(showOtpErrorMessage(''));	
		event.preventDefault();
		//added codefix 13092021
	
		this.startStop();
		setTimeout(()=>{
			
		this.reset();
		},21000);

				
	

		store.dispatch(showDashBoardComponentSection('transactionHsitory'));
		store.dispatch(showErrorMessageSection(''));
	
		event.preventDefault();
		  const routePage={
			origincomponent:'otpAfterRegsiter',
			destinationcomponent:'otpAfterRegsiter',
			callBackMethod:'POST',
			dashBoardContentType:'user',
			responseContentType:'userData'
		}
		  
		var otpurl= "/api/v1/otp/generate";        
		const requestHeaderForOtp={
		  requestType: "GENERATE_OTP",
		  requestId: uuidv4(),
		  requesterTimestamp: moment(),
		  mobileNumber:this.props.phone,
		  originalDialogueTraceId: null,
		  walletOwner: "SPAR",
		  channel: "WEB"
		}
       

			const otpDetails={
			otpType: "LOGIN",
			refNo: null,
			otp: null
		}

		
		const request={
		  requestHeader:requestHeaderForOtp,
		  deviceInfo:this.props.deviceInfo,
		  otpDetails:otpDetails
		}

	  console.log('otp generate request:',JSON.stringify(request));  
	  otpService.generateOtpServiceCall(otpurl,request,routePage);

	  }
      render(){  


          return(
            
			<div>
            <form onSubmit={this.handleGetProfile}>
			{/* {this.props.errorMessage!='' &&
			 <div class="valid-error">
			 {this.props.errorMessage} 
			 </div>
	  			} */}
			{this.props.otpErrorMessage!='' &&						
                            <div class="valid-error">
                            <strong>{this.props.otpErrorMessage}</strong> 
                            </div>
                            }
				{this.state.displayErrorMessage!='' &&						
                            <div class="valid-error">
                            <strong>{this.state.displayErrorMessage}</strong> 
                            </div>
                            }			
					<div class="form-floating">
						<input type="tel" 
						onChange={this.handleOtpChange}
						//pattern="[0-9]{4,8}" 
						autoFocus required
						//required

						class="form-control" id="userOtp"   
						maxLength="8" placeholder="Enter OTP" required />
						<label for="userOtp">Enter OTP</label>
					
					</div>

					{(this.state.buttonVisible==true) &&
					<button type="submit" class="btn primary">Continue</button>
					}
					{(this.state.buttonVisible==false) &&
					<button type="submit" disabled class="btn primary"> Continue  <span class="spinner-border spinner-border-sm"></span></button>
					}
				
			
				
				
				</form>	
		

			{this.state.resendVisible==true &&
				<h6>Didn’t receive OTP? <button type="submit" class="resend" onClick={this.handleReOTP}>Resend
				  </button> <span class="timer">in 0:{this.state.displaySeconds} secs</span></h6> 
					
				}
				 {this.state.resendVisible==false &&
				<h6>Didn’t receive OTP? <button type="submit" disabled class="resend">Resend
				  </button> <span class="timer">in 0:{this.state.displaySeconds} secs</span></h6> 
					
				}
					</div>
          );
      } 
    }



const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,otpErrorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,otpVal,otpRefNo
	,deviceInfo,
	userRegisterData}}
	) => ({
		viewDashBoardSection,errorMessage,
		otpErrorMessage,
		responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,otpVal,otpRefNo
		,deviceInfo,
		userRegisterData
});

export default connect(mapStateToProps, null)(userOtpLoginAfterRegsiter);
