import React, { useState, useEffect } from 'react';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import Pagination from "react-js-pagination";
import { setCouponData } from '../../redux/misc-data/misc-data.action';

import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import moment from 'moment';
import { dateFormat } from 'dateformat';



class MyCouponsComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			zResponse:[],
			tResponse:
			this.props.myCouponDetails!=null?
			this.props.myCouponDetails.slice(0,6)
			:[],
			sResposne:[],
			activePage: 1,
        }
    }

    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	cashBackDetailsSection= (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashBackDetailsSection'));
	  }
    couponDetails= (event,response) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(setCouponData(response));
		store.dispatch(showDashBoardComponentSection('couponDetailsSection'));
	}
	backModal= (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}

	copy=(event,value)=> {
		event.preventDefault();
		
	    console.log('copied value',value);
		 /* Copy the text inside the text field */
		navigator.clipboard.writeText(value.couponCode);
	  
		/* Alert the copied text */
		//alert("Copied the text: " + value);
	  }
	  
    

	handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	
		this.setState({
			tResponse:this.props.myCouponDetails.slice((pageNumber-1)*6,(pageNumber)*6)
		 })
	   }
	render(){
console.log('myCouponDetails....'+this.props.myCouponDetails);
return(
	
		<div class="innercard">
			<div class="head"><h3>My Coupons </h3>
			<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
				<button type="button" class="back" onClick={this.backModal} data-toggle="tooltip" title="Close">close</button>  
                   	</div>
					  
					<div class="couponshow">
						<div class="coupondtl">
							<i></i>
							<h4>Total Coupons 
							{this.props.myCouponDetails!=null &&
							
							this.props.myCouponDetails.length>0 &&						
								
								
								<span>{this.props.myCouponDetails.length}</span>
						
							}
							

						{this.props.myCouponDetails==null &&
							
								<span>Not Available</span>
						
							}

						{this.props.myCouponDetails!=null &&
							this.props.myCouponDetails.length==0 &&
							<span>Not Available</span>
					
						}
						</h4>
						</div>
						
						<div class="couponlist">
							<div class="row">

							{this.props.myCouponDetails!=null &&

						this.state.tResponse.length==0?
								
								this.props.myCouponDetails.length>0 &&	
									
							this.props.myCouponDetails.map(response =>(							
								parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                    /31536000000*365).toFixed(0)>-1 ?

								<div class="col-12 col-sm-6 col-md-4">
									<div class="couponbox">

										<h4>{response.couponDesc}</h4>
										<h5>{response.couponCode} <button type="button"
										 class="copy" 
										 data-toggle="tooltip" title="Copy"
										 onClick={(e)=>this.copy(e,response)}

										 id={response.couponCode}
										  >Copy</button></h5>
										<p><button type="button" class="view"
										 onClick={(e)=>this.couponDetails(e,response)}
										 >View Details</button> <span>
										
										

									{response.validTillDate.split("T")[0]}
									</span></p>										
									</div>								
								</div>:
							 	
							<div class="col-12 col-sm-6 col-md-4">
							<div class="couponbox exprd">

								<h4>{response.couponDesc}</h4>
								<h5>{response.couponCode} 
								
								</h5>
								<p><button type="button" class="view"
								 onClick={(e)=>this.couponDetails(e,response)}
								 >View Details</button> <span>
								
								

								{response.validTillDate.split("T")[0]
								}</span></p>										
							</div>								
							</div>
								
							)


							):
							this.state.tResponse.map(response =>(
								parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                    /31536000000*365).toFixed(0)>-1 ?

								<div class="col-12 col-sm-6 col-md-4">
									<div class="couponbox">

										<h4>{response.couponDesc}</h4>
										<h5>{response.couponCode} <button type="button" class="copy" data-toggle="tooltip"
										 onClick={(e)=>this.copy(e,response)}
										 id={response.couponCode}
										
										title="Copy">Copy</button></h5>
										<p><button type="button" class="view"
										 onClick={(e)=>this.couponDetails(e,response)}
										 >View Details</button> <span>
										
										

									{response.validTillDate.split("T")[0]}
									</span></p>										
									</div>								
								</div>:
							 	

							<div class="col-12 col-sm-6 col-md-4">
							<div class="couponbox exprd">

								<h4>{response.couponDesc}</h4>
								<h5>{response.couponCode} 
								
								</h5>
								<p><button type="button" class="view"
								 onClick={(e)=>this.couponDetails(e,response)}
								 >View Details</button> <span>
								
								

								{response.validTillDate.split("T")[0]
								}</span></p>										
							</div>								
							</div>

							))
	
							
							
							
							}
						
								

							</div>
							<div class="pager">
						
							{this.props.myCouponDetails!=null &&
								
										
									this.props.myCouponDetails.length>=6 ?
	
						<div>
						<p>Showing {this.props.myCouponDetails.length} entries</p>
						<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={6}
						totalItemsCount={this.props.myCouponDetails.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChangeDefault.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>
						:
						<div>
							<p>Showing {this.props.myCouponDetails!=null ?
					
					this.props.myCouponDetails.length:0} entries</p>
					 
					 </div>
						
						}

	
						</div>
						</div>	

						
						
					</div>
				</div>	
	
)

}
}

// export default MyCouponsComponent;

const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
	,deviceInfo,myCouponDetails}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
		deviceInfo,myCouponDetails
});

export default connect(mapStateToProps, null)(MyCouponsComponent);
