import { RoleActionTypes } from './role.types';

export const setRoleData = roleData => ({
  
  type: RoleActionTypes.SET_ROLE_DATA,
  
  payload: roleData
});

export const resetRole = ({
    type: RoleActionTypes.RESET
})
