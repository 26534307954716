import React, { useState, useEffect } from 'react';




class BlankExpiringComponemt extends React.Component{
    constructor(props){
        super(props);
        
    }

     render(){
    return(
	<>
    
    	<div class="expiry-list">
        {/* <div  class="col-12 valid-success">You don't have any expiring component yet!
        </div> */}
        <div  class="col-12 valid-success">No Expiring component available now!
        </div>
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                                            </div>
                                                            
                                            <div class="expiry-list">
                                            <div class="row">
                                                            <div class="col-12 col-md-4">
                                                            </div>
                                                            </div>
                        </div>
                                		
</>)

}
}

export default BlankExpiringComponemt;
