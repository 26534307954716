import { ResponseType } from './response.type';

const INITIAL_STATE = {
    //default: errorMessage:''
    responseDetailsVal:'',
    dashBoardResponseDetails:'',
    userResponse:'',
    walletResponse:'',
    walletServiceResponse:'',
    transactionResposne:'',
    userProfileResponse:'',
    fetchTransactionResponse:'',
    expiringFirstResponse:[],
    cashBackResponse:'',
    creditNoteResponse:'',
    bonushCashResponse:'',
    txnResponse:'',
    txnResponsePager:'',
    txnResponseLength:'',
    complaitFetchResponse:'',
    gameOfWheelResponse:'',
    gameOfWheelTexts:[]

};

const responseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
     

        case ResponseType.showResponse:
            return {
                    ...state,
                responseDetailsVal: action.payload,
            };
        case ResponseType.showDashBoardResponse:
            return {
                ...state,
                dashBoardResponseDetails: action.payload,         
            }; 

            case ResponseType.showUserProfileResponse:
                return {
                    ...state,
                    userProfileResponse: action.payload,         
                };     
        case ResponseType.showUserResponse:
            return {
                ...state,
                userResponse: action.payload,         
        };   
        case ResponseType.showWalletResponse:
            return {
                ...state,
                walletResponse: action.payload,         
            };
        case ResponseType.showWalletServiceResponse:
            return {
                ...state,
                walletServiceResponse: action.payload,         
            }; 
        case ResponseType.showCashBackResponse:
            return {
                 ...state,
                 cashBackResponse: action.payload,         
            }; 
        case ResponseType.showCreditNoteResponse:
            return {
                ...state,
                 creditNoteResponse: action.payload,         
            };
        case ResponseType.showBonusCashResponse:
            return {
                ...state,
                bonushCashResponse: action.payload,         
            };        
         case ResponseType.showTransaction:
            return {
                ...state,
                transactionResposne: action.payload,         
            }; 
            
        case ResponseType.showTxnResponse:
            return {
                ...state,
                txnResponse: action.payload,         
            };  
            
            case ResponseType.showTxnResponsePager:
                return {
                    ...state,
                txnResponsePager: action.payload,         
                };
                case ResponseType.showTxnResponseLength:
                    return {
                        ...state,
                    txnResponseLength: action.payload,         
                    };       
        case ResponseType.fetchTransaction:
            return {
                ...state,
                fetchTransactionResponse: action.payload,         
            };
        case ResponseType.showExpiringFirstResponse:
            return {
                ...state,
                 expiringFirstResponse: action.payload,         
            };
        case ResponseType.setComplaintFetchResponse:
            return {
                ...state,
                complaitFetchResponse: action.payload,         
            };
            
            case ResponseType.setGameOfWheelLoadResponse:
                return {
                    ...state,
                    gameOfWheelResponse: action.payload,         
                }; 
                
            case ResponseType.setGameOfWheelTextValues:
                    return {
                        ...state,
                    gameOfWheelTexts: action.payload,         
            };                   
        case ResponseType.RESET:
            return INITIAL_STATE;
                                                           
        default:
            return state;
    }
};

export default responseReducer; 