import React, { useState, useEffect } from 'react';


import { connect } from "react-redux";

import history from '../../auth/history';



class TncGameOfWheel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',


		}
	}



	gameOfWheelRedIrect = () => {
		history.push('/gameOfWheel');
		history.go();
	}




	render() {




		return (
			<>

				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="termtxt">
								<h2>Terms and Conditions <button class="linkbtn" onClick={this.gameOfWheelRedIrect}>Back</button></h2>

								{this.props.tncGameOfwhhel != null &&
									this.props.tncGameOfwhhel != undefined &&
									this.props.tncGameOfwhhel != "" &&
									this.props.tncGameOfwhhel.length > 0 &&
									this.props.tncGameOfwhhel.map(tnc =>
										<p>{tnc}</p>
									)}

							</div>
						</div>
					</div>
				</div>

			</>)

	}
}





const mapStateToProps = (
	{ showAddHide: { viewDashBoardSection },
		miscReduce: { tncGameOfwhhel } }
) => ({
	viewDashBoardSection, tncGameOfwhhel
});

export default connect(mapStateToProps, null)(TncGameOfWheel);

