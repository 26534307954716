import React, { useState, useEffect } from 'react';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";


import { setCardNumber } from '../../redux/misc-data/misc-data.action';
import { setCardPin } from '../../redux/misc-data/misc-data.action';
import { showErrorMessageSection } from '../../redux/error-message/eror-message.action';


class PurchaseGiftCard extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
			giftCardNumber:'',
			displayMessage:''
        }
    }
	

	closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	 
	giftCardOnChange= (event) =>{
		
			this.setState({
				giftCardNumber: event.target.value
			})		
	}
	 

    purchaseGiftCardSubmit= (event) => {
		this.setState({
			displayMessage:''
		})
	   event.preventDefault();
	   store.dispatch(showErrorMessageSection(''));
	//    localStorage.setItem('cardNumber',this.state.giftCardNumber);
       store.dispatch(setCardNumber(this.state.giftCardNumber));
	
	   if(this.state.giftCardNumber.length==16 && !isNaN(this.state.giftCardNumber)){
	   store.dispatch(showDashBoardComponentSection('giftCardOtp'));
	   }else{
		   this.setState({
			   displayMessage:'Please enter valid giftcard.'
		   })
	   }
	}
	backModal= (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('addGiftCardSection'));
	 }

	 render(){
		
	return(
		<>

	<div class="innercard">
			   <div class="head"><h3>Add Gift Card </h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button>
			   <button type="button" class="back"  onClick={this.backModal} data-toggle="tooltip" title="Back">back</button>	</div>

					
					<div class="giftdtlshow">
						<div class="giftsts">
							<div class="row">
								<div class="col-12 col-sm-7 col-md-8 col-xl-9">
									<div class="giftamnt">
										<i></i>

										{this.props.walletServiceResponse!=null &&
										  this.props.walletServiceResponse.giftcard!=null && 
										<h6>Gift Card Balance <span>₹ {this.props.walletServiceResponse.giftcard.totalSubWalletBalance}</span></h6>
	 									}
										 {this.props.walletServiceResponse!=null &&
										  this.props.walletServiceResponse.giftcard==null && 
										<h6>Gift Card Balance <span>₹ 0.00</span></h6>
	 									}
										  {this.props.walletServiceResponse==null && 
										<h6>Gift Card Balance <span>₹ 0.00</span></h6>
	 									}
									</div>
								</div>
								
								<div class="col-12 col-sm-5 col-md-4 col-xl-3">
									<div class="giftamnt gbdr">
										{this.props.walletServiceResponse!=null && 
												this.props.walletServiceResponse.giftcard!=null && 
													this.props.walletServiceResponse.giftcard.subSections!=null &&
										<h6>Total Cards <span> {this.props.walletServiceResponse.giftcard.subSections.length}</span></h6>
	 									}
										 {this.props.walletServiceResponse!=null && 
												this.props.walletServiceResponse.giftcard!=null && 
													this.props.walletServiceResponse.giftcard.subSections==null &&
										<h6>Total Cards <span>0</span></h6>
	 									}
										 {this.props.walletServiceResponse!=null && 
												this.props.walletServiceResponse.giftcard==null && 
													
										<h6>Total Cards <span>0</span></h6>
	 									}
										  {this.props.walletServiceResponse==null && 
													
										<h6>Total Cards <span>0</span></h6>
	 									}
									</div>
								</div>
							</div>
						</div>
						
						<div class="addgiftcard">
							<div class="giftcardform">						
								<p>Enter Gift Card Number purchased from SPAR store</p>
								
								<form onSubmit={this.purchaseGiftCardSubmit}>
									<div class="form-floating">
										<input 
										 type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
										 class="form-control" id="card" placeholder="Gift Card Number"
										  onChange={this.giftCardOnChange}/>
										 {this.state.displayMessage!='' &&
										 <div class='valid-error'>
											 {this.state.displayMessage}
										 </div>
										}
										<label for="card">Gift Card Number</label>
									</div>
									<button type="submit" class="btn primary">Continue</button>
								</form>
							</div>							
						</div>
					</div>
				</div>	

	
</>)

}
}

export default PurchaseGiftCard;
