export const ErrorMessageType = {
  showErrorMessageSection:'showErrorMessageSection',
  showOtpErrorMessage:'showOtpErrorMessage',
  showRegisterErrorMessage:'showRegisterErrorMessage',
  profileUpdateErrorMessage:'profileUpdateErrorMessage',
  walletErrorMessage:'walletErrorMessage',
  transactionErrorMessage:'transactionErrorMessage',
  showLoginErrorMessage:'showLoginErrorMessage',
  orderErrorMessage:'orderErrorMessage',
  orderTrnasferMessage:'orderTrnasferMessage',

    RESET: "RESET",
    
	
  };