import rules from '../../auth/rules/rbac-rules';
import { setRoleData } from '../../redux/role/role.action';
import { setUserRole } from '../../redux/admin/admin.action';
import apiCallService from "../api-call-service";

import { store } from '../../redux/store';
import history from '../../auth/history';

class AclService { 
  async aclServiceCall(url) {
    const  res = await apiCallService.getMethodCallWithoutParam(url);
    console.log('inside aclServiceCall method', url ,res)   
    if(res !=undefined &&  res.responseCode ==0 &&  res.responseMessage=="Success"){
  const permissions = res.actionRoleResponses !=undefined ? res.actionRoleResponses:null;
          var data = []
          var x;
          var userRole;
          if(permissions !=null && permissions.length > 0){

              for (x of permissions) {
                data.push(x.action);
                localStorage.setItem('role', x.role);
                userRole = x.role;
                console.log(rules);

                //console.log(x.actionName);
              }
              localStorage.setItem('adminStaticData', data);
              rules.admin.static = data;
              store.dispatch(setRoleData(data)); 
              store.dispatch(setUserRole(userRole));
              



           }else{
            localStorage.setItem('adminStaticData', data);
            rules.admin.static = data;
            store.dispatch(setRoleData(data)); 
            store.dispatch(setUserRole(userRole));
           }
        } else if (res !== undefined && res.data !== undefined && res.data.responseCode === 0) {
          localStorage.setItem('errorMessage', res.data.responseMessage);
          history.push('/');
          history.go();
        } 

  }

}



export default new AclService();