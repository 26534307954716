
import { ErrorMessageType } from './error-message.type';

export const showErrorMessageSection = (data) => ({
  type: ErrorMessageType.showErrorMessageSection,
  payload: data
  
});

export const showLoginErrorMessage = (data) => ({
  type: ErrorMessageType.showLoginErrorMessage,
  payload: data
  
});

export const showOtpErrorMessage = (data) => ({
  type: ErrorMessageType.showOtpErrorMessage,
  payload: data
  
});

export const showRegisterErrorMessage = (data) => ({
  type: ErrorMessageType.showRegisterErrorMessage,
  payload: data
  
});

export const profileUpdateErrorMessage = (data) => ({
  type: ErrorMessageType.profileUpdateErrorMessage,
  payload: data
  
});

export const walletErrorMessage = (data) => ({
  type: ErrorMessageType.walletErrorMessage,
  payload: data
  
});


export const transactionErrorMessage = (data) => ({
  type: ErrorMessageType.transactionErrorMessage,
  payload: data
  
});


export const orderErrorMessage = (data) => ({
  type: ErrorMessageType.orderErrorMessage,
  payload: data
  
});

export const orderTrnasferMessage = (data) => ({
  type: ErrorMessageType.orderTrnasferMessage,
  payload: data
  
});

export const resetErrorMessage ={
  type: ErrorMessageType.RESET
}