import React, { useState, useEffect } from 'react';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';
import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { getCashBack } from '../../redux/misc-data/misc-data.action';

import Pagination from "react-js-pagination";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import moment from 'moment';



const Option = (props) => {
	return (
	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };

class SparCashBackPlusComponents extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			zResponse:[],
			tResponse:
			this.props.cashBackResponse==''?[]:this.props.cashBackResponse.slice(0,6),
			
			sResposne:[],
			activePage: 1,
			formDateResponse:[],
			toDateResponse:[],
			
			
		
			typeOption : [
				{ value: "Add Money", label: "Add Money" },
				{ value: "Bonus Club", label: "Bonus Club" },
				{ value: "Promotional", label: "Promotional" },
			 ],
			type:[],
			typeList:[],
			typeResponse:[],
			uResponse:
			this.props.cashBackResponse!=''?this.props.cashBackResponse.slice(0,6):[]
			,
			
			statusOption:[
			{ value: "Active", label: "Active" },
			{ value: "Expired", label: "Expired" },
			{ value: "Used", label: "Used" },
			
			],
			
			status:[],
			statusList:[],
			statusResponse:[],
			
			
			formDate:'',
			toDate:'',
			message:'',
			statusVal:''
		}
	}




    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
	  backModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	  }
		cashBackDetailsSection= (event,cashBackResponse) =>{
		event.preventDefault();
		console.log('cashBackResponse',cashBackResponse);
		store.dispatch(getCashBack(cashBackResponse));
		store.dispatch(showDashBoardComponentSection('sparCashBackDetailsSection'));
	  }
	  
	  
	  
		dateFromSelect= (event) => {
			this.state.formDate=moment(event.target.value).format("DD-MM-YYYY");
		
			this.handleFormDate();
			
		}

		dateToSelect= (event) => {
			this.state.toDate=moment(event.target.value).format("DD-MM-YYYY");
			this.handleToDate();
			
		}

		handleFormDate(){

			this.state.formDateResponse=[];
			this.props.cashBackResponse.map(response => { 
				
				
				const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
				
			
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
				
				
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
			
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
				
				
	

				if(date1.getTime()>date2.getTime()){
				this.state.formDateResponse.push(response);
				}
					
			})

			if(this.state.formDateResponse.length>0){
				this.state.formDateResponse.map(response => {  
					if(!this.state.uResponse.includes(response)){
						this.state.uResponse.push(response);
				}	 			
			})
			}
			
			this.commonFilter();
		}

			handleToDate(){
			this.state.toDateResponse=[];
			this.props.cashBackResponse.map(response => { 
				const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
		
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
				}
			
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				console.log(date1.getTime());
				console.log(date2.getTime());
    			
				if(date1.getTime()<date2.getTime()){
				this.state.toDateResponse.push(response);
				}	 			
			})

			if(this.state.toDateResponse.length>0){
				this.state.toDateResponse.map(response => {  
					if(!this.state.uResponse.includes(response)){
						this.state.uResponse.push(response);
				}	 			
			})
			}
			console.log(this.state.uResponse.length);
			this.commonFilter();
		}		
	  
	  
			handleChange = (selected) => {
	
				this.state.type=[];
				this.setState({
					typeList:selected
				})
				if(selected.length>0){
				selected.map(response => { 
				this.state.type.push(response.value);
				})}

				console.log(this.state.type);
			this.handleFilterTypes();
				
			};
			
			
			handleFilterTypes(){

			this.state.typeResponse=[];

			this.props.cashBackResponse.map(response => { 
				
				if(this.state.type.includes(response.type)){
				this.state.typeResponse.push(response);
				}	 			
			});

			if(this.state.typeResponse.length>0){
				this.state.typeResponse.map(response => {  
					if(!this.state.uResponse.includes(response)){
					
						this.state.uResponse.push(response);
				}	 			
			})
			}
		 
			 this.commonFilter();
			 
			
			}
			
			
			handleStatusChange= (selected) => {
	
				this.state.status=[];
				this.setState({
					statusList:selected
				})
				if(selected.length>0){
				selected.map(response => { 
				this.state.status.push(response.value);
				})}

				console.log(this.state.status);
			this.handleFilterStatusType();
				
			};
			
			
					
			handleFilterStatusType(){

			   this.state.statusResponse=[];
			   const d = new Date();

			   this.props.cashBackResponse.map(response => { 
					
					console.log(response.expiringOn);
					const date1 = new Date(moment(response.expiringOn,'DD/MM/YYYY HH:mm:ss'));	
					if(date1=='Invalid date'){
						console.log('Invalid date format.');
						date1=new Date(moment(response.expiringOn,'MM/DD/YYYY HH:mm:ss'));
					}

					if(date1.getTime()<d.getTime()){
						this.state.statusVal='Expired';
					}
					if(date1.getTime()>d.getTime()){
						this.state.statusVal='Active';
					}
				
					
					if(response.balance=='0'){
					this.state.statusVal='Used';
					}
					
			
				 if(this.state.status.includes(this.state.statusVal)){
				 this.state.statusResponse.push(response);
				 }	 
				});

				if(this.state.statusResponse.length>0){
					this.state.statusResponse.map(response => {  
						if(!this.state.uResponse.includes(response)){
						
							this.state.uResponse.push(response);
					}	 			
				})
				}
			 
			 this.commonFilter();
			 
			
			}
				
			
			
		commonFilter(){
				
			const d=new Date();
				this.state.zResponse=[];
				var x=[];
				var y=[];
				
				this.state.uResponse.map(response => { 
					if(this.state.status.length>0){

						console.log(response.expiringOn);
						const date1 = new Date(moment(response.expiringOn,'DD/MM/YYYY HH:mm:ss'));	
						if(date1=='Invalid date'){
							console.log('Invalid date format.');
							date1=new Date(moment(response.expiringOn,'MM/DD/YYYY HH:mm:ss'));
						}
			
						if(date1.getTime()<d.getTime()){
							this.state.statusVal='Expired';
						}
						if(date1.getTime()>d.getTime()){
							this.state.statusVal='Active';
						}
						if(response.balance=='0'){
							this.state.statusVal='Used';
						}
						
						 if(!this.state.status.includes(this.state.statusVal)){
							if(!y.includes(response)){	
								y.push(response);
								}
						 }	 			
					}
					}
					);


			
					this.state.uResponse.map(
						response => { 
					if(this.state.formDate!=''){
						
					const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));	
						
					if(date1=='Invalid date'){
						console.log('Invalid date format.');
						date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
					}
					const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
					
					if(date2=='Invalid date'){
						console.log('Invalid date format.');
						date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
					}
				
					if(date1.getTime()<date2.getTime()){
					 
						 if(!y.includes(response)){	
							y.push(response);
							}
					}else{
						console.log("uResposne createddate greater::",date1.getTime());
						console.log("uResponse formdate::",date2.getTime()); 
					}
					}
					}
					);
					
	
					//TODATE
					this.state.uResponse.map(
						response => { 
					 if(this.state.toDate!=''){
					const date1 = new Date(moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss'));
					if(date1=='Invalid date'){
						console.log('Invalid date format.');
						date1=new Date(moment(response.expiringOn,'MM-DD-YYYY HH:mm:ss'));
					}
					const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
					
					if(date2=='Invalid date'){
						console.log('Invalid date format.');
						date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
					}
					if(date1.getTime()>date2.getTime()){
						 if(!y.includes(response)){	
							y.push(response);
							}
					}else{
						console.log("uResposne createddate lesser::",date1.getTime());
						console.log("uResponse todate::",date2.getTime()); 
					}
					}
					}
				);
		
				
				 	
			console.log('y length::',y.length);
			console.log('uResponse length::',this.state.uResponse.length);
            this.state.uResponse.map(response =>{
             if(!y.includes(response)){
				console.log('response remains',response);
				x.push(response);
			 }
			});
			console.log('x.length c:',x.length);
			    if(x.length>0){
				x.map(response=>{
				this.state.zResponse.push(response);
				});
				this.setState({
				message:'',
				sResposne:this.state.zResponse.slice(0,6)
				}
				)
			   }else{
				this.setState({
					zResponse:[],
					message:'No record found',
					sResposne:[]
				})  
			   }
			
			
		
		
			}
		
		




	   handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	
		this.setState({
			tResponse:this.props.cashBackResponse.slice((pageNumber-1)*6,(pageNumber)*6)
		 })
	   }

	   handlePageChange(pageNumber) {
		console.log('Inside handlePageChange');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		//console.log(JSON.stringify(this.state.uResponse));
		
		this.setState({
			sResposne:this.state.zResponse.slice((pageNumber-1)*6,(pageNumber)*6)
		})
	   }
    
	render(){
// 		console.log('cashBackResponse..',this.props.cashBackResponse);
// console.log('tResponse..',this.state.tResponse);
console.log('cashBackCampaigns...'+JSON.stringify(this.props.cashBackCampaigns));
return(


<div class="innercard">
			   <div class="head"><h3>My Cashback</h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button>	
			   <button type="button" onClick={this.backModal} 
			   class="back">close</button></div>

					
					<div class="cashshow">
						<div class="cashdtl">
							<div class="row">
								<div class="col-12 col-sm-9 col-xl-10">
									<div class="cashblc">
										<i></i>
										<h6>Cashback Balance: 
										{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.cashback!=null &&
													<span>₹ {parseFloat(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance).toFixed(2)}</span>
													}	
										{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.cashback==null &&
													<span>₹ 0.00</span>
													}

										{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails==null &&
													<span>₹ 0.00</span>
													}					
										
										</h6>
									</div>
								</div>

								
							
					
								
						

								<div class="col-12 col-sm-3 col-xl-2">
								{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
														this.props.walletServiceResponse.walletDetails.cashback!=null &&
														this.props.walletServiceResponse.walletDetails.cashback.subSections!=null &&
									
									<div class="dropdown gcfltr">
										<button type="button" class="filter dropdown-toggle" id="fillist" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filter</button>
										
										<div class="dropdown-menu dropdown-menu-end" aria-labelledby="fillist">
										
											<form>									
												{/* <div class="filters">
													<label for="status">Type:</label>
													
													
													<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
													options={this.state.typeOption}
													isMulti
													closeMenuOnSelect={false}
													hideSelectedOptions={false}
													components={{
														Option
													}}
													onChange={this.handleChange}
													allowSelectAll={true}
													value={this.state.typeList}
													/>
												</div> */}
												
												<div class="filters">
													<label for="type">Status:</label>
													
													
													
													<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
													options={this.state.statusOption}
													isMulti
													closeMenuOnSelect={false}
													hideSelectedOptions={false}
													components={{
														Option
													}}
													onChange={this.handleStatusChange}
													allowSelectAll={true}
													value={this.state.statusList}
													/>
												</div>
												
												<div class="filters">
													<label for="select-state">From Date:</label>
													<input type="date"  name="" id=""onChange={this.dateFromSelect}/>
												</div>
												
												<div class="filters">
													<label for="select-state">To Date:</label>
													<input type="date"  name="" id=""onChange={this.dateToSelect}/>
												</div>

												<div class="fbtn">
													<button class="mediumbtn" type="reset" id="btnClear">Clear all</button>
												</div>
											</form>
										</div>
									</div>
									}
									
								</div>
							</div>
						</div>
					
						<div class="cashbklist">
						{this.props.walletServiceResponse!=null &&
						this.props.walletServiceResponse.walletDetails!=null &&
						this.props.walletServiceResponse.walletDetails.cashback==null &&
						<div class="alertPopUp success">
						{/* <span class="closebtnPopUp">&times;</span>   */}
						<strong>No CashBack Record Available!</strong>
						</div>
						} 
						
							<div class="row">
							
							
					{
					(this.state.message=='' &&
					this.state.zResponse.length==0)?
					        
				 		this.state.tResponse.map(cashBackResp => (
                                            
											(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null) ?
												<div class="col-12 col-sm-6 col-md-4">
														<div class="cashview">
															<button type="button" id={cashBackResp.id}
															onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
															>
																<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        <h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>
														{
														this.props.cashBackCampaigns!=null &&
														this.props.cashBackCampaigns.length>0 &&
															this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
															))}
															{this.props.cashBackCampaigns!=null &&
																this.props.cashBackCampaigns.length==0 &&
																cashBackResp.campaignId
															}
																<h6 class="text-truncate">#{cashBackResp.id}</h6>
																<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
																<p>Validity <span>{
																(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
																moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
																(cashBackResp.expiringOn).split(" ")[0]}
																</span></p>
																</button>
														</div>								
													</div>:

														moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
														<div class="col-12 col-sm-6 col-md-4">
														<div class="cashview exprd">
														<button type="button" id={cashBackResp.id}
														onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
														>
															<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        	<h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>

															{this.props.cashBackCampaigns!=null &&
															this.props.cashBackCampaigns.length>0 &&
															this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
															))}
															{
																this.props.cashBackCampaigns!=null &&
																this.props.cashBackCampaigns.length==0 &&
																cashBackResp.campaignId
															}
															
															<h6 class="text-truncate">#{cashBackResp.id}</h6>
															<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
															<p>Validity <span>{
															(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
															moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
															(cashBackResp.expiringOn).split(" ")[0]}
															</span></p>
															</button>
													</div>								
													</div>	:
													<div class="col-12 col-sm-6 col-md-4">
													<div class="cashview">
													<button type="button" id={cashBackResp.id}
													onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
													>
														<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        <h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>
														{this.props.cashBackCampaigns!=null &&
														this.props.cashBackCampaigns.length>0 &&
															this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
															))}
															{this.props.cashBackCampaigns!=null &&
																this.props.cashBackCampaigns.length==0 &&
																cashBackResp.campaignId
															}
														<h6 class="text-truncate">#{cashBackResp.id}</h6>
														<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
														<p>Validity <span>{
														(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
														moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
														(cashBackResp.expiringOn).split(" ")[0]}
														</span></p>
														</button>
												</div>								
												</div>

														)
								
						)
						:		
						
								this.state.sResposne.map(cashBackResp => (
                                            
											(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null) ?
												<div class="col-12 col-sm-6 col-md-4">
														<div class="cashview">
															<button type="button" id={cashBackResp.id}
															onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
															>

														<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        <h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>
																<h4>₹ {parseFloat(cashBackResp.value).toFixed(2)}</h4>
																{this.props.cashBackCampaigns!=null &&
																this.props.cashBackCampaigns.length>0 &&
																this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																	(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																	<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
																))}
																{this.props.cashBackCampaigns!=null &&
																	this.props.cashBackCampaigns.length==0 &&
																	cashBackResp.campaignId
																}
																<h6 class="text-truncate">#{cashBackResp.id}</h6>
																<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
																<p>Validity <span>{
																(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
																moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
																(cashBackResp.expiringOn).split(" ")[0]}
																</span></p>
																</button>
														</div>								
													</div>:

														moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
														<div class="col-12 col-sm-6 col-md-4">
														<div class="cashview exprd">
														<button type="button" id={cashBackResp.id}
														onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
														>
															<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        <h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>
														{this.props.cashBackCampaigns!=null &&
														this.props.cashBackCampaigns.length>0 &&
																this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																	(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																	<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
																))}
																{this.props.cashBackCampaigns!=null &&
																	this.props.cashBackCampaigns.length==0 &&
																	cashBackResp.campaignId
																}
															<h6 class="text-truncate">#{cashBackResp.id}</h6>
															<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
															<p>Validity <span>{
															(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
															moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
															(cashBackResp.expiringOn).split(" ")[0]}
															</span></p>
															</button>
													</div>								
													</div>	:
													<div class="col-12 col-sm-6 col-md-4">
													<div class="cashview">
													<button type="button" id={cashBackResp.id}
													onClick={(e)=>this.cashBackDetailsSection(e,cashBackResp)}
													>
														<h3><span>Available balance</span><br/>₹ {parseFloat(cashBackResp.balance).toFixed(2)} </h3>
                                                        <h4>Cashback amount: <span>₹ {parseFloat(cashBackResp.value).toFixed(2)}</span></h4>
														{this.props.cashBackCampaigns!=null &&
														this.props.cashBackCampaigns.length>0 &&
																this.props.cashBackCampaigns.map(cashBackCampaigns=>(
																	(cashBackCampaigns.campaignId==(cashBackResp.campaignId)) &&
																	<h5>{cashBackCampaigns.displayName==null?cashBackResp.campaignId:cashBackCampaigns.displayName}</h5>
																))}
																{this.props.cashBackCampaigns!=null &&
																	this.props.cashBackCampaigns.length==0 &&
																	cashBackResp.campaignId
																}
														<h6 class="text-truncate">#{cashBackResp.id}</h6>
														<p class="date">{(cashBackResp.startingFrom).split(" ")[0]}</p>
														<p>Validity <span>{
														(cashBackResp.expiringOn==''||cashBackResp.expiringOn==null)?'NA':
														moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?'Expired':
														(cashBackResp.expiringOn).split(" ")[0]}
														</span></p>
														</button>
												</div>								
												</div>

														)
								)
								
						} 

						{
						this.state.message!='' &&
						<div>
						{this.state.message}
						</div>
	  					}
							
							
							
							
							
							

	
								
								
						</div>
						
						</div>




			
					<div class="pager">
						

					{
					(this.state.message=='') ?
					this.state.zResponse.length==0 ?
					(this.props.cashBackResponse!=null &&
						this.props.cashBackResponse.length>=6)?

					<div>
					<p>Showing  {
					this.props.cashBackResponse!=null ?
					this.props.cashBackResponse.length:0} entries</p>
					<Pagination
					activePage={this.state.activePage}
					itemsCountPerPage={6}
					totalItemsCount={
						this.props.cashBackResponse!=null &&
						this.props.cashBackResponse.length}
					pageRangeDisplayed={5}
					onChange={this.handlePageChangeDefault.bind(this)}
					className="pagination"
					linkClass="page-link"
					itemClass="page-item"
					/>  
					</div>
					:
					<div>
					<p>Showing {
					this.props.cashBackResponse!=null ?

					

					this.props.cashBackResponse.length:0} entries</p>
					</div>
					:
					(this.state.zResponse.length>=6) &&
					<div>
					<p>Showing {this.state.zResponse.length} entries</p>
					<Pagination
          				activePage={this.state.activePage}
						itemsCountPerPage={6}
						totalItemsCount={this.state.zResponse.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>  :
						<div>
						</div>
					}     


					</div>
				</div>			
	</div>
)


}
}


const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse,
		cashBackResponse
	},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{
		cashBackCampaigns,
		phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		cashBackResponse,
		blockScreenConfirm,orderId,cashBackCampaigns,
		phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
});

export default connect(mapStateToProps, null)(SparCashBackPlusComponents);
