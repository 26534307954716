import { TokenActionTypes } from './token.types';
import { act } from 'react-dom/test-utils';

const INITIAL_STATE = {
  token: null,
  authenticated: false,
  noPricingAvailable: false,
  uniqueId:''
};

const tokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TokenActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
      case TokenActionTypes.IS_AUTHENTICATED:
        return{
          ...state,
          authenticated: action.payload
        };
     
      case TokenActionTypes.SET_NO_PRICING_AVAILABLE:
        return{
          ...state,
          noPricingAvailable: action.payload
        }
        case TokenActionTypes.SET_UNIQUE_ID:
        return{
          ...state,
          uniqueId: action.payload
        }
        case TokenActionTypes.RESET:
          return INITIAL_STATE;
    default:
      return state;
  }
};

export default tokenReducer;