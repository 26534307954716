
import { DivShowAndHideTypes } from './div-show-and-hide.type';



export const showUserComponentSection = (data) => ({
  type: DivShowAndHideTypes.showUserComponenetSection,
  payload: data
  
});

 export const showDashBoardComponentSection = (data) => ({
  type: DivShowAndHideTypes.showDashBoardComponentSection,
   payload: data
  
 });


export const resetDivShowAndHide ={
  type: DivShowAndHideTypes.RESET
}