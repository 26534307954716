import React, { useState, useEffect } from 'react';
import SparPng from "../../assests/images/spar.png";
import UserJpg from "../../assests/images/user.jpg"

import SecureLogosPng from "../../assests/images/secure-logos.png";
//import user8 from './images/user-8.jpg';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import { store } from '../../redux/store';
//import { setRoMdn, setUserFullName } from '../../redux/user/user.actions';
import { setRoMdn, setUserFullName } from "../../redux/user/user.action";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";

import ProfileComponent from "../../omts/component/profileComponents";

class Sparcash extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',
			response: null,
			captchaDone: false,

		}
	}
	componentDidMount() {

	}

	userProfileView = (event) => {
		event.preventDefault();
		var data = 'profileSection';
		store.dispatch(showDashBoardComponentSection(data));
	}

	addMoney = (event) => {
		event.preventDefault();
		var data = 'addMoneySection';
		store.dispatch(showDashBoardComponentSection(data));
	}


	notificationDetails = (event) => {
		event.preventDefault();
		var data = 'notificationSection';
		store.dispatch(showDashBoardComponentSection(data));
	}


	closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	}

	addMoneySection = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('addMoneySection'));
	}

	addGiftCardSection = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('addGiftCardSection'));
	}

	paymentSubmit = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('paymentSection'));
	}



	addMoneyCloseModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('addMoneySection'));
	}
	loadDetail = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('loadDetailSection'));
	}


	sparCashModal = (event) => {
		event.preventDefault()
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}

	sparCashBackModal = (event) => {
		event.preventDefault()
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}
	sparCouponModal = (event) => {
		event.preventDefault()
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}

	creditNoteSection = (event) => {
		event.preventDefault()
		store.dispatch(showDashBoardComponentSection('creditNoteSection'));
	}
	bonusClubSection = (event) => {
		event.preventDefault()
		store.dispatch(showDashBoardComponentSection('bonusclubSubSection'));
	}
	render() {


		console.log(":::::response::::", this.props.walletServiceResponse.walletDetails);
		return (
			<>

				<div class="innercard">
					<div class="head"><h3>My Cash </h3> <button type="button" onClick={this.closeModal}
						class="close">close</button>
					</div>


					<div class="sparcash">
						<div class="row">
							<div class="col-12 col-sm-6">
								<div class="sparbox">
									<div class="sparbal">
										<button type="submit" class="next" onClick={this.addMoneySection}>Next</button>
										<i class="wplus"></i>
										<p>E-Cash</p>
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.ecash != null) &&
											<h4>₹ {this.props.walletServiceResponse.walletDetails.ecash.totalSubWalletBalance}</h4>
										}


										{
											(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails != null &&
												this.props.walletServiceResponse.walletDetails.ecash == null &&
												this.props.walletServiceResponse.walletDetails.totalWalletBalance == null) &&
											<h4>Not Availabe</h4>
										}
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.ecash == null) &&

											<h4>Not Availabe</h4>
										}
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails == null) &&

											<h4>Not Availabe</h4>
										}

										{(this.props.walletServiceResponse == null) &&

											<h4>Not Availabe</h4>
										}

									</div>

									{this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.ecash != null &&
										<h5>Total Top-up  <span>
											{this.props.walletServiceResponse.walletDetails.ecash.subSections.length}</span></h5>
									}

									{
										this.props.walletServiceResponse == null &&
										<h5>Total Top-up  <span>
											0</span></h5>
									}

									{
										this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails == null &&
										<h5>Total Top-up  <span>
											0</span></h5>
									}
									{/* {
										  
										  this.props.walletServiceResponse==null &&
										<h4>{this.props.walletServiceResponse.walletDetails.walletDetails.length}</h4>
	  									} */}


								</div>
							</div>

							<div class="col-12 col-sm-6">
								<div class="sparbox">
									<div class="sparbal">
										{/* {(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
											this.props.walletServiceResponse.walletDetails.giftcard!=null &&
										       this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null) && */}

										<button type="submit" class="next" onClick={this.addGiftCardSection}>Next</button>

										{/* } */}

										<i class="giftcard"></i>
										<p>Gift Cards</p>
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.giftcard != null) &&

											<h4>₹ {this.props.walletServiceResponse.walletDetails.giftcard.totalSubWalletOwnerBalance}</h4>
										}
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.giftcard == null) &&
											<h4>Not Available</h4>
										}

										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails == null) &&
											<h4>Not Available</h4>
										}
										{(this.props.walletServiceResponse == null) &&

											<h4>Not Available</h4>
										}
									</div>


									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.giftcard != null &&
										this.props.walletServiceResponse.walletDetails.giftcard.subSections == null
									) &&
										<h5>Total Cards  <span>

											0</span></h5>
									}

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.giftcard == null) &&

										<h5>Total Cards  <span>

											0</span></h5>
									}

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails == null) &&


										<h5>Total Cards  <span>

											0</span></h5>
									}

									{(this.props.walletServiceResponse == null) &&


										<h5>Total Cards  <span>

											0</span></h5>
									}



									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.giftcard != null &&
										this.props.walletServiceResponse.walletDetails.giftcard.subSections != null
									) &&
										<h5>Total Cards  <span>



											{this.props.walletServiceResponse.walletDetails.giftcard.subSections.length}</span></h5>
									}

								</div>
							</div>

							<div class="col-12 col-sm-6">
								<div class="sparbox">
									<div class="sparbal">


										{/* {(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
											this.props.walletServiceResponse.walletDetails.giftcard!=null &&
											this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null) && */}

										<button type="submit" class="next" onClick={this.creditNoteSection}>Next</button>
										{/* } */}

										<i class="creditnote"></i>
										<p>Credit Note</p>
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.creditNote != null) &&
											<h4>₹ {this.props.walletServiceResponse.walletDetails.creditNote.totalSubWalletOwnerBalance}</h4>
										}

										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.creditNote == null) &&

											<h4>Not Available</h4>
										}

										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails == null) &&

											<h4>Not Available</h4>
										}

										{(this.props.walletServiceResponse == null) &&

											<h4>Not Available</h4>
										}

									</div>

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.creditNote != null &&
										this.props.walletServiceResponse.walletDetails.creditNote.subSections != null) &&
										<h5>Total Credit  <span>
											{this.props.walletServiceResponse.walletDetails.creditNote.subSections.length}</span></h5>
									}

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.creditNote != null &&
										this.props.walletServiceResponse.walletDetails.creditNote.subSections == null) &&
										<h5>Total Credit  <span>
											0</span></h5>
									}

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails != null &&
										this.props.walletServiceResponse.walletDetails.creditNote == null) &&
										<h5>Total Credit  <span>
											0</span></h5>
									}

									{(this.props.walletServiceResponse != null &&
										this.props.walletServiceResponse.walletDetails == null) &&
										<h5>Total Credit  <span>
											0</span></h5>
									}


									{(this.props.walletServiceResponse == null) &&
										<h5>Total Credit  <span>
											0</span></h5>
									}

									{/* {(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null &&
												  this.props.walletServiceResponse.walletDetails.creditNote.subSections==null) &&
										<h5>Total Credit  <span>
										
										0</span></h5>
	  									} */}
								</div>
							</div>

							<div class="col-12 col-sm-6">
								<div class="sparbox">
									<div class="sparbal">

										{/* {(this.props.walletServiceResponse!=null &&
										    this.props.walletServiceResponse.walletDetails!=null &&
												this.props.walletServiceResponse.walletDetails.creditNote!=null &&
												  this.props.walletServiceResponse.walletDetails.creditNote.subSections!=null) &&
											
											
										} */}
										<button type="submit" class="next" onClick={this.bonusClubSection}>Next</button>
										<i class="bonusclb"></i>
										<p>SPAR Bonus</p>

										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.bonusCash != null) &&
											<h4>₹ {this.props.walletServiceResponse.walletDetails.bonusCash.totalSubWalletOwnerBalance}</h4>
										}
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.bonusCash == null) &&
											<h4>Not Available</h4>
										}
										{(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails == null) &&
											<h4>Not Available</h4>
										}
										{(this.props.walletServiceResponse == null) &&

											<h4>Not Available</h4>
										}
									</div>
									{
										(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.bonusCash != null &&

											this.props.walletServiceResponse.walletDetails.bonusCash.subSections != null) &&
										<h5>Total Bonus  <span>

											{this.props.walletServiceResponse.walletDetails.bonusCash.subSections.length}</span></h5>
									}


									{
										(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.bonusCash != null &&

											this.props.walletServiceResponse.walletDetails.bonusCash.subSections == null) &&
										<h5>Total Bonus  <span>

											0</span></h5>
									}
									{
										(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails != null &&
											this.props.walletServiceResponse.walletDetails.bonusCash == null) &&
										<h5>Total Bonus  <span>

											0</span></h5>
									}

									{
										(this.props.walletServiceResponse != null &&
											this.props.walletServiceResponse.walletDetails == null) &&
										<h5>Total Bonus  <span>

											0</span></h5>
									}
									{
										(this.props.walletServiceResponse == null) &&
										<h5>Total Bonus  <span>

											0</span></h5>
									}
									{/* {(this.props.walletServiceResponse.walletDetails.bonusCash.subSections!=null) &&
										<h5>Total Bonus  <span>
										
										{this.props.walletServiceResponse.walletDetails.bonusCash.subSections.length}</span></h5>
	  									} */}

								</div>
							</div>

						</div>
					</div>
				</div>

			</>


		);
	}
}

const mapStateToProps = ({ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
	responseReduce: { responseDetailsVal, dashBoardResponseDetails,
		userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse, fetchTransactionResponse },
	leadingOverLay: { blockScreenConfirm },
	ordeDetailsReduce: { orderId },

	miscReduce: { phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
		, deviceInfo, myCouponDetails } }
) => ({
	viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse,
	userProfileResponse, orderServiceResponse, fetchTransactionResponse,
	blockScreenConfirm, orderId, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints,
	deviceInfo, myCouponDetails
});

export default connect(mapStateToProps, null)(Sparcash);
