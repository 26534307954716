import React, { useState, useEffect } from 'react';
import pageNotFoundImage from "../../assests/images/icons/404.svg";
import history from '../../auth/history';



class pageNotFoundSection extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0
        }
    }
	
    loginRedirect=(event)=>{

        history.push('/');
		
		history.go();
		
     
    }


   render(){

return(
	<>
		
        <div class="container">
	<div class="row justify-content-sm-center">
		<div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
			<div class="notfound">
            <img src={pageNotFoundImage} alt="SPAR" class="img-fluid"/>
				<button type="submit" class="btn primary" onClick={this.loginRedirect}>Go to login</button>
			</div>
		</div>
	</div>
</div>
						
	
</>)

}
}

export default pageNotFoundSection;
