import React from "react";
import history from "../../auth/history";

import {store} from "../../redux/store";

import UserService from "../../service/spar-service-impl/userService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection, showLoginErrorMessage } from "../../redux/error-message/eror-message.action";
import { connect } from "react-redux";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { setDeviceInfo, setLock, setPhone } from "../../redux/misc-data/misc-data.action";
import deviceDetails from "../../service/spar-service-impl/deviceDetails";

import { resetErrorMessage } from '../../redux/error-message/eror-message.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { resetLeadingOverLay } from '../../redux/leading-overlay/leading-overlay.action';
import { resetOrderId } from '../../redux/order-details/order-details.action';
import { resetResponse, showDashBoardResponse, showTransaction } from '../../redux/response/response.action';

import axios from 'axios'
import { resetRoleData } from './../../redux/admin/admin.action';
import { resetToken } from './../../redux/token/token.action';
import { resetUser } from './../../redux/user/user.action';
import { resetRole } from "../../redux/role/role.action";
import { setAuth } from "../../redux/auth/auth.action";

class Test2 extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
		}
    }
    componentDidMount() {
    }

    testClick= (event) => {
        event.preventDefault();
        if(this.props.lockVal=='true'){
            alert('current time::'+moment().format("DD-MM-YYYY HH:mm:ss"))
            store.dispatch(setLock(''));
        }else{
            alert('You have used your chance for now!Re-direct to Dashboard page');
            history.push('/user-dashboard');
            history.go();
        }
    }
	   componentWillUnmount(){
		 
	   }
      render(){
        console.log('Inside render');
	
          return(
          <div>
              <h2>This is test data</h2>
              <button type="button" class="close" onClick={this.testClick}
               title="Click">Click</button>
          </div>
		);
      } 
}

const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,loginErrorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,
		giftCardBalnceEnquiry,lockVal}}
	) => ({
		viewDashBoardSection,errorMessage,loginErrorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,
        cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,lockVal
});

export default connect(mapStateToProps, null)(Test2);
