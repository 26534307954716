import React, { useState, useEffect } from 'react';

import GiftCardFifthJpg from "../../assests/images/giftcard-5.jpg";

import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showErrorMessageSection } from '../../redux/error-message/eror-message.action';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
	return (
	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };
class GiftCardAdd extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
			tResponse:

			this.props.walletServiceResponse.walletDetails!=null?
			this.props.walletServiceResponse.walletDetails.giftcard.subSections==null?[]:this.props.walletServiceResponse.walletDetails.giftcard.subSections.slice(0,6)
			:[],
			
			sResposne:[],
			zResponse:[],
			activePage: 1,
			cardTypeOption : [
				{ value: "SPAR", label: "SPAR" },
				{ value: "Other", label: "Other" },
			 ],
			 statusOption:[
				{ value: "Active", label: "Active" },	
				{ value: "Expired", label: "Expired" },
				{ value: "Used", label: "Used" },
			],
			 cardTypeList:[],
			 cardType:[],
			 cardTypeResponse:[],
			 uResponse:this.props.walletServiceResponse.walletDetails!=null?
			 this.props.walletServiceResponse.walletDetails.giftcard.subSections==null?[]:
			 this.props.walletServiceResponse.walletDetails.giftcard.subSections
			 :[],
			 statusList:[],
			 status:[],
			 statusResponse:[],
			 formDate:'',
			 toDate:'',
			 formDateResponse:[],
			 toDateResponse:[],
			 message:'',
			 statusVal:''

        }
    }
	


	handleCardTypeChange = (selected) => {

		this.state.cardType=[];
		this.setState({
			cardTypeList:selected
		})
		if(selected.length>0){
		selected.map(response => { 
		this.state.cardType.push(response.value);
		})}

		console.log('cardType....',this.state.cardType);
	 this.handleCardTypeSelect();
		
	};

	handleCardTypeSelect(){

		this.state.cardTypeResponse=[];


		this.props.walletServiceResponse.walletDetails!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
		 this.props.walletServiceResponse.walletDetails.giftcard.subSections.map(response => { 
			 if(this.state.cardType.includes(response.walletOwner)){
			 this.state.cardTypeResponse.push(response);
			 }	 			
		 });

		 if(this.state.cardTypeResponse.length>0){
			 this.state.cardTypeResponse.map(response => {  
				 if(!this.state.uResponse.includes(response)){
				 
					 this.state.uResponse.push(response);
			 }	 			
		 })
		 }
	  
	  this.commonFilter();
	  
	 
	 }

	 statusChange=(selected) => {

		this.state.status=[];
		this.setState({
			statusList:selected
		})
		if(selected.length>0){
		selected.map(response => { 
		this.state.status.push(response.value);
		})}

		console.log('status....',this.state.cardType);
	this.handleStatus();
		
	};
	handleStatus(){

		this.state.statusResponse=[];
		const d = new Date();

		this.props.walletServiceResponse.walletDetails!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
		 this.props.walletServiceResponse.walletDetails.giftcard.subSections.map(response => { 

             
			console.log(response.expiringOn);
			const date1 = new Date(moment(response.expiringOn,'DD/MM/YYYY HH:mm:ss'));	
			if(date1=='Invalid date'){
				console.log('Invalid date format.');
				date1=new Date(moment(response.expiringOn,'MM/DD/YYYY HH:mm:ss'));
			}

			if(date1.getTime()<d.getTime()){
				this.state.statusVal='Expired';
			}else if(date1.getTime()>d.getTime()){
				this.state.statusVal='Active';
			}else if(response.balance=='0'){
				this.state.statusVal='Used';
			}
			 if(this.state.status.includes(this.state.statusVal)){
			 this.state.statusResponse.push(response);
			 }	 			
		 });

		 if(this.state.statusResponse.length>0){
			 this.state.statusResponse.map(response => {  
				 if(!this.state.uResponse.includes(response)){
				 
					 this.state.uResponse.push(response);
			 }	 			
		 })
		 }
	  
	  this.commonFilter();
	  
	 
	 }

	 dateFromSelect= (event) => {
		this.state.formDate=moment(event.target.value).format("DD-MM-YYYY");
		this.handleFormDate();
		
	}

	handleFormDate(){

		this.state.formDateResponse=[];

		this.props.walletServiceResponse.walletDetails!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard.subSections.map(response => { 
			const date1 = new Date(moment(response.addedOn,'DD/MM/YYYY HH:mm:ss'));	
			if(date1=='Invalid date'){
				console.log('Invalid date format.');
				date1=new Date(moment(response.addedOn,'MM/DD/YYYY HH:mm:ss'));
			}
			
			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
		
			if(date2=='Invalid date'){
				console.log('Invalid date format.');
				date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
			}
			
			if(date1.getTime()>date2.getTime()){
			this.state.formDateResponse.push(response);
			}
				
		})

		if(this.state.formDateResponse.length>0){
			this.state.formDateResponse.map(response => {  
				if(!this.state.uResponse.includes(response)){
					this.state.uResponse.push(response);
			}	 			
		})
		}
		
		this.commonFilter();
	}


	dateToSelect= (event) => {
		this.state.toDate=moment(event.target.value).format("DD-MM-YYYY");
		this.handleToDate();
		
	}

	handleToDate(){
		this.state.toDateResponse=[];


		this.props.walletServiceResponse.walletDetails!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
		this.props.walletServiceResponse.walletDetails.giftcard.subSections.map(response => { 
			const date1 = new Date(moment(response.addedOn,'DD/MM/YYYY HH:mm:ss'));	
	
			if(date1=='Invalid date'){
				console.log('Invalid date format.');
				date1=new Date(moment(response.addedOn,'MM/DD/YYYY HH:mm:ss'));
			}
		
			const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
			if(date2=='Invalid date'){
				console.log('Invalid date format.');
				date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
			}
		
			if(date1.getTime()<date2.getTime()){
			this.state.toDateResponse.push(response);
			}	 			
		})

		if(this.state.toDateResponse.length>0){
			this.state.toDateResponse.map(response => {  
				if(!this.state.uResponse.includes(response)){
					this.state.uResponse.push(response);
			}	 			
		})
		}
		console.log(this.state.uResponse.length);
		this.commonFilter();
	}



	commonFilter(){
					
		        const d=new Date();
				this.state.zResponse=[];
				var x=[];
				var y=[];

				this.state.uResponse.map(response => { 
				if(this.state.cardType.length>0){
				 if(!this.state.cardType.includes(response.walletOwner)){
				if(!y.includes(response)){	
                y.push(response);
				}
				}
				}
				}
				);


				this.state.uResponse.map(response => { 
					if(this.state.status.length>0){

						console.log(response.expiringOn);
						const date1 = new Date(moment(response.expiringOn,'DD/MM/YYYY HH:mm:ss'));	
						if(date1=='Invalid date'){
							console.log('Invalid date format.');
							date1=new Date(moment(response.expiringOn,'MM/DD/YYYY HH:mm:ss'));
						}
			
						if(date1.getTime()<d.getTime()){
							this.state.statusVal='Expired';
						}else if(date1.getTime()>d.getTime()){
							this.state.statusVal='Active';
						}else if(response.balance=='0'){
							this.state.statusVal='Used';
						}
						
						 if(!this.state.status.includes(this.state.statusVal)){
							if(!y.includes(response)){	
								y.push(response);
								}
						 }	 			
					}
					}
					);
	

				
				//formdate 
				this.state.uResponse.map(
					response => { 
				if(this.state.formDate!=''){
					
		        const date1 = new Date(moment(response.addedOn,'DD/MM/YYYY HH:mm:ss'));	
					
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.addedOn,'MM/DD/YYYY HH:mm:ss'));
				}
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
			
				if(date1.getTime()<date2.getTime()){
					 
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate greater::",date1.getTime());
					console.log("uResponse formdate::",date2.getTime()); 
				}
				}
				}
				);
			    

                //TODATE
				this.state.uResponse.map(
					response => { 
				 if(this.state.toDate!=''){
				const date1 = new Date(moment(response.addedOn,'DD/MM/YYYY HH:mm:ss'));
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.addedOn,'MM/DD/YYYY HH:mm:ss'));
				}
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				if(date1.getTime()>date2.getTime()){
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate lesser::",date1.getTime());
					console.log("uResponse todate::",date2.getTime()); 
				}
				}
				}
			);
		
				
				 	
			console.log('y length::',y.length);
			console.log('uResponse length::',this.state.uResponse.length);
            this.state.uResponse.map(response =>{
             if(!y.includes(response)){
				console.log('response remains',response);
				x.push(response);
			 }
			});
			console.log('x.length c:',x.length);
			    if(x.length>0){
				x.map(response=>{
				this.state.zResponse.push(response);
				});
				this.setState({
				message:'',
				sResposne:this.state.zResponse.slice(0,6)
				}
				)
			   }else{
				this.setState({
					zResponse:[],
					message:'No record found',
					sResposne:[]
				})  
			   }
			
			
		
		
	}
		







    closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }


      addGiftCard = (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('giftCardPurChase'));
	  }

	  sparCashSection= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	  }
	  

	  clearAll= (event) => {
		//event.preventDefault();
		console.log('clearAll');
		this.setState({
			message:'',
			typeSelect:[],
			uResposne:[],
			zResponse:[],
			tResponse:
			this.props.walletServiceResponse.walletDetails!=null?
			this.props.walletServiceResponse.walletDetails.giftcard.subSections==null?[]:this.props.walletServiceResponse.walletDetails.giftcard.subSections.slice(0,6)
			:[],
			statusList:[],
			typeList:[],
			cardTypeList:[],
			 cardType:[],
			 cardTypeResponse:[],
			 uResponse:[],
			 status:[],
			 statusResponse:[],
			 formDate:'',
			 toDate:'',
			 formDateResponse:[],
			 toDateResponse:[],
		})
	console.log('clearAllAfter');

	}

      handleAmountChange= (event) => {

        this.setState({
            amount: event.target.value
		})		
		
	  }
	  handlePageChange(pageNumber) {
		console.log('Inside handlePageChange');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		//console.log(JSON.stringify(this.state.uResponse));
		
		this.setState({
           sResponse:this.state.zResponse.slice((pageNumber-1)*6,(pageNumber)*6)
		})
	   }

	   handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	
		this.setState({
			tResponse:this.props.walletServiceResponse.walletDetails.giftcard.subSections.slice((pageNumber-1)*6,(pageNumber)*6)
		 })
	   }

   render(){

	   //console.log(":::walletServiceResponse:::",this.props.walletServiceResponse);
	   console.log(":::tResponse:::",this.state.tResponse);

		return(
			<>

		<div class="innercard">
			   <div class="head">
			   <button type="button" onClick={this.closeModal} 
			   class="close">close</button>
			   <button type="button" class="back"  onClick={this.sparCashSection} data-toggle="tooltip" title="Back">back</button>
			   	<h3>Gift Cards </h3>
				   </div>
			   <div class="giftdtlshow">
						<div class="giftsts">
							<div class="row">
							<div class="col-12 col-sm-7 col-md-6 col-xl-8">
									<div class="giftamnt gbdr">
										<i></i>
										
										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails!=null && 
										    this.props.walletServiceResponse.walletDetails.giftcard!=null && 	
											<h6>Gift Card Balance 
											<span>₹ {this.props.walletServiceResponse.walletDetails.giftcard.totalSubWalletBalance}</span></h6>
										}
										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails!=null && 
										    this.props.walletServiceResponse.walletDetails.giftcard==null && 	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}
										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails==null &&	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}
										{this.props.walletServiceResponse==null &&
										  	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}
									</div>
								</div>
								
							<div class="col-10 col-sm-4 col-md-4 col-xl-3">
								<div class="giftamnt">
										<button type="button" class="add" onClick={this.addGiftCard} data-toggle="tooltip"
										 title="Add Gift Card">Add Card</button>
										{this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
													   this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
													   <h6>Total Cards <span>
											    			{this.props.walletServiceResponse.walletDetails.giftcard.subSections.length}</span></h6>
   										    }	
											{this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
													   this.props.walletServiceResponse.walletDetails.giftcard.subSections==null &&
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    } 
											   {this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard==null &&
													 
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   

											   {this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails==null &&
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   	

											     {this.props.walletServiceResponse==null && 
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   

									</div>
								</div>	
								<div class="col-2 col-sm-1 col-md-2 col-xl-1">

								{this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
													   this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&	
									<div class="dropdown gcfltr">
										<button type="button" class="filter dropdown-toggle" id="fillist" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filter</button>
											<div class="dropdown-menu dropdown-menu-end" aria-labelledby="fillist">
											
												<form>
												<div class="filters">
													<label for="cardType"  className="filterLabel">Card Type:</label>
												
														<ReactSelect tabindex="8" id="cardType" data-placeholder="..."  multiple class="chosen-select"
														options={this.state.cardTypeOption}
														isMulti
														closeMenuOnSelect={false}
														hideSelectedOptions={false}
														components={{
															Option
														}}
													
														onChange={this.handleCardTypeChange}
														allowSelectAll={true}
														value={this.state.cardTypeList}
														/>
												</div>
												
												<div class="filters">
													<label for="status" className="filterLabel">Status:</label>
													<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
														options={this.state.statusOption}
														isMulti
														closeMenuOnSelect={false}
														hideSelectedOptions={false}
														components={{
															Option
														}}
														onChange={this.statusChange}
														allowSelectAll={true}
														value={this.state.statusList}
														></ReactSelect>
										
												</div>
												
												<div class="filters">
													<label for="select-state" className="filterLabel">Added From Date:</label>
													<input type="date"  id="" onChange={this.dateFromSelect}/>
												</div>
												
												<div class="filters">
													<label for="select-state" className="filterLabel">Added To Date:</label>
													<input type="date"  id="" onChange={this.dateToSelect}/>
												</div>

												<div class="fbtn">
													<button class="mediumbtn" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
												</div>
											</form>
									</div>
								
								</div>
   								}
								
							</div>
   							
										
												
									
							</div>
						</div>
						
						<div class="giftlist">
							
						<div class="row">
					{
					(this.state.message=='' &&
					this.state.zResponse.length==0)?
					        
				 		this.state.tResponse.map(response => (



							parseInt(response.balance)==0?
								<div class="col-12 col-sm-6 col-md-4">
								<div class="giftview used">
									
									<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

										<h4>{response.giftcardNumber}</h4>
										<h5>₹ {response.balance} 
										{
										moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')>new Date()?
										<span class="used">Used</span>:

										<span>{response.expiringOn.split(" ")[0]}</span>
										
										   }
										  
										</h5>
								</div>								
								</div>:

							moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
							<div class="col-12 col-sm-6 col-md-4">
									<div class="giftview exprgift">
										
										<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

											<h4>{response.giftcardNumber}</h4>
											<h5>₹ {response.balance} 
											{
											moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')>new Date()?
											<span class="exprtxt">Expired</span>:

											<span>{response.expiringOn.split(" ")[0]}</span>
											
   											}
											  
											</h5>
									</div>								
									</div>:
									<div class="col-12 col-sm-6 col-md-4">
									<div class="giftview">
										
										<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

											<h4>{response.giftcardNumber}</h4>
											<h5>₹ {response.balance} 
											{
											moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
											<span class="exprtxt">Expired</span>:

											<span>{response.expiringOn.split(" ")[0]}</span>
   											}
											   
											</h5>
									</div>								
									</div>
								)
								
						)
						:		
						
						this.state.sResposne.map(response =>(
							parseInt(response.balance)==0?
								<div class="col-12 col-sm-6 col-md-4">
								<div class="giftview used">
									
									<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

										<h4>{response.giftcardNumber}</h4>
										<h5>₹ {response.balance} 
										{
										moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')>new Date()?
										<span class="used">Used</span>:

										<span>{response.expiringOn.split(" ")[0]}</span>
										
										   }
										  
										</h5>
								</div>								
								</div>:

							moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
							<div class="col-12 col-sm-6 col-md-4">
									<div class="giftview exprgift">
										
										<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

											<h4>{response.giftcardNumber}</h4>
											<h5>₹ {response.balance} 
											{
											moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')>new Date()?
											<span class="exprtxt">Expired</span>:

											<span>{response.expiringOn.split(" ")[0]}</span>
											
   											}
											  
											</h5>
									</div>								
									</div>:
									<div class="col-12 col-sm-6 col-md-4">
									<div class="giftview">
										
										<img src={GiftCardFifthJpg} alt="SPAR" class="img-fluid"/>

											<h4>{response.giftcardNumber}</h4>
											<h5>₹ {response.balance} 
											{
											moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')<new Date()?
											<span class="exprtxt">Expired</span>:

											<span>{response.expiringOn.split(" ")[0]}</span>
   											}
											   
											</h5>
									</div>								
									</div>
								)
								)
								
						} 

						{
						this.state.message!='' &&
						<div>
						{this.state.message}
						</div>
	  					}
								

				</div>			
			</div>	
					

			<div class="pager">
						
						{
						(this.state.message=='') ?
						this.state.zResponse.length==0 ?
						(this.props.walletServiceResponse!=null &&
							this.props.walletServiceResponse.walletDetails!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard.subSections.length>=6)?
	
						<div>
						<p>Showing {
						this.props.walletServiceResponse!=null &&
						this.props.walletServiceResponse.walletDetails!=null &&
						this.props.walletServiceResponse.walletDetails.giftcard!=null &&
						this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
						this.props.walletServiceResponse.walletDetails.giftcard.subSections.length} entries</p>
						<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={6}
						totalItemsCount={
							this.props.walletServiceResponse!=null &&
							this.props.walletServiceResponse.walletDetails!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard.subSections.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChangeDefault.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>
						:
						<div>
						{this.props.walletServiceResponse!=null &&
							this.props.walletServiceResponse.walletDetails!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard!=null &&
						this.props.walletServiceResponse.walletDetails.giftcard.subSections==null &&
						<div>
						<p>Showing 0 entries</p></div> 
						}
						{this.props.walletServiceResponse!=null &&
							this.props.walletServiceResponse.walletDetails!=null &&
							this.props.walletServiceResponse.walletDetails.giftcard!=null &&
						this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
						<div><p>Showing {this.props.walletServiceResponse.walletDetails.giftcard.subSections.length} entries</p></div> 
						}	
						</div>
						:
						(this.state.zResponse.length>=6) &&
						<div>
						<p>Showing {this.state.zResponse.length} entries</p>
						<Pagination
							  activePage={this.state.activePage}
							itemsCountPerPage={6}
							totalItemsCount={this.state.zResponse.length}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange.bind(this)}
							className="pagination"
							linkClass="page-link"
							itemClass="page-item"
							/>  
							</div>  :
							<div>
							</div>
						}     
	
						</div>
						</div> 
						</div>                           
			</>
	
			);
}
}

// export default GiftCardAdd;

const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},miscReduce:{cardNumber,cardPin,phone}},

	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,cardNumber,cardPin,phone
});

export default connect(mapStateToProps, null)(GiftCardAdd);

