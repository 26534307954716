import React from "react";
import {store} from "../../redux/store";
import { connect } from "react-redux";
import moment from "moment";
import BlankExpiringComponemt from './BlankExpiringComponent';
class expiringCompnent extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false
        }
    }
     componentDidMount() {
	 	
    }
      render(){
    
          return(
            <>
         
             <div class="row">
                 {this.props.expiringFirstResponse!=[] &&
                 this.props.expiringFirstResponse.length==0 &&
                 <BlankExpiringComponemt/>
                 }
                 {this.props.expiringFirstResponse!=[] &&
                 this.props.expiringFirstResponse.slice(0,3).map(response=>(

                 
							<div class="col-12 col-md-4">
                            <div class="expiry-box">
									<h5>{response.bonusCashType!=null?'SPAR Bonus Cash':
                                    response.campaignId!='null'?'SPAR My Cashback':response.giftcardNumber!=null?
                                    'SPAR Gift Card':'SPAR E-Cash'}</h5>
									<h4>₹ {parseFloat(response.balance).toFixed(2)}</h4>
                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)<=7 &&
                                									
									<p class="soon">Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==0?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }

                                    {
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)>7 &&
                                									
									<p>Expires {parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)==0?'today':' in '+
                                    parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                                    /31536000000*365).toFixed(0)+' days'}</p>
                                    }
									<p class="date">{response.expiringOn.split(" ")[0]}</p>
							</div> 
                            </div>
                          
                            ))

                 
                    }
                   </div>
          
                                

                   
                                        
                              </>


		
          );
    } 
}

const mapStateToProps = (
    { showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},responseReduce:{responseDetailsVal,dashBoardResponseDetails
    ,expiringFirstResponse},
    leadingOverLay:{blockScreenConfirm},
    ordeDetailsReduce:{orderId}}
    ) => ({
        viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,
        expiringFirstResponse,
        blockScreenConfirm,orderId
});

const mapDispatchToProps = (dispatch) => {
    return {
        transactionDetails : (showDashBoardComponentSection) => {
           dispatch(showDashBoardComponentSection('transactionDetails'))
        }
    }
   }

export default connect(mapStateToProps, mapDispatchToProps)(expiringCompnent);
