import axios from 'axios';
import { LogTypes } from '../logs/LogType'
import logger from '../logs/log';
import { store } from '../redux/store';
import { resetToken } from '../redux/token/token.action';
import { resetUser } from '../redux/user/user.action'
import {showUserComponentSection} from '../redux/div-show-and-hide/div-show-and-hide.action';
import {showDashBoardComponentSection} from '../redux/div-show-and-hide/div-show-and-hide.action';
//mport {showErrorMessageSection} from '../redux/error-message/error-message.action';
import {showCreditNoteResponse, showExpiringFirstResponse, showResponse, showTxnResponseLength} from '../redux/response/response.action';
import {showDashBoardResponse} from '../redux/response/response.action';
import {showUserResponse} from '../redux/response/response.action';
import {showWalletResponse} from '../redux/response/response.action';
import {showWalletServiceResponse} from '../redux/response/response.action';
import { showUserProfileResponse } from '../redux/response/response.action';
import {showTransaction} from '../redux/response/response.action';
import {sha256} from 'js-sha256';
import { cashBackCampaign, setCampaignRecords, setQCAuthToken,setTxnLoader } from '../redux/misc-data/misc-data.action';
import { setGiftCardBalanceEnquiry } from '../redux/misc-data/misc-data.action';
import { setGiftCardLinkResponse } from '../redux/misc-data/misc-data.action';
import { setOrderServiceResponse } from '../redux/misc-data/misc-data.action';
import { setMyCouponDetails } from '../redux/misc-data/misc-data.action';
import { setMyPoints } from '../redux/misc-data/misc-data.action';
import { fetchTransaction } from '../redux/response/response.action';
import moment from 'moment';
import { showCashBackResponse, showBonusCashResponse, showTxnResponse } from './../redux/response/response.action';
import { showTxnResponsePager } from '../redux/response/response.action';
import { setProfile } from '../redux/auth/auth.action';
import history from '../auth/history';



var response = [];
var errorData = '';
const httpClient = axios.create();
httpClient.defaults.timeout = parseInt(process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);

class ApiCall {



  async postCall(url, request) {

    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('url', url);
    const state = store.getState();
  
   
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request) 
     .then((response) => {
        console.log('enter httpclient call section');
  
       // response =  json;
       console.log('inside response', response);
        console.log('inside response', response.data);
      
        var errorMessage='';
       // store.dispatch(showErrorMessageSection(errorMessage));
     
        return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }

   getMethodCallWithoutParam(url) {

    const state = store.getState();

    // Check for Geolocation API permissions
    navigator.permissions.query({ name: 'geolocation' })
      .then(function (permissionStatus) {
        console.log('geolocation permission state is ', permissionStatus.state);

        permissionStatus.onchange = function () {
          console.log('geolocation permission state has changed to ', this.state);
        };
      });

    var long = '';
    var lat = '';
    navigator.geolocation.watchPosition(async function (position) {
      long = position.coords.longitude;
      lat = position.coords.latitude;
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
 
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log('position', position)
    });
    console.log('REACT_APP_Get_USER_PROFILE url', url, 'request');
    var device_info = {
      "roMdn": state.user.roMdn,
      "device_info": {
        "device_os": window.navigator.vendor,
        "device_os_version": '9.0',
        "device_id": "8.3",
        "model_name": "Simulator",
        "imei": '',
        "time_stamp": "2018-05-02T16:29:17",
        "latitude": lat,
        "longitude": long,
        "ipaddress": "",
        "channel": ""
      }
    }
 
    console.log('getMethodCallWithoutParam ', device_info)
    var clientId ='';
    var userType = state.user.userType;
    if(userType==process.env.REACT_APP_var_UserType_For_Admin){
      clientId = process.env.REACT_APP_var_Client_ID_FOR_Admin;
    }
    if(userType==process.env.REACT_APP_var_UserType_For_retailer){
      clientId = process.env.REACT_APP_var_Client_ID_FOR_retailer;
    }
    if(userType==process.env.REACT_APP_var_UserType_For_WhitelabelRetailer){
      clientId = process.env.REACT_APP_var_Client_ID_FOR_whitelabel_retailer;
    }
    console.log("userType____",userType);
    let headers = {
      "device_info": JSON.stringify(device_info),
      "token": state.token.token,
      "userType" : state.user.userType,
      "clientId":clientId,
      "user":state.user.loggedInUserId,
      "uniqueId" : state.token.uniqueId,
      "hashCode" :  this.generateHash('')
    }

    //logger.infolog('200', url, LogTypes.REQUEST, ' ', '', 'Inside Api Calling');
    console.log('header_value_get', headers)
    //return await axios.get(url, { headers },{timeout : process.env.REACT_APP_SERVICE_CALL_TIME_OUT})
   // return await axios.get(url, { timeout: process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT,headers :{"device_info": JSON.stringify(device_info),
   return  httpClient.get(url, { headers :{"device_info": JSON.stringify(device_info),
    "token": state.token.token,
    "userType" : state.user.userType,
    "client_id":clientId,
    "user":state.user.loggedInUserId,
    "uniqueId" : state.token.uniqueId, 
    "hashCode" :  this.generateHash(''),
    "loggedInUser": state.user.loggedInUserId} }, { headers })
      .then(async res => {
        console.log('device info', (window.navigator.vendor))
        console.log('device info', (window))
        console.log('device info header', res);
        response =  res.data;
        console.log('inside response', response)
        //logger.infolog('200', url, LogTypes.RESPONSE, JSON.stringify(response), '', 'Response From Api Calling');
        if (response.response_code === 6503) {
                url = process.env.REACT_APP_REDIRECT_URL
                  //store.dispatch(resetMoneyTransfer);
                //  store.dispatch(resetToken);
                //  store.dispatch(resetDivShowAndHide);
                //  store.dispatch(resetLinkedWallet);
                //  store.dispatch(resetMessage);
                //  store.dispatch(resetUser);
         window.location.replace(url)
        }
        return response;
      }).catch(error => {
        console.log('error', error.response);
        errorData = 'error';
        //logger.errorlog("url__"+url,'200', url, LogTypes.RESPONSE, ' ', 'Inside Error', error);
        console.log('inside errorr', error.response);
        if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
          console.log('error', error.response.data.debugMessage);
          console.log('error', error.response.status);
          console.log('inside errorr', error);
          if (error.response.data.code !== undefined) {
            errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          } else {
            errorData = `${error.response.data.message}`
          }
          console.log('inside errorr', errorData);
          if (error.response.status === 500) {
            if (error.response.data.code !== undefined) {
              errorData = `${error.response.data.message}(Error:${error.response.data.code})`
            } else {
              errorData = `${error.response.data.message}(Error:${error.response.status})`
            }
            //errorData = `${error.response.data.message}(Error:${error.response.data.code})`
          }
          //logger.errorlog('200', url, LogTypes.RESPONSE, ' ', 'Inside Error', error);

        } else {
          console.log('inside errorr ', error);
          //logger.errorlog('200', url, LogTypes.RESPONSE, ' ', 'Inside Error', error);
          errorData = 'error';
        }
        return errorData;
      })
  }

   async getMethodCallWithParam(url, param,routePage) {
    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(param), '', 'Inside Api Calling');
    console.log('url', url);
    const state = store.getState();

   
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    //return await axios.get(url, { params: param }, { headers },{timeout : process.env.REACT_APP_SERVICE_CALL_TIME_OUT})
     return await fetch(url,{
       method:'GET',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
         },
        param
      }) 
 

 
     .then((response) => response.json())
      .then((json) => {
        console.log('enter httpclient call section');
        console.log('response date',JSON.stringify(json));
        response =  json;
        console.log('inside response', response);
        var errorMessage='';
     //   store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showUserComponentSection(routePage.destinationcomponent));
        
      return response;
      }
       // return response;
      ).catch(error => {
  
        // localStorage.setItem('errorMessage','Somthing went wrong we are looking at our End ');
        console.log('error',error);
        errorData = 'error';
        //console.log('error', error.response);
        //logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(param), 'Inside Error', error);

          //logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(param), 'Inside Error', error);
          errorData = 'error';
   //     }
        return errorData;
      })
  }

generateHash(messageBody){
  //var msg= JSON.stringify(messageBody) ;
  var privateKey=process.env.REACT_APP_CLIENT_ID;
  var hash = sha256.hmac.create(privateKey);
  hash.update(messageBody);
  var hexData=hash.hex();
console.log('hexed data',hexData);
var base64String= btoa(hexData);
  console.log(messageBody ,'---->hash Generated --->', base64String );
  return base64String;
}



async getMethodCalling(url) {
  //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(param), '', 'Inside Api Calling');
  console.log('url', url);
  const state = store.getState();

 
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  //return await axios.get(url, { params: param }, { headers },{timeout : process.env.REACT_APP_SERVICE_CALL_TIME_OUT})
   return await httpClient.get(url,{
     method:'GET',
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
       },
      
    }) .then((response) => {
      console.log('enter httpclient call section with get request');

     // response =  json;
     // console.log('inside response', response.data);
     
       return response.data;
    }
    ).catch(error => {

      console.log('error',error);
     // errorData = 'error';
      console.log('error', error.response);
      //logger.errorlog('200', url, LogTypes.RESPONSE, JSON.stringify(error), 'Inside Error', error);

     // console.log('inside errorrdata block 1', error.response.data);
      //if (!(error === undefined) && !(error.response === undefined) && !(error.response.data === undefined) && !(error.response.data.message === undefined)) {
        //console.log('error', error.response.data.debugMessage);
        //console.log('error', error.response.status);
        //console.log('inside errorr', error);
       // errorData = `${error.response.data.message}(Error:${error.response.data.code})`
        //console.log('inside errorr', errorData);
        if (error.response.status === 500) {
          errorData = error.response;
          console.log('errorData response', error.response.status);
          console.log('errorData details', errorData);
        }

      return errorData;
    })
}

generateHash(messageBody){
//var msg= JSON.stringify(messageBody) ;
var privateKey=process.env.REACT_APP_CLIENT_ID;
var hash = sha256.hmac.create(privateKey);
hash.update(messageBody);
var hexData=hash.hex();
console.log('hexed data',hexData);
var base64String= btoa(hexData);
console.log(messageBody ,'---->hash Generated --->', base64String );
return base64String;
}




async walletServiceCall(url,request){
  console.log('url', url);
  const state = store.getState();
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  return await httpClient.post(url,request)
     .then((response) => {
      console.log('enter httpclient call section');

     // response =  json;
      console.log('inside response', response.data);
  
      return response.data;
    }
    ).catch(error => {
      
      console.log('error',error);
     // errorData = 'error';
      errorData=error.data;

      return errorData;
    })
  }


  async updateUserCall(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside response', response.data);
      
       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async otpValidation(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside otpValidation response', response.data);
       
       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async generateOtp(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
      console.log('inside otp generate responseresponse', response.data);
    
        //store.dispatch('otp',response.data);

       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async userUpdateService(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
      console.log('inside userUpdateService', response.data);
      return response.data;
      }
      ).catch(error => {
        console.log('error',error);
        errorData=error.data;
        return errorData;
      })
  }


  async fetchMyCouponDetails(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside fetchMyCouponDetails response', response.data);
        var couponResponse=[];
        var couponExpiredResponse=[];

      
      


  
    
  
  

        if(response.data!=null){
          if(response.data.lmrResponse!=null){
            if(response.data.lmrResponse.couponList!=null){
              if(response.data.lmrResponse.couponList.length>0){
                response.data.lmrResponse.couponList.map(response=>{
                 // console.log("test...."+moment(response.validTillDate.split("T")[0],'DD-MM-YYYY HH:mm:ss'));

                  if(parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                  /31536000000*365).toFixed(0)<-7){

                  }else{

                    if(parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                    /31536000000*365).toFixed(0)>=0){
                      console.log('coupon res:'+parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                      /31536000000*365).toFixed(0));
                  couponResponse.push(response);
                    }
                }
              }
            )
          }
        }
      }    
          
        }
  

        console.log('couponResponse.length::'+couponResponse.length);
  
        if(response.data!=null){
          if(response.data.lmrResponse!=null){
            if(response.data.lmrResponse.couponList!=null){
              if(response.data.lmrResponse.couponList.length>0){
                response.data.lmrResponse.couponList.map(response=>{
                if(parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                  /31536000000*365).toFixed(0)<-7){
                }else{
                  if(parseFloat((moment(response.validTillDate.split("T")[0],'YYYY-MM-DD')-new Date())
                  /31536000000*365).toFixed(0)<=-1){
                    console.log('coupon expired res:'+JSON.stringify(response));
                    couponExpiredResponse.push(response); 
                }
                }
              
              }
            
              )
            }
            }
          }
        }
        
  
  
        const couponOrder = couponResponse.sort((a, b) =>
        (moment(a.validTillDate.split("T")[0],'YYYY-MM-DD') - 
        moment(b.validTillDate.split("T")[0],'YYYY-MM-DD'))
        
        );

        const couponExpiredOrder = couponExpiredResponse.sort((a, b) =>
        (moment(a.validTillDate.split("T")[0],'YYYY-MM-DD') - 
        moment(b.validTillDate.split("T")[0],'YYYY-MM-DD'))
        
        );
        
  
        if(couponExpiredOrder.length>0){
          couponExpiredOrder.map(coupon=>{
            couponOrder.push(coupon);
          })
        }
  

       
    
        store.dispatch(setMyCouponDetails(couponOrder));
       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async fetchCampaignRecords(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.get(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside fetchCampaignRecords response', response.data);
        store.dispatch(setCampaignRecords(response.data));
       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }

  async fetchMyPoints(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside fetchMyPoints response', response.data);
        store.dispatch(setMyPoints(response.data));
       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }

  async addTransactionService(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside addTransactionService call', response.data);
  

       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async updateTransactionServiceCall(url, request) {
    console.log('url', url);
    const state = store.getState();
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
      console.log('REQUEST DATA:',JSON.stringify(request));
        console.log('inside updateTransactionServiceCall call', response.data);
  

       return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }


  async postMethodCallForUserLogin(url, request,routePage) {

    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('url', url);

  
   
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
  
       // response =  json;
        console.log('inside response', response.data);
  
        //store.dispatch(showUserProfileResponse(response.data)
        //);
        // if(routePage.responseContentType=='userData'){
        //   store.dispatch(showUserResponse(response.data));
        // }else{
        //   store.dispatch(showDashBoardResponse(response.data));
  
        // }
        // console.log('content-type',routePage.dashBoardContentType)
        
  
        return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }

  

  async fetchUserCall(url, request,routePage) {

    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('url', url);
    const state = store.getState();
  
   
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
  
       // response =  json;
        console.log('inside userProfile response', response.data);
  
        // store.dispatch(showUserProfileResponse(''));
       // setTimeout(()=>{
          store.dispatch(showUserProfileResponse(response.data));


         
       
        //},3000);
        
        //store.dispatch(showUserProfileResponse(''));

      
      
        // if(routePage.responseContentType=='userData'){
        //   store.dispatch(showUserResponse(response.data));
        // }else{
        //   store.dispatch(showDashBoardResponse(response.data));
  
        // }
       // console.log('content-type',routePage.dashBoardContentType)
        
        store.dispatch(setProfile(true));
      
        return response.data;
      }
      ).catch(error => {
        
        console.log('error',error);
        errorData=error.data;
  
        return errorData;
      })
  }  

async postMethodCall(url, request,routePage) {

  //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
  console.log('url', url);
  const state = store.getState();

 
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  return await httpClient.post(url,request
  ) .then((response) => {

     // response =  json;
      console.log('inside response', response.data);

      store.dispatch(showUserProfileResponse(response.data)
      );
      if(routePage.responseContentType=='userData'){
        store.dispatch(showUserResponse(response.data));
      }else{
        store.dispatch(showDashBoardResponse(response.data));

      }
      console.log('content-type',routePage.dashBoardContentType)
      

      return response.data;
    }
    ).catch(error => {
      
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}



async postUpdateCheckCall(url, request,routePage) {

  //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
  console.log('url', url);
  const state = store.getState();

 
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  return await httpClient.post(url,request
  ) 
   
 
    .then((response) => {

     // response =  json;
      console.log('inside response', response.data);

      store.dispatch(showUserProfileResponse(response.data));
      

      return response.data;
    }
    ).catch(error => {
      
      console.log('error',error);
      errorData=error.data;
      return errorData;
    })
}


async orderServiceCall(url, request,routePage) {

  //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
  console.log('url', url);
  const state = store.getState();
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  return await httpClient.post(url,request
  ) .then((response) => {
      console.log('inside orderServiceCall response', response.data);
      if(routePage.responseContentType=='userData'){
        store.dispatch(showUserResponse(response.data));
      }else{
        store.dispatch(showDashBoardResponse(response.data));

      }
     
      console.log('content-type',routePage.dashBoardContentType)
      return response.data;
    }
    ).catch(error => {
      
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}

async orderServiceCallForBonusClub(url, request,routePage) {

  //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
  console.log('url', url);
  const state = store.getState();
  console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
  return await httpClient.post(url,request
  ) .then((response) => {
      console.log('inside orderServiceCall response', response.data);
      if(routePage.responseContentType=='userData'){
        store.dispatch(showUserResponse(response.data));
      }else{
        store.dispatch(showDashBoardResponse(response.data));

      }
      
      console.log('content-type',routePage.dashBoardContentType)
      return response.data;
    }
    ).catch(error => {
      
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async postCallForWallet(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside wallet response fetchcall', response.data);
      
      store.dispatch(showWalletResponse(response.data));
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async checkAuthToken(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside checkAuthTokenl', response.data);
      store.dispatch(setQCAuthToken(response.data.authTokenCheckResponse.authToken));
       
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async fetchGiftCardRedeem(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('fetchGiftCardRedeem',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside fetchGiftCardRedeem', response.data);
   
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async fetchGiftCardReverseRedeem(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('fetchGiftCardReverseRedeem',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside fetchGiftCardReverseRedeem', response.data);
   
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async giftCardEnquiryCall(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside giftCardEnquiryCall', response.data);
      store.dispatch(setGiftCardBalanceEnquiry(response.data));

  
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async giftCardLinkServiceCall(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside giftCardLinkService', response.data);
      store.dispatch(setGiftCardLinkResponse(response.data));
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}


async postCallForWalletService(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside wallet Service response', response.data);

      var cashBackResponse=[];
      var cashBackExpiredResponse=[];
      var bonusResponse=[];
      var bonusExpiredResponse=[];
      var creditNoteResponse=[];
      var creditNoteExpiredRessponse=[];

      //console.log("response.data...."+response.data);

      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.cashback!=null){
           if(response.data.walletDetails.cashback.subSections!=null){
            response.data.walletDetails.cashback.subSections.map(cashBackResp=>{

              if(parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7){
              }else{


                if(parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>-1
              && (parseInt(cashBackResp.balance)>0 )
              ){
                  console.log('cashback resp'+parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                  /31536000000*365).toFixed(0));
                  cashBackResponse.push(cashBackResp); 

                }
              }


              //cashBackResponse.push(cashBackResp); 

              
            }


            
          
            )
          }
          }
        }
      }

      const sortedCashBackinOrder = cashBackResponse.sort((a, b) => 
       (moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'))
      
      );
      console.log('sortedCashBackinOrder:'+JSON.stringify(sortedCashBackinOrder));




      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.cashback!=null){
           if(response.data.walletDetails.cashback.subSections!=null){
            response.data.walletDetails.cashback.subSections.map(cashBackResp=>{
              if(parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7){
              }else{
                if(parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-1
              && (parseInt(cashBackResp.balance)>0 )
              ){
                  console.log('cashback resp'+parseFloat((moment(cashBackResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                  /31536000000*365).toFixed(0));
                  cashBackExpiredResponse.push(cashBackResp); 
              }
              }


              //cashBackExpiredResponse.push(cashBackResp); 
            }
          
            )
          }
          }
        }
      }
      


      const sortedCashBackExpiredinOrder = cashBackExpiredResponse.sort((a, b) =>(moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - 
      moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'))
      
      );
      console.log('sortedCashBackExpiredinOrder:'+JSON.stringify(sortedCashBackExpiredinOrder));

      if(sortedCashBackExpiredinOrder.length>0){
        sortedCashBackExpiredinOrder.map(cashBckRsp=>{
          sortedCashBackinOrder.push(cashBckRsp);
        })
      }


      store.dispatch(showCashBackResponse(sortedCashBackinOrder));

      var cashbackCampaignList=[];
      var cashbackCampaignResponseList=[];


      sortedCashBackinOrder.map(response=>{
       if(!cashbackCampaignList.includes(response.campaignId)){
          cashbackCampaignList.push(response.campaignId);
       }

      })

      console.log('cashbackCampaignList '+cashbackCampaignList);

      var cashBanckCampaignCallUrl='/api/v1/landmark/campaign/get/brief/';
      var cashBackRsp={
        campaignId:'',
        description:'',
        displayName:'',
        termsAndConditions:''
      }

      if(cashbackCampaignList.length>0){
      cashbackCampaignList.map(campaigns=>{
      httpClient.post(cashBanckCampaignCallUrl+campaigns
        ).then((response) => {
          
          console.log('inside cashBanckCampaignCallUrl response', response.data);
          if(response.data!=null && response.data!='' && response.data!=undefined){
          cashbackCampaignResponseList.push(response.data);
          }else{
            console.log('cashBackRsp...',response);
            cashBackRsp.campaignId=campaigns;
            cashBackRsp.description=campaigns;
            cashBackRsp.displayName=campaigns;
            cashBackRsp.termsAndConditions=campaigns;
            console.log('cashBackRsp...'+cashBackRsp);
            cashbackCampaignResponseList.push(cashBackRsp);

          }
      
          return response.data;
        }).catch(error => {
            
          console.log('error',error);
          errorData=error.data;
          return errorData;
        })
      })
    }


      console.log('cashbackCampaignResponseList '+cashbackCampaignResponseList);


    
      store.dispatch(cashBackCampaign(cashbackCampaignResponseList));
    



      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.bonusCash!=null){
            if(response.data.walletDetails.bonusCash.subSections!=null){
            response.data.walletDetails.bonusCash.subSections.map(bonusCashResp=>{
              if(parseFloat((moment(bonusCashResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7
             
              )
              {
              }else{

                if(parseFloat((moment(bonusCashResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                /31536000000*365).toFixed(0)>-1
                && (parseInt(bonusCashResp.balance)>0 )
                ){
                bonusResponse.push(bonusCashResp); 
              }
            }
          }
            )
          }
          }
        }
      }
      

      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.bonusCash!=null){
            if(response.data.walletDetails.bonusCash.subSections!=null){
            response.data.walletDetails.bonusCash.subSections.map(bonusCashResp=>{
              if(parseFloat((moment(bonusCashResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7
             
               ){
              }else{
                if(parseFloat((moment(bonusCashResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                /31536000000*365).toFixed(0)<=-1
                && (parseInt(bonusCashResp.balance)>0 )
                ){
                bonusExpiredResponse.push(bonusCashResp); 
              }
              }
            }
            )
          }
          }
        }
      }


  
     const sortedBonusinOrder = bonusResponse.sort((a, b) => (
       //console.log(moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss').valueOf());
      
         moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss').valueOf() - moment(b.expiringOn,'DD-MM-YYYY  HH:mm:ss').valueOf()
         
     ));

      console.log('sortedBonusinOrder:'+JSON.stringify(sortedBonusinOrder));
      var sortedBonusExpiredinOrder = bonusExpiredResponse.sort((a, b) => 
        (moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - 
      moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'))
    
      );

      console.log('sortedBonusExpiredinOrder:'+JSON.stringify(sortedBonusExpiredinOrder));


      if(sortedBonusExpiredinOrder.length>0){
        sortedBonusExpiredinOrder.map(bonusResp=>{
          sortedBonusinOrder.push(bonusResp);
        })
      }

     

      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.creditNote!=null){
            if(response.data.walletDetails.creditNote.subSections!=null){
            response.data.walletDetails.creditNote.subSections.map(creditNoteResp=>{
              if(parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7){
                }else if(parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>-1
              && (parseInt(creditNoteResp.balance)>0 )
              ){
                  console.log('credit resp'+parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                  /31536000000*365).toFixed(0));
                  creditNoteResponse.push(creditNoteResp); 
                }

                creditNoteResponse.push(creditNoteResp); 
            }
            )
          }
          }
        }
      }

      var sortedCreditNoteInOrder = creditNoteResponse.sort((a, b) => (
          moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - moment(b.expiringOn,'DD-MM-YYYY  HH:mm:ss')
          )
         );

      if(response.data!=null){
        if(response.data.walletDetails!=null){
          if(response.data.walletDetails.creditNote!=null){
            if(response.data.walletDetails.creditNote.subSections!=null){
            response.data.walletDetails.creditNote.subSections.map(creditNoteResp=>{
              if(parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-7){
                }else if(parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)<=-1
              && (parseInt(creditNoteResp.balance)>0 )
              ){
                  console.log('credit resp'+parseFloat((moment(creditNoteResp.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                  /31536000000*365).toFixed(0));
                  creditNoteExpiredRessponse.push(creditNoteResp); 

                  
              }

              creditNoteExpiredRessponse.push(creditNoteResp); 
            }
            )
          }
          }
        }
      }

      var sortedCreditNoteExpiredInOrder = creditNoteExpiredRessponse.sort((a, b) => 
      (moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - 
      moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'))
      
      );


      if(sortedCreditNoteExpiredInOrder.length>0){
        sortedCreditNoteExpiredInOrder.map(creditNoteResp=>{
          sortedCreditNoteInOrder.push(creditNoteResp);
        })
      }

      //const sortedCreditNote = creditNoteResponse.sort((a, b) => moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'));

      store.dispatch(showWalletServiceResponse(response.data));
     
      store.dispatch(showCreditNoteResponse(sortedCreditNoteInOrder));
      store.dispatch(showBonusCashResponse(sortedBonusinOrder));
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
     
          errorData = error.data;
          

      return errorData;
    })
}

async expiringFirstCall(url, request){
  console.log('url', url);


   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside expiringFirstCall Service response', response.data);
      var expiringFirstResponse=[];

      if(response.data.walletDetails.bonusCash!=null){
        console.log('bonuscash not null....');
      }

      if(response.data.walletDetails.bonusCash!=null){
        if(response.data.walletDetails.bonusCash.subSections!=null
          && response.data.walletDetails.bonusCash.subSections!=[]){


            response.data.walletDetails.bonusCash.subSections.map(response=>{

               
                if(parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
                /31536000000*365).toFixed(0)>0
                
                && (parseInt(response.balance)>0 )
                ){
              expiringFirstResponse.push(response);
              
                }})
        }
      }


      if(response.data.walletDetails.giftcard!=null){
        if(response.data.walletDetails.giftcard.subSections!=null
          && response.data.walletDetails.giftcard.subSections!=[]){
            response.data.walletDetails.giftcard.subSections.map(response=>{
              if(parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>0
              && (parseInt(response.balance)>0 )
              ){
              expiringFirstResponse.push(response);
            }
          }
          )
        }
      }

      if(response.data.walletDetails.cashback!=null){
        if(response.data.walletDetails.cashback.subSections!=null
          && response.data.walletDetails.cashback.subSections!=[]){
            response.data.walletDetails.cashback.subSections.map(response=>{
              if(parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>0
              && (parseInt(response.balance)>0 )
              ){
              expiringFirstResponse.push(response);
            }
          
          })
        }
      }

      if(response.data.walletDetails.creditNote!=null){
        if(response.data.walletDetails.creditNote.subSections!=null
          && response.data.walletDetails.creditNote.subSections!=[]){
            response.data.walletDetails.creditNote.subSections.map(response=>{
              if(parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>0
              && (parseInt(response.balance)>0 )
              ){
              expiringFirstResponse.push(response);
            }
          
          })
        }
      }

      if(response.data.walletDetails.ecash!=null){
        if(response.data.walletDetails.ecash.subSections!=null
          && response.data.walletDetails.ecash.subSections!=[]){
            response.data.walletDetails.ecash.subSections.map(response=>{
              if(parseFloat((moment(response.expiringOn,'DD-MM-YYYY HH:mm:ss')-new Date())
              /31536000000*365).toFixed(0)>0
              && (parseInt(response.balance)>0 )
              ){
              expiringFirstResponse.push(response);
            }
          
          })
        }
      }

      

      const sortedActivities = expiringFirstResponse.sort((a, b) => moment(a.expiringOn,'DD-MM-YYYY HH:mm:ss') - moment(b.expiringOn,'DD-MM-YYYY HH:mm:ss'));


      console.log('expiringFirstResponse:::',sortedActivities);


      store.dispatch(showExpiringFirstResponse(sortedActivities));
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData = error.data;
      return errorData;
    })
}
async checkCallForWallet(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside wallet response', response.data);
    
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
        errorData=error.data;
      return errorData;
    })
}


async postCallForTransaction(url, request){
  console.log('url', url);
  const state = store.getState();

   console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside transaction service response', response.data);
      
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}

async getAllTransactionPager(url,request){
  
    // console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request).then((response) => {
      // console.log('enter httpclient call section');
      // console.log('inside transaction service response', response.data);

      var pagerResponse=[];
      
      if(response.data!=null){
        if(response.data.txnHistory.length>0){
          response.data.txnHistory.map(rsp=>{
          if(parseInt(rsp.appliedAmount)>0){
            if(!pagerResponse.includes(response)){
              pagerResponse.push(rsp);
            
            }
          }
         
            
          })
        }
  
      }
      console.log('pagerResponse..'+JSON.stringify(pagerResponse));


      
      store.dispatch(showTxnResponsePager(pagerResponse));
      
      store.dispatch(showTxnResponseLength(response.data.totalNoElements));
      store.dispatch(setTxnLoader("active"));  
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;
      return errorData;
    })



}


async getAllTransaction(url,request){

    var txnResponse=[];
  
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside transaction service response', response.data);



     
      var txnResponse=[];
      
      if(response.data!=null){
        if(response.data.txnHistory.length>0){
          response.data.txnHistory.map(rsp=>{
          if(parseInt(rsp.appliedAmount)>0){
            if(!txnResponse.includes(response)){
              txnResponse.push(rsp);
            
            }
          }
         
            
          })
        }
  
      }
      console.log('txnResponse..'+JSON.stringify(txnResponse));
      store.dispatch(showTransaction(txnResponse));
      store.dispatch(showTxnResponsePager(txnResponse));
      store.dispatch(showTxnResponseLength(response.data.totalNoElements));
      store.dispatch(setTxnLoader("active"));  


      // response.data.txnHistory.map(response=>{
      // if(parseInt(response.appliedAmount)>0){
      //   txnResponse.push(response);

      // }  

      // }
      // )

      // console.log('inside TXN service response', JSON.stringify(txnResponse));
      store.dispatch(showTxnResponse(txnResponse));


      
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;
      return errorData;
    })



}

async getAllTransactionBackUp(url,request){
  console.log('url', url);
  const state = store.getState();
    var txnResponse=[];
  
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside transaction service response', response.data);



      // if(response.data!=null){
      //   if(this.props.transactionResponse.txnHistory.length>0){
      //     this.props.transactionResponse.txnHistory.map(response=>{
      //     if(parseInt(response.appliedAmount)>0 && count<=5){
      //       if(!this.state.initialResponse.includes(response)){
      //                this.state.initialResponse.push(response);
      //        count++;
      //       }
      //     }
      //     if(parseInt(response.appliedAmount)>0){
      //       if(!this.state.pagerTransactionResponse.includes(response)){
      //       this.state.pagerTransactionResponse.push(response);
      //       }
      //       if(!this.state.uResponse.includes(response)){
      //         this.state.uResponse.push(response);
      //       }
      //      }
            
      //     })
      //   }
  
      // }


      
      store.dispatch(showTransaction(response.data));
      response.data.txnHistory.map(response=>{
      if(parseInt(response.appliedAmount)>0){
        txnResponse.push(response);

      }  

      }
      )

      console.log('inside TXN service response', JSON.stringify(txnResponse));
      store.dispatch(showTxnResponse(txnResponse));


      
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;
      return errorData;
    })



}


async getTransaction(url,request){
  console.log('url', url);
  const state = store.getState();

  
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside getTransaction service response', response.data);
      store.dispatch(showTransaction(response.data));
      
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}

async getOrderIdStatus(url){
  console.log('url', url);
  const state = store.getState();

  
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.get(url).then((response) => {
      console.log('enter httpclient call section');
      console.log('inside Order service response', response.data);
      
      return response.data;
    }).catch(error => {
        
      console.log('error',error);
      errorData=error.data;

      return errorData;
    })
}
  async postMethodCallforPaymentForBonusClub(url, request,routePage,phoneNo) {

    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('url', url);
    const state = store.getState();

     console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request) .then((response) => {
        console.log('enter httpclient call section');
       // response =  json;
        console.log('inside response', response.data);
       // console.log('content-type',routePage.dashBoardContentType)
        return response.data;
      }
      ).catch(error => {
        console.log('error',error);
        errorData=error.data;

        return errorData;
      })
  }


  async postMethodCallforPayment(url, request,routePage,phoneNo) {

    //logger.infolog('200', url, LogTypes.REQUEST, JSON.stringify(request), '', 'Inside Api Calling');
    console.log('url', url);
    const state = store.getState();

     console.log(JSON.stringify(request));
    console.log('getMethodCallWithParam',  process.env.REACT_APP_SET_API_CALL_TIMEOUT_LIMIT);
    return await httpClient.post(url,request
    ) .then((response) => {
        console.log('enter httpclient call section');
       // response =  json;
        console.log('inside response', response.data);
       // console.log('content-type',routePage.dashBoardContentType)
        return response.data;
      }
      ).catch(error => {
        console.log('error',error);
        errorData=error.data;
        return errorData;
      })
  }

}

export default new ApiCall();