import { MiscType } from './misc-data.type';

const INITIAL_STATE = {
    //default: errorMessage:''
    orderId: "",
    phone: "",
    amount: "",
    transactionId: "",
    transactionTimeStamp: "",
    cardNumber: "",
    cardPin: "",
    qcauthToken: "",
    giftCardBalnceEnquiry: "",
    giftCardLinkResponse: "",
    giftCardRedeemResponse: "",
    otpVal: "",
    orderServiceResponse: "",
    myCouponDetails: "",
    myPoints: "",
    firstname: "",
    otpRefNo: "",
    campaigns: "",
    deviceInfo: "",
    userRegisterData: "",
    completeOrderId: "",
    txnLoader: "",
    complaintId: "",
    gameIdVal: "",
    uploadFile: [],
    gameOfWheelRedirect: '',
    lockVal: '',
    playCount: 0,
    tncGameOfwhhel: []

};

const miscReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MiscType.setOrderId:
            return {
                ...state,
                orderId: action.payload,
            };
        case MiscType.setOtp:
            return {
                ...state,
                otpVal: action.payload,
            };
        case MiscType.setPhone:
            return {
                ...state,
                phone: action.payload,
            };
        case MiscType.setAmount:
            return {
                ...state,
                amount: action.payload,
            };
        case MiscType.setTransactionId:
            return {
                ...state,
                transactionId: action.payload,
            };
        case MiscType.setTransactionTimeStamp:
            return {
                ...state,
                transactionTimeStamp: action.payload,
            };
        case MiscType.setCardNumber:
            return {
                ...state,
                cardNumber: action.payload,
            };
        case MiscType.setCardPin:
            return {
                ...state,
                cardPin: action.payload,
            };
        case MiscType.setQCAuthToken:
            return {
                ...state,
                qcauthToken: action.payload,
            };
        case MiscType.setGiftCardBalanceEnquiry:
            return {
                ...state,
                giftCardBalnceEnquiry: action.payload,
            };
        case MiscType.setGiftCardLinkResponse:
            return {
                ...state,
                giftCardLinkResponse: action.payload,
            };
        case MiscType.setGiftCardRedeemResponse:
            return {
                ...state,
                giftCardRedeemResponse: action.payload,
            };
        case MiscType.setOrderServiceResponse:
            return {
                ...state,
                orderServiceResponse: action.payload,
            };
        case MiscType.setMyCouponDetails:
            return {
                ...state,
                myCouponDetails: action.payload,
            };
        case MiscType.setMyPoints:
            return {
                ...state,
                myPoints: action.payload,
            };
        case MiscType.setUserFirstName:
            return {
                ...state,
                firstname: action.payload,
            };
        case MiscType.setOtpRefNo:
            return {
                ...state,
                otpRefNo: action.payload,
            };
        case MiscType.setCampaignRecords:
            return {
                ...state,
                campaigns: action.payload,
            };
        case MiscType.getCashBack:
            return {
                ...state,
                cashBackRow: action.payload,
            };

        case MiscType.cashBackCampaign:
            return {
                ...state,
                cashBackCampaigns: action.payload,
            };
        case MiscType.setDeviceInfo:
            return {
                ...state,
                deviceInfo: action.payload,
            };
        case MiscType.setUserRegisterData:
            return {
                ...state,
                userRegisterData: action.payload,
            };

        case MiscType.setCreditNoteData:
            return {
                ...state,
                creditNoteData: action.payload,
            };

        case MiscType.setCouponData:
            return {
                ...state,
                couponData: action.payload,
            };

        case MiscType.setTxnLoader:
            return {
                ...state,
                txnLoader: action.payload,
            };

        case MiscType.setUploadedFiles:
            return {
                ...state,
                uploadFile: action.payload,
            };

        case MiscType.setCompleteOrderId:
            return {
                ...state,
                completeOrderId: action.payload,
            };

        case MiscType.complaintIdValue:
            return {
                ...state,
                complaintId: action.payload,
            };
        case MiscType.setGameId:
            return {
                ...state,
                gameIdVal: action.payload,
            };

        case MiscType.setGameofWheelReditect:
            return {
                ...state,
                gameOfWheelRedirect: action.payload,
            };

        case MiscType.setLock:
            return {
                ...state,
                lockVal: action.payload,
            };
        case MiscType.setPlayCount:
            return {
                ...state,
                playCount: action.payload,
            };
        case MiscType.setTncGameOfwhhel:
            return {
                ...state,
                tncGameOfwhhel: action.payload,
            };

        case MiscType.RESET:
            return INITIAL_STATE;

        default:
            return state;
    }

};

export default miscReducer; 