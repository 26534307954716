import AdminType from "./admin.type";

const INITIAL_STATE = {
   isAdmin:false,
   userRoleId:''
};

const adminReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminType.SET_IS_ADMIN:
            return {
                ...state,
                isAdmin:action.payload
            }
            case AdminType.SET_USER_ROLE:
                    return {
                        ...state,
                        userRoleId:action.payload
                    }
                    case AdminType.RESET:
                             
                                return INITIAL_STATE;
                               
        default:
            return state;
    }
};

export default adminReducer;