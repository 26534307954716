import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import tokenReducer from './token/token.reducer';
import roleReducer from './role/role.reducer';
import adminReducer from './admin/admin.reducer';
import showAddHideReducer from './div-show-and-hide/div-show-and-hide.reducer';
import errorMessageReducer from './error-message/error-message.reducer';
import responseReducer from './response/response.reducer';
import leadingOverLayReducer from './leading-overlay/leading-overlay.reducer';
import ordeDetailsReducer from './order-details/ordr-details.reducer';
import miscReducer from './misc-data/misc-data.reducer';
import authReducer from './auth/auth.reducer';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user',
        'role',
        'admin',
        'showAddHide',
        'errorMessageReduce',
        'responseReduce',
        'leadingOverLay',
        'ordeDetailsReduce',
        'miscReduce',
        'authReduce'
        ]
}

const rootReducer = combineReducers({
    user: userReducer,
    token: tokenReducer,
    role: roleReducer,
    admin:adminReducer,
	showAddHide:showAddHideReducer,
    errorMessageReduce:errorMessageReducer,
    responseReduce:responseReducer,
    leadingOverLay:leadingOverLayReducer,
    ordeDetailsReduce:ordeDetailsReducer,
    miscReduce:miscReducer,
    authReduce:authReducer
    
})


export default persistReducer(persistConfig, rootReducer);