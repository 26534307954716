
import React from 'react';

import { store } from '../../redux/store';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetUser } from '../../redux/user/user.action'
import { resetDivShowAndHide, showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SparPng from "../../admin/common/images/spar.png";
import ReactTooltip from 'react-tooltip';
import {showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';


import { setRoMdn } from '../../redux/user/user.action';
import { setToken } from '../../redux/token/token.action';


import UserOtpLogin from '../component/userOtplogin';

import UserRegistrationForm from '../component/userRegistration';
import UserDashBoard from '../component/userDashBoard';

import UserReferral from '../component/userReferral';

import UserLogin from '../component/userlogin';
import {} from '../../redux/div-show-and-hide/div-show-and-hide.action'
import LoadingOverlay from 'react-loading-overlay-ts';

import WalletService from '../../service/spar-service-impl/walletService';
import Welcome from './welcome';
import createHistory from 'history/createBrowserHistory'
import OtpLoginAfterRegister from './otpLoginAfterRegister';

//import { Redirect } from 'react-router-dom';
//import history from '../../auth/history';


class UserComponets extends React.Component {

    constructor() {
        super();

        this.state = {
            transactionsDetails: [],
            senderProfiles:  [],
            senderValue:'',
            senderBeneficiaries: [],
            viewSecton:'',
            phoneNo: '',
            errorMessage:''
        };
    }

    componentDidMount = () => {
       
        console.log(store);
        const history = createHistory();
        console.log('history:::',JSON.stringify(history));
       
        if (history.location.pathname="/") {
            console.log('history.location.pathname::',history.location.pathname)
            store.dispatch(showUserComponentSection('login'));

        }
  }

    handleClick = (e) => {
        store.dispatch(showDashBoardComponentSection('login'));
       

      };

      loGinReturn = (e) => {
        store.dispatch(showUserComponentSection('login'));
       

      };

      handleCallBack= (response) => {
        

        this.setState({
            phoneNo:response
        }            
        )
       
      };

    render() {
             
        
    
        const res=this.props.userResponse;

        return(
            <>

        <div>
        
        {(this.props.viewSection == 'login' ) &&

        <div class="container">
            <div class="row justify-content-sm-center">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <div class="login">		
						
				<img src={SparPng} alt="SPAR" class="img-fluid"/>
				<h1>Hello there! Welcome to SPAR Wallet</h1>
              
				<UserLogin parentCallback ={this.handleCallBack}/>
                <h6>By continuing, you agree to our <br/><a href="https://www.sparindia.com/pages/terms/pgid-1395927.aspx" target="_blank" > Terms and Conditions </a> and 
				<a href="https://www.sparindia.com/pages/privacypolicy/pgid-1399867.aspx" target="_blank"> Privacy Policy </a>
				</h6>
                </div>	
            </div>
            </div>
            <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
                </div>
         
	
	     }
        
            {
            (this.props.viewSection == 'otp' ) &&
              
            <div class="container">
                <div class="row justify-content-sm-center">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div class="login">
                        <img src={SparPng} alt="SPAR" class="img-fluid"/>
                            <h1>Awesome, about there!</h1>				
                            <p>Enter OTP sent to your mobile number </p>				
                            <h5>+91-{this.props.phone} <button type="submit" class="edit" data-toggle="tooltip" title="Change" onClick={this.loGinReturn}>Edit</button></h5>
                            <UserOtpLogin phoneNo={this.state.phoneNo} parentCallback ={this.handleCallBack} response={res} />
                           
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
                </div>
                
            </div>
	         }


             {
            (this.props.viewSection == 'otpAfterRegsiter' ) &&
              
            <div class="container">
                <div class="row justify-content-sm-center">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div class="login">
                        <img src={SparPng} alt="SPAR" class="img-fluid"/>
                            <h1>Awesome, about there!</h1>				
                            <p>Enter OTP sent to your mobile number </p>				
                            <h5>+91-{this.props.phone} <button type="submit" class="edit" data-toggle="tooltip" title="Change" onClick={this.loGinReturn}>Edit</button></h5>
                          
                            <OtpLoginAfterRegister phoneNo={this.state.phoneNo} parentCallback ={this.handleCallBack} response={res} />
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
                </div>
                
            </div>
	         }

            {
            (this.props.viewSection == 'welcome' ) &&
              
            <div class="container">
                <div class="row justify-content-sm-center">
                    <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div class="login">
                        <img src={SparPng} alt="SPAR" class="img-fluid"/>
                        <h1>Welcome to SPAR Wallet</h1>
				        <p>We’re glad you are here, <span>
                        {this.props.firstname.split(" ")[0].charAt(0).toUpperCase()+this.props.firstname.split(" ")[0].slice(1).toLowerCase()}</span>!</p>
				
                    <ul>
                        <li><i class="save"></i> <br/>More Savings</li>
                        <li><i class="checkout"></i> <br/>Faster Checkouts</li>
                        <li><i class="offer"></i> <br/>Offer Vault</li>
                    </ul>
				    <Welcome/>
				    </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
                </div>
                
            </div>
	         }
            {(this.props.viewSection == 'register') &&

            <div class="container">
                   
            <div class="row justify-content-sm-center">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                    <div class="login">
                    <img src={SparPng} alt="SPAR" class="img-fluid"/>
                    {/* {this.props.errorMessage} */}
                    <h1>Help us know you a bit?</h1>				
				
                
                        <UserRegistrationForm parentCallback ={this.handleCallBack}  phoneNo={this.state.phoneNo}/>
                        
                                    
                    </div>
                </div>
                
                <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
            </div>
            </div>

            }

            {(this.props.viewSection == 'referral') &&

            <div class="container">
               
            <div class="row justify-content-sm-center">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                    <div class="login">
                    <img src={SparPng} alt="SPAR" class="img-fluid"/>
                        <h1>Welcome to SPAR India Wallet</h1>
                        {/* {this.props.errorMessage} */}
                        <p>You have successfully registered for LMR and SPAR India Wallet.</p>
                        <p>Thank you for your patronage.</p>
                            <UserReferral parentCallback ={this.handleCallBack}/>
                            <h6><a href="/user-dashboard">Skip</a></h6>
                    </div>	
                    </div>
                    
                    <div class="col-12">
                        <div class="logcopy">SPAR India &copy; All rights Reserved. <br/> Powered by <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></div>
                    </div>
                </div>
            </div>
            }

            
            {(this.props.viewSection == 'dashBoard') &&
            // <LoadingOverlay
            // active={true}
            // spinner={true}
            // text='Loading your content...'
            // >
            <UserDashBoard/>
            // </LoadingOverlay>
            }
            
        </div>
    
        </>
        );
    }
    }
    const mapStateToProps = (
        { showAddHide:{viewSection},errorMessageReduce:{errorMessage,otpErrorMessage},
        responseReduce:{responseDetailsVal,dashBoardResponseDetails,
            userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
        leadingOverLay:{blockScreenConfirm},miscReduce:{otpVal,firstname},
        miscReduce:{phone}
        }) => ({
        viewSection,errorMessage,
        otpErrorMessage,
        responseDetailsVal,dashBoardResponseDetails,userResponse,
        walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse
        ,blockScreenConfirm,otpVal,firstname,phone
    });

    export default connect(mapStateToProps, null)(UserComponets);