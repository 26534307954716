import { store, persistor} from '../src/redux/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { MemoryRouter } from 'react-router'
//import { ToastProvider } from 'react-toast-notifications'

// import 'bootstrap/dist/css/bootstrap-theme.css';\
import App from './App';
import ReactDOM from 'react-dom';

// if (process.env.NODE_ENV !== "development"){
//     console.log = () => {};
//   }

const rootElement = document.getElementById('root');
ReactDOM.render(
 
  <Provider store={store}>
    <BrowserRouter>
    <PersistGate persistor={persistor}>
    <MemoryRouter>
      <App />
      </MemoryRouter>
      </PersistGate>
    </BrowserRouter>

  </Provider>
  
  , rootElement);
//serviceWorker.unregister();
