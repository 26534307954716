import React from "react";
import history from '../../auth/history';
import { store } from '../../redux/store';

import { connect } from "react-redux";

import axios from 'axios';
import deviceDetails from "../../service/spar-service-impl/deviceDetails";
import { setDeviceInfo, setGameofWheelReditect, setLock, setPlayCount } from "../../redux/misc-data/misc-data.action";
import { setAuth, setErrMsg } from "../../redux/auth/auth.action";
import { setPhone } from "../../redux/misc-data/misc-data.action";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import fetchUserDetails from "../../service/spar-service-impl/fetchUserDetails";
import { setGameOfWheelLoadResponse, setGameOfWheelTextValues, showUserProfileResponse } from './../../redux/response/response.action';
import { setGameId } from "../../redux/misc-data/misc-data.action";


const httpClient = axios.create();
class SSOReddirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNo: '',

    }
  }
  componentDidMount() {

    store.dispatch(setGameofWheelReditect(''));
    store.dispatch(setPhone(''));
    store.dispatch(setGameId(''));
    store.dispatch(setGameOfWheelLoadResponse([]));
    store.dispatch(setAuth(false));
    store.dispatch(setErrMsg(''));

    // Check browser support
    if (typeof (Storage) !== "undefined") {
      // Store
      sessionStorage.setItem("lastRoute", "/");

    }

    var query = window.location.search.substring(1);
    console.log('sso query' + query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars);
    var clientId = "";
    var clientSecret = "";
    var encryptedMobileNumber = "";
    var encryptedToken = "";

    var routePage = "";
    var gameId = "";


    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair);
      if (pair[0] == "clientId") {
        clientId = pair[1];
      } else if (pair[0] == "clientSecret") {
        clientSecret = pair[1];
      } else if (pair[0] == "encryptedMobileNumber") {
        encryptedMobileNumber = pair[1];
      } else if (pair[0] == "encryptedToken") {
        encryptedToken = pair[1];
      } else if (pair[0] == "routePage") {
        routePage = pair[1];
      } else if (pair[0] == "gameId") {
        gameId = pair[1];

        store.dispatch(setGameId(pair[1]));
      }
    }
    const deviceInfo = deviceDetails.fetchDeviceInfo('0.0', '0.0');

    deviceInfo.then((deviceData) => {

      store.dispatch(setDeviceInfo(deviceData));
    }).catch(error => {
      console.log(error);
    });



    const requestHeader = {
      requestType: "LOGIN_USER",
      requestId: uuidv4(),
      requesterTimestamp: moment(),
      mobileNumber: "",
      originalDialogueTraceId: null,
      walletOwner: "SPAR",
      channel: "WEB"
    }

    const singleSignOnDetails = {
      clientId: clientId,
      clientSecret: clientSecret,
      encryptedMobileNumber: encryptedMobileNumber,
      encryptedToken: encryptedToken
    }



    const request = {
      requestHeader: requestHeader,
      singleSignOnDetails: singleSignOnDetails
    }

    console.log('request::' + request);

    var url = '/api/v1/landmark/user/single-signon';

    httpClient.post(url, request
    ).then((response) => {



      // ****critical**** if(response.data.responseHeader.responseCode=='SUCCESS'){

      if (response.data.responseHeader.responseCode == 'SUCCESS') {

        store.dispatch(setPhone(response.data.requestHeader.mobileNumber));
        store.dispatch(setAuth(true));

        const deviceInfo = deviceDetails.fetchDeviceInfo('0.0', '0.0');

        deviceInfo.then((deviceData) => {

          store.dispatch(setDeviceInfo(deviceData));


          if (routePage == "gameOfWheel") {
            store.dispatch(setPhone(response.data.requestHeader.mobileNumber));
            history.push('/gameOfWheel');
            history.go();

          } else {

            store.dispatch(setPhone(response.data.requestHeader.mobileNumber));
            const requestHeader = {
              requestType: "GET_USER_PROFILE",
              requestId: uuidv4(),
              requesterTimestamp: moment(),
              mobileNumber: response.data.requestHeader.mobileNumber,
              originalDialogueTraceId: null,
              walletOwner: "SPAR",
              channel: "WEB"
            }

            const request = {
              requestHeader: requestHeader,
              deviceInfo: deviceData
            }

            var userFetchUrl = '/api/v1/user/profile';
            console.log('userprofile request' + JSON.stringify(request));
            fetchUserDetails.fetchUserCall(userFetchUrl, request);
          }

        }).catch(error => {
          console.log(error);
          store.dispatch(setErrMsg(error.message));
          setTimeout(() => {
            history.push('/403');
            history.go();
          }, 500);

        });

      } else {
        //alert('here')


        store.dispatch(setErrMsg(response.data.responseHeader.responseMsg));
        setTimeout(() => {
          history.push('/403');
          history.go();
        }, 500);
      }
    }).catch(error => {

      console.log('error', error);
      store.dispatch(setErrMsg(error.message));
      setTimeout(() => {
        history.push('/403');
        history.go();
      }, 500);
    })



  }

  render() {

    return (
      <>

      </>


    );
  }
}
const mapStateToProps = (
  { showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage }, responseReduce: { responseDetailsVal, dashBoardResponseDetails },
    leadingOverLay: { blockScreenConfirm },
    ordeDetailsReduce: { orderId } }
) => ({
  viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, blockScreenConfirm, orderId
});

const mapDispatchToProps = (dispatch) => {
  return {
    transactionDetails: (showDashBoardComponentSection) => {
      dispatch(showDashBoardComponentSection('transactionDetails'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SSOReddirect);