
import ApiCallingService from '../api-call-service';

import { store } from '../../redux/store';

import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';

import systemErrorCodes from '../../omts/component/systemErrorCodes';
import { setTxnLoader } from '../../redux/misc-data/misc-data.action';


class TransactionService {


  fetchAllTransactionPagerData(url,request) {
    store.dispatch(setTxnLoader("inactive"));
    console.log('inside Transactionservice ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
  

      response=  await ApiCallingService.getAllTransactionPager(url,request);
      if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
      
      
       console.log("__transactionservice response__",JSON.stringify(response));
      }else if(response !=undefined && response.resposeHeader!=undefined
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(parseInt(response.responseHeader.responseCode)=="SUCCESS"){
         console.log("fetchAllTransactionData for responsecode SUCCESS",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
       
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

    fetchAllTransactionData(url,request) {
      store.dispatch(setTxnLoader("inactive"));
        console.log('inside Transactionservice ', url);
        //store.dispatch(setLoggedInUserID(username));
    
        var response = [];
        
         async function run() {
      
    
          response=  await ApiCallingService.getAllTransaction(url,request);
          if (response === 'error'||response==undefined) {
            console.log(response);
            
            var errorMessage='Somthing went wrong we are looking at our End';
          
          
           console.log("__transactionservice response__",JSON.stringify(response));
          }else if(response !=undefined && response.resposeHeader!=undefined
            //&& response.status==200
            ){ 
    
          console.log('res_code',response.responseHeader.responseCode);
           if(parseInt(response.responseHeader.responseCode)=="SUCCESS"){
             console.log("fetchAllTransactionData for responsecode SUCCESS",response);
            var errorMessage='';
            store.dispatch(showErrorMessageSection(errorMessage));
           
          }
          else{
            console.log("error response!",response);
            var errorMessage='Somthing went wrong we are looking at our End';
            store.dispatch(showErrorMessageSection(errorMessage));
            
          } 
          
        }
       
      }
      run();
      
       }


       fatchTransactionData(url,request) {
        console.log('inside fatchTransactionData ', url);
        //store.dispatch(setLoggedInUserID(username));
    
        var response = [];
        
         async function run() {
      
    
          response=  await ApiCallingService.getTransaction(url,request);
          
          
           console.log("__transactionservice response__",JSON.stringify(response));
           if (response === 'error') {
            console.log(response);
            
            var errorMessage='Somthing went wrong we are looking at our End--generic error';
            store.dispatch(showErrorMessageSection(errorMessage));
            
          }
          else if (response.status === 404) {
            console.log(response);
           
           var errorMessage='Somthing went wrong we are looking at our End--400 error';
           store.dispatch(showErrorMessageSection(errorMessage));
          }
          else if (response.status === 500) {
            console.log(response);
          
           var errorMessage='Somthing went wrong we are looking at our End--500 error';
           store.dispatch(showErrorMessageSection(errorMessage));
          }
          else if (response.status === 404) {
            console.log(response);
          
           var errorMessage='Somthing went wrong we are looking at our End--404 error';
           store.dispatch(showErrorMessageSection(errorMessage));
          }
          
          else if(response !=undefined 
            //&& response.status==200
            ){ 
    
          console.log('res_code',response.responseCode);
           if(parseInt(response.responseCode)==0){
             console.log("fetchAllTransactionData for responsecode 0",response);
            var errorMessage='';
            store.dispatch(showErrorMessageSection(errorMessage));
            //store.dispatch(showResponse(response.response.amount));
     
           {systemErrorCodes.syetemErrorCodesDetails.map(systemErrCodes=>{
           if(systemErrCodes.id==parseInt(response.responseCode))
           {
           console.log("fetchAllTransactionData for "+ systemErrCodes.id,response);
           var errorMessage=systemErrCodes.details;
    
           store.dispatch(showErrorMessageSection(errorMessage));
          }
          })}
          }
          else{
            console.log("error response!",response);
            var errorMessage='Somthing went wrong we are looking at our End';
            store.dispatch(showErrorMessageSection(errorMessage));
            
          } 
          
        }
       
      }
      run();
      
       }
    
    
    
    
       
      
 
    
    
    
    
       
      
 

   addTransaction(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('wallet service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForTransaction(url, requestTosend);
      
      
       console.log("__transactionservice response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      else if (response.status === 404) {
        console.log(response);
       
       var errorMessage='Somthing went wrong we are looking at our End--400 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 500) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--500 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 404) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--404 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      
      else if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("postCallForTransaction for responsecode 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
        //store.dispatch(showResponse(response.response.amount));
 
       {systemErrorCodes.syetemErrorCodesDetails.map(systemErrCodes=>{
       if(systemErrCodes.id==parseInt(response.responseCode))
       {
       console.log("postCallForTransaction for "+ systemErrCodes.id,response);
       var errorMessage=systemErrCodes.details;

       store.dispatch(showErrorMessageSection(errorMessage));
      }
      })}
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }




   
  }

  

export default new TransactionService();
