import React, { useState, useEffect } from 'react';
import SparPng from "../../assests/images/spar.png";
import UserJpg from "../../assests/images/user.jpg"
//import user8 from './images/user-8.jpg';
import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import { store } from '../../redux/store';
//import { setRoMdn, setUserFullName } from '../../redux/user/user.actions';
import { setRoMdn, setUserFullName } from "../../redux/user/user.action";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import SparServiceImpl from '../../service/spar-service-impl/SparService.js';

import ProfileComponent from "./profileComponents";
import moment from 'moment';
import SparCashBackTandC18thto31st from './SparCashBackTandC18thto31st';
import SparCashBackTandC1stTo15thNov from './SparCashBackTancC1stto15thNov';


class SparCashBackDetailsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',
		}
	}


	closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	}
	cashBackDetailsSection = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashBackDetailsSection'));
	}
	backModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashBackPlusSection'));
	}

	copyText = (event, resp) => {
		navigator.clipboard.writeText(resp);

	}

	render() {
		console.log('cashback record', this.props.cashBackRow);
		return (
			<>
				{this.props.cashBackRow != '' &&


					<div class="innercard">
						<div class="head"><h3>My Cashback Details </h3><button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
							<button type="button" class="back" onClick={this.backModal} data-toggle="tooltip" title="Back">back</button>	</div>


						<div class="cashshow">
							<div class="cashbox">
								<div class="row">
									<div class="col-12 col-md-3 order-md-2">
										<h4

										>₹ {parseFloat(this.props.cashBackRow != null ? this.props.cashBackRow.balance : 0).toFixed(2)}</h4>
									</div>

									<div class="col-12 col-sm-9 col-md-7 order-md-1">

										{this.props.cashBackCampaigns != null &&
											this.props.cashBackCampaigns.length > 0 &&
											this.props.cashBackCampaigns.map(cashBackCampaigns => (
												(cashBackCampaigns.campaignId == (this.props.cashBackRow.campaignId)) &&
												<h5>{cashBackCampaigns.displayName}</h5>
											))}

										{/* <h5>{this.props.cashBackRow.campaignId}</h5> */}
										<h6>#{this.props.cashBackRow != null ? this.props.cashBackRow.id : 'NA'}</h6>
										<p class="date">{this.props.cashBackRow != null ? this.props.cashBackRow.startingFrom.split(" ")[0] : 'NA'}
										</p>
									</div>

									<div class="col-12 col-sm-3 col-md-2 order-md-3">
										<p>Validity <span>{this.props.cashBackRow != null ? this.props.cashBackRow.expiringOn.split(" ")[0] : 'NA'}</span></p>
									</div>
								</div>
							</div>


							{this.props.cashBackCampaigns != null &&
								this.props.cashBackCampaigns.length > 0 &&
								<div class="csbktxtdtl">
									<h4>Terms & Conditions</h4>
									{this.props.cashBackCampaigns.length > 0 &&
										this.props.cashBackCampaigns.map(cashBackCampaigns => (
											(cashBackCampaigns.campaignId == (this.props.cashBackRow.campaignId) &&
												cashBackCampaigns.termsAndConditions != null) ?
												cashBackCampaigns.termsAndConditions.split("<br>").map(tnc => (
													<ol>{tnc}</ol>
												))

												:
												// (cashBackCampaigns.campaignId==(this.props.cashBackRow.campaignId)) &&
												(cashBackCampaigns.campaignId == (this.props.cashBackRow.campaignId) &&
													cashBackCampaigns.termsAndConditions == null) &&
												cashBackCampaigns.campaignId


										))}
								</div>
							}

							{this.props.cashBackCampaigns.length == 0 &&
								<div class="csbktxtdtl">
									<h4>Terms & Conditions</h4>
									<div>{this.props.cashBackRow.campaignId}</div>
								</div>

							}


							{/* {(moment(this.props.cashBackRow.expiringOn.split(" ")[0],'DD-MM-YYYY')>=new Date('2021-10-18')
	&& (moment(this.props.cashBackRow.expiringOn.split(" ")[0],'DD-MM-YYYY')<=new Date('2021-10-31')


								))
							&&
							<div class="csbktxtdtl">
							<h4>Terms & Conditions</h4>
							<SparCashBackTandC18thto31st/>
							</div>
								} */}

							{/* {(moment(this.props.cashBackRow.expiringOn.split(" ")[0],'DD-MM-YYYY')>=new Date('2021-11-01')
&& (moment(this.props.cashBackRow.expiringOn.split(" ")[0],'DD-MM-YYYY')<=new Date('2021-11-15')
							))
						&&
						<div class="csbktxtdtl">
							<h4>Terms & Conditions</h4>
						<SparCashBackTandC1stTo15thNov/>
						</div>
							} */}


						</div>
					</div>
					// </div>	


				}
			</>)

	}
}


const mapStateToProps = ({ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
	responseReduce: { responseDetailsVal, dashBoardResponseDetails,
		userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse, fetchTransactionResponse },
	leadingOverLay: { blockScreenConfirm },
	ordeDetailsReduce: { orderId },

	miscReduce: { cashBackCampaigns, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
		, cashBackRow } }
) => ({
	viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse,
	userProfileResponse, orderServiceResponse, fetchTransactionResponse,
	blockScreenConfirm, orderId, cashBackCampaigns, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints,
	cashBackRow
});

export default connect(mapStateToProps, null)(SparCashBackDetailsComponent);

