import React from "react";
import history from "../../auth/history";

import {store} from "../../redux/store";

import UserService from "../../service/spar-service-impl/userService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection, showLoginErrorMessage } from "../../redux/error-message/eror-message.action";
import { connect } from "react-redux";
import { setLock } from "../../redux/misc-data/misc-data.action";



class Test1 extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
		}
    }
    componentDidMount() {
        store.dispatch(setLock('true'));
        setTimeout(()=>{
            history.push('/test2');
            history.go();
        },500);
      
    }
	   componentWillUnmount(){
		 
	   }
      render(){
        console.log('Inside render');
	
          return(
          <div></div>
		);
      } 
}

const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage,loginErrorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,
		giftCardBalnceEnquiry}}
	) => ({
		viewDashBoardSection,errorMessage,loginErrorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
});

export default connect(mapStateToProps, null)(Test1);
