import React, { useState, useEffect } from 'react';

//import user8 from './images/user-8.jpg';

import {store} from '../../redux/store';
//import { setRoMdn, setUserFullName } from '../../redux/user/user.actions';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";




class notificationSection extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0
        }
    }
	

    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }


      addGiftCard = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('giftCardPurChase'));
	  }
	  

      handleAmountChange= (event) => {

        this.setState({
            amount: event.target.value
		})		
	
		
	  }

   render(){

return(
	<>
		

						<div class="innercard">
			   <div class="head"><h3>Notifications </h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button></div>
						
						{/* <div class="dropdown fltr">
							<button type="button" class="filter dropdown-toggle" id="fillist" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filter</button>
							
							<div class="dropdown-menu dropdown-menu-end" aria-labelledby="fillist">
								<i class="closefil">x</i>
								<form>
									<div class="filters">
										<label for="type">Type:</label>
										<select data-placeholder="..." multiple class="chosen-select" tabindex="8" id="type">
											<option value="1">Add Money</option>
											<option value="2">Bonus Club</option>
											<option value="3">Cashback</option>
											<option value="4">Coupon</option>
											<option value="5">Credit Note</option>
											<option value="6">Gift Card</option>
										</select>
									</div>
									
									<div class="filters">
										<label for="select-state">From Date:</label>
										<input type="date" value="" name="" id=""/>
									</div>
									
									<div class="filters">
										<label for="select-state">To Date:</label>
										<input type="date" value="" name="" id=""/>
									</div>

									<div class="fbtn">
										<button class="mediumbtn" type="reset" id="btnClear">Clear all</button>
									</div>
								</form>
							</div>
						</div> */}
					
					
					<div class="notifylist">

					<div class="valid-success">
						{/* <span class="closebtnPopUp">&times;</span>   */}
						<strong>No Notifications Available!</strong> 
						</div>
						{/* <div class="notifybox">
							<h5>Dear user your dashboard login time 13-08-2021 12:32PM</h5>
							<p>13-08-2021 12:32PM</p>
						</div>
						
						<div class="notifybox">
							<h5>Add Bonus Club Cashback amount ₹99.00  <a href="cashback-plus.html">View details</a></h5>
							<p>13-08-2021 12:32PM</p>
						</div>
						
						<div class="notifybox">
							<h5>SPAR Pay Balance is low ₹150.00  <a href="add-money.html">Load Money</a></h5>
							<p>10-08-2021 12:32PM</p>
						</div>
						
						<div class="notifybox">
							<h5>Beauty & Personal Care Shop - Up To 50% OFF  <a href="cashback-plus.html">View details</a></h5>
							<p>13-08-2021 12:32PM</p>
						</div>
						
						<div class="notifybox">
							<h5>Dear user your dashboard logout time 13-08-2021 12:32PM</h5>
							<p>13-08-2021 12:32PM</p>
						</div>
						
						<div class="notifybox">
							<h5>Flat Rs 500 OFF On Orders Above Rs 1499 <a href="cashback-plus.html">View details</a></h5>
							<p>13-08-2021 12:32PM</p>
						</div> */}
						
						

						
					</div>
					
				</div>			
	
</>)

}
}

export default notificationSection;
