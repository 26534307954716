import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {store} from '../../redux/store';
import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";

class CreditNoteDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0
        }
    }
	
    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }
      creditNoteSection   = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('creditNoteSection'));
	  }
    

     render(){
	console.log('creditNoteData:::',this.props.creditNoteData);
	return(
	<>
    			<div class="innercard">
					<div class="head">
						<h3>Credit Note Details</h3>
						<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button> 
                        <button type="button" class="back" onClick={this.creditNoteSection} data-toggle="tooltip" title="Back">back</button>
					</div>					
					
					<div class="creditshow">
						<div class="crdtbox">
							<div class="row">
								<div class="col-12 col-sm-9">
									<h4>₹ {this.props.creditNoteData.balance}</h4>								
									<h5>Credit Cash Refund</h5>
									<h6>#{this.props.creditNoteData.id}</h6>
									<p class="date">{this.props.creditNoteData.addedOn.split(" ")[0]}</p>
								</div>
								
								<div class="col-12 col-sm-3">
									<p>Validity <span>{this.props.creditNoteData.expiringOn.split(" ")[0]}</span></p>
								</div>
							</div>
						</div>
						
						<div class="crdtxtdtl">
							<h4>Store Location:  {this.props.creditNoteData.walletOwner}</h4>
							
							<ol>
								{/* <li>Now buy 1 and get 1 Free on your order.</li>
								<li>Lorem Ipsum is simply dummy text of the typesetting industry</li>
								<li>Standard dummy text ever since the 1500s</li>
								<li>Lorem Ipsum is not simply random text</li>
								<li>Now buy 1 and get 1 Free on your order.</li>
								<li>Lorem Ipsum is simply dummy text of the printing</li>
								<li>Standard dummy text ever since the 1500s</li>
								<li>Lorem Ipsum is not simply random text</li> */}
							</ol>
						</div>
					</div>
				</div>			

</>)

}
}


const mapStateToProps = ({ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse,fetchTransactionResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
		creditNoteData}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,
		userProfileResponse,orderServiceResponse,fetchTransactionResponse,
		blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
		,creditNoteData
});

export default connect(mapStateToProps, null)(CreditNoteDetails);

