import React, { useState, useEffect } from 'react';

import ApiCallingService from '../../service/api-call-service';
import transactionService from '../../service/spar-service-impl/transactionService';
import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showErrorMessageSection } from '../../redux/error-message/eror-message.action';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import TransactionService from '../../service/spar-service-impl/transactionService';
import deviceDetails from '../../service/spar-service-impl/deviceDetails';

class HeaderF extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			activeDivForMyCash:false,
			activeDivForCashBack:false,
			activeDivForCoupon:false
	
        }
    }
     componentDidMount() {
	 	
    }

    userProfileView= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		var data='profileSection';
		store.dispatch(showDashBoardComponentSection(data));
	}

	addMoney= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		var data='addMoneySection';
		store.dispatch(showDashBoardComponentSection(data));
	}


	notificationDetails= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		var data='notificationSection';
		store.dispatch(showDashBoardComponentSection(data));
	}


    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		var transactionResponse=[];


		 //new userTransactionData
	  

		 const transactionRequestHeader={
			requestType: "GET_TRANSACTION_HISTORY",
			requestId: uuidv4(),
			 requesterTimestamp: moment(),
			 mobileNumber:localStorage.getItem('mobile'),
			 originalDialogueTraceId: null,
			 walletOwner: "SPAR",
			 channel: "WEB"
		   }
		  
		
	
		   const storeDetails={
			storeCode: "SPAR123",
			terminalId: "456",
			optionalInfo: ""
		}
	
	
		   const fetchTransactionHistoryRequest={
			requestHeader:transactionRequestHeader,
			deviceInfo:this.props.deviceInfo,
		   }
	
			  
		   const fetchTransactionUrl="/api/v1/transaction/history";
	
			var userTransactionUrl='/api/v1/transaction/history';
	
			console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
			
		//TransactionService.fetchAllTransactionData(fetchTransactionUrl,fetchTransactionHistoryRequest);
		// var userTransactionUrl='/api/v1/pg/fetchAllTransaction';
		// const requestHeaderForFetchWallet={
		// 	requestType: "CREATE_ORDER",
		// 	requestId: uuidv4(),
		// 	 requesterTimestamp: moment(),
		// 	 mobileNumber:localStorage.getItem('mobile'),
		// 	originalDialogueTraceId: null,
		// 	 walletOwner: "SPAR",
		// 	 channel: "POS"
		// }
		// var userTransactionUrl='/api/v1/pg/fetchAllTransaction';

		
		// const transactionRequest={
		// 	requestHeader:requestHeaderForFetchWallet,
		// 	phoneNo:localStorage.getItem('mobile'),
		// }
		

		// // transactionResponse=transactionService.fetchAllTransactionData(userTransactionUrl,transactionRequest);
		// console.log('fetchAllTransactionData response'+JSON.stringify(transactionResponse));

		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }

	  paymentSubmit= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('paymentSection'));
	  }

	  addMoneyCloseModal= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('addMoneySection'));
	  }
      loadDetail= (event) => {
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('loadDetailSection'));
	  }


      sparCashModal= (event) => {
		this.setState({
			activeDivForMyCash:true,
			activeDivForCashBack:false,
			activeDivForCoupon:false
		})  
		event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	  }

    sparCashBackModal= (event) => {
		this.setState({
			activeDivForMyCash:false,
			activeDivForCashBack:true,
			activeDivForCoupon:false
		})  
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('sparCashBackPlusSection'));
	  }
      sparCouponModal= (event) => {
		this.setState({
			activeDivForMyCash:false,
			activeDivForCashBack:false,
			activeDivForCoupon:true
		})  
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('sparCouponSection'));
	  }
      render(){
    
          return(
            <>
                    <div class="col-12 col-lg-4"> 
                                    <div class="wallet">
										
                                        <ul>
											{this.state.activeDivForMyCash==true &&
                                            <li class="active">
											
                                          
                                                    <i class="cash"></i>
                                                    <p>My Cash</p>

 
													{/* {this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null?
													this.props.walletServiceResponse.walletDetails.cashback!=null?
													<h3>₹ {parseFloat(parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)-
													parseInt(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance)).toFixed(2)}
													</h3>:
													<h3>₹ {this.props.walletServiceResponse.walletDetails.totalWalletBalance}</h3>
													:<h3>₹0.00</h3>
													} 


													{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance==null &&
													<h3>₹0.00</h3>
													} */}
													{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails==null ?
													
													<h3>Not Available</h3>:

													this.props.walletServiceResponse.walletDetails.totalWalletBalance==null ?
													<h3>₹0.00</h3>:
													this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null?
													this.props.walletServiceResponse.walletDetails.cashback!=null?
													<h3>₹ {parseFloat(parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)-
													parseInt(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance)).toFixed(2)}
													</h3>:
													<h3>₹ {this.props.walletServiceResponse.walletDetails.totalWalletBalance}</h3>
													:<h3>₹0.00</h3>
													}
													
													
													
                                                    <button type="button" class="next"  onClick={this.sparCashModal}>next</button>
                                                
                                            	</li>
	  											}
												{
												  this.state.activeDivForMyCash==false &&
                                            		<li>
											
                                          
                                                    <i class="cash"></i>
                                                    <p>My Cash</p>









													{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails==null ?
													
													<h3>Not Available</h3>:

													this.props.walletServiceResponse.walletDetails.totalWalletBalance==null ?
													<h3>₹0.00</h3>:
													this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null?
													this.props.walletServiceResponse.walletDetails.cashback!=null?
													<h3>₹ {parseFloat(parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)-
													parseInt(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance)).toFixed(2)}
													</h3>:
													<h3>₹ {this.props.walletServiceResponse.walletDetails.totalWalletBalance}</h3>
													:<h3>₹0.00</h3>
													}

													{this.props.walletServiceResponse==null &&
													<h3>Not Available</h3>
													}

													


													{/* {this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance!=null?
													this.props.walletServiceResponse.walletDetails.cashback!=null?
													<h3>₹ {parseFloat(parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)-
													parseInt(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance)).toFixed(2)}
													</h3>:
													<h3>₹ {this.props.walletServiceResponse.walletDetails.totalWalletBalance}</h3>
													:<h3>₹0.00</h3>
													} 

													{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.totalWalletBalance==null &&
													<h3>₹0.00</h3>
													}
													{this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails==null &&
													
													<h3>₹0.00</h3>
													}
													{this.props.walletServiceResponse==null &&
													<h3>₹0.00</h3>
													} */}
												
                                                    <button type="button" class="next"  onClick={this.sparCashModal}>next</button>
                                                
                                            	</li>
	  											}
												{this.state.activeDivForCashBack==true &&
                                            	<li class="active">
                                              
                                                    <i class="cashback"></i>
                                                    <p>My Cashback</p>
                                                    {this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.cashback!=null &&
													<h3>₹ {parseFloat(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance).toFixed(2)}</h3>
													}
													
                                                    <button type="button" class="next"  onClick={this.sparCashBackModal}>next</button>
                                                
												
													{this.props.walletServiceResponse==null &&
													<h3>Not Available</h3>
													}

													{this.props.walletServiceResponse!=null &&
													 this.props.walletServiceResponse.walletDetails==null &&
													<h3>Not Available</h3>
													}

                                            	</li>
	  											}
												{this.state.activeDivForCashBack==false &&
                                            	<li>
                                              
                                                    <i class="cashback"></i>
                                                    <p>My Cashback</p>
                                                    {this.props.walletServiceResponse!=null &&
													  this.props.walletServiceResponse.walletDetails!=null &&
													this.props.walletServiceResponse.walletDetails.cashback!=null &&
													<h3>₹ {parseFloat(this.props.walletServiceResponse.walletDetails.cashback.totalSubWalletBalance).toFixed(2)}</h3>
													}


													
													{this.props.walletServiceResponse==null &&
													<h3>Not Available</h3>
													}

													
											{this.props.walletServiceResponse!=null &&
													 this.props.walletServiceResponse.walletDetails==null &&
													<h3>Not Available</h3>
													}
													
                                                    <button type="button" class="next"  onClick={this.sparCashBackModal}>next</button>
                                                
                                            	</li>
	  											}
												{this.state.activeDivForCoupon==true &&						
                                            	<li class="active">
                                              
                                                    <i class="coupon"></i>
                                                    <p>My Coupons</p>

													{this.props.myCouponDetails!=null &&
													
													this.props.myCouponDetails.length>0 &&							
								
								
													<h3>{this.props.myCouponDetails.length} </h3>
						
													}

													{/* {this.props.myCouponDetails!=null &&
													this.props.myCouponDetails.lmrResponse!=null &&
													this.props.myCouponDetails.lmrResponse.couponList==null &&
																			
								
								
													<h3>Not available</h3>
						
													} */}

													{/* {this.props.myCouponDetails!=null &&
													this.props.myCouponDetails.lmrResponse==null &&
													<h3>Not available</h3>
						
													} */}

										{this.props.myCouponDetails!=null &&
													this.props.myCouponDetails.length==0 &&
													<h3>Not available</h3>
						
													}


													{this.props.myCouponDetails==null &&
													
													<h3>Not available</h3>
						
													}
                                                   
                                                    <button type="button" class="next"  onClick={this.sparCouponModal}>next</button>
                                                
                                            	</li>
	  											}
												{this.state.activeDivForCoupon==false &&						
                                            	<li>
                                              
                                                    <i class="coupon"></i>
                                                    <p>My Coupons</p>

                                                    {this.props.myCouponDetails!=null &&
													
													this.props.myCouponDetails.length>0 &&							
								
								
													<h3>{this.props.myCouponDetails.length} </h3>
						
													}

													{/* {this.props.myCouponDetails!=null &&
													this.props.myCouponDetails.lmrResponse!=null &&
													this.props.myCouponDetails.lmrResponse.couponList==null &&
																			
								
								
													<h3>Not available</h3>
						
													} */}

													{this.props.myCouponDetails!=null &&
													this.props.myCouponDetails.length==0 &&
													<h3>Not available</h3>
						
													}


													{this.props.myCouponDetails==null &&
													
													<h3>Not available</h3>
						
													}
                                                   
                                                    <button type="button" class="next"  onClick={this.sparCouponModal}>next</button>
                                                
                                            	</li>
	  											}
                                        </ul>
                                    </div>
                                </div>
           

            
            
            </>

		
          );
    }
}
// export default HeaderF;

const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
	,myCouponDetails,deviceInfo}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
		,myCouponDetails,deviceInfo
});

export default connect(mapStateToProps, null)(HeaderF);



