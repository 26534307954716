import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import WalletService from '../spar-service-impl/walletService';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection, showLoginErrorMessage, showOtpErrorMessage} from '../../redux/error-message/eror-message.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import otpService from './otpService';
import myCouponService from './myCouponService';
import getMyPointsService from './getMyPointsService';
import TransactionService from './transactionService';
import fetchUserService from './fetchUserDetails';
import { setOtp } from '../../redux/misc-data/misc-data.action';
import CampaignService from './campaignService';
import deviceDetails from './deviceDetails';
import { setAuth } from './../../redux/auth/auth.action';


class UserService {

  otpValidationCheckAfterRegsiter(url, requestTosend,phoneNo) {
    console.log('inside UserService otpValidationCheckl laoding ', url);
    

    var response = [];
    
     async function run() {

      response=  await ApiCallingService.updateUserCall(url, requestTosend);
      
       console.log("otpvalidation__response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showOtpErrorMessage(errorMessage));
        
      }else if(response !=undefined && response.responseHeader!=undefined
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=='SUCCESS'){
          console.log("api_response for otp validation",response);
        var errorMessage='';
        store.dispatch(setOtp(''))
        store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showOtpErrorMessage(errorMessage));

        store.dispatch(showUserComponentSection('welcome'));
    }else{
      var errorMessage='Uh-Oh! Incorrect Otp!';
    store.dispatch(showOtpErrorMessage(errorMessage));
    }
      
      
    }else{
      console.log("response_login_",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      store.dispatch(showOtpErrorMessage(errorMessage));
      
    } 
   
  }
  run();
  
  }




  otpValidationCheck(url, requestTosend,phoneNo,otpDetails) {
    
    console.log('inside UserService otpValidationCheckl laoding ', url);
    // store.dispatch(showUserComponentSection('welcome'));
  
    var response = [];
    
    
     async function run() {

      response=  await ApiCallingService.updateUserCall(url, requestTosend);
      
       console.log("otpvalidation__response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showOtpErrorMessage(errorMessage));
       }
        if(response !=undefined && response.responseHeader!=undefined
        ){ 

        console.log('res_code',response.responseHeader.responseCode);
      if(response.responseHeader.responseCode=='SUCCESS'){
          console.log("api_response for otp validation",response);
        var errorMessage='';
        store.dispatch(setAuth(true));
        store.dispatch(setOtp(otpDetails.otp));
        store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showOtpErrorMessage(errorMessage));

    

       

        // var userResponse=[];
        // const routePageProfile={       
        //       origincomponent:'otp',
        //       destinationcomponent:'dashBoard',
        //       callBackMethod:'POST',
        //       dashBoardContentType:'user',
        //       responseContentType:'dashBoardData',
        //       failureComponent:'otp'
        //     }
      
      //userProfile Data


      const requestHeader={
        requestType: "GET_USER_PROFILE",
        requestId: uuidv4(),
        requesterTimestamp: moment(),
        mobileNumber: phoneNo,
        originalDialogueTraceId: null,
        walletOwner: "SPAR",
        channel: "WEB"
      }

   

      const request={
        requestHeader:requestHeader,
        deviceInfo:requestTosend.deviceInfo
      }

		var userFetchUrl='/api/v1/user/profile';
      
     console.log('userprofile request'+JSON.stringify(request));
     fetchUserService.fetchUserCall(userFetchUrl,request);
      // console.log('userprofile response'+JSON.stringify(userResponse));

      //walletService data
      // var walletUrl='/api/v1/wallet/details';

		  // const walletRequestHeader={
			// requestType: "GET_WALLET_DETAILS",
			// requestId: uuidv4(),
			// requesterTimestamp: moment(),
			// mobileNumber: phoneNo,
			// originalDialogueTraceId:  uuidv4(),
			// walletOwner: "SPAR",
			// channel: "WEB"
		  // }


	

		// const wallerServiceRequest={
		// 	requestHeader:walletRequestHeader,
		//   deviceInfo:requestTosend.deviceInfo
		// }

	//	WalletService.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
	

		// const requestHeaderForFetchWallet={
    //         requestType: "CREATE_ORDER",
    //         requestId: uuidv4(),
    //          requesterTimestamp: moment(),
    //          mobileNumber:phoneNo,
    //         originalDialogueTraceId: null,
    //          walletOwner: "SPAR",
    //          channel: "WEB"
    //     }

	  //   const myCouponRequestHeader={
		// 	  requestType: "GET_COUPONS",
		// 	  requestId: uuidv4(),
		// 	   requesterTimestamp: moment(),
		// 	   mobileNumber:phoneNo,
		// 	   originalDialogueTraceId: null,
		// 	   walletOwner: "SPAR",
		// 	   channel: "WEB"
		// }
    // const couponRequest={
    //   requestHeader:myCouponRequestHeader,
    //   deviceInfo:requestTosend.deviceInfo
    // }

    // console.log('mycoupon request::',JSON.stringify(couponRequest));

		// const myCouponUrl='/api/v1/landmark/rewards/mycoupons';
   
 //  myCouponService.fetchMyCoupons(myCouponUrl,couponRequest);

		//get My points service

		// const getMyPointsRequestHeader={
		// 	requestType: "GET_POINTS",
		// 	requestId: uuidv4(),
		// 	 requesterTimestamp: moment(),
		// 	 mobileNumber:phoneNo,
		// 	 originalDialogueTraceId: null,
		// 	 walletOwner: "SPAR",
		// 	 channel: "WEB"
	  // }

	  // const getMyPointsRequest={
		//   requestHeader:getMyPointsRequestHeader
	  // }

	  // const myPointsUrl='/api/v1/landmark/customer/points';

//	 getMyPointsService.fetchMyPoints(myPointsUrl,getMyPointsRequest);

    //fetch campaign
    // const campaignUrl='/api/v1/subwallet/bonuscash/campaign/get';
    // CampaignService.fetchCampaigns(campaignUrl,'');
    
    
    //new userTransactionData
	  

	  //  const transactionRequestHeader={
		// requestType: "GET_TRANSACTION_HISTORY",
		// requestId: uuidv4(),
		//  requesterTimestamp: moment(),
		//  mobileNumber:phoneNo,
		//  originalDialogueTraceId: null,
		//  walletOwner: "SPAR",
		//  channel: "WEB"
	  //  }


	  //  const fetchTransactionHistoryRequest={
    //   requestHeader:transactionRequestHeader,
    //   deviceInfo:requestTosend.deviceInfo
	  //  }

		  
	  // const fetchTransactionUrl="/api/v1/transaction/history";
		// console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
//		TransactionService.fetchAllTransactionData(fetchTransactionUrl,
 //  fetchTransactionHistoryRequest);



    //expiring first		
		// const expiringFirstRequestHeader={
    //   requestType: "GET_EXPIRING_FIRST",
		// 	requestId: uuidv4(),
		// 	requesterTimestamp: moment(),
		// 	mobileNumber: phoneNo,
		// 	originalDialogueTraceId:  uuidv4(),
		// 	walletOwner: "SPAR",
		// 	channel: "WEB"
		// }
		
    //   const expiringFirstRequest={
    //     requestHeader:expiringFirstRequestHeader,
    //     deviceInfo:requestTosend.deviceInfo
    //   }

    //   const expiringFirstUrl='/api/v1/wallet/expiringFirst';
    
    //   console.log('expiringFirstRequest.....',JSON.stringify(expiringFirstRequest));
 //     WalletService.expiringFirstApiCall(expiringFirstUrl,expiringFirstRequest);
      

    //   history.push('/user-dashboard');
    // //  setTimeout(()=>{
    //   history.go();
    //   //  },1000);

    

   }else{
     var errorMessage='Uh-Oh! Incorrect Otp!';
    store.dispatch(showOtpErrorMessage(errorMessage));
    store.dispatch(showOtpErrorMessage(errorMessage));
   }
    
      
    }else{
      console.log("response_login_",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      store.dispatch(showOtpErrorMessage(errorMessage));
      
    } 
   
  }
  run();
  
  }

  updateUserCall(url, requestTosend) {
    console.log('inside UserService updateUserCall laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.updateUserCall(url, requestTosend);
      
        if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
      
       }else if(parseInt(response.responseCode)==2){
        console.log("api_response for 2",response);
        var errorMessage='user does not exist';
        store.dispatch(showErrorMessageSection(errorMessage));
    
       }
       else if(parseInt(response.responseCode)==1){
        console.log("api_response for 2",response);
        var errorMessage='check user operation  failed';
        store.dispatch(showErrorMessageSection(errorMessage));
       
       }else{
        console.log("response_login_",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
  }



  fetchUserCall(url, requestTosend,routePage) {
    console.log('inside UserService fetchUserCall laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.postMethodCall(url, requestTosend,routePage);
      
       console.log("__response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } else if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=='SUCCESS'){
         console.log("api_response for USER PROFILE",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

      //  }else if(parseInt(response.responseCode)==2){
      //   console.log("api_response for 2",response);
      //   var errorMessage='user does not exist';
      //   store.dispatch(showErrorMessageSection(errorMessage));
      //   store.dispatch(showUserComponentSection(routePage.failureComponent));
          

      //  }
      //  else if(parseInt(response.responseCode)==1){
      //   console.log("api_response for 2",response);
      //   var errorMessage='check user operation  failed';
      //   store.dispatch(showErrorMessageSection(errorMessage));
      //   store.dispatch(showUserComponentSection(routePage.origincomponent));
      //  }else{
      //   console.log("response_login_",response);
      //   var errorMessage='Somthing went wrong we are looking at our End';
      //   store.dispatch(showErrorMessageSection(errorMessage));
        
      // } 
      
    }
  }
   
  }
  run();
  
  }


  fetchUserAfterUpdateCall(url, requestTosend,routePage) {
    console.log('inside UserService fetchUserCall laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.postUpdateCheckCall(url, requestTosend,routePage);
      
       console.log("__response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
        } else if(response !=undefined &&
        response.responseHeader!=undefined){ 

          console.log('res_code',response.responseHeader.responseCode);
          if(response.responseHeader.responseCode=='SUCCESS'){
            console.log("api_response for USER PROFILE",response);
           var errorMessage='';
           store.dispatch(showErrorMessageSection(errorMessage));
   
         //  }else if(parseInt(response.responseCode)==2){
         //   console.log("api_response for 2",response);
         //   var errorMessage='user does not exist';
         //   store.dispatch(showErrorMessageSection(errorMessage));
         //   store.dispatch(showUserComponentSection(routePage.failureComponent));
             
   
         //  }
         //  else if(parseInt(response.responseCode)==1){
         //   console.log("api_response for 2",response);
         //   var errorMessage='check user operation  failed';
         //   store.dispatch(showErrorMessageSection(errorMessage));
         //   store.dispatch(showUserComponentSection(routePage.origincomponent));
         //  }else{
         //   console.log("response_login_",response);
         //   var errorMessage='Somthing went wrong we are looking at our End';
         //   store.dispatch(showErrorMessageSection(errorMessage));
           
         // } 
         
       }
     }
    
  }
  run();
  
  }





userDashBoardLoadCall(url, requestTosend, routePage) {
    console.log('inside UserService dashboard laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.postMethodCall(url, requestTosend,routePage);
      
       console.log("__response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
     
      else if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.response.responseCode);
       if(parseInt(response.response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

      
       }else if(parseInt(response.response.responseCode)==2){
        console.log("api_response for 2",response);
        var errorMessage='user does not exist';
        store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showUserComponentSection(routePage.failureComponent));
          

       }
       else if(parseInt(response.response.responseCode)==1){
        console.log("api_response for 2",response);
        var errorMessage='check user operation  failed';
        store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showUserComponentSection(routePage.origincomponent));
       }else{
        console.log("response_login_",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
  }
    
  apiCall(url, requestTosend, routePage,phoneNo) {
    console.log('inside UserService api ', url+'  '+phoneNo);
    
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.postMethodCallForUserLogin(url, requestTosend,routePage);
      
       console.log("__response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showLoginErrorMessage(errorMessage));
      
       
      }else
        if(response !=undefined && response.responseHeader!=undefined
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=='SUCCESS'){
         console.log("api_response for SUCCESS",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
        store.dispatch(showLoginErrorMessage(errorMessage));

        //generate otp call
        var otpurl= "/api/v1/otp/generate";        
          const requestHeader={
            requestType: "GENERATE_OTP",
            requestId: uuidv4(),
            requesterTimestamp: moment(),
            mobileNumber:phoneNo,
            originalDialogueTraceId: null,
            walletOwner: "SPAR",
            channel: "WEB"
          }
          
    
          const otpDetails={
            otpType: "LOGIN",
            refNo: null,
            otp: null

          }
          const request={
            requestHeader:requestHeader,
            
            deviceInfo:requestTosend.deviceInfo,
            otpDetails:otpDetails
          }

        console.log('otp generate request:',JSON.stringify(request));  
        otpService.generateOtpServiceCall(otpurl,request,routePage,phoneNo);

        store.dispatch(showUserComponentSection(routePage.destinationcomponent));
          
       }else if(response.responseHeader.responseCode=='USER_NOT_FOUND'){
        console.log("api_response for 2",response);
        var errorMessage='user does not exist';
       

        
        store.dispatch(showUserComponentSection(routePage.failureComponent));
          

       }
       
      //  else if(parseInt(response.responseCode)==1){
      //   console.log("api_response for 2",response);
      //   var errorMessage='check user operation  failed';
      //   //store.dispatch(showErrorMessageSection(errorMessage));
      //   store.dispatch(showUserComponentSection(routePage.origincomponent));
      //  }else{
      //   console.log("response_login_",response);
      //   var errorMessage='Somthing went wrong we are looking at our End';
      //   //store.dispatch(showErrorMessageSection(errorMessage));
        
      // } 
      
    }
   
  }
  run();
  
  }
   
  
  registerCall(url, requestTosend, routePage,phoneNo) {
    console.log('inside UserService register method', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.postMethodCall(url, requestTosend,routePage);
      
       console.log("__response__",JSON.stringify(response));  
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showOtpErrorMessage(errorMessage));
      
       
      }else
       if(response !=undefined && response.responseHeader!=undefined
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=="USER_CREATED"){
         console.log("api_response for USER_CREATED",response);
        var errorMessage='';

            store.dispatch(showErrorMessageSection(errorMessage));
            setTimeout(()=>{
            store.dispatch(showUserComponentSection(routePage.destinationcomponent));
            },2000);
       }else if(response.responseHeader.responseCode=="UNKNOWN_EXCEPTION"){
         console.log(response.responseHeader.exception.details[0].message);
         if(response.responseHeader.exception.details[0].message=="java.lang.RuntimeException: OTP could not be validated"){
            store.dispatch(showOtpErrorMessage('Uh-Oh! Incorrect Otp!'));
           
         }else{
          store.dispatch(showOtpErrorMessage('Somthing went wrong we are looking at our End'));
         }

       }else{
        console.log("response_login_",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showOtpErrorMessage(errorMessage));
        } 
      }
   
   
  }
   run();
  }
   

  
  }
export default new UserService();
