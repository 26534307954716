import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import ApiCallingService from '../api-call-service';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';

class WalletServiceImpl {
  debitWalletCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));
  
    var response = [];
    
     async function run() {
      console.log('debitWalletCall service Request',JSON.stringify(requestTosend));
  
      response=  await ApiCallingService.postCallForWallet(url, requestTosend);
      
      
       console.log("debitWalletCall response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      
      if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
       }
      else if(response !=undefined 
        //&& response.status==200
        ){ 
  
      console.log(' creditWalletCall res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("---response for creditWalletCall",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
        //store.dispatch(showResponse(response.response.amount));
          console.log("----credit wallet call ended----");
          console.log("----fetch  wallet call started----");
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

 creditWalletCall(url, requestTosend) {
  console.log('inside WalletService ', url);
  //store.dispatch(setLoggedInUserID(username));

  var response = [];
  
   async function run() {
    console.log('creditWalletCall service Request',JSON.stringify(requestTosend));

    response=  await ApiCallingService.postCallForWallet(url, requestTosend);
    

     console.log("",JSON.stringify(response));
     if (response === 'error') {
      console.log(response);
      
      var errorMessage='Somthing went wrong we are looking at our End--generic error';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    }
    else if (response.status === 404) {
      console.log(response);
     
     var errorMessage='Somthing went wrong we are looking at our End--400 error';
     store.dispatch(showErrorMessageSection(errorMessage));
    }
    else if (response.status === 500) {
      console.log(response);
    
     var errorMessage='Somthing went wrong we are looking at our End--500 error';
     store.dispatch(showErrorMessageSection(errorMessage));
    }
    else if (response.status === 404) {
      console.log(response);
    
     var errorMessage='Somthing went wrong we are looking at our End--404 error';
     store.dispatch(showErrorMessageSection(errorMessage));
    }
    
    else if(response !=undefined 
      //&& response.status==200
      ){ 

    console.log(' creditWalletCall res_code',response.responseCode);
     if(parseInt(response.responseCode)==0){
       console.log("---response for creditWalletCall",response);
      var errorMessage='';
      store.dispatch(showErrorMessageSection(errorMessage));
      //store.dispatch(showResponse(response.response.amount));
        console.log("----credit wallet call ended----");
        console.log("----fetch  wallet call started----");


    }
    else{
      console.log("error response!",response);
      var errorMessage='Somthing went wrong we are looking at our End';
      store.dispatch(showErrorMessageSection(errorMessage));
      
    } 
    
  }
 
}
run();

 }


fetchWalletApiCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('fetchWalletApiCall service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForWallet(url, requestTosend);
      
      
       console.log("_fetchWalletApiCall response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      else if (response.status === 404) {
        console.log(response);
       
       var errorMessage='Somthing went wrong we are looking at our End--400 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 500) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--500 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      else if (response.status === 404) {
        console.log(response);
      
       var errorMessage='Somthing went wrong we are looking at our End--404 error';
       store.dispatch(showErrorMessageSection(errorMessage));
      }
      
      else if(response !=undefined 
        //&& response.status==200
        ){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
        //store.dispatch(showResponse(response.response.amount));
 
       {systemErrorCodes.syetemErrorCodesDetails.map(systemErrCodes=>{
       if(systemErrCodes.id==parseInt(response.responseCode))
       {
       console.log("api_response for "+ systemErrCodes.id,response);
       var errorMessage=systemErrCodes.details;

       store.dispatch(showErrorMessageSection(errorMessage));
      }
      })}
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }
}

export default new WalletServiceImpl();
