import { LeadingOverlayType } from './leading-overlay.type';

const INITIAL_STATE = {
    //default: errorMessage:''
    blockScreenConfirm:false

};

const leadingOverlayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
     

        case LeadingOverlayType.blockScreen:
            return {
                    ...state,
                blockScreenConfirm: action.payload,
            };
           
       
        case LeadingOverlayType.RESET:
            return INITIAL_STATE;
                                                           
        default:
            return state;
    }
};

export default leadingOverlayReducer; 