import React, { useState, useEffect } from 'react';
import pageNotAccessable from "../../assests/images/icons/403.svg";
import history from '../../auth/history';



class SparCashBackTandC1stTo15thNov extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0
        }
    }
	


   render(){

return(
	<>


		
        <ol>
			<li>To avail the offer, customer should ensure that they provide the same number they have used to register before billing at the store.</li>
            <li>Cashback of Rs.150 redeemable on purchases from 1st Nov till 15th Nov 2021 on minimum bill of Rs.499 at SPAR Stores.</li>
            <li>There will be no roll over of the cashback amount.</li>
            <li>Offer valid only once per registered mobile number.</li>
            <li>Offer is not applicable on purchase of Liquor and Tobacco.</li>
		</ol>
						
	
</>)

}
}

export default SparCashBackTandC1stTo15thNov;
