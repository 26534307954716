import { AuthType } from "./auth.type";
const INITIAL_STATE = {

    isAuth: false,
    isProfile: false,
    errorMsg:''

};


const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {


        case AuthType.setAuth:
            return {
                ...state,
                isAuth: action.payload,
            };
        case AuthType.setErrMsg:
            return {
                ...state,
                errorMsg: action.payload,
            };

        case AuthType.setProfile:
            return {
                ...state,
                isProfile: action.payload,
            };

        case AuthType.RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default authReducer; 