import React from "react";
import history from '../../auth/history';
//import { ReCaptcha } from 'react-recaptcha-google'
//import { loadReCaptcha } from 'react-recaptcha-google'
import AuthService from "../../service/retailer-service-impl/AuthService";
class userOtpLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',
			
			response: null,
			captchaDone: false
		}
	}
	componentDidMount() {

	}

	handlePhoneNoChange = (event) => {
		if (event.target.value.length == 10) {
			this.setState({
				phoneNo: event.target.value
			})
		}
		console.log('Testing-handlePhoneNoChange :' + this.state)
	}

	handleReferral = (event) => {
		event.preventDefault();

		var url = process.env.REACT_APP_url_For_WhitelabelRetailer_login;
	

		const referralData={
			showLoginSection:false,
			showOtpSection:false,
			showReferralSection:true,
			showRegisterSection:false

		}

		console.log(referralData);
	
		var url =  process.env.REACT_APP_url_For_WhitelabelRetailer_login;
		

		this.props.parentCallback(this.state.phoneNo);
	
	
	}
	render() {


		return (
			<div>
				<form onSubmit={this.handleReferral}>
				<div class="form-floating">
						<input type="text" class="form-control" id="refer" 
						placeholder="Referral code" maxLength="10" required />
						<label for="refer">Referral code</label>
					</div>
					<button type="submit" className="btn primary">Submit</button>
				</form>
			</div>
		);
	}
}

export default userOtpLogin;