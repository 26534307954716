import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import WalletService from '../spar-service-impl/walletService';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { setOtp } from '../../redux/misc-data/misc-data.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';


class CampaignService {


  fetchCampaigns(url, requestTosend) {
    console.log('inside fetchMyCoupons laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.fetchCampaignRecords(url, requestTosend);
      
       console.log("__fetchMyCoupons response__",JSON.stringify(response));

       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
        if(response !=undefined && response.responseHeader!=undefined
        ){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=="SUCCESS"){
         console.log("api_response for fetchMyCoupons",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
      
       }else{
        console.log("otp_generate",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
  }


}

export default new CampaignService();
