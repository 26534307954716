export const UserActionTypes = {
    SET_RO_MDN: 'SET_RO_MDN',
	SET_CURRENT_USER: 'SET_CURRENT_USER',
    RESET: "RESET",
    SET_PRICING_GROUP_ID: "SET_PRICING_GROUP_ID",
    SET_MAX_MIN_AMOUNT:'SET_MAX_MIN_AMOUNT',
    SET_PRODUCT_ID:'SET_PRODUCT_ID',
    SET_IN_SUFFICIENT_BASED_CONDITION:'SET_IN_SUFFICIENT_BASED_CONDITION',
    SET_LOGIN_USER_NAME:'SET_LOGIN_USER_NAME', 
    SET_USER_LAST_LOGIN_TIME:'SET_USER_LAST_LOGIN_TIME', 
    SET_USER_TYPE:'SET_USER_TYPE',
    SET_LOGGED_USER_ID: 'SET_LOGGED_USER_ID',
    SET_Transaction_Type:'SET_Transaction_Type',
    SET_setChannelId:'SET_setChannelId',
    SET_USER_CLIENTID:'SET_USER_CLIENTID',
    SET_USER_LOGOUT:'SET_USER_LOGOUT',
    SET_LOGGED_USER_ID: 'SET_LOGGED_USER_ID'
};