import React from "react";

import {store} from '../../redux/store';


import {showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { connect } from "react-redux";

import Pagination from "react-js-pagination";
import moment from "moment";
import { components } from "react-select";
import TransactionFailureComponent from "./TranscationFailureComponent";
import TransactionSuccessComponent from "./TransactionSuccessComponet";
import TransactionService from "../../service/spar-service-impl/transactionService";
import { v4 as uuidv4 } from 'uuid';

 
import { default as ReactSelect } from "react-select";


const Option = (props) => {
	return (
	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };
class TransactionDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
			filterVisible:true,
			pagerTransactionResponse:[],
			phoneNo: '',
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
			activePage: 1,
			zResponse:[],
			// tResponse:[],
			sResposne:[],
			uResponse:[],
			initialResponse:[],
			clearToggler:false,
			status:[],
			typeSelect:[],
			value:'select',
			formDate:'',
			toDate:'',
			message:'',
			options: [{name: 'Success', id: 'Success'},{name: 'Pending', id: 'Pending'}],
			statusResponse:[],
			typeResponse:[],
			formDateResponse:[],
			toDateResponse:[],
			statusOptions : [
				{ value: "SUCCESS", label: "Success" },
				{ value: "FAILED", label: "Failed" },
			 ],
			 typeOptions:[
				{ value: "Add Money", label: "Add Money" },	
				{ value: "Bonus Club", label: "Bonus Club" },
				{ value: "Cashback", label: "Cashback" },
				{ value: "Coupon", label: "Coupon" },	
				{ value: "Credit Note", label: "Credit Note" },	
				{ value: "Gift Card", label: "Gift Card" }	
												
			 ],
			  optionSelected: null,
			  statusList:[],
			  typeList:[],
			  responseTypeVal:'',
			  totalNoElements:this.props.txnResponseLength
        }
		
    }

	
      componentDidMount() {	
	
       }
       transactionDetails= (event) => {
        event.preventDefault();
        store.dispatch(showDashBoardComponentSection('transactionDetails'));
       }

	   closeModal= (event) => {		
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		}

	   handlePageChange(pageNumber) {
		console.log('Inside handlePageChange');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});

		this.setState({ showText: false })
			//new userTransactionData
			

			const transactionRequestHeader={
				requestType: "GET_TRANSACTION_HISTORY",
				requestId: uuidv4(),
				requesterTimestamp: moment(),
				mobileNumber:this.props.phone,
				originalDialogueTraceId: null,
				walletOwner: "SPAR",
				channel: "WEB",
				
			}


			const fetchTransactionHistoryRequest={
			requestHeader:transactionRequestHeader,
			deviceInfo:this.props.deviceInfo,
			pageNo:pageNumber-1
			}

				
			const fetchTransactionUrl="/api/v1/transaction/history";
				console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
				TransactionService.fetchAllTransactionPagerData(fetchTransactionUrl,
				fetchTransactionHistoryRequest);	
			}
	
	

	   handlePageChangeDefault(pageNumber) {
	    console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});

		this.setState({ showText: false })
    //new userTransactionData
	  

				const transactionRequestHeader={
					requestType: "GET_TRANSACTION_HISTORY",
					requestId: uuidv4(),
					requesterTimestamp: moment(),
					mobileNumber:this.props.phone,
					originalDialogueTraceId: null,
					walletOwner: "SPAR",
					channel: "WEB",
				
				}


				const fetchTransactionHistoryRequest={
				requestHeader:transactionRequestHeader,
				deviceInfo:this.props.deviceInfo,
				pageNo:pageNumber-1
				}

					
				const fetchTransactionUrl="/api/v1/transaction/history";
					console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
					TransactionService.fetchAllTransactionPagerData(fetchTransactionUrl,
					fetchTransactionHistoryRequest);
					

				}
	 

	

			handleChange = (selected) => {
	
				this.state.status=[];
				this.setState({
					statusList:selected
				})
				if(selected.length>0){
				selected.map(response => { 
				this.state.status.push(response.value);
				})}

				console.log(this.state.status);
			this.handleFilterStatus();
				
			};

			typeChange= (selected) => {
	
				this.state.typeSelect=[];
				this.setState({
					typeList:selected
				})
				if(selected.length>0){
				selected.map(response => { 
				this.state.typeSelect.push(response.value);
				})}

			console.log(this.state.typeSelect);
			this.handleFilterItemType();
				
			};

		clearAll= (event) => {
			
		
			this.setState({
				message:'',
				status:[],
				typeSelect:[],
				uResposne:[],
				zResponse:[],
				statusList:[],
				typeList:[],
				toDate:'',
				formDate:''
			})
	
	
        }

		dateFromSelect= (event) => {
			this.state.formDate=moment(event.target.value).format("DD-MM-YYYY");
		
			this.handleFormDate();
			
		}

		dateToSelect= (event) => {
			this.state.toDate=moment(event.target.value).format("DD-MM-YYYY");
			this.handleToDate();
			
		}

		handleFormDate(){

			this.state.formDateResponse=[];
			this.props.txnResponsePager.map(response => { 
				
				
				const date1 = new Date(moment(response.createdOn,'DD/MM/YYYY HH:mm:ss'));	
				
			
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.createdOn,'MM/DD/YYYY HH:mm:ss'));
				}
				
				
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
			
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
				
				
	

				if(date1.getTime()>date2.getTime()){
				this.state.formDateResponse.push(response);
				}
					
			})

			
			this.commonFilter();
		}

		handleToDate(){
			this.state.toDateResponse=[];
			this.props.txnResponsePager.map(response => { 
				const date1 = new Date(moment(response.createdOn,'DD/MM/YYYY HH:mm:ss'));	
		
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.createdOn,'MM/DD/YYYY HH:mm:ss'));
				}
		
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				console.log(date1.getTime());
				console.log(date2.getTime());
    			
				if(date1.getTime()<date2.getTime()){
				this.state.toDateResponse.push(response);
				}	 			
			})

			
		
			this.commonFilter();
		}

		commonFilter(){
		//console.log(this.state.status);
		console.log(this.state.typeSelect);	
		this.state.uResponse=this.props.txnResponsePager;
		this.state.zResponse=[];
		var x=[];
		var y=[];


		     
				this.state.uResponse.map(response => { 
				if(this.state.status.length>0){
				 if(!this.state.status.includes(response.status)){
				if(!y.includes(response)){	
                y.push(response);
				}
				}
				}
				}
				);

				this.state.uResponse.map(response => {
					
					if(this.state.typeSelect.length>0){


						if(response.txnType=='LINK_GIFTCARD'){
						this.state.responseTypeVal='Gift Card';
						}else if(response.txnType=='CREDIT_WALLET'){
								if(response.subWalletTxnIds.CASHBACK!=null){
								this.state.responseTypeVal='Cashback';
								}else if(response.subWalletTxnIds.BONUS_CASH!=null){
								this.state.responseTypeVal='Bonus Club';
								}else if(response.subWalletTxnIds.ECASH!=null){
								this.state.responseTypeVal='Add Money';
								}else if(response.subWalletTxnIds.CREDIT_NOTE!=null){
								this.state.responseTypeVal='Credit Note';	
								}
								
							}else if(response.txnType=='DEBIT_WALLET'){
				                if(parseInt(response.appliedDetails.creditNoteAmount)>0){
									this.state.responseTypeVal='Credit Note';	
								}else if(parseInt(response.appliedDetails.bonusCashAmount)>0){
									this.state.responseTypeVal='Bonus Club';
								}else if(parseInt(response.appliedDetails.giftCardAmount)>0){
									this.state.responseTypeVal='Gift Card';
								}else if(parseInt(response.appliedDetails.cashbackAmount)>0){
									this.state.responseTypeVal='Cashback';
								}else if(parseInt(response.appliedDetails.ecashAmount)>0){
									this.state.responseTypeVal='Add Money';
								}
							}
							
				
				   
				   if(!this.state.typeSelect.includes(this.state.responseTypeVal)){
					if(!y.includes(response)){	
						y.push(response);
					}
				   }	 
				 
				
				}
				});


				//formdate 
				this.state.uResponse.map(
					response => { 
				if(this.state.formDate!=''){
					
		        const date1 = new Date(moment(response.createdOn,'DD/MM/YYYY HH:mm:ss'));	
					
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.createdOn,'MM/DD/YYYY HH:mm:ss'));
				}
    			const date2 = new Date(moment(this.state.formDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.formDate,'MM/DD/YYYY'));
				}
			
				if(date1.getTime()<date2.getTime()){
		
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate greater::",date1.getTime());
					console.log("uResponse formdate::",date2.getTime()); 
				}
				}
				}
				);
			    

                //TODATE
				this.state.uResponse.map(
					response => { 
				 if(this.state.toDate!=''){
				const date1 = new Date(moment(response.createdOn,'DD/MM/YYYY HH:mm:ss'));
				if(date1=='Invalid date'){
					console.log('Invalid date format.');
					date1=new Date(moment(response.createdOn,'MM/DD/YYYY HH:mm:ss'));
				}
				const date2 = new Date(moment(this.state.toDate,'DD/MM/YYYY'));
				
				if(date2=='Invalid date'){
					console.log('Invalid date format.');
					date2=new Date(moment(this.state.toDate,'MM/DD/YYYY'));
				}
				if(date1.getTime()>date2.getTime()){
					 if(!y.includes(response)){	
						y.push(response);
						}
				}else{
					console.log("uResposne createddate lesser::",date1.getTime());
					console.log("uResponse todate::",date2.getTime()); 
				}
				}
				}
			);
		
				
				 	
			console.log('y length::',y.length);
			
            this.state.uResponse.map(response =>{
             if(!y.includes(response)){
				// console.log('response remains',response);
				x.push(response);
			 }
			});
			console.log('x.length c:',x.length);
			    if(x.length>0){
				x.map(response=>{
				this.state.zResponse.push(response);
				});
				this.setState({
				message:'',
				sResposne:this.state.zResponse.slice(0,10)
				}
				)
			   }else{
				this.setState({
					zResponse:[],
					message:'No record found',
					sResposne:[]
				})  
			   }
			
			
		
		
	}
		

			

		handleFilterStatus(){

           this.state.statusResponse=[];

			this.props.txnResponsePager.map(response => { 
				//console.log('All Response ',response);
				if(this.state.status.includes(response.status)){
				this.state.statusResponse.push(response);
				}	 			
			});

			// if(this.state.statusResponse.length>0){
			// 	this.state.statusResponse.map(response => {  
			// 		if(!this.state.uResponse.includes(response)){
					
			// 			this.state.uResponse.push(response);
			// 	}	 			
			// })
			// }
		 
		 this.commonFilter();
		 
		
		}

	   handleFilterItemType(){
		this.state.typeResponse=[];

		this.props.txnResponsePager.map(response => { 
			
			if(response.txnType=='LINK_GIFTCARD'){
				this.state.responseTypeVal='Gift Card';
				}else if(response.txnType=='CREDIT_WALLET'){
						if(response.subWalletTxnIds.CASHBACK!=null){
						this.state.responseTypeVal='Cashback';
						}else if(response.subWalletTxnIds.BONUS_CASH!=null){
						this.state.responseTypeVal='Bonus Club';
						}else if(response.subWalletTxnIds.ECASH!=null){
						this.state.responseTypeVal='Add Money';
						}else if(response.subWalletTxnIds.CREDIT_NOTE!=null){
						this.state.responseTypeVal='Credit Note';	
					}
						
					}else if(response.txnType=='DEBIT_WALLET'){
						if(parseInt(response.appliedDetails.creditNoteAmount)>0){
							this.state.responseTypeVal='Credit Note';	
						}else if(parseInt(response.appliedDetails.bonusCashAmount)>0){
							this.state.responseTypeVal='Bonus Club';
						}else if(parseInt(response.appliedDetails.giftCardAmount)>0){
							this.state.responseTypeVal='Gift Card';
						}else if(parseInt(response.appliedDetails.cashbackAmount)>0){
							this.state.responseTypeVal='Cashback';
						}else if(parseInt(response.appliedDetails.ecashAmount)>0){
							this.state.responseTypeVal='Add Money';
						}
					}
					
		
			console.log('responseTypeVal',this.state.responseTypeVal);

			//console.log("txnType::::",response.txnType)
			
			if(this.state.typeSelect.includes(this.state.responseTypeVal)){
			console.log('matched');
			this.state.typeResponse.push(response);
			}	 			
		}
		)

		
	
	  this.commonFilter();	
		}
		statusSelect = (event) => {
			console.log('Inside statusSelect ');
			if(!this.state.status.includes(event.target.value)){
			this.state.status.push(event.target.value);
			}
			this.handleFilterStatus();
		}
		
		typeSelect = (event) => {		
		if(!this.state.typeSelect.includes(event.target.value)){
		this.state.typeSelect.push(event.target.value);
		}
		this.handleFilterItemType();
		}

		filterVisibleToggle= (event) => {
			this.setState({
              filterVisible:false
			})
				
		}


		clearAllToggle = (event) => {

			this.setState({
				filterVisible:true
			  })
			console.log('filterVisible '+this.state.filterVisible);  
			if(this.state.clearToggler==false){
				this.setState({
					clearToggler:true
				})
			}else if(this.state.clearToggler==true){
				this.setState({
					clearToggler:false
				})
				this.clearAll();
			}
		}

		
       render(){
   

          return(
            <>
			      
     
				<div class="innercard">
				
					<div class="headtxt">
						<h3>Transactions</h3>
						<button type="button" class="close" onClick={this.closeModal}>close</button>
						<div class="dropdown fltr">
							<button type="button" class="filter dropdown-toggle" onClick={this.clearAllToggle}
							id="fillist" 
							data-bs-toggle="dropdown"
							 data-bs-auto-close="false" 
							 aria-expanded="false"
							>Filter</button>
					
							<div class="dropdown-menu dropdown-menu-end"
							 aria-labelledby="fillist"
							 >
									{this.state.filterVisible==true &&
								<form>
								
								<div>
									<div class="filters">
									<i class="closefil"
									 onClick={this.filterVisibleToggle}
									
									id="fillist" 
							data-bs-toggle="dropdown"
							 data-bs-auto-close="false" 
							 aria-expanded="false">x</i>
									
										<label for="status"  className="filterLabel">Status:</label>
										
											<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
											options={this.state.statusOptions}
											isMulti
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											components={{
												Option
											}}
											onChange={this.handleChange}
											allowSelectAll={true}
											value={this.state.statusList}
											/>
									</div>
									
									<div class="filters">
										<label for="type" className="filterLabel">Type:</label>
										<ReactSelect tabindex="8" id="status" data-placeholder="..."  multiple class="chosen-select"
											options={this.state.typeOptions}
											isMulti
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											components={{
												Option
											}}
											onChange={this.typeChange}
											allowSelectAll={true}
											value={this.state.typeList}
											></ReactSelect>
							
									</div>
									
									<div class="filters">
										<label for="select-state" className="filterLabel">From Date:</label>
										<input type="date"  id="" onChange={this.dateFromSelect}/>
									</div>
									
									<div class="filters">
										<label for="select-state" className="filterLabel">To Date:</label>
										<input type="date"  id="" onChange={this.dateToSelect}/>
									</div>

									<div class="fbtn">
										<button class="mediumbtn" type="reset" id="btnClear" onClick={this.clearAll}>Clear all</button>
									</div>
								</div>
							
								</form>
								}	
							</div>
	   						
						</div>
					
					</div>
					
					<div class="txnhistory" id="txnlist">	


					{this.props.txnLoader=='active' &&
					
					(this.state.message=='' &&

					this.state.zResponse.length==0 ?
				
					this.props.txnResponsePager!='' &&
						this.props.txnResponsePager.map(response => (


							parseInt(response.appliedAmount)>0 &&(
								//this.props.transactionResponse.responseList.map(resp => (
									(JSON.stringify(response.status).includes("SUCCESS"))? 
									<TransactionSuccessComponent response={response}/>
									:<TransactionFailureComponent response={response}/>
									)
						
								
						)
						):
						this.state.zResponse.map(response => (


							parseInt(response.appliedAmount)>0 &&(
								//this.props.transactionResponse.responseList.map(resp => (
									(JSON.stringify(response.status).includes("SUCCESS"))? 
									<TransactionSuccessComponent response={response}/>
									:<TransactionFailureComponent response={response}/>
									)
						
								
						)
						)
						
						)
								
						}

						{
						this.state.message!='' &&
						<div>
						{this.state.message}
						</div>
	  					}
					
					</div>



					<div class="pager">
						

					{
					(this.state.message=='') ?
					this.state.zResponse.length==0 ?
					(this.props.txnResponsePager.length>=0)?
					

					<div>
					<p>Showing {this.state.totalNoElements} entries</p>
					<Pagination
					activePage={this.state.activePage}
					itemsCountPerPage={10}
					totalItemsCount={
						this.state.totalNoElements}
					pageRangeDisplayed={5}
					onChange={this.handlePageChangeDefault.bind(this)}
					className="pagination"
					linkClass="page-link"
					itemClass="page-item"
					/>  
					</div>
					:
					<div>
					<p>Showing {
						this.props.txnResponsePager.length>0 ?
						this.props.txnResponsePager.length:0} entries</p>
					</div>
					:
					(this.state.zResponse.length>=10) ?
					<div>
					<p>Showing {this.state.zResponse.length} entries</p>
					<Pagination
          				activePage={this.state.activePage}
						itemsCountPerPage={10}
						totalItemsCount={this.state.zResponse.length}
						pageRangeDisplayed={5}
						onChange={this.handlePageChange.bind(this)}
						className="pagination"
						linkClass="page-link"
						itemClass="page-item"
						/>  
						</div>  
						:<p>Showing {this.state.zResponse.length} entries</p>
						:
						<div>
						</div>
					}     

					</div>
					</div>                            
        </>

		
          );
      } 
}
const mapStateToProps = (
    { showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		txnResponsePager,txnResponseLength},
    leadingOverLay:{blockScreenConfirm},
	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
        ,deviceInfo,myCouponDetails,txnLoader},
    ordeDetailsReduce:{orderId}}
    ) => ({
        viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,
		txnResponsePager,txnResponseLength,
		blockScreenConfirm,
		phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints
        ,deviceInfo,myCouponDetails,txnLoader,
		orderId
});



export default connect(mapStateToProps, null)(TransactionDetails);