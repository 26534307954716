import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import WalletService from '../spar-service-impl/walletService';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { setOtp } from '../../redux/misc-data/misc-data.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import userService from './userService';


class UserupdateService {


  userUpdateServiceCall(url, requestTosend) {
    console.log('inside UserupdateService laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.userUpdateService(url, requestTosend);
      
       console.log("__response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      
      else if(response !=undefined 
        && response.responseHeader!=undefined){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=="SUCCESS"){
        console.log("api_response for userUpdateServiceCall",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

        const requestHeaderUserProfile={
          requestType: "GET_USER_PROFILE",
          requestId: uuidv4(),
          requesterTimestamp: moment(),
          mobileNumber: response.requestHeader.mobileNumber,
          originalDialogueTraceId: null,
          walletOwner: "SPAR",
          channel: "WEB"
        }
        
  
        const requestUserProfile={
          requestHeader:requestHeaderUserProfile,
          //deviceInfo:this.props.deviceInfo
        }
  
        var userFetchUrl='/api/v1/user/profile';
        
        console.log('userprofile request'+JSON.stringify(requestUserProfile));
      
        // setTimeout(()=>{
          userService.fetchUserAfterUpdateCall(userFetchUrl,requestUserProfile);
        // },2000);
      
       }else{
        console.log("userUpdateServiceCall error",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
  }
}

export default new UserupdateService();
