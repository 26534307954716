import React, { useState, useEffect } from 'react';


import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import qcConnectorService from '../../service/spar-service-impl/qcConnectorService';

import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { setCardPin } from '../../redux/misc-data/misc-data.action';
import {showErrorMessageSection} from '../../redux/error-message/eror-message.action';

import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';


class GiftCardPinAdd extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			amount:0,
			cardPin:'',
			buttonVisible:true,
			displayMessage:''
        }
    }
	
    closeModal = (event) => {
		event.preventDefault();
		// store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }


      purchaseGiftCardModal = (event) => {
       console.log('entering purchaseGiftCardModal');
	   event.preventDefault();
	   store.dispatch(showUserComponentSection('login'));
	   store.dispatch(showErrorMessageSection(''));
	   store.dispatch(showDashBoardComponentSection('giftCardPurChase'));
	}


    giftCardOtpSubmit= (event) => {
        event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('giftCardAddedDetails'));
     }

	 closeModal= (event) => {
        event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('trasactionHistory'));
     }
	 
	 addGiftCardSection= (event) => {
        event.preventDefault();
		store.dispatch(showErrorMessageSection(''));
		store.dispatch(showUserComponentSection('login'));
        store.dispatch(showDashBoardComponentSection('giftCardPurChase'));
     }


	 handlePhoneNoChange = (event) => {
		if(event.target.value.length ==10){
		this.setState({
            phoneNo: event.target.value
		})		
	
		}
	
	  }
     pinChange= (event) => {
		event.preventDefault();
		this.setState({
            cardPin: event.target.value
		})		
	  }
	 

	 giftCardVerification= (event) => {
        event.preventDefault();
        store.dispatch(showErrorMessageSection(''));
		this.setState({
			buttonVisible: false,
			
		})
		setTimeout(()=>{
			this.setState({
				buttonVisible:true
			})	
		},1000);
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showErrorMessageSection(''));
		console.log("cardpin::::",this.state.cardPin);
		store.dispatch(setCardPin(this.state.cardPin));


		  const requestHeader={
			requestType: "GET_GIFTCARD_BALANCE",
			requestId: uuidv4(),
			 requesterTimestamp: moment(),
			 mobileNumber:this.props.phone,
			 originalDialogueTraceId: null,
			 walletOwner: "SPAR",
			 channel: "WEB"
	  	}


		
		//   var deviceData='';
		//   deviceInfo.then((response) => {
		// 	  console.log(response);
		// 	  deviceData=JSON.stringify(response)
		//   }).catch(error => {
		// 	  console.log(error);
		//   });
  
		

	  const routePage={
		origincomponent:'giftCardPurChase',
		destinationcomponent:'',
		failureComponent:'giftCardPurChase',
		callBackMethod:'POST',
		dashBoardContentType:'user',
		responseContentType:'userData'
	  }

	  var authTokenInfo={}

	//   if (process.env.NODE_ENV == "development"){
		 authTokenInfo={
			terminalId: "",
			Username: "",
			Password: "",
			forwardingEntityId: "",
			forwardingEntityPassword: "",
			transactionId: "",
			DateAtClient: ""
		   }


		//   }else if (process.env.NODE_ENV == "production"){
		// 	authTokenInfo={
		// 	   terminalId: "SPAR-OXIGEN-WALLET-01",
		// 	   Username: "spar.wallet",
		// 	   Password: "Spar.wallet@123",
		// 	   forwardingEntityId: "sparuser",
		// 	   forwardingEntityPassword: "f3568e8e33d4ec96",
		// 	   transactionId: uuidv4(),
		// 	   DateAtClient: moment().format("YYYY-MM-DD HH:mm:ss")
		// 	  }
			//  }else{
			// 	authTokenInfo={
			// 	terminalId: "spar-oxigen-pos1",
			// 	Username: "oxigen.user",
			// 	Password: "spar@123",
			// 	forwardingEntityId: "com.spar",
			// 	forwardingEntityPassword: "com.spar",
			// 		transactionId: uuidv4(),
			// 		DateAtClient: moment().format("YYYY-MM-DD HH:mm:ss")
			//  }
			// }

	   const authTokenRequest={
		requestHeader:requestHeader,
		authTokenInfo:authTokenInfo,
		deviceInfo:this.props.deviceInfo

		
	   }
	   
	   const qcConnectorUrl='/api/v1/qc/authTokenChecking';


	   const cardInfo={
		   cardNumber:this.props.cardNumber,
		   cardPin:this.state.cardPin
	   }
  
	   if(this.state.cardPin.length==6 && !isNaN(this.state.cardPin)){

	   console.log("cardInfo:::",JSON.stringify(cardInfo));


	   qcConnectorService.checkAuthToken(qcConnectorUrl,authTokenRequest,routePage,cardInfo,this.props.phone);
	   }
	   else{
		   this.setState({
			   displayMessage:'Please enter valid pin no.'
		   })
		   setTimeout(()=>{
			   this.setState({
				displayMessage:''
			   })
			  
		   }
		   ,3000);
	   }
     }
     render(){
		 console.log("process env..",process.env.NODE_ENV+' '+process.env.NODE_TLS_REJECT_UNAUTHORIZED );

		
return(
	<>
    			<div class="innercard">
					<div class="head">
						<h3>Verify Gift Card</h3>
						<button type="button" class="close" onClick={this.closeModal} data-toggle="tooltip" title="Close">close</button>
						<button type="button" class="back"  onClick={this.addGiftCardSection} data-toggle="tooltip" title="Back">back</button>
					</div>
					
					<div class="giftdtlshow">
						<div class="giftsts">
							<div class="row">
								<div class="col-12 col-sm-7 col-md-8 col-xl-9">
									<div class="giftamnt">
										<i></i>



										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails!=null && 
										    this.props.walletServiceResponse.walletDetails.giftcard!=null && 	
											<h6>Gift Card Balance 
											<span>₹ {this.props.walletServiceResponse.walletDetails.giftcard.totalSubWalletBalance}</span></h6>
										}
										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails!=null && 
										    this.props.walletServiceResponse.walletDetails.giftcard==null && 	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}
										{this.props.walletServiceResponse!=null &&
										   this.props.walletServiceResponse.walletDetails==null &&	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}
										{this.props.walletServiceResponse==null &&
										  	
											<h6>Gift Card Balance 
											<span>₹ 0.00</span></h6>
										}



										{/* <h6>Gift Card Balance <span>₹ {this.props.walletServiceResponse.giftcard.totalSubWalletBalance}</span></h6> */}
									</div>
								</div>
								
								<div class="col-12 col-sm-5 col-md-4 col-xl-3">
								<div class="giftamnt gbdr">
								{this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
													   this.props.walletServiceResponse.walletDetails.giftcard.subSections!=null &&
													   <h6>Total Cards <span>
											    			{this.props.walletServiceResponse.walletDetails.giftcard.subSections.length}</span></h6>
   										    }	
											{this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard!=null &&
													   this.props.walletServiceResponse.walletDetails.giftcard.subSections==null &&
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    } 
											   {this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails!=null &&
											   		this.props.walletServiceResponse.walletDetails.giftcard==null &&
													 
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   

											   {this.props.walletServiceResponse!=null &&
											   this.props.walletServiceResponse.walletDetails==null &&
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   	

											     {this.props.walletServiceResponse==null && 
													   <h6>Total Cards <span>
											    			0</span></h6>
   										    }   			
									</div>
								</div>
							</div>
						</div>
						
						<div class="addgiftcard">
							<div class="giftcardform">						
								<p>Enter the PIN of Gift Card you wish to add. <br/>Gift Card: <span>{this.props.cardNumber}</span></p>
								
								<form onSubmit={this.giftCardVerification}> 
									<div class="form-floating">
										
										<input type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
										class="form-control" id="otp" placeholder="OTP" onChange={this.pinChange}/>
										<label for="otp">Enter PIN</label>
										
										{this.state.displayMessage!='' &&
										<div class='valid-error'>
										{this.state.displayMessage}
										</div>
	 									}
										<div class="valid-error">
										{this.props.errorMessage!='' &&
										<div class='valid-error'>
										{this.props.errorMessage}
										</div>
	 									}

									
										</div>
									</div>
									{this.state.buttonVisible==true &&
									<button type="submit" class="btn primary" >Submit </button>
	 							    }
									 {this.state.buttonVisible==false &&
									<button type="submit" disabled class="btn primary" >Submit <span class="spinner-border spinner-border-sm"></span></button>
	 							    }
								</form>
							</div>							
						</div>
					</div>
				</div>	

</>)

}
}


const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},
	miscReduce:{cardNumber,cardPin,phone,deviceInfo
	,qcauthToken}},

	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,cardNumber,cardPin,phone
		,deviceInfo
		,qcauthToken
});

export default connect(mapStateToProps, null)(GiftCardPinAdd);
