import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import UserService from '../spar-service-impl/userService';
import ApiCallingService from '../api-call-service';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection} from '../../redux/error-message/eror-message.action';
import { showResponse} from '../../redux/response/response.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import Payloads from '../../omts/component/payloads';
import walletServiceImpl from './walletServiceImpl';
import transactionService from './transactionService';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import walletServiceFetch from './walletServiceFetch';
import transactionUpdateService from './transactionUpdateService';
import TransactionService from './transactionService';
import deviceDetails from './deviceDetails';
import { setCompleteOrderId, setOrderServiceResponse } from '../../redux/misc-data/misc-data.action';
class WalletService  extends React.Component {

  checkWalletForBonusClub(url, requestTosend,creditInfoDetails,otpVal,otpRefNo) {
    
      console.log('checkWallet service Request',JSON.stringify(requestTosend));
          
      var errorMessage='';
      store.dispatch(showErrorMessageSection(errorMessage));
      store.dispatch(setOrderServiceResponse(requestTosend));
      store.dispatch(showDashBoardComponentSection('loadDetails'));
        



       //walletService data
       var walletUrl='/api/v1/wallet/details';

      
 

        const walletRequestHeader={
          requestType: "GET_WALLET_DETAILS",
          requestId: uuidv4(),
          requesterTimestamp: moment(),
          mobileNumber: creditInfoDetails.phone,
          originalDialogueTraceId:  uuidv4(),
          walletOwner: "SPAR",
          channel: "WEB"
          }
    
       
    
        const wallerServiceRequest={
          requestHeader:walletRequestHeader,
          
          deviceInfo:requestTosend.deviceInfo
        }

        setTimeout(()=>{
        walletServiceFetch.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
        },6000);

        
      

        const transactionRequestHeader={
          requestType: "GET_TRANSACTION_HISTORY",
          requestId: uuidv4(),
           requesterTimestamp: moment(),
           mobileNumber:creditInfoDetails.phone,
           originalDialogueTraceId: null,
           walletOwner: "SPAR",
           channel: "WEB"
           }
      
           
      
           const fetchTransactionHistoryRequest={
            requestHeader:transactionRequestHeader,
            deviceInfo:requestTosend.deviceInfo,
           
           }
      
          
           const fetchTransactionUrl="/api/v1/transaction/history";

        
      
          console.log("Input TRANSACTION request after credit call:::"+JSON.stringify(fetchTransactionHistoryRequest));
          
        setTimeout(()=>{
          TransactionService.fetchAllTransactionData(fetchTransactionUrl,fetchTransactionHistoryRequest);
        },7000);
        
   
      }

      checkWalletFailed(url, requestTosend,creditInfoDetails,otpVal,otpRefNo){
        store.dispatch(setOrderServiceResponse(requestTosend));
        
      }
      
    checkWallet(url, requestTosend,creditInfoDetails,otpVal,otpRefNo) {
      console.log('checkWallet service Request',JSON.stringify(requestTosend));


        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));


 
        store.dispatch(setOrderServiceResponse(requestTosend));

        store.dispatch(showDashBoardComponentSection('loadDetails'));

       

        const walletRequestHeader={
          requestType: "GET_WALLET_DETAILS",
          requestId: uuidv4(),
          requesterTimestamp: moment(),
          mobileNumber: requestTosend.phoneNo,
          originalDialogueTraceId:  uuidv4(),
          walletOwner: "SPAR",
          channel: "WEB"
          }
    
        
    
        const wallerServiceRequest={
          requestHeader:walletRequestHeader,
          deviceInfo:requestTosend.deviceInfo
        }

        var walletUrl='/api/v1/wallet/details';
  
      
        setTimeout(()=>{
        walletServiceFetch.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
        },6000);


  
	  

    const transactionRequestHeader={
      requestType: "GET_TRANSACTION_HISTORY",
      requestId: uuidv4(),
       requesterTimestamp: moment(),
       mobileNumber:requestTosend.phoneNo,
       originalDialogueTraceId: null,
       walletOwner: "SPAR",
       channel: "WEB"
       }
  
  
       const fetchTransactionHistoryRequest={
        requestHeader:transactionRequestHeader,
        deviceInfo:requestTosend.deviceInfo
       }
  
        
       const fetchTransactionUrl="/api/v1/transaction/history";

      console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
     
     
      
          setTimeout(()=>{
            TransactionService.fetchAllTransactionData(fetchTransactionUrl,
              fetchTransactionHistoryRequest);
          },7000);
          
     
      }
      
      
    
   
 
  
   


  fetchWalletApiCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('fetchWalletApiCall service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForWallet(url, requestTosend);
      
      
       console.log("_fetchWalletApiCall response__",JSON.stringify(response));
       if (response === 'error') {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End--generic error';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
     
      else if(response !=undefined 
     
        ){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
      
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

   fetchWalletServiceApiCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('fetchWalletApiCall service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForWalletService(url, requestTosend);
      
      
       console.log("_fetchWalletApiCall response__",JSON.stringify(response));

       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
       }
       else if(response !=undefined){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=='SUCCESS'){
         console.log("api_response for fetchwallet api call",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

 

   expiringFirstApiCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('expiringFirstApiCall service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.expiringFirstCall(url, requestTosend);

      if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
       }
      
    else if(response !=undefined
        && response.responseHeader!=undefined){ 

      console.log('res_code',response.responseHeader.responseCode);
       if(response.responseHeader.responseCode=='SUCCESS'){
         console.log("api_response for expiringFirst api call",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

   addWalletApiCall(url, requestTosend) {
    console.log('inside WalletService ', url);
    //store.dispatch(setLoggedInUserID(username));

    var response = [];
    
     async function run() {
      console.log('wallet service Request',JSON.stringify(requestTosend));

      response=  await ApiCallingService.postCallForWallet(url, requestTosend);
      
      
       console.log("__walletservice response__",JSON.stringify(response));
       if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
       }
      else if(response !=undefined 
       
        ){ 

      console.log('res_code',response.responseCode);
       if(parseInt(response.responseCode)==0){
         console.log("api_response for 0",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));
       
      }
      else{
        console.log("error response!",response);
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      } 
      
    }
   
  }
  run();
  
   }

}

  

export default new WalletService();
