import React from  "react";
import SparPng from "../../assests/images/spar.png";
import Favicon from "../../assests/images/favicon.png"

class FooterComponent extends React.Component{
constructor(){
        super();

    }
render(){
    return(
        <footer>

		<div class="footertxt">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-md-2 d-none d-lg-block">
					<div class="footerlogo"><img src={SparPng} alt="SPAR" class="img-fluid"/></div>
				</div>
				
				<div class="col-12 col-sm">
					<div class="footerlink">
						<h6>Information</h6>
						<ul>
							<li><a href="https://www.sparindia.com/pages/aboutus/pgid-1399865.aspx" target="_blank">About Us</a></li>
							<li><a href="https://www.sparindia.com/pages/privacypolicy/pgid-1399867.aspx" target="_blank">Privacy Policy</a></li>
							<li><a href="https://www.sparindia.com/pages/terms/pgid-1395927.aspx" target="_blank">Terms</a></li>
							<li><a href="https://www.sparindia.com/pages/storelocator/pgid-1399869.aspx" target="_blank">Store Locator</a></li>
						</ul>
					</div>
				</div>
				
				<div class="col-12 col-sm">
					<div class="footerlink">
						<h6>Shopping</h6>
						<ul>
							<li><a href="https://www.sparindia.com/" target="_blank">SPAR Website</a></li>
							<li><a  href="https://www.sparindia.com/pages/faqs/pgid-1399871.aspx" target="_blank">FAQs</a></li>
							<li><a href="https://www.sparindia.com/pages/returnpolicy/pgid-1399873.aspx" target="_blank">Return Policy</a></li>
						</ul>
					</div>
				</div>
				
				<div class="col-12 col-sm">
					<div class="footerlink">
						<h6>Engage</h6>
						<ul>
							<li><a href="https://www.sparindia.com/pages/contactus/pgid-1399875.aspx" target="_blank">Contact Us</a></li>
							<li><a href="https://www.sparindia.com/pages/feedback/pgid-1399877.aspx" target="_blank">Share Feedback</a></li>
							<li><a href="https://sparindia.org.in/" target="_blank">Corporate Site</a></li>
						</ul>
					</div>
				</div>
				
				<div class="col-12 col-md-5 col-lg-4 col-xl-3">
					<div class="downloadapp">
					<img src={Favicon} alt="SPAR" class="img-fluid"/>
						<p>Download the SPAR Wallet Mobile App</p>						
						<a href="https://play.google.com/store/apps/details?id=com.spar.india&hl=en" class="gplay" rel="noopener noreferrer" target="_blank">Play store</a>
					</div>
					
					<div class="connect">Connect with us  <a href="https://www.facebook.com/sparindiahyper" class="fb" target="_blank">Facebook</a> 
					<a href="https://www.twitter.com/sparindiahyper" class="twr" target="_blank">twitter</a> 
					<a href="https://www.instagram.com/sparindiahyper" class="insta" target="_blank">instagram</a></div>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid">	
		<div class="copytxt">
			<p class="float-sm-start">SPAR India &copy; All rights Reserved.</p>
			<p class="float-sm-end">Powered by: <a href="https://www.zro.money/" target="_blank">Electrum Fintech Solutions</a></p>
		</div>
	</div>



</footer>

	

    )
}
}

export default FooterComponent;