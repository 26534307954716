import React from  "react";
import { connect } from "react-redux";
import axios from "axios";
import SparPng from "../../admin/common/images/spar.png";
import { setUploadedFiles } from '../../redux/misc-data/misc-data.action';
import { store } from "../../redux/store";
const httpClient = axios.create();
class SubFooter extends React.Component{
constructor(){
        super();
    }


    componentDidMount(){

     }    
render(){
    return(


                            <div class="col-12">
                                <div class="offers">
                                    <div id="owl-demo" class="owl-carousel">

                                    {/* {
                                    this.props.uploadFile.length==0 &&    
                                    <div></div>
                                    }     */}

                                    {/* {
                                    this.props.uploadFile.length>0 &&    
                                    this.props.uploadFile.map(rsp=>(
                                    <div class="offercard"><img src={`data:image/jpeg;base64,${rsp.file.data}`} alt="SPAR" class="img-fluid"/></div>))
                                    } */}
{/* 

                                        <div class="offercard"><img src={process.env.PUBLIC_URL+"/images/banner1.jpg"} alt="SPAR" class="img-fluid"/></div>
                                        <div class="offercard"><img src={process.env.PUBLIC_URL+"/images/banner2.jpg"} alt="SPAR" class="img-fluid"/></div>
                                        <div class="offercard"><img src={process.env.PUBLIC_URL+"/images/banner3.jpg"} alt="SPAR" class="img-fluid"/></div> */}
                                
                                    </div>
                                </div>
                                </div>
                                    

    )
}
}

const mapStateToProps = ({ 
	miscReduce:{phone,amount,transactionId,
		transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,
		orderServiceResponse,myPoints,deviceInfo,myCouponDetails,uploadFile}}
	) => ({
	phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry,orderServiceResponse,myPoints,
		deviceInfo,myCouponDetails,uploadFile
});

export default connect(mapStateToProps, null)(SubFooter);