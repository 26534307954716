import React, { useState, useEffect } from 'react';

import ApiCallingService from '../../service/api-call-service';
import { connect } from "react-redux";
import {store} from '../../redux/store';

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import moment from 'moment';

import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    
  } from 'react-share';
  import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    EmailShareButton,
  } from 'react-share';

  import { FacebookIcon } from "react-share";
  import { LinkedinIcon } from "react-share";
  import { WhatsappIcon } from "react-share";
  import { EmailIcon } from "react-share";
  import { TwitterIcon } from "react-share";


class LoadDetail extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			showLoginSection:true,
			showOtpSection:false,
			showReferralSection:false,
			showRegisterSection:false,
			shareButtonActive:false,
			shareUrl:"https://www.wallet.sparindia.com"
        }
    }
	

	userProfileView= (event) => {
		event.preventDefault();
		var data='profileSection';
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection(data));
	}

	addMoney= (event) => {
		event.preventDefault();
		var data='addMoneySection';
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection(data));
	}


	notificationDetails= (event) => {
		event.preventDefault();
		var data='notificationSection';
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection(data));
	}


    closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }

	  paymentSubmit= (event) => {
		event.preventDefault();
		store.dispatch(showUserComponentSection('login'));
		store.dispatch(showDashBoardComponentSection('paymentSection'));
	  }

	  print= (event) => {
		window.print();
	  }

	  share=(event)=>{
		console.log('shere clicked');
		if(this.state.shareButtonActive==false){
		this.setState({
			shareButtonActive:true
		})}else{
			this.setState({
				shareButtonActive:false
			})
		}
		
		}
		render(){

		console.log("orderServiceResponse:::",this.props.orderServiceResponse)
		return(
			<>
           <div class="innercard">
		   <div class="head"><h3>Transaction Details  </h3> <button type="button" onClick={this.closeModal} 
			   class="close">close</button>	</div>

					<div class="loadtls">
						<div class="row">
							
							<div class="col-12">
								<div class="txndone">Transation has been done successfuly!</div>
							</div>
						
							<div class="col-12">
								<div class="loadtxn">
									{/* <h5><i class="wplus"></i> SPAR Wallet - Load Amount</h5>
									<h4>Load Amount <span>₹ {parseFloat(this.props.orderServiceResponse.response.amount)}.00</span></h4>
									<h4>Surcharge <span>₹ 0.00</span></h4>
									<h6>Total Amount <span>₹ {parseFloat(this.props.orderServiceResponse.response.amount)}.00</span></h6>
									<p>TXN ID: {this.props.orderServiceResponse.response.txnId}</p>
									<p class="date">{moment(this.props.orderServiceResponse.responseHeader.responseTimestamp).format("DD-MM-YYYY HH:mm:ss")}</p>
									<p class="txnissue">See an issue? Report to us @ <a href="mailto:care@sparindia.com">care@sparindia.com</a>  or Talk to us at <a href="tel:18605009418">18605009418</a></p>
									<div class="actionbtn"> */}

									<h5><i class="wplus"></i> SPAR Wallet - Load Amount</h5>
									<h4>Load Amount <span>₹ {parseFloat(this.props.orderServiceResponse.amount).toFixed(2)}</span></h4>
									<h4>Surcharge <span>₹ 0.00</span></h4>
									<h6>Total Amount <span>₹ {parseFloat(this.props.orderServiceResponse.amount).toFixed(2)}</span></h6>
									<p>TXN ID: {this.props.orderServiceResponse.txnId}</p>
									<p class="date">{moment().format("DD-MM-YYYY HH:mm:ss")}</p>
									<p class="txnissue">See an issue? Report to us @ <a href="mailto:care@sparindia.com">care@sparindia.com</a>  or Talk to us at <a href="tel:18605009418">18605009418</a></p>
									<div class="actionbtn">
										
									
										<button type="button" id="print" class="print" onClick={this.print}>Print</button>
									
										<button type="button" id="share" class="share" onClick={this.share}>share</button>
								
									</div>
									
								</div>
							</div>


													{this.state.shareButtonActive==true && 
                                                                <div class="subtxnshow">
                                                                <div class="txndtls scs">
                                                                <FacebookShareButton url={this.state.shareUrl}>
        
                                                                <FacebookShareCount url={this.state.shareUrl}>
                                                                {shareCount => (
                                                                    <span className="myShareCountWrapper">{shareCount}</span>
                                                                )}
                                                                </FacebookShareCount> 
                                                                <FacebookIcon ></FacebookIcon>
                                                                </FacebookShareButton>
                                                                
                                                                 <WhatsappShareButton url={this.state.shareUrl}>
                                                                 <WhatsappIcon ></WhatsappIcon>
                                                                 </WhatsappShareButton>

                                                                 <EmailShareButton url={this.state.shareUrl}>
                                                                 <EmailIcon ></EmailIcon>
                                                                 </EmailShareButton>
                                                                 </div>
                                                                 </div>
                                                                }
						</div>						
					</div>
					
				</div>		

	
</>)

}
}

export default LoadDetail;
