import React from "react";

import {store} from "../../redux/store";

import SecureLogosPng from "../../assests/images/secure-logos.png";

import {showDashBoardComponentSection} from "../../redux/div-show-and-hide/div-show-and-hide.action";
class Paymetnt extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
	
        }
    }
     componentDidMount() {
	 	
    }

	closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	  }

	  addMoneyCloseModal= (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('addMoneySection'));
	  }
      render(){
    
          return(
            <>
          <div>

                <div class="loadcard">
					<h3>Load Money Review 
                        <a href="#" class="close" onClick={this.closeModal}>close</a> 
                        <a href="#" class="back" onClick={this.addMoneyCloseModal} >back</a>
                    </h3>
					<div class="payment">		
						<div class="row">
							<div class="col-12">
								<div class="availblnc">
									<h4>₹ 5000.00</h4>
									<i class="wplus"></i>
									<p>SPAR Wallet - Load Amount</p>
								</div>
							</div>
							
							<div class="col-12">
								<h5>Payment through:</h5>
							</div>
							
							<div class="col-12">
								<div class="paybox">
									<div class="row">
										<div class="col-12 col-sm-4">
											<div class="paytab">
												<ul>
													<li class="active"><label><input class="form-check-input" type="radio" name="pay" id="cc"/> Debit Card</label></li>
													<li><label><input class="form-check-input" type="radio" name="pay" id="cc"/> Credit Card</label></li>
													<li><label><input class="form-check-input" type="radio" name="pay" id="cc"/> Net Banking</label></li>
													<li><label><input class="form-check-input" type="radio" name="pay" id="cc"/> UPI Payment</label></li>
												</ul>
											</div>
										</div>
										
										<div class="col-12 col-sm-8">
											<div class="payfield">
												<div class="row">
													<div class="col-12">
														<div class="payimg"> 
														{/* code to be fixed 
														<img src={PaymentPng} alt="SPAR" class="img-fluid"/> */}
                                                            </div>				
													</div>
													<div class="col-12">
														<div class="form-floating">
															<input type="text" value="" class="form-control" id="dbt" placeholder="Card Number"/>
															<label for="dbt">Debit Card Number</label>
														</div>
													</div>
													<div class="col-7 col-md-8">
														<div class="form-floating clndr">
															<input type="text" value="" class="form-control" id="dbtvalid" placeholder="MM/YYYY"/>
															<label for="dbtvalid">Valid From</label>
														</div>
													</div>
													<div class="col-5 col-md-4">
														<div class="form-floating cvv">
															<input type="text" value="" class="form-control" id="dbtcvv" placeholder="CVV"/>
															<label for="dbtcvv">CVV</label>
														</div>
													</div>
													
													<div class="col-12">
														<div class="form-floating name">
															<input type="text" value="" class="form-control" id="dbtname" placeholder="Name"/>
															<label for="dbtname">Card Holder Name</label>
														</div>
													</div>
													
													<div class="col-12">
														<div class="form-check">
															<input class="form-check-input" type="checkbox" value="" id="savedc" checked/>
															<label class="form-check-label" for="savedc">Save card details</label>
														</div>														
													</div>
													
													<div class="col-12">
														<button type="submit" class="btn primary" onClick="window.location.href='load-detail.html'">
                                                            Pay Now</button>
													</div>
												</div>
												
											</div>
										</div>
									</div>
								</div>						
							</div>
							
							<div class="col-12">
								<div class="secure">
                                <img src={SecureLogosPng} alt="SPAR" class="img-fluid"/>
								</div>						
							</div>
						</div>						
					</div>
					
				</div>	       
            </div>
            
            
            </>

		
          );
    }
}
export default Paymetnt;