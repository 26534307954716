import React from "react";
import history from '../../auth/history';

import UserService from "../../service/spar-service-impl/userService";
import WalletService from "../../service/spar-service-impl/walletService";

import TransactionService from "../../service/spar-service-impl/transactionService";


import { store } from '../../redux/store';
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessageSection } from "../../redux/error-message/eror-message.action";
import myCouponService from "../../service/spar-service-impl/myCouponService";
import getMyPointsService from "../../service/spar-service-impl/getMyPointsService";
import { showDashBoardComponentSection } from "../../redux/div-show-and-hide/div-show-and-hide.action";
import CampaignService from "../../service/spar-service-impl/campaignService";
import { setAuth } from './../../redux/auth/auth.action';
import fetchUserDetails from "../../service/spar-service-impl/fetchUserDetails";

class WelCome extends React.Component{
    constructor(props){
        super(props);
        this.state={
			phoneNo: '',
			response: null,
			captchaDone: false,
			username: '',
			dateOfBirth: Date,
			emailId: '',
			pincode: '',
			gender: '',
			selectedOption:'',
			buttonVisible:true
        }
    }
     componentDidMount() {
	 	
     }

     loadDashBoardComponents = (event) => {	
		 store.dispatch(setAuth(true));
		event.preventDefault();
		this.setState({
			buttonVisible:false
		})
		setTimeout(()=>{
			this.setState({
				buttonVisible:true
			})	
		},1000);
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
		store.dispatch(showErrorMessageSection(''));

		
		// var userResponse=[];
		
		// var walletServiceResponse=[];
		

		// const routePageProfile={       
        //     origincomponent:'welcome',
        //     destinationcomponent:'dashBoard',
        //     callBackMethod:'POST',
        //     dashBoardContentType:'user',
        //     responseContentType:'dashBoardData',
        //     failureComponent:'otp'
        //   }
		
		// //userProfile Data


		const requestHeader={
			requestType: "GET_USER_PROFILE",
			requestId: uuidv4(),
			requesterTimestamp: moment(),
			 mobileNumber:this.props.phone ,
			 originalDialogueTraceId: null,
			 walletOwner: "SPAR",
			 channel: "WEB"
		}


		const request={
			requestHeader:requestHeader,
			deviceInfo:this.props.deviceInfo
			
		}

		var userFetchUrl='/api/v1/user/profile';
		
		console.log('userprofile request'+JSON.stringify(request));
		fetchUserDetails.fetchUserCall(userFetchUrl,request);
		// console.log('userprofile response'+JSON.stringify(userResponse));

		// //walletService data
		// var walletUrl='/api/v1/wallet/details';

		// const walletRequestHeader={
		// 	requestType: "GET_WALLET_DETAILS",
		// 	requestId: uuidv4(),
		// 	requesterTimestamp: moment(),
		// 	mobileNumber: this.props.phone,
		// 	originalDialogueTraceId:  uuidv4(),
		// 	walletOwner: "SPAR",
		// 	channel: "WEB"
		//   }

		// const wallerServiceRequest={
		// 	requestHeader:walletRequestHeader,
		// 	deviceInfo:this.props.deviceInfo
		// }

		// WalletService.fetchWalletServiceApiCall(walletUrl,wallerServiceRequest);
	

		// const requestHeaderForFetchWallet={
        //     requestType: "CREATE_ORDER",
        //     requestId: uuidv4(),
        //      requesterTimestamp: moment(),
        //      mobileNumber:this.props.phone,
        //     originalDialogueTraceId: null,
        //      walletOwner: "SPAR",
        //      channel: "WEB"
        // 	}

		// //myCoupon service

	    // const myCouponRequestHeader={
		// 	  requestType: "GET_WALLET_DETAILS",
		// 	  requestId: uuidv4(),
		// 	   requesterTimestamp: moment(),
		// 	   mobileNumber:this.props.phone,
		// 	  originalDialogueTraceId: null,
		// 	   walletOwner: "SPAR",
		// 	   channel: "WEB"
		// }

		// const myCouponUrl='/api/v1/landmark/rewards/mycoupons';
   
        // myCouponService.fetchMyCoupons(myCouponUrl,myCouponRequestHeader);

		// //get My points service

		// const getMyPointsRequestHeader={
		// requestType: "GET_WALLET_DETAILS",
		// requestId: uuidv4(),
		//  requesterTimestamp: moment(),
		//  mobileNumber:this.props.phone,
		//  originalDialogueTraceId: null,
		//  walletOwner: "SPAR",
		//  channel: "WEB"
  		// }

		// const getMyPointsRequest={
		// 	requestHeader:getMyPointsRequestHeader
		// }

		// const myPointsUrl='/api/v1/landmark/customer/points';

		// getMyPointsService.fetchMyPoints(myPointsUrl,getMyPointsRequest);	


		//  //new userTransactionData
	  

		//  const transactionRequestHeader={
		// 	requestType: "GET_TRANSACTION_HISTORY",
		// 	requestId: uuidv4(),
		// 	 requesterTimestamp: moment(),
		// 	 mobileNumber:this.props.phone,
		// 	 originalDialogueTraceId: null,
		// 	 walletOwner: "SPAR",
		// 	 channel: "WEB"
		//    }
	
		

		//    const fetchTransactionHistoryRequest={
		// 	requestHeader:transactionRequestHeader,
		// 	deviceInfo:this.props.deviceInfo,
			
		//    }
	
			  
		//    const fetchTransactionUrl="/api/v1/transaction/history";
		// 	console.log("Input TRANSACTION request:::"+JSON.stringify(fetchTransactionHistoryRequest));
			
		// 	TransactionService.fetchAllTransactionData(fetchTransactionUrl,fetchTransactionHistoryRequest);

		// 	//fetch campaign
		// 	const campaignUrl='/api/v1/subwallet/bonuscash/campaign/get';
		// 	CampaignService.fetchCampaigns(campaignUrl,'');


		// 	 //expiring first		
		// 	const expiringFirstRequestHeader={
		// 		  requestType: "GET_EXPIRING_FIRST",
		// 		  requestId: uuidv4(),
		// 		  requesterTimestamp: moment(),
		// 		  mobileNumber: this.props.phone,
		// 		  originalDialogueTraceId:  uuidv4(),
		// 		  walletOwner: "SPAR",
		// 		  channel: "WEB"
		// 	  }
			  
		//   	const expiringFirstRequest={
		// 		  requestHeader:expiringFirstRequestHeader,
		// 		  deviceInfo:this.props.deviceInfo
		// 	  }
	  
		//   const expiringFirstUrl='/api/v1/wallet/expiringFirst';
		
		//   console.log('expiringFirstRequest.....',JSON.stringify(expiringFirstRequest));
		// 	  WalletService.expiringFirstApiCall(expiringFirstUrl,expiringFirstRequest);

			// history.push('/user-dashboard');
			// // setTimeout(()=>{
			// history.go();
			// },1100);
		
	 }

      render(){
        
       
          return(
            <div>

		{(this.state.buttonVisible==true) &&
				<button type="submit" class="btn primary" onClick={this.loadDashBoardComponents}>Okay</button>
	  				}
					{(this.state.buttonVisible==false) &&
					<button type="submit" disabled class="btn primary"> Okay  <span class="spinner-border spinner-border-sm"></span></button>	
	  				}


					</div>
          );
      } 
    }


const mapStateToProps = (
	{ showAddHide:{viewDashBoardSection},errorMessageReduce:{errorMessage},
	responseReduce:{responseDetailsVal,dashBoardResponseDetails,
		userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse},
	leadingOverLay:{blockScreenConfirm},
	ordeDetailsReduce:{orderId},

	miscReduce:{phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
	,deviceInfo}}
	) => ({
		viewDashBoardSection,errorMessage,responseDetailsVal,dashBoardResponseDetails,userResponse,walletResponse,transactionResposne,walletServiceResponse,userProfileResponse
		,blockScreenConfirm,orderId,phone,amount,transactionId,transactionTimeStamp,cardNumber,cardPin,qcauthToken,giftCardBalnceEnquiry
		,deviceInfo
});

export default connect(mapStateToProps, null)(WelCome);