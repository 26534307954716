import history from '../../auth/history';
import React from 'react'
import AclService from '../service-impl/ACLService';
import ApiCallingService from '../api-call-service';
import WalletService from '../spar-service-impl/walletService';
import { setToken ,isAuthenticated} from '../../redux/token/token.action';
//import { setCurrentUser } from '../../redux/user/user.actions';
import { setCurrentUser } from "../../redux/admin-user/user.action";
import { setIsAdmin,setUserRole } from '../../redux/admin/admin.action';
import { setLoggedInUserID,setUserType ,setLoginUserName ,setUserLastLoginTime} from '../../redux/user/user.action';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import { resetRole } from '../../redux/role/role.action';
import { resetDivShowAndHide } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showErrorMessageSection, showOtpErrorMessage} from '../../redux/error-message/eror-message.action';
import { showUserComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection} from '../../redux/div-show-and-hide/div-show-and-hide.action';
import SystemErrorCodes from '../../omts/component/systemErrorCodes';
import systemErrorCodes from '../../omts/component/systemErrorCodes';
import RenderComponent from '../../omts/component/renderComp';
import { render } from '@testing-library/react';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import otpService from './otpService';
import myCouponService from './myCouponService';
import getMyPointsService from './getMyPointsService';
import TransactionService from './transactionService';
import { setErrMsg, setProfile } from '../../redux/auth/auth.action';

class FetchUserService {


fetchUserCall(url, requestTosend,routePage) {
    store.dispatch(setProfile(false));
    console.log('inside UserService fetchUserCall laoding ', url);
    
  
    var response = [];
    
     async function run() {

      response=  await ApiCallingService.fetchUserCall(url, requestTosend,routePage);
      
      console.log("__response__",JSON.stringify(response));
      if (response === 'error'||response==undefined) {
        console.log(response);
        
        var errorMessage='Somthing went wrong we are looking at our End';
        store.dispatch(showErrorMessageSection(errorMessage));
        
      }
      if(response !=undefined 
        //&& response.status==200
      ){ 

      console.log('res_code',response.responseHeader.responseCode);

 
       if(response.responseHeader.responseCode=='SUCCESS'){
         console.log("api_response for USER PROFILE",response);
        var errorMessage='';
        store.dispatch(showErrorMessageSection(errorMessage));

        history.push('/user-dashboard');
        history.go();
    
      
    }else{
      //alert(JSON.stringify(response))
      store.dispatch(setErrMsg(response.responseHeader.responseMsg));
      history.push('/403');
      history.go();
    }
  }
   
  }
  run();
  
}
}
export default new FetchUserService();