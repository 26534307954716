class SystemErrorCodes{
   
    componentDidMount() {
        
   }

 syetemErrorCodesDetails= [
    {
    id: 901,
    details:"Customer Doesn’t Exit"
    },
    {
    id: 11,
    details: "Unsupported OTP Type",
    },
   {
    id: 12,
    details: "Unique OTP could not be generated",
    },
    {
     id:21,
     details: " Customer could not be added",
    },
            
    {
    id:22,
    details: " Customer already exists",
    }, 
    {		
     id: 902,
    details:" Incorrect OTP"
    },
    {		
     id: 903,
    details:" OTP data mismatch Any member of the OTP Data object structure"
    },
    {		
     id: 903,
    details:" OTP data mismatch Any member of the OTP Data object structure does not match"
    },
    {		
     id: 904,
    details:" Store data validation failure"
    },
    {		
     id: 901,
    details:" Customer doesn't exist"
    }
    ]

}

export default new SystemErrorCodes()