import React, { useState, useEffect } from 'react';

import SparServiceImpl from '../../service/spar-service-impl/SparService';
import { connect } from "react-redux";
import { store } from '../../redux/store';
import Pagination from "react-js-pagination";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { showUserComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import { showDashBoardComponentSection } from '../../redux/div-show-and-hide/div-show-and-hide.action';
import deviceDetails from '../../service/spar-service-impl/deviceDetails';
import { orderErrorMessage, orderTrnasferMessage } from '../../redux/error-message/eror-message.action';
import { showBonusCashResponse } from '../../redux/response/response.action';

class BonusClubSubSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNo: '',
			amount: 0,
			firstName: this.props.userProfileResponse.customerInfo.firstName,
			lastName: this.props.userProfileResponse.customerInfo.lastName,
			surl: '',
			phone: this.props.userProfileResponse.customerInfo.mobileNumber,
			key: '',
			hash: '',
			curl: '',
			furl: '',
			txnId: '',
			productInfo: '',
			submitAmount: this.props.amount,
			email: this.props.userProfileResponse.customerInfo.email,
			loading: false,
			isActive: false,
			buttonVisible: true,
			displayMessage: '',
			transactionAmount: '5000',

			activePage: 1,
			zResponse: [],
			tResponse: (this.props.bonushCashResponse == undefined || this.props.bonushCashResponse == null) ? [] : this.props.bonushCashResponse.slice(0, 6),

			sResposne: [],
			startIndex: 0,
			endIndex: 6
		}
	}

	change(e, key) {

		console.log('curval,,', e.currentTarget.value);
		// this.setState({
		// 	transcationAmount:e.currentTarget.value
		// })
		const newState = {};
		newState[key] = e.currentTarget.value;
		this.setState(newState)
	}

	submit = (event) => {
		event.preventDefault();
		console.log("send via AJAX", this.state.transactionAmount);
	}
	submitVal(transactionValue) {
		console.log('transactionValue', transactionValue);
		console.log('entered here');


	}

	closeModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('trasactionHistory'));
	}

	componentDidMount() {
	

		store.dispatch(orderErrorMessage(''));
		store.dispatch(orderTrnasferMessage(''));
	}

	backModal = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('sparCashSection'));
	}

	bonusClubSection = (event) => {
		event.preventDefault();
		store.dispatch(showDashBoardComponentSection('bonusClubSection'));
	}


	test(e, value) {
		console.log("event val...", e);

		console.log('entering methods......');

		console.log('value...', value);
	}


	addMoney = (event, transactionAmount) => {
		event.preventDefault();
		var allow = true;
		this.setState({
			displayMessage: ''
		})

		if (this.props.walletServiceResponse != null &&
			this.props.walletServiceResponse.walletDetails != null &&
			this.props.walletServiceResponse.walletDetails.totalWalletBalance != null) {
			var remainingAmnt = 10000 - transactionAmount;
			if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) >= 10000) {
				allow = false;
			}
			if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) < 10000) {

				if (parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) > remainingAmnt) {
					allow = false;
					remainingAmnt = 10000 - parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance)
				}
			}

			if (allow == true) {
				console.log('entering methods......');

				var firstNameArray = this.props.userProfileResponse.customerInfo.firstName.split(" ");
				var firstname = firstNameArray.join();
				console.log('value...', transactionAmount);
				this.setState({
					buttonVisible: false,
					active: true,
					displayMessage: "You will be redirected to the transaction screen."

				})
				setTimeout(() => {
					this.setState({
						displayMessage: '',
						buttonVisible: true
					})
				}, 20000);

				// store.dispatch(showUserComponentSection('login'));
				const request = {
					phoneNo: this.props.userProfileResponse.customerInfo.mobileNumber,
					amount: transactionAmount,
					firstName: firstname,
					lastName: this.props.userProfileResponse.customerInfo.lastName,
					surl: "",
					phone: this.props.userProfileResponse.customerInfo.mobileNumber,
					key: "",
					hash: "",
					curl: "",
					furl: "",
					txnId: "",
					productInfo: "BonusClub",
					submitAmount: transactionAmount,
					email: this.props.userProfileResponse.customerInfo.email == '' ? 'nouser@email.com' : this.props.userProfileResponse.customerInfo.email
				}



				console.log('createOrder reuest', JSON.stringify(request));

				const routePage = {
					origincomponent: 'addMoney',
					destinationcomponent: 'addMoney',
					callBackMethod: 'POST',
					dashBoardContentType: 'dashBoard',
					responseContentType: 'userData'
				}

				const requestHeaderCreateOrder = {
					requestType: "CREATE_ORDER",
					requestId: uuidv4(),
					requesterTimestamp: moment(),
					mobileNumber: this.props.phone,
					originalDialogueTraceId: null,
					walletOwner: "SPAR",
					channel: "WEB"
				}
				const orderInfo = {
					itemType: 'Bonus Club',
					itemDescription: 'Spar Cash Wallet',
					phoneNo: this.props.userProfileResponse.customerInfo.mobileNumber,
					amount: transactionAmount,
					firstName: firstname,
					lastName: this.props.userProfileResponse.customerInfo.lastName,
					surl: "",
					phone: this.props.userProfileResponse.customerInfo.mobileNumber,
					key: "",
					hash: "",
					curl: "",
					furl: "",
					txnId: "",
					productInfo: "BonusClub",
					submitAmount: transactionAmount,
					email: this.props.userProfileResponse.customerInfo.email == '' ? 'nouser@email.com' : this.props.userProfileResponse.customerInfo.email
				}

				const deviceInfoM = {
					deviceId: this.props.deviceInfo.deviceId,
					deviceIdValue: this.props.deviceInfo.deviceIdValue,
					osName: this.props.deviceInfo.osName,
					osVer: this.props.deviceInfo.osVer,
					ipAddr: this.props.deviceInfo.ipAddr,
					browserVer: this.props.deviceInfo.browserVer,
					mobileNumber: this.props.userProfileResponse.customerInfo.mobileNumber
				}


				const requestForCreateOrder = {
					requestHeader: requestHeaderCreateOrder,
					orderInfo: orderInfo,
					deviceInfo: deviceInfoM
				}




				console.log("Bonus club request::", requestForCreateOrder);

				const creaateOrderUrl = '/api/v1/pg/createOrder';
				setTimeout(() => {
					SparServiceImpl.apiCallForBoNusClub(creaateOrderUrl, requestForCreateOrder, '', routePage,
						this.props.userProfileResponse.customerInfo.mobileNumber
						, this.props.otpVal, this.props.otpRefNo);
				}, 2000);

				setTimeout(() => {
					store.dispatch(showDashBoardComponentSection('trasactionHistory'));
				}, 5000);

			} else {
				if (remainingAmnt > 0 && parseInt(this.props.walletServiceResponse.walletDetails.totalWalletBalance) < 10000) {
					this.setState({
						displayMessage: 'Exceeded the maximum wallet balance.'
					})
				} else {
					this.setState({
						displayMessage: 'Exceeded the maximum wallet balance.'
					})
				}

			}
		}
	}

	handlePageChangeDefault(pageNumber) {
		console.log('Inside handlePageChangeDefault');
		console.log(`active page is ${pageNumber}`);
		this.setState({ activePage: pageNumber });

		this.setState({
			startIndex: (pageNumber - 1) * 6,
			endIndex: (pageNumber) * 6,
			tResponse: this.props.bonushCashResponse.slice((pageNumber - 1) * 6, (pageNumber) * 6)
		})
	}
	render() {
		//console.log('campaign:::::', this.props.campaigns);
		return (
			<>
				<div class="innercard">
					<div class="head"><h3>SPAR Bonus </h3> <button type="button" onClick={this.closeModal}
						class="close">close</button>
						<button type="button" class="back" onClick={this.backModal} data-toggle="tooltip" title="Back">back</button>
					</div>


					<div class="bonushow">

						<div class="bonusdtl">
							<div class="row">
								<div class="col-10 col-md-11">
									<div class="bonusblc">
										<i></i>
										<h6>My Bonus:




											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails != null &&
												this.props.walletServiceResponse.walletDetails.bonusCash != null) &&
												<span>₹ {parseFloat(this.props.walletServiceResponse.walletDetails.bonusCash.totalSubWalletOwnerBalance).toFixed(2)}</span>
											}
											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails != null &&
												this.props.walletServiceResponse.walletDetails.bonusCash == null) &&
												<span>₹ 0.00</span>
											}
											{(this.props.walletServiceResponse != null &&
												this.props.walletServiceResponse.walletDetails == null) &&
												<span>₹ 0.00</span>
											}
											{(this.props.walletServiceResponse == null) &&

												<span>Not Available</span>
											}
										</h6>
									</div>
								</div>

								<div class="col-2 col-md-1">
									<div class="bnsbtn"><button type="button" class="add"
										onClick={this.bonusClubSection} data-toggle="tooltip" title="Add">Add</button></div>
								</div>



							</div>
						</div>

						<div class="bnslist">

							<div class="row">
								{


									this.props.bonushCashResponse != undefined &&
									this.props.bonushCashResponse != null &&
									this.props.bonushCashResponse != '' &&
									this.props.bonushCashResponse.length >= 0 &&
									this.props.bonushCashResponse.slice(this.state.startIndex, this.state.endIndex).map(respone => (


										parseInt(respone.balance) == 0 ?
											<div class="col-12 col-sm-6 col-md-4">
												<div class="bnsview used">

													<h3><span>Available balance</span><br />₹ {parseFloat(respone.balance).toFixed(2)} </h3>
													<h4>Bonus amount: <span>₹ {parseFloat(respone.value).toFixed(2)}</span></h4>
													<h5>{respone.bonusCashType}</h5>
													<h6 class="text-truncate">#{respone.id}</h6>
													<p class="date">{(respone.startingFrom).split(" ")[0]}</p>
													<p>Validity <span>

														{(respone.expiringOn == '' || respone.expiringOn == null) ? 'NA' :
															moment(respone.expiringOn, 'DD-MM-YYYY HH:mm:ss') < new Date() ? 'Expired' :
																(respone.expiringOn).split(" ")[0]}

													</span></p>

												</div>
											</div> :
											(moment(respone.expiringOn, 'DD-MM-YYYY HH:mm:ss') < new Date()) ?
												<div class="col-12 col-sm-6 col-md-4">
													<div class="bnsview exprd">

														<h3><span>Available balance</span><br />₹ {parseFloat(respone.balance).toFixed(2)} </h3>
														<h4>Bonus amount: <span>₹ {parseFloat(respone.value).toFixed(2)}</span></h4>
														<h5>{respone.bonusCashType}</h5>
														<h6 class="text-truncate">#{respone.id}</h6>
														<p class="date">{(respone.startingFrom).split(" ")[0]}</p>
														<p>Validity <span>

															{(respone.expiringOn == '' || respone.expiringOn == null) ? 'NA' :
																moment(respone.expiringOn, 'DD-MM-YYYY HH:mm:ss') < new Date() ? 'Expired' :
																	(respone.expiringOn).split(" ")[0]}

														</span></p>

													</div>
												</div>

												:



												<div class="col-12 col-sm-6 col-md-4">
													<div class="bnsview">

														<h3><span>Available balance</span><br />₹ {parseFloat(respone.balance).toFixed(2)} </h3>
														<h4>Bonus amount: <span>₹ {parseFloat(respone.value).toFixed(2)}</span></h4>
														<h5>{respone.bonusCashType}</h5>
														<h6 class="text-truncate">#{respone.id}</h6>
														<p class="date">{(respone.startingFrom).split(" ")[0]}</p>
														<p>Validity <span>

															{(respone.expiringOn == '' || respone.expiringOn == null) ? 'NA' :
																moment(respone.expiringOn, 'DD-MM-YYYY HH:mm:ss') < new Date() ? 'Expired' :
																	(respone.expiringOn).split(" ")[0]}

														</span></p>

													</div>
												</div>



									))
								}



							</div>

							<div class="pager">

								{this.props.bonushCashResponse != undefined &&
									this.props.bonushCashResponse != null &&
									this.props.bonushCashResponse != '' &&
									this.props.bonushCashResponse.length >= 0 ?

									<div>

										<p>Showing {this.props.bonushCashResponse.length} entries</p>
										<Pagination
											activePage={this.state.activePage}
											itemsCountPerPage={6}
											totalItemsCount={this.props.bonushCashResponse.length}
											pageRangeDisplayed={5}
											onChange={this.handlePageChangeDefault.bind(this)}
											className="pagination"
											linkClass="page-link"
											itemClass="page-item"
										/>
									</div>
									:
									<div>
										<p>Showing {(this.props.bonushCashResponse != undefined &&
											this.props.bonushCashResponse != null &&
											this.props.bonushCashResponse != '') ?
											this.props.bonushCashResponse.length : 0} entries</p>

									</div>
								}


							</div>
						</div>
					</div>
				</div>

			</>)

	}
}

// export default MyCouponsComponent;

const mapStateToProps = ({ showAddHide: { viewDashBoardSection }, errorMessageReduce: { errorMessage },
	responseReduce: { responseDetailsVal, dashBoardResponseDetails,
		userResponse, walletResponse, transactionResposne, walletServiceResponse, userProfileResponse, fetchTransactionResponse
		, bonushCashResponse },
	leadingOverLay: { blockScreenConfirm },
	ordeDetailsReduce: { orderId },

	miscReduce: { phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints
		, deviceInfo, myCouponDetails } }
) => ({
	viewDashBoardSection, errorMessage, responseDetailsVal, dashBoardResponseDetails, userResponse, walletResponse, transactionResposne, walletServiceResponse,
	userProfileResponse, orderServiceResponse, fetchTransactionResponse,
	bonushCashResponse,
	blockScreenConfirm, orderId, phone, amount, transactionId, transactionTimeStamp, cardNumber, cardPin, qcauthToken, giftCardBalnceEnquiry, orderServiceResponse, myPoints,
	deviceInfo, myCouponDetails
});

export default connect(mapStateToProps, null)(BonusClubSubSection);